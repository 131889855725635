import { API_BASEURL } from '../constants/global';
import { post } from '../util/httpClient';

export const facebookSignInFromApp = async (dataToSend) => {
  //let url = `${API_BASEURL}/facebook-login`;
  let url = `${API_BASEURL}/facebook-web-login`;
  return post(url, dataToSend);
};

export const googleSignInFromApp = async (dataToSend) => {
  // let url = `${API_BASEURL}/google-login`;
  let url = `${API_BASEURL}/google-web-login`;
  return post(url, dataToSend);
};
