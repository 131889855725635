import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import withAuthorization from '../../../Session/withAuthorization';
import { CF_USER } from '../../../constants/global';
import { selectUser, setMenu } from '../../../actions/user/userSlice';

const Settings = (props) => {
    let history = useHistory();
    const userData = useSelector(selectUser);
    let dispatch = useDispatch();

    const setUserMenu = (menu) => {
        dispatch(setMenu(menu));
    };

    const goToPage = (path, data) => {
        let menu = path.split('/')[1];
        setUserMenu(menu);
        history.push({
            pathname: path,
            // search: '?query=abc',
            state: data,
        });
    };

    return (
        <div className="content-wrapper">
            <section className="unlock-membership">
                <div className="container">
                    <div className="row">
                        {!userData.is_subscribed ? (
                            <div className="col-lg-12">
                                <div className="unlock-bg">
                                    <div className="figcaption">
                                        <h1>Get Fluent Faster!</h1>
                                        <p className="mb-0">Unlock Your Membership!</p>
                                    </div>

                                    <a href="JavaScript:Void(0);" onClick={() => goToPage('/subscription', {})}>
                                        I'm Ready
                                    </a>
                                </div>
                            </div>
                        ) : null}

                        <div className="col-lg-12">
                            <div className="settings">
                                <ul>
                                    <li>
                                        <figure>
                                            <img src="images/ic12.png" />
                                        </figure>
                                    </li>
                                    <li>
                                        <strong>Billing info</strong>
                                    </li>
                                    <li onClick={() => goToPage('/billingInfo')}>
                                        <a href="javascript:void(0);">
                                            <img src="images/right-arrow.png" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="settings">
                                <ul>
                                    <li>
                                        <figure>
                                            <img src="images/ic13.png" />
                                        </figure>
                                    </li>
                                    <li>
                                        <strong>User Activity</strong>
                                    </li>
                                    <li onClick={() => goToPage('/user_activity')}>
                                        <a href="javascript:void(0);">
                                            <img src="images/right-arrow.png" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <p className="settings-privacy mt-2 mb-2">
                        <a href="https://chatterfox.com/terms-and-conditions" target="_blank" rel="noreferrer">
                            Terms & Condition
                        </a>
                    </p>
                </div>
            </section>
        </div>
    );
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(Settings);
