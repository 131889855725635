import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import AudioPlayer from '../../components/CustomAudioPlayer';
import Button from '../../components/Button';
import styles from './AIResult.module.css';
import { isMobile } from 'react-device-detect';
import Segment from '../../../segment';

const AIResult = (props) => {
  const sampleData = {
    data: {
      user_id: '30aaa035ea6addf3032dc3d63b674344',
      words: [
        {
          image_url:
            'https://chatterfox-ai-public-storage.s3.amazonaws.com/assessment_visualization/visual_assess_539_2021_08_30_18_36_47_Q3UxaeU.png',
          voice_url:
            'https://chatterfox-ai-public-storage.s3.amazonaws.com/assessment_audio/assess_539_words_joined_2021_08_30_18_36_47_C4BNIj8.mp3',
        },
        {
          image_url:
            'https://chatterfox-ai-public-storage.s3.amazonaws.com/assessment_visualization/visual_assess_539_2021_08_30_18_36_47_ITQk_Fs.png',
          voice_url:
            'https://chatterfox-ai-public-storage.s3.amazonaws.com/assessment_audio/assess_539_words_joined_2021_08_30_18_36_47_d_oDo3Q.mp3',
        },
        {
          image_url:
            'https://chatterfox-ai-public-storage.s3.amazonaws.com/assessment_visualization/visual_assess_539_2021_08_30_18_36_47_T_KNLe4.png',
          voice_url:
            'https://chatterfox-ai-public-storage.s3.amazonaws.com/assessment_audio/assess_539_words_joined_2021_08_30_18_36_48_q53a6f4.mp3',
        },
        {
          image_url: 'https://chatterfox-ai-public-storage.s3.amazonaws.com/static/image/assessment_tips_hint.png',
          voice_url: '',
        },
      ],
      text_tips: [
        'We predict your speaking will improve by 67% based on our data and your initial assessment results.',
      ],
      voice_tips: [
        'https://chatterfox-ai-public-storage.s3.amazonaws.com/static/audio/assessment_tips/first_tip/assessment_first_tip_67_percent.mp3',
        'https://chatterfox-ai-public-storage.s3.amazonaws.com/static/audio/assessment_tips/assessment_second_tip.mp3',
        'https://chatterfox-ai-public-storage.s3.amazonaws.com/static/audio/assessment_tips/assessment_third_tip.mp3',
      ],
      assessment_id: 539,
    },
  };

  useEffect(() => {
    Segment.track('AssResultViewed');
  }, []);

  const { voice_tips, text_tips, words } = props.result ? props.result : sampleData.data;
  const audiosRef = {};

  const stopOtherAudioPlayers = (currentPlayerId) => {
    Object.keys(audiosRef).forEach((value) => {
      if (value !== currentPlayerId && audiosRef[value].current) audiosRef[value].current.stop();
    });
  };

  const ImageResult = ({ id, image }) => {
    const playerId = `img_${id}`;
    audiosRef[playerId] = useRef();
    return (
      <div className={`${styles.image} ${image.voice_url ? '' : styles.noAudio}`}>
        <div className={styles.imageBox}>
          <img alt="" src={`${image.image_url}`} />
        </div>
        {image.voice_url ? (
          <AudioPlayer
            ref={audiosRef[playerId]}
            id={playerId}
            className={styles.audioPlayer}
            source={image.voice_url}
            hideBar={true}
            pauseable={false}
            onStart={() => stopOtherAudioPlayers(playerId)}
          />
        ) : null}
      </div>
    );
  };

  ImageResult.propTypes = {
    id: PropTypes.number,
    image: PropTypes.object,
  };

  const AudioResult = ({ id, audioSource }) => {
    const playerId = `aud_${id}`;
    audiosRef[playerId] = useRef();
    return (
      <div className={styles.audio}>
        <div className={styles.bot}>
          <img alt="" src="/images/bot.png" />
        </div>
        <div className={styles.player}>
          <AudioPlayer
            ref={audiosRef[playerId]}
            id={playerId}
            source={audioSource}
            pauseable={false}
            onStart={() => stopOtherAudioPlayers(playerId)}
          />
        </div>
      </div>
    );
  };

  AudioResult.propTypes = {
    id: PropTypes.number,
    audioSource: PropTypes.string,
  };

  return (
    <div className={`${styles.container} ${isMobile && 'mobile'} cf-assessment-result-container`}>
      <div className={styles.content} style={props.asNotification ? { marginTop: '10px' } : {}}>
        {/* {!props.asNotification ? <div className={styles.title}>Assessment Test Result</div> : null} */}
        <div className={styles.body}>
          <span>
            Check out your initial fluency assessment results and get some feedback from your AI-powered fluency coach.
          </span>
          <br />
          <span>
            The sounds you see in green are the ones you are already making well. Great job! The ones in yellow could
            use some more practice and the sounds in read will be your top priority to improve.
          </span>
          <div className={styles.result}>
            <div className={styles.imageResult}>
              {words.map((image, index) => (
                <ImageResult key={index} id={index} image={image} />
              ))}
            </div>
            {text_tips.map((textTip, index) => (
              <span key={index}>
                <span>{textTip}</span>
                <br />
              </span>
            ))}
            <span>Listen below to the message from your human coach.</span>
            <div className={styles.audioResult}>
              {voice_tips.map((audio, index) => (
                <AudioResult key={index} id={index} audioSource={audio} />
              ))}
            </div>
          </div>
        </div>

        <div className={styles.btnHolder}>
          <Button
            type="primary"
            style={{ textTransform: 'unset' }}
            content="Continue to course"
            onClick={props.onContinue}
          />
        </div>
      </div>
    </div>
  );
};

AIResult.propTypes = {
  result: PropTypes.object,
  asNotification: PropTypes.bool,
  onContinue: PropTypes.func,
  onSkip: PropTypes.func,
};

export default AIResult;
