import React, { useState, useRef, useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import ReactPlayer from 'react-player/lazy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faUndo, faAngleRight, faAngleLeft, faPause } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from '../Button';
import { getSuggestion, getVideoResult, getDefinition, getTextAnalysis } from '../../../services/dictionaryServices';

import styles from './DictionaryResult.module.css';
import Segment from '../../../segment';

const DictionaryResult = (props) => {
  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchInputRef = useRef();
  const optionsRef = useRef([]);
  const searchRef = useRef('');
  const pageRef = useRef(1);
  const playerRef = useRef();
  const [playing, setPlaying] = useState(false);
  const [videoResult, setVideoResult] = useState();
  const [definition, setDefinition] = useState();
  const [textAnalysis, setTextAnalysis] = useState();
  const [subtitle, setSubtitle] = useState();
  const subtitleIndexRef = useRef(0);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);

    if (query && query.get('q')) {
      searchRef.current = query.get('q');
      setSelected([query.get('q')]);

      handleGetVideoResult();
      getTextAnalysis(searchRef.current).then((res) => {
        if (res) setTextAnalysis(res.word_level[0]);
      });
      getDefinition(searchRef.current).then((res) => {
        if (_.has(res, 'list')) {
          setDefinition(res.list);
        }
      });
    }
  }, [props.location]);

  const handleSearch = () => {
    Segment.track('Dictionary Search', { word: searchInputRef.current.getInput().value });
    history.push(`/dictionary/search?q=${searchInputRef.current.getInput().value}`);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) handleSearch();
  };

  const handleSelectedChange = (selected) => {
    if (selected && selected[0]) history.push(`/dictionary/search?q=${selected[0].suggestion_name}`);
    setSelected(selected);
  };

  const handleGetVideoResult = (backward) => {
    if (backward) pageRef.current--;
    getVideoResult({ search: searchRef.current, page: pageRef.current++ }).then((res) => {
      if (_.get(res, 'errorCode', -1) === 0) {
        // Find index of current subtitle
        subtitleIndexRef.current = _.findIndex(res.video_subtitle, {
          time_sec: res.video_list.data.time_sec,
        });
        setSubtitle(res.video_subtitle[subtitleIndexRef.current].caption_text);
        setVideoResult({
          video: res.video_list.data,
          subtitle: res.video_subtitle,
        });
      } else {
        // Handle error
      }
    });
  };

  const handleProgress = (progress) => {
    if (
      +videoResult.subtitle[subtitleIndexRef.current].time_sec +
        +videoResult.subtitle[subtitleIndexRef.current].duration <
      progress.playedSeconds
    ) {
      subtitleIndexRef.current++;
      setSubtitle(videoResult.subtitle[subtitleIndexRef.current].caption_text);
    }
  };

  const handleBackward = () => {
    Segment.track('Dictionary Previous');
    handleGetVideoResult(true);
  };

  const handlePlay = () => {
    Segment.track(`Dictionary ${playing ? 'Pause' : 'Play'}`);
    setPlaying(!playing);
  };

  const handleForward = () => {
    Segment.track('Dictionary Next');
    handleGetVideoResult();
  };

  const handleReset = () => {
    Segment.track('Dictionary Previous');
    // Find index of current subtitle
    subtitleIndexRef.current = _.findIndex(videoResult.subtitle, {
      time_sec: videoResult.video.time_sec,
    });
    playerRef.current.seekTo(videoResult.video.time_sec, 'seconds');
    setSubtitle(videoResult.subtitle[subtitleIndexRef.current].caption_text);
    setPlaying(false);
  };

  const Highlight = ({ text, word }) => {
    let result = <></>;
    let temp = text;
    let index = temp.toLowerCase().indexOf(word.toLowerCase());
    while (index >= 0) {
      result = (
        <>
          {result}
          {temp.substring(0, index)}
          <span className={styles.highlight}>{temp.substring(index, index + word.length)}</span>
        </>
      );
      temp = temp.substring(index + word.length);
      index = temp.toLowerCase().indexOf(word.toLowerCase());
    }

    return (
      <>
        {result}
        {temp}
      </>
    );
  };

  Highlight.propTypes = {
    text: PropTypes.string,
    word: PropTypes.string,
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <AsyncTypeahead
          id="dictionarySearch"
          ref={searchInputRef}
          className={styles.input}
          placeholder="What do you want to pronounce..."
          isLoading={isLoading}
          labelKey="suggestion_name"
          selected={selected}
          onChange={handleSelectedChange}
          onKeyDown={handleKeyDown}
          onSearch={(query) => {
            setIsLoading(true);
            getSuggestion(query).then((res) => {
              optionsRef.current = res.suggestion;
              setIsLoading(false);
            });
          }}
          options={optionsRef.current}
        />
        <Button className={styles.btn} type="primary" content="Hear Pronunciation" onClick={handleSearch} />
      </div>
      {videoResult && (
        <div className={styles.body}>
          <ReactPlayer
            ref={playerRef}
            playing={playing}
            className={styles.player}
            url={`${videoResult.video.yt_video_url}&start=${videoResult.video.time_sec}`}
            onProgress={handleProgress}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
          />
          <div className={styles.subtitle}>
            <span>
              <Highlight text={subtitle} word={searchRef.current} />
            </span>
          </div>
          <div className={styles.controls}>
            <span className={`${styles.control} ${styles.step}`} onClick={handleBackward}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </span>
            <span className={`${styles.control} ${styles.play}`} onClick={handlePlay}>
              <FontAwesomeIcon icon={playing ? faPause : faPlay} />
            </span>
            <span className={`${styles.control} ${styles.step}`} onClick={handleForward}>
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
            <span className={`${styles.control} ${styles.reset}`} onClick={handleReset}>
              <FontAwesomeIcon icon={faUndo} />
            </span>
          </div>
          {textAnalysis && (
            <div className={styles.textAnalysis}>
              <h3>How to pronounce (easy phoneme):</h3>
              <span className={styles.phoneme}>{textAnalysis.pronunciation}</span>
              <h3>Definition (Word NET):</h3>
              {textAnalysis.wordnet_info.map((item, index) => (
                <div key={index} className={styles.definition}>
                  <span>
                    <strong>Definition: </strong>
                    {item.definition}
                  </span>
                  <br />
                  <span>
                    <strong>Example: </strong>
                    {item.examples[0]}
                  </span>
                </div>
              ))}
            </div>
          )}
          {definition && (
            <div className={styles.definitions}>
              <h3>Definition (Urban Dictionary):</h3>
              {definition.map((item, index) => (
                <div key={index} className={styles.definition}>
                  <span>
                    <strong>Definition: </strong>
                    {item.definition}
                  </span>
                  <br />
                  <span>
                    <strong>Example: </strong>
                    {item.example}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DictionaryResult.propTypes = {
  location: PropTypes.object,
};

export default DictionaryResult;
