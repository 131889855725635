import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import _ from 'lodash';

import { selectUser, setMenu } from '../../../actions/user/userSlice';
import { CF_USER } from '../../../constants/global';
import { getpaymentsDetailsforPlan, addOrder, stripeSubscripe } from '../../../services/paymentServices';
import withAuthorization from '../../../Session/withAuthorization';
import SubmitButtonBig from '../../components/SubmitButtonBig';
import Segment from '../../../segment';

const Payments = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  let history = useHistory();
  let dispatch = useDispatch();
  const userData = useSelector(selectUser);
  const [isLoading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [plans, setPlans] = useState(null);
  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardExpiryError, setCardExpiryError] = useState(false);
  const [cardCvvError, setCardCvvError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [adjective, setAdjective] = useState('');
  const [isSubmitHit, setIsSubmitHit] = useState(false);
  const [userFirstName, setUserFirstName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userEmail, setUserEmail] = useState(userData.email);
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [isRedirected, setIsredirected] = useState(false);

  useEffect(() => {
    const values = queryString.parse(history.location.search);

    if (!values.pid) {
      dispatch(setMenu('subscription'));
      redirectTo('/subscription');
    } else {
      setUserId(values.uid);
      setPaymentId(values.pid);
      let token = userData.api_token;

      getpaymentsDetailsforPlan(token, values.pid).then((res) => {
        Segment.track('Checkout Step View', {
          name: res.plans[0].display_name,
          price: res.plans[0].original_price,
          hasTrial: res.plans[0].is_trial_plan,
        });
        console.log('active plans are..', res);

        if (!res.plans[0].is_subscribeble) {
          Swal.fire('Error!', 'This plan is not upgradable with your current plan', 'error');
          dispatch(setMenu('subscription'));
          redirectTo('/subscription');
          return false;
        }

        if (res.user_current_plan.is_admin_subscribed) {
          Swal.fire('Error!', res.user_current_plan.admin_subscribed_upgrade_message, 'error');
          dispatch(setMenu('subscription'));
          redirectTo('/subscription');
        } else if (res.user_current_plan.plan_id) {
          if (parseInt(values.pid) == parseInt(res.user_current_plan.plan_id)) {
            Swal.fire('Info!', 'You already subscribe this plan', 'info');
            dispatch(setMenu('subscription'));
            redirectTo('/subscription');
          } else {
            setPlans(res);
          }
        } else {
          setPlans(res);
        }

        if (res.plans[0].interval_count == 1) {
          if (res.plans[0].interval.toLowerCase() == 'month') {
            setAdjective('ly');
          } else if (res.plans[0].interval.toLowerCase() == 'year') {
            setAdjective('ly');
          } else if (res.plans[0].interval.toLowerCase() == 'week') {
            setAdjective('ly');
          } else {
            setAdjective('');
          }
        } else {
          setAdjective('');
        }
      });
    }
  }, []);

  const goToTop = () => {
    //it allways scroll to top when lesson get change
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onClickPaypal = () => {
    Segment.track('Payment Info Entered', {
      paymentMethod: 'Paypal',
      name: _.get(plans, 'plans[0].display_name'),
      price: _.get(plans, 'plans[0].original_price'),
      hasTrial: _.get(plans, 'plans[0].is_trial_plan'),
    });
    setLoading(true);
    let planId = plans ? plans.plans[0].id : '';
    let userToken = userData.api_token;
    let stripeToken = '';
    addOrder(userToken, planId, 'paypal').then((res) => {
      stripeSubscripe(userToken, res.order_id, stripeToken).then((resp) => {
        console.log('stripe subscription resposne...', resp);
        if (resp && resp.isError == 0) {
          setLoading(false);
          props.setPlanName(plans.plans[0].display_name);
          redirectToPaypal(resp.data.paypal_link);
        } else {
          if (!resp.message) {
            Swal.fire('Error!', 'Something went wrong', 'error');
          } else {
            Swal.fire('Error!', resp.message, 'error');
          }
          setLoading(false);
        }
      });
    });
  };

  const redirectToPaypal = (url) => {
    try {
      window.open(url, '_blank').focus();
      setIsredirected(true);
    } catch (error) {
      setIsredirected(false);
    }
  };

  const addPlanOrder = async () => {
    Segment.track('Payment Info Entered', {
      paymentMethod: 'Stripe',
      name: _.get(plans, 'plans[0].display_name'),
      price: _.get(plans, 'plans[0].original_price'),
      hasTrial: _.get(plans, 'plans[0].is_trial_plan'),
    });
    setIsSubmitHit(true);
    if (!userFirstName || !userEmail || !userLastName) {
      goToTop();
      return false;
    }

    if (!stripe || !elements) {
      console.log('stripe or element not found...');
      return;
    }
    setLoading(true);

    const cardElement = await elements.getElement('cardNumber');

    if (cardElement) {
      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: userFirstName + ' ' + userLastName,
            address: {
              postal_code: zipCode,
            },
          },
        })
        .then((result) => {
          //console.log("stripe generated token..",result);

          if (!result.error) {
            let userToken = userData.api_token;
            let planId = plans ? plans.plans[0].id : '';
            let stripeToken = result.paymentMethod.id;

            addOrder(userToken, planId, 'stripe').then((res) => {
              stripeSubscripe(userToken, res.order_id, stripeToken).then((resp) => {
                console.log('stripe subscription resposne...', resp);
                setLoading(false);
                if (resp && resp.isError == 0) {
                  Segment.track('Order Completed', {
                    paymentMethod: 'Stripe',
                    name: _.get(plans, 'plans[0].display_name'),
                    price: _.get(plans, 'plans[0].original_price'),
                    hasTrial: _.get(plans, 'plans[0].is_trial_plan'),
                  });
                  props.setPlanName(plans.plans[0].display_name);
                  // props.setIsDone(true);
                  setIsSubmitHit(false);
                  redirectTo('/confirm', { package_name: plans.plans[0].display_name });
                } else {
                  Segment.track('Payment Failed', {
                    paymentMethod: 'Stripe',
                    name: _.get(plans, 'plans[0].display_name'),
                    price: _.get(plans, 'plans[0].original_price'),
                    hasTrial: _.get(plans, 'plans[0].is_trial_plan'),
                    error: resp.message,
                  });
                  if (!resp.message) {
                    Swal.fire('Error!', 'Something went wrong', 'error');
                  } else {
                    Swal.fire('Error!', resp.message, 'error');
                  }
                  setLoading(false);
                }
              });
            });
          } else {
            if (result.error.code == 'incomplete_number' || result.error.code == 'invalid_number') {
              setCardNumberError(true);
            } else if (result.error.code == 'incomplete_expiry' || result.error.code == 'invalid_expiry_year_past') {
              setCardExpiryError(true);
            } else if (result.error.code == 'incomplete_cvc' || result.error.code == 'invalid_cvc') {
              setCardCvvError(true);
            }
            //Swal.fire("Error!", "card not valid", "error");
            //alert("card not valid");
            setLoading(false);
          }
        });
    }
  };

  const redirectTo = (path, data = {}) => {
    if (path && path != '') {
      history.push({
        pathname: path,
        state: data,
      });
    }
  };

  return (
    <div className="app-content content">
      <section className="payment-process" style={{ paddingTop: '7rem' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              {userData ? (
                <div className="row">
                  <div className="col-lg-12 mt-1">
                    <div className="bill-heading">
                      <h2>Billing Details</h2>
                    </div>
                  </div>
                  <div className="col-lg-6 text-left mt-1">
                    <label>First Name</label>
                    <input
                      type="text"
                      className={
                        !userFirstName && isSubmitHit ? 'form-control mb-cus border-red' : 'form-control mb-cus'
                      }
                      placeholder="First name"
                      onChange={(e) => {
                        setUserFirstName(e.target.value);
                      }}
                      //value={userFirstName}
                    />
                    {!userFirstName && isSubmitHit ? <p className="mb-1 red">Please enter user first name </p> : null}
                  </div>
                  <div className="col-lg-6 text-left mt-1">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className={
                        !userLastName && isSubmitHit ? 'form-control mb-cus border-red' : 'form-control mb-cus'
                      }
                      placeholder="Last name"
                      //value={userLastName}
                      onChange={(e) => {
                        setUserLastName(e.target.value);
                      }}
                    />
                    {!userLastName && isSubmitHit ? <p className="mb-1 red">Please enter user last name </p> : null}
                  </div>
                  <div className="col-lg-12 mt-1">
                    <label>Email</label>
                    <input
                      type="email"
                      className={!userEmail && isSubmitHit ? 'form-control mb-cus border-red' : 'form-control mb-cus'}
                      placeholder="email"
                      onChange={(e) => {
                        setUserEmail(e.target.value);
                      }}
                      value={userEmail}
                    />
                    {!userEmail && isSubmitHit ? <p className="mb-1 red">Please enter user email </p> : null}
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="col-lg-12 mt-3">
                  <div className="bill-heading">
                    <h2>Payment Method</h2>
                  </div>
                </div>
                <div className="col-lg-12 mt-1">
                  <ul className="nav nav-tabs payment-method">
                    <li className={paymentMethod == 'stripe' ? 'active' : ''}>
                      <button
                        type="button"
                        data-toggle="tab"
                        href="#stripe"
                        className={paymentMethod == 'stripe' ? 'btn active' : 'btn'}
                        onClick={() => {
                          setPaymentMethod('stripe');
                        }}
                      >
                        <img src="images/py1.png" /> CREDIT CARD
                      </button>
                    </li>
                    <li className={paymentMethod == 'paypal' ? 'active' : ''}>
                      <button
                        type="button"
                        data-toggle="tab"
                        href="#paypal"
                        className={paymentMethod == 'paypal' ? 'btn active' : 'btn'}
                        onClick={() => {
                          setPaymentMethod('paypal');
                          setIsredirected(false);
                        }}
                      >
                        <img src="images/py3.png" /> PAYPAL
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              {paymentMethod == 'stripe' && (
                <div className="row">
                  <div className="col-lg-12 mt-2 mb-0">
                    <div className="bill-heading">
                      <h2>Payment Details</h2>
                    </div>
                  </div>
                  {/* <div id="stripe" className="tab-pane in active"> */}
                  <div className="col-lg-12 col-xs-12 mt-1">
                    <label>Card Number</label>
                    <CardNumberElement
                      className={cardNumberError && isSubmitHit ? 'form-control border-red mb-cus' : 'form-control'}
                      options={{ style: { base: { backgroundColor: 'white' } } }}
                      onChange={() => {
                        setCardNumberError(false);
                      }}
                    />
                    {cardNumberError && isSubmitHit ? <p className="mb-2 red">Please enter Card Number </p> : null}
                  </div>
                  <div className="col-lg-4 col-xs-12 mt-1">
                    <label>EXP Month and Year</label>
                    <CardExpiryElement
                      className={cardExpiryError && isSubmitHit ? 'form-control border-red mb-cus' : 'form-control'}
                      options={{ style: { base: { backgroundColor: 'white' } } }}
                      onChange={() => {
                        setCardExpiryError(false);
                      }}
                    />
                    {cardExpiryError && isSubmitHit ? <p className="mb-2 red">Please enter Card Expiry date </p> : null}
                  </div>

                  <div className="col-lg-4 col-xs-12 mt-1">
                    <label>CVV</label>
                    <CardCvcElement
                      className={
                        cardCvvError && isSubmitHit
                          ? 'form-control border-red mb-cus float-right'
                          : 'form-control float-right'
                      }
                      options={{ style: { base: { backgroundColor: 'white' } } }}
                      onChange={() => {
                        setCardCvvError(false);
                      }}
                    />
                    {cardCvvError && isSubmitHit ? (
                      <p className="mb-2 red pl-cus">Please enter Card CVV number </p>
                    ) : null}
                  </div>

                  <div className="col-lg-4 col-xs-12 mt-1">
                    <label>Zip Code</label>
                    <input
                      type="text"
                      className={zipcodeError && isSubmitHit ? 'form-control mb-cus border-red' : 'form-control mb-cus'}
                      placeholder="Zip"
                      onChange={(e) => {
                        setZipCode(e.target.value);
                        setZipcodeError(false);
                      }}
                      //value={userFirstName}
                    />
                    {zipcodeError && isSubmitHit ? <p className="mb-1 red">Please enter Zip code </p> : null}
                  </div>
                  {/* <div className="col-lg-12 mt-1">
										<label>Do you have a coupon ?</label>
										<div className="d-flex">
											<input type="text" className="form-control" placeholder="" />
											<a href="#">APPLY</a>
										</div>
									</div> */}
                  <div className="col-lg-12 mt-1">
                    <h3 className="payment-total">
                      <span className="trial_plan">
                        {plans
                          ? plans.plans[0].trial_days > 0
                            ? '$' +
                              plans.plans[0].trial_amount +
                              ' for the first ' +
                              plans.plans[0].trial_days +
                              ' days, Then '
                            : null
                          : null}
                      </span>
                      {plans ? '$' + plans.plans[0].cost + '' : '-'}
                      <span className="trial_plan">
                        {plans
                          ? plans.plans[0].interval_count > 1
                            ? 'per ' + plans.plans[0].interval_count + ' ' + plans.plans[0].interval
                            : '' + ' ' + plans.plans[0].interval + adjective
                          : ''}
                      </span>
                    </h3>
                  </div>
                  <div className="col-lg-12 mt-1">
                    <SubmitButtonBig
                      caption="Submit Payment"
                      btnClass="btn btn-red btn-orange w-100 mb-2"
                      isLoading={isLoading}
                      onClick={() => addPlanOrder()}
                    />
                    <span>
                      By purchasing a product, you are agreeing to our
                      <a href="https://chatterfox.com/terms-and-conditions" target="_blank" rel="noreferrer">
                        terms and conditions
                      </a>
                      .
                    </span>
                  </div>
                </div>
              )}
              {paymentMethod == 'paypal' && (
                <div className="row">
                  {!isRedirected ? (
                    <>
                      <div className="col-lg-12 mt-1">
                        <h3 className="payment-total">
                          <span className="trial_plan">
                            {plans
                              ? plans.plans[0].trial_days > 0
                                ? '$' +
                                  plans.plans[0].trial_amount +
                                  ' for the first ' +
                                  plans.plans[0].trial_days +
                                  ' days, Then '
                                : null
                              : null}
                          </span>
                          {plans ? '$' + plans.plans[0].cost + '' : '-'}
                          <span className="trial_plan">
                            {plans
                              ? plans.plans[0].interval_count > 1
                                ? 'per ' + plans.plans[0].interval_count + ' ' + plans.plans[0].interval
                                : '' + ' ' + plans.plans[0].interval + adjective
                              : ''}
                          </span>
                        </h3>
                      </div>
                      <div className="col-lg-12 mt-1">
                        <SubmitButtonBig
                          caption="Pay With Paypal"
                          btnClass="btn btn-red btn-orange w-100 mb-2"
                          isLoading={isLoading}
                          onClick={() => onClickPaypal()}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="col-lg-12 text-center  p-0">
                      <h4>
                        Make payment on paypal page then
                        <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => redirectTo('/modules')}>
                          click here
                        </span>
                      </h4>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="col-lg-1"></div>

            {plans ? (
              <div className="col-lg-3 mt-2">
                <div className="payment-package">
                  <h2>{plans.plans[0].display_name}</h2>
                  <ul className="list subslist">
                    <li>
                      <strong>Has free trial :</strong>
                      {plans.plans[0].is_trial_plan == 1 ? 'Yes' : 'No'}
                    </li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: '<strong>Description : </strong> ' + plans.plans[0].description,
                      }}
                    />
                  </ul>
                </div>

                {plans.plans[0].plan_option.length ? (
                  <div className="payment-package risk-free">
                    <h2>Try It Risk Free</h2>
                    <ul>
                      {plans.plans[0].plan_option.map((elm, ind) => {
                        return (
                          <li key={'key' + ind}>
                            <strong>{elm.option_name}</strong>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </div>
  );
};
const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(Payments);
