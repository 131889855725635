import React, { useState, useRef } from 'react';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from '../../components/Button';
import styles from './Questions.module.css';
import globalStyles from './InitialAssessment.module.css';
import { getData } from '../../../util/localStorageHandler';
import Segment from '../../../segment';
import { updateUserProfile } from '../../../services/userServices';
import { getVariable } from '../../../services/apiServices';

const Questions = (props) => {
  const languages = ['Spanish', 'Chinese', 'Hindi', 'Portuguese', 'Persian', 'Arabic', 'Other'];
  const languageList = [
    'Bengali',
    'Russian',
    'Japanese',
    'Punjabi',
    'German',
    'Javanese',
    'Malay/Indonesian',
    'Korean',
    'Telugu',
    'Vietnamese',
    'French',
    'Marathi',
    'Tamil',
    'Urdu',
    'Turkish',
    'Italian',
    'Thai',
    'Polish',
    'Others',
  ];
  const languageVideos = {
    English: 'https://chatterfox.com/public/videos/Intro - English.mp4',
    Spanish: 'https://chatterfox.com/public/videos/Intro - Spanish.mp4',
    Bengali: 'https://chatterfox.com/public/videos/Intro - Bengali.mp4',
    Chinese: 'https://chatterfox.com/public/videos/Intro - Chinese.mp4',
    Hindi: 'https://chatterfox.com/public/videos/Intro - Hindi.mp4',
    Portuguese: 'https://chatterfox.com/public/videos/Intro - Portuguese.mp4',
    French: 'https://chatterfox.com/public/videos/Intro - French.mp4',
    Arabic: 'https://chatterfox.com/public/videos/Intro - Arabic.mp4',
  };
  const authUser = getData();
  const [showLanguageList, setShowLanguageList] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const selectedLanguageRef = useRef();
  const videoLanguageRef = useRef();

  const handleRadioChange = (e) => {
    if (e.target.value) {
      if (e.target.value === 'Other') {
        selectedLanguageRef.current = undefined;
        setShowLanguageList(true);
      } else {
        setShowLanguageList(false);
        selectedLanguageRef.current = e.target.value;
      }
    }
  };

  const handleSelectChange = (e) => {
    selectedLanguageRef.current = e.target.value;
  };

  const handleSubmit = async () => {
    if (step == 1) {
      if (selectedLanguageRef.current) {
        setLoading(true);
        let asmtvideo;
        if (localStorage.getItem('asmtvideo')) {
          asmtvideo = localStorage.getItem('asmtvideo');
        } else {
          const responseVariable = await getVariable(`${selectedLanguageRef.current}-asmtvideo`);
          asmtvideo = _.get(responseVariable, 'value', 0) % 2 === 0 ? 'English' : 'Native';
          localStorage.setItem('asmtvideo', asmtvideo);
        }
        if (authUser) {
          Segment.identify(authUser.email, { nativeLanguage: selectedLanguageRef.current, asmtvideo });
          // Update user's profile
          updateUserProfile({ native_language: selectedLanguageRef.current });
        } else {
          Segment.identify({ nativeLanguage: selectedLanguageRef.current, asmtvideo });
          // Store for updating later
          localStorage.setItem('nativeLanguage', selectedLanguageRef.current);
        }

        videoLanguageRef.current = asmtvideo === 'Native' ? selectedLanguageRef.current : 'English';
        setStep(2);

        setLoading(false);
      } else {
        Swal.fire('Error!', 'Please choose your native language.');
      }
    } else {
      props.nextStep([{ key: 'nativeLanguage', value: selectedLanguageRef.current }]);
    }
  };

  return (
    <div
      className={`${globalStyles.contentContainer} ${globalStyles.edgeSpace} ${globalStyles.waveBg} ${styles.contentContainer}`}
    >
      <div>
        {step === 1 ? <div className={globalStyles.title}>What is your native language?</div> : null}
        <div className={styles.content}>
          {step === 1 ? (
            <Form>
              <div className={styles.languages} onChange={handleRadioChange}>
                {languages.map((language, index) => (
                  <Form.Check key={'radio-' + index} name="language" type="radio" label={language} value={language} />
                ))}
              </div>
              {showLanguageList ? (
                <Form.Select onChange={handleSelectChange}>
                  <option>Open this select menu</option>
                  {languageList.map((language, index) => (
                    <option key={'option-' + index} value={language}>
                      {language}
                    </option>
                  ))}
                </Form.Select>
              ) : null}
            </Form>
          ) : step === 2 ? (
            <video
              className={styles.video}
              src={languageVideos[videoLanguageRef.current] || languageVideos.English}
              controls
            ></video>
          ) : (
            <div>
              Hi there and welcome! As you continue, we will ask you to record a few words and sentences. Your responses
              will allow us to analyze where you are on your speaking journey and predict how soon you will reach your
              speaking goals.
            </div>
          )}
          <div className={styles.btnGroup}>
            <Button content="Next" isLoading={loading} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

Questions.propTypes = {
  nextStep: PropTypes.func,
};

export default Questions;
