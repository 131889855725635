import { createSlice } from '@reduxjs/toolkit';
import { setData, getData, setSessionData } from '../../util/localStorageHandler';
import _ from 'lodash';
import Segment from '../../segment';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    api_token: '',
    id: '',
    name: '',
    is_verified: false,
    email: '',
    mb_no: '',
    photo_url: '',
    username: '',
    role_id: '',
    searchKey: '',
    searching: false,
    menu: '',
    notifications: [],
    unreadNotificationCount: 0,
    isTestUser: false,
    remainingCoachingCredit: 0,
    isCoachCreditEnable: false,
  },

  reducers: {
    setUser: (state, action) => {
      state.api_token = action.payload.api_token ? action.payload.api_token : null;
      state.api_token_external = action.payload.api_token_external ? action.payload.api_token_external : null;
      state.id = action.payload.user ? action.payload.user.id : '';
      state.name = action.payload.user ? action.payload.user.name : '';
      state.first_name = action.payload.user
        ? action.payload.user.first_name
          ? action.payload.user.first_name
          : ''
        : '';
      state.last_name = action.payload.user ? (action.payload.user.last_name ? action.payload.user.last_name : '') : '';
      state.is_verified = action.payload.user ? action.payload.user.is_verified : '';
      state.email = action.payload.user ? action.payload.user.email : '';
      state.mb_no = action.payload.user ? action.payload.user.mb_no : '';
      state.photo_url = action.payload.user ? action.payload.user.photo_url : '';
      state.username = action.payload.user ? action.payload.user.username : '';
      state.role_id = action.payload.user ? action.payload.user.role_id : '';
      state.refferal_coupon = action.payload.user ? action.payload.user.refferal_coupon : '';

      state.redirectUrl = action.payload.user
        ? action.payload.user.redirectUrl
          ? action.payload.user.redirectUrl
          : ''
        : '';
      // state.isWithFeedback = _.get(action.payload, 'user.plan_type', '') === 'with_feedback';
      state.isTestUser =
        _.get(action.payload, 'user.email', '').includes('navandn+') ||
        _.get(action.payload, 'user.email', '').includes('samiwil85+cc');
      state.remainingCoachingCredit = _.get(action.payload, 'remaining_coaching_credit', state.remainingCoachingCredit);
      // Set local storage
      let thisUser = {};
      thisUser.id = action.payload.user ? action.payload.user.id : '';
      thisUser.name = action.payload.user ? action.payload.user.name : '';
      thisUser.is_verified = action.payload.user ? action.payload.user.is_verified : '';
      thisUser.email = action.payload.user ? action.payload.user.email : '';
      thisUser.mb_no = action.payload.user ? action.payload.user.mb_no : '';
      thisUser.photo_url = action.payload.user ? action.payload.user.photo_url : '';
      thisUser.username = action.payload.user ? action.payload.user.username : '';
      thisUser.role_id = action.payload.user ? action.payload.user.role_id : '';
      thisUser.refferal_coupon = action.payload.user ? action.payload.user.refferal_coupon : '';
      thisUser.first_name = action.payload.user
        ? action.payload.user.first_name
          ? action.payload.user.first_name
          : ''
        : '';
      thisUser.last_name = action.payload.user
        ? action.payload.user.last_name
          ? action.payload.user.last_name
          : ''
        : '';
      thisUser.isTestUser =
        _.get(action.payload, 'user.email', '').includes('navandn+') ||
        _.get(action.payload, 'user.email', '').includes('samiwil85+cc');
      thisUser.remainingCoachingCredit = _.get(
        action.payload,
        'remaining_coaching_credit',
        state.remainingCoachingCredit,
      );

      const userDataFromLocalStorage = getData();
      if (!action.payload.isSession) {
        setData({
          api_token: action.payload.api_token ? action.payload.api_token : null,
          api_token_external: action.payload.api_token_external ? action.payload.api_token_external : null,
          user: thisUser,
          allModulesData: _.get(userDataFromLocalStorage, 'allModulesData', []),
        });
      } else {
        setSessionData({
          api_token_external: action.payload.api_token_external ? action.payload.api_token_external : null,
          user: thisUser,
        });
      }
    },

    setSubscription: (state, action) => {
      state.is_subscribed = _.get(action.payload, 'user.is_subscribed', '');
      state.is_free_plan = _.get(action.payload, 'user.is_free_plan', '');
      state.isTrialing = _.get(action.payload, 'user.isTrialing', '');
      state.isWithFeedback = _.get(action.payload, 'active_subscription.plan_type', '') === 'with_feedback';

      const userDataFromLocalStorage = getData() || {};
      setData({ ...userDataFromLocalStorage, hasSubscription: state.is_subscribed });
    },

    setCoachingCredit: (state, action) => {
      state.isCoachCreditEnable = action.payload.isCoachCreditEnable;
    },

    setSearch: (state, action) => {
      state.searchKey = action.payload.searchKey;
      state.searching = action.payload.searching;
    },

    setMenu: (state, action) => {
      state.menu = action.payload;
    },

    logout: (state) => {
      Segment.track('WebLogOut');
      state.api_token = '';
      state.id = '';
      state.name = '';
      state.is_verified = '';
      state.email = '';
      state.mb_no = '';
      state.photo_url = '';
      state.username = '';
      state.role_id = '';
      state.notifications = [];
      state.unreadNotificationCount = 0;

      // Clear local storage
      setData('');
      // Clear Session storage
      setSessionData('');
    },

    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },

    setUnreadNotificationCount: (state, action) => {
      state.unreadNotificationCount = action.payload;
    },
  },
});

export const { setUser, setSubscription, setSearch, setMenu, logout, setNotifications, setUnreadNotificationCount, setCoachingCredit } =
  userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(setUserAsync(user))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const setUserAsync = (user) => (dispatch) => {
  setTimeout(() => {
    dispatch(setUser(user));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectUser = (state) => state.user;
export const selectCoachCreditEnable = (state) => state.user.isCoachCreditEnable;

export default userSlice.reducer;

/**
 * https://redux-toolkit.js.org/api/createslice
 */
