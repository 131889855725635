import CryptoJS from 'crypto-js';
import TrackHistory from './TrackHistory';
import { getAnalytics, logEvent, setUserId as setFirebaseUserId } from 'firebase/analytics';
import facebook from '../../facebook';
import { addKartraTag } from '../services/activityServices';

/**
 *
 * @param {name, email, country, gender, age, native_language} user
 */
const identify = async (userId, traits = {}) => {
  try {
    if (userId) {
      window.analytics.identify(userId, { ...traits, $onesignal_user_id: CryptoJS.MD5(userId).toString() });
      const analytics = getAnalytics();
      setFirebaseUserId(analytics, userId);
    } else {
      window.analytics.identify({ ...traits });
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 *
 * @param {string} event
 * @param {Object} properties
 */
const track = async (event, properties = {}, token) => {
  try {
    window.analytics.track(event, properties);
    if (event === 'Login' || event === 'WebLogin') {
      const analytics = getAnalytics();
      logEvent(analytics, 'login');
      addKartraTag('Login', token);
    }
    if (event === 'Order Completed') facebook.fbSendPurchase();
  } catch (error) {
    console.error(error);
  }
};

/**
 *
 * @param {string} event
 * @param {Object} properties
 * @returns
 */
const page = async (properties = {}) => {
  try {
    window.analytics.page(properties);
  } catch (error) {
    console.error(error);
  }
};

export default { identify, track, page };
export { TrackHistory };
