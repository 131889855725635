import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from '../../../components/CustomAudioPlayer';
import { confirmAlert } from 'react-confirm-alert';
import { isIOS, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import _ from 'lodash';

import StartMessage from '../message/StartMessage.js';
import EndMessage from '../message/EndMessage.js';
import { smartCoachV3 } from '../../../../services/lessonsServices';
import styles from './SmartCoach.module.css';
import Button from '../../../components/Button';
import PlayCard from '../../../components/PlayCard';
import Segment from '../../../../segment';

const SmartCoach = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();
  let lesson = props.lessonData;
  let nextFire = props.nextFire;
  let isEndMessage = props.lessonData.end_message ? true : false;
  const [isStartMessage, setStartMessage] = useState(!nextFire ? (lesson.start_message ? true : false) : false);
  const [isEndMessageShow, setEndMessageShow] = useState(false);
  const [audioUrl, setAudioUrl] = useState(props.lessonData.user_recorded_file);
  const [counter, setCounter] = useState(lesson.words.map((e) => e.unique_id).indexOf(lesson.unique_id) + 1);
  const [smartCoachData, setSmartCoachData] = useState('');
  const [labels, setLabels] = useState([]);
  const [scroes, setScroes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [smartWordDetails, setSmartWordDetails] = useState('');
  const [smartWord, setSmartWord] = useState('');
  const [submitCoachCalled, setSubmitCoachCalled] = useState(false);
  const [improvementAdvice, setImprovementAdvice] = useState();
  const [nativeness, setNativeness] = useState();
  const feedbackSoundRef = useRef();
  const audiosRef = {
    advice: useRef(),
    client: useRef(),
  };

  useEffect(() => {
    if (props.module.intro_module && counter === 1) Segment.track('IntroAIStarted');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAudioUrl(lesson.user_recorded_file);
    setSmartCoachData('');
    setSmartWord('');
    setSubmitCoachCalled(false);
    setLoading(false);
  }, [lesson]);

  useEffect(() => {
    setInfoData(smartCoachData);
    smartCoachData.custom_result &&
      smartCoachData.custom_result.map((elm) => {
        elm.custom_title == 'Improvement advice' && setImprovementAdvice(elm.custom_value);
        elm.custom_title == 'Nativeness' && setNativeness(elm.custom_value);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartCoachData]);

  useEffect(() => {
    if (feedbackSoundRef.current && submitCoachCalled) {
      feedbackSoundRef.current.audioEl.current.play();
    }
  }, [submitCoachCalled]);

  const stopOtherAudioPlayers = (currentPlayerId) => {
    Object.keys(audiosRef).forEach((value) => {
      if (value !== currentPlayerId && audiosRef[value].current) audiosRef[value].current.stop();
    });
  };

  const goToNextLesson = () => {
    if (!isEndMessage) {
      props.next(lesson);
    } else {
      setSmartCoachData('');
      setEndMessageShow(true);
    }
  };

  const goToPrevLesson = () => {
    if (!isStartMessage) {
      props.prev(lesson);
    } else {
      setSmartCoachData('');
      setStartMessage(true);
    }
  };

  const setInfoData = (smartCoachData) => {
    let word = '';
    let wordDetails = '';

    if (smartCoachData.alignment_info) {
      smartCoachData.alignment_info.map((elm, ind) => {
        let htmlElm = '';
        if (elm.score <= 1.5) {
          htmlElm = '<span class="smart-red">' + elm.phone + '</span>';
        } else if (elm.score > 1.5 && elm.score <= 2.0) {
          htmlElm = '<span class="smart-orange">' + elm.phone + '</span>';
        } else if (elm.score > 2.0) {
          htmlElm = '<span class="smart-green">' + elm.phone + '</span>';
        } else {
          htmlElm = '<span class="smart-black">' + elm.phone + '</span>';
        }

        wordDetails = wordDetails + htmlElm;
      });
    }
    if (smartCoachData.letter_based) {
      smartCoachData.letter_based.map((elm, ind) => {
        let htmlElm = '';

        if (elm.score <= 1.5) {
          htmlElm = '<span class="smart-red">' + elm.letter + '</span>';
        } else if (elm.score > 1.5 && elm.score <= 2.0) {
          htmlElm = '<span class="smart-orange">' + elm.letter + '</span>';
        } else if (elm.score > 2.0) {
          htmlElm = '<span class="smart-green">' + elm.letter + '</span>';
        } else {
          htmlElm = '<span class="smart-black">' + elm.letter + '</span>';
        }

        word = word + htmlElm;
      });
    } else {
      word = `<span class="smart-red">${lesson.word}</span>`;
    }

    setSmartWord(word);
    wordDetails.length > 0 && setSmartWordDetails(`( ${wordDetails} )`);
  };

  const onRecordStop = (voice) => {
    setAudioUrl(voice.blobURL);
    submitRecord(voice);
    //setRecordedVoice(voice);
  };

  const blobToFile = (theBlob, fileName) => {
    var file = new File([theBlob], fileName, { type: 'audio/mpeg', lastModified: new Date() });
    return file;
  };

  const submitRecord = (voice) => {
    Segment.track('Smart Coach', {
      state: 'recorded',
      moduleId: lesson.module_id,
    });
    try {
      setSmartCoachData(''); 
      setLoading(true);
      const extension = isIOS ? 'wav' : 'mp3';
      const formData = new FormData();
      formData.append('module_code', props.moduleCode || lesson.module_id);
      formData.append('recorded_file', blobToFile(voice.blob, `lesson_record_${lesson.id}.${extension}`));
      formData.append('text', lesson.word);

      smartCoachV3(formData).then((res) => {
        Segment.track('Smart Coach', {
          state: 'respond',
          response: res,
          moduleId: lesson.module_id,
        });
        if (!res) {
          confirmAlert({
            title: 'Error',
            message: 'Something went wrong, please record again',
            overlayClassName: `cf-one-button ${isMobile && 'mobile'}`,
            buttons: [
              {
                label: 'OK',
              },
            ],
          });
        } else if (res.ai_data.status !== 200 && res.ai_data.status !== 201) {
          confirmAlert({
            title: 'Error',
            message: _.get(res, 'ai_data.data.error_message', 'Something went wrong, please record again'),
            overlayClassName: `cf-one-button ${isMobile && 'mobile'}`,
            buttons: [
              {
                label: 'OK',
              },
            ],
          });
        } else {
          setSmartCoachData(res.ai_data.data);
          setSubmitCoachCalled(true);
        }
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
      Segment.track('Smart Coach', {
        state: 'failed',
        error,
        moduleId: lesson.module_id,
      });
    }
  };

  const tryAgain = () => {
    setSmartCoachData('');
    setSubmitCoachCalled(false);
  };

  const getVoiceToTextData = (text) => {
    //setAudioUrl(text)
    // console.log('recorded audio is ...', text);
  };

  const SmartResult = (data) => {
    return (
      <div className={styles.resultBox}>
        {data.data.map((result, index) => {
          const barHeight = (result.score * 100) / 3;
          return (
            <div className={styles.resultColumn} key={index}>
              <span className={styles.resultBar}>
                <span
                  style={{ height: `${barHeight}%`, backgroundColor: result.color}}
                  className={`${styles.coloredLine}`}
                ></span>
              </span>
              <span className={styles.resultLetter}>{result.letter}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const smartCoachResult = (
    <>
      {smartCoachData ? (
        <>
          <div
            className={`${styles.row} ${
              smartCoachData.advice_voice && smartCoachData.advice_voice.has_voice ? '' : styles.center
            }`}
          >
            {_.get(smartCoachData, 'advice_voice.has_voice', false) || improvementAdvice ? (
              <div className={styles.leftCol}>
                {_.get(smartCoachData, 'advice_voice.has_voice', false) && (
                  <AudioPlayer
                    id="advice"
                    ref={audiosRef['advice']}
                    className={styles.grayPlayer}
                    hideBar
                    source={smartCoachData.advice_voice.link || ''}
                    pauseable={false}
                    onStart={() => stopOtherAudioPlayers('advice')}
                  />
                )}
                <div className={styles.advice}>{improvementAdvice}</div>
                <span className={styles.chatterIcon}>
                  <img src={process.env.PUBLIC_URL + '/images/foxHead.png'} />
                </span>
              </div>
            ) : null}
            <div className={styles.rightCol}>
              <div className={styles.nativeness}>{nativeness} Nativeness</div>
              <SmartResult data={smartCoachData.letter_based} />
              <div className={styles.audioBtnHolder}>
                <AudioPlayer
                  className={`${styles.audioPlayer}  ${styles.modalContentBtn}`}
                  id="client"
                  ref={audiosRef['client']}
                  hideBar
                  type="button"
                  mode="client"
                  label="Your Voice"
                  source={audioUrl}
                  pauseable={false}
                  onStart={() => stopOtherAudioPlayers('client')}
                />
              </div>
            </div>
            <div className={styles.watchVideo}></div>
          </div>
          <div className={styles.btnHolder}>
            <Button
              type="secondary"
              content="Try Again"
              icon="refresh"
              iconPosition="left"
              size="medium"
              onClick={tryAgain}
            />
            <Button
              type="primary"
              content="Next One"
              icon="rightArrow"
              iconPosition="right"
              size="medium"
              onClick={() => goToNextLesson()}
            />
          </div>
        </>
      ) : null}
    </>
  );

  return (
    <>
      {/* {isStartMessage ? (
        <StartMessage message={lesson.start_message} next={() => setStartMessage(false)} />
      ) : */}
      {isEndMessageShow ? (
        <EndMessage
          message={lesson.end_message}
          nextLesson="Fluency Activities"
          next={props.next}
          backToHome={props.backToHome}
        />
      ) : (
        <section className="unlock-membership listen-record">
          <div className="container smart-coach-cont lesson-dropdwn">
            <PlayCard
              word={lesson.word}
              bgColor="#34a783"
              pattern="/images/patterns/zigZagBgWhite.svg"
              progressCurrent={counter}
              progressTotal={lesson.words.length}
              onRecordStop={onRecordStop}
              getVoiceToTextData={(data) => getVoiceToTextData(data)}
              isLoading = {isLoading}
            />

            <div>
              {smartCoachData ? (
                <strong
                  className="d-block coach-word"
                  dangerouslySetInnerHTML={{
                    __html: smartWord + ' ( ' + smartWordDetails + ' )',
                  }}
                ></strong>
              ) : null}
              <p className="d-none" data-lessonid={lesson.id} data-lessonword={lesson.word} id="storelessonId"></p>
              {isLoading ? (
                <img src="/images/loading.gif" className={styles.loading} />
              ) : (
                <span className={styles.notice}>Please press the mic button and say the word</span>
              )}
            </div>
            <div className="row">
              <div className={styles.btnHolder}>
                <Button
                  type="secondary"
                  content="Previous"
                  icon="leftArrow"
                  iconPosition="left"
                  size="medium"
                  isDisabled={counter <= 1 || isLoading}
                  onClick={goToPrevLesson}
                />
                <Button
                  type="secondary"
                  content="Next"
                  icon="rightArrow"
                  iconPosition="right"
                  size="medium"
                  isDisabled={isLoading}
                  onClick={goToNextLesson}
                />
              </div>
            </div>
          </div>

          {/*this audio player is for play sound after select smart coach feedback come*/}
          <ReactAudioPlayer
            src={process.env.PUBLIC_URL + '/sounds/LandC_Correct.mp3'}
            ref={feedbackSoundRef}
            autoPlay={false}
            controls={false}
          />
        </section>
      )}
      {/* <Modal
        className={styles.smartCoachModal}
        show={smartCoachData ? true : false}
        message={smartCoachResult}
        backdrop="static"
        centered
      /> */}
      {smartCoachData && (
        <div className={styles.modal}>
          <div className={styles.modalCover}></div>
          <div className={styles.modalContainer}>
            <div className={styles.modalContent}>{smartCoachResult}</div>
          </div>
        </div>
      )}
    </>
  );
};

SmartCoach.propTypes = {
  moduleId: PropTypes.string,
  moduleCode: PropTypes.string,
  lessonData: PropTypes.object,
  lessonIndex: PropTypes.number,
  nextFire: PropTypes.bool,
  next: PropTypes.func,
  module: PropTypes.object,
  backToHome: PropTypes.func,
};

export default SmartCoach;
