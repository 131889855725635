import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import withAuthorization from '../../../../Session/withAuthorization';
import { CF_USER } from '../../../../constants/global';
import Modal from '../../../components/Modal';
import { selectLessonStore, setSelectedLesson } from '../../../../actions/lesson/lessonStore';
import styles from './ModuleSection.module.css';
import Segment from '../../../../segment';
import Button from '../../../components/Button';
import { selectUser } from '../../../../actions/user/userSlice';

const ModuleSection = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const modulePath = props.modulePath;
  const module = props.module;
  const user = useSelector(selectUser);
  const allLessonData = useSelector(selectLessonStore);
  const [modal, setModal] = useState({ show: false });
  const [showLock, setShowLock] = useState(false);
  const checkPostLessonRef = useRef(false);

  const redirectTo = (path, data = {}) => {
    if (path && path != '') {
      Segment.track('Module Section Started', {
        moduleId: _.get(props, 'location.state.id'),
        name: _.get(props, 'location.state.title'),
        section: _.get(data, 'lessonData.lesson_type'),
      });
      history.push({
        pathname: path,
        state: {
          ...data,
          prevPath: modulePath,
          title: _.get(props, 'location.state.title'),
          moduleId: _.get(props, 'location.state.id'),
          moduleCode: _.get(props, 'location.state.moduleCode'),
        },
      });
    }
  };

  const loadDefaultImg = (event) => {
    event.target.src = process.env.PUBLIC_URL + '/images/logo.png';
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.headerBullet}>{props.index}</span>
        <span className={styles.headerText}>{props.title}</span>
      </div>
      <div className={styles.content}>
        {module.map((elm, ind) => {
          if (ind !== 0 && elm.lesson_type === 'finalAssignment') {
            if (checkPostLessonRef.current) return;
            else checkPostLessonRef.current = true;
          }
          const pos = allLessonData.map((e) => e.unique_id).indexOf(elm.unique_id);

          let customProps = {};
          if (elm.type === 'feedback') {
            if (elm.hasFeedback) {
              customProps = {
                onClick: () => {
                  dispatch(setSelectedLesson(elm));
                  redirectTo(props.path, {
                    lessonData: elm,
                    modulePath: modulePath,
                  });
                },
              };
            } else {
              if (user.is_subscribed && !user.isWithFeedback) {
                customProps = {
                  onClick: () => {
                    setModal({
                      show: true,
                      content:
                        "Visa plan doesn't include coaching sessions. For coaching sessions, you should upgrade to the Green Card plan",
                      icon: process.env.PUBLIC_URL + '/images/menu/icon-human-coach-deactive.svg',
                      buttonText: 'Upgrade Now',
                      buttonOnClick: () => {
                        window.open('https://chatterfox.com/upgrade-to-green-card-inapp/', '_self');
                      },
                    });
                  },
                };
              } else if (!elm.isCompleted) {
                customProps = {
                  onClick: () => {
                    setModal({
                      show: true,
                      content: "You haven't completed your final assessment.",
                      icon: process.env.PUBLIC_URL + '/images/attentionIcon.png',
                      buttonText: 'Got it',
                      buttonOnClick: () => {
                        setModal({ show: false });
                      },
                    });
                  },
                };
              }
            }
          } else {
            customProps =
              elm.is_completed || pos == 0
                ? {
                    onClick: () => {
                      dispatch(setSelectedLesson(elm));
                      const path = `${props.path}/${elm.unique_id}`;
                      redirectTo(path, {
                        lessonData: elm,
                        modulePath: modulePath,
                      });
                    },
                  }
                : pos !== 0 && allLessonData[pos - 1] && allLessonData[pos - 1].is_completed
                ? {
                    onClick: () => {
                      dispatch(setSelectedLesson(elm));
                      redirectTo(`${props.path}/${elm.unique_id}`, {
                        lessonData: elm,
                        modulePath: modulePath,
                      });
                    },
                  }
                : {
                    'data-target': '#unlockWarn',
                    onClick: () => setShowLock(true),
                  };
          }

          let title = elm.title;
          if (props.type == 'guided_lesson') {
            if (elm.lesson_title) title = elm.lesson_title;
            else if (elm.guided_lesson_type != 'lesson') {
              title = elm.guided_lesson_type.replace('_', ' ');
              //make title camel casing
              title = title.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
                return str.toUpperCase();
              });
            }

            //if previous exists
            if (module[ind - 1]) {
              //this checking is for show only one lesson listed for below types
              //though there multiple lesson u are there same type

              if (
                elm.guided_lesson_type == 'listen_records' ||
                elm.guided_lesson_type == 'listen_options' ||
                elm.guided_lesson_type == 'recognising_tools'
              ) {
                if (module[ind - 1].guided_lesson_type == elm.guided_lesson_type) {
                  return false;
                }
              }
            }
          }

          let imgIcon = elm.icon_url ? elm.icon_url : 'images/md-ic1.png';
          return (
            <div key={props.title + ind} className={styles.lesson} {...customProps}>
              <div className={styles.figure}>
                <div className={styles.orangeLine}>
                  <div className={styles.imgBack}>
                    <img onError={loadDefaultImg} src={imgIcon} />
                  </div>
                </div>
              </div>
              <span className={styles.title}>{title ? title : 'Title'}</span>
              {elm.type === 'feedback' ? (
                elm.isCompleted && !elm.hasFeedback && user.isWithFeedback ? (
                  <div className={styles.feedbackBox}>
                    <span>
                      Your coach is preparing your feedback.
                      <br /> It will be ready in 1-2 days and you will receive a notification when it is ready to view.
                    </span>
                    <Button
                      type="gold"
                      isDisabled={true}
                      content="Show my Feedback"
                      size="medium"
                      onClick={customProps.onClick}
                    />
                  </div>
                ) : (
                  <div className={styles.feedbackBox}>
                    <span>I am going to listen and analyse your recordings and correct your mistakes</span>
                    <Button type="gold" content="Show my Feedback" size="medium" onClick={customProps.onClick} />
                  </div>
                )
              ) : null}
            </div>
          );
        })}
      </div>
      {modal.show && (
        <div className={styles.modal}>
          <div className={styles.modalCover} onClick={() => setModal({ show: false })}></div>
          <div className={styles.modalContainer}>
            <div className={styles.modalContent}>
              <div className={styles.msgHolder}>
                <span className={styles.modalContentIcon}>
                  <img src={modal.icon} />
                </span>
                <p>{modal.content}</p>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <Button
                type="primary"
                content={modal.buttonText}
                size="medium"
                //isLoading={isLoading}
                onClick={modal.buttonOnClick}
              />
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showLock}
        message="Please complete the previous lesson to unlock this lesson"
        yesText="OK"
        yesClick={() => setShowLock(false)}
        handleClose={() => setShowLock(false)}
      />
    </div>
  );
};

ModuleSection.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.string,
  module: PropTypes.array,
  modulePath: PropTypes.string,
  path: PropTypes.string,
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(ModuleSection);
