import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import { findModuleById } from '../../../util';
import { selectModule, setSelectedModule } from '../../../actions/module/moduleSlice';
import { setSelectedLesson } from '../../../actions/lesson/lessonStore';
import { setHumanCoach } from '../../../actions/initialAssessment/initialAssessmentStore';
import { setBreadCrumpsPath } from '../../../actions/breadcrumps/breadCrumps';
import { selectUser, setNotifications, setUnreadNotificationCount } from '../../../actions/user/userSlice';

import NotificationItem from './NotificationItem';
import './Notification.css';

import CoachFeedback from '../../pages/initialAssessment/CoachFeedback';
import AIResult from '../../pages/initialAssessment/AIResult';
import { readNotification } from '../../../services/notificationServices';
import { getAICoachFeedback, getHumanCoachFeedback } from '../../../services/initialAssessmentServices';
import Segment from '../../../segment';
import Swal from 'sweetalert2';

import styles from './Notification.module.css';

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const _notifications_ = [
  {
    header: 'Your personalized plan',
    icon: '/images/coachIcon.svg',
    title: 'Clara',
    subtitle: 'Human Coach',
    date: '16/02/21',
    unread: true,
  },
  {
    header: 'Your Assessment Results',
    icon: '/images/botIcon.svg',
    title: 'AI',
    subtitle: 'AI Coach',
    date: '16/02/21',
  },
];

export const NotificationIcon = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const modules = useSelector(selectModule);
  const [show, setShow] = useState(false);
  const modulesRef = useRef();

  useEffect(() => {
    modulesRef.current = modules;
  }, [modules]);

  useEffect(() => {
    user.notifications.forEach((notification) => {
      if (!notification.is_read)
        toast.info(notification.header, {
          toastId: notification.id,
          autoClose: false,
          hideProgressBar: true,
          onClick: () => clickNotification(notification),
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const clickNotification = (notification) => {
    // Read notification
    readNotification(notification.id).then((res) => {
      if (res && !res.isError && !res.errorCode) {
        const userNotification = user.notifications.find((elm) => elm.id == notification.id);
        if (userNotification.is_read !== res.userNotification.is_read) {
          const notificationIndex = user.notifications.indexOf(userNotification);
          const newNotifications = [
            ...user.notifications.slice(0, notificationIndex),
            res.userNotification,
            ...user.notifications.slice(notificationIndex + 1),
          ];
          dispatch(setNotifications(newNotifications));
          if (user.unreadNotificationCount) dispatch(setUnreadNotificationCount(user.unreadNotificationCount - 1));
        }
      }
    });

    if (notification.notification_type === 'module-assessment-coach-feedback') {
      const module = findModuleById(modulesRef.current, notification.object_id);
      if (!module) {
        Swal.fire('Error!', 'Something went wrong', 'error');
        return;
      }
      Segment.track('Enter Show Feedback', {
        moduleId: module.id,
        title: module.title,
      });
      let feedbackObj = { lesson_type: 'feedback', module };
      dispatch(setSelectedModule({ module }));
      dispatch(setSelectedLesson(feedbackObj));
      history.push({
        pathname: `/modules/${notification.object_id}/feedback`,
        state: {
          lessonData: feedbackObj,
          allModule: '',
          modulePath: `/modules/${notification.object_id}`,
          title: 'Feedback',
        },
      });
    } else history.push(`/notifications/${notification.id}`, { prevPath: '/modules' });
  };

  const handleToggle = (isOpen, event, metadata) => {
    props.onToggle(isOpen);
    setShow(isOpen);
  };

  return (
    <Dropdown align="end" className="cf-notification-icon" onToggle={handleToggle} show={show}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <img alt="" src={process.env.PUBLIC_URL + '/images/menu/icon-notification-active.svg'} />
        {user.unreadNotificationCount ? <span className="cf-badge">{user.unreadNotificationCount}</span> : null}
      </Dropdown.Toggle>

      <Dropdown.Menu
        popperConfig={
          isMobile
            ? {
                strategy: 'fixed',
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -42],
                    },
                  },
                ],
              }
            : {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [15, 0],
                    },
                  },
                ],
              }
        }
      >
        <Dropdown.Header>
          Notifications
          <img
            className="cf-notification-close"
            src={process.env.PUBLIC_URL + '/images/icon/icon-exit.svg'}
            onClick={() => handleToggle(false)}
          />
        </Dropdown.Header>
        <div className="cf-notification-content">
          {user.notifications.map((notification, index) => (
            <Dropdown.Item key={index} eventKey={index}>
              <NotificationItem
                header={notification.header}
                icon={notification.icon}
                title={notification.title}
                subtitle={notification.subtitle}
                date={notification.created_at}
                unread={!notification.is_read}
                onClick={() => clickNotification(notification)}
              />
            </Dropdown.Item>
          ))}
        </div>
        {user.notifications.length === 0 && <span className="cf-noNotification">No notifications</span>}
      </Dropdown.Menu>
    </Dropdown>
  );
};

NotificationIcon.propTypes = {
  onToggle: PropTypes.func,
};

export const NotificationPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const modules = useSelector(selectModule);

  const clickNotification = (notification) => {
    // Read notification
    readNotification(notification.id).then((res) => {
      if (res && !res.isError && !res.errorCode) {
        const userNotification = user.notifications.find((elm) => elm.id == notification.id);
        if (userNotification.is_read !== res.userNotification.is_read) {
          const notificationIndex = user.notifications.indexOf(userNotification);
          const newNotifications = [
            ...user.notifications.slice(0, notificationIndex),
            res.userNotification,
            ...user.notifications.slice(notificationIndex + 1),
          ];
          dispatch(setNotifications(newNotifications));
          if (user.unreadNotificationCount) dispatch(setUnreadNotificationCount(user.unreadNotificationCount - 1));
        }
      }
    });

    if (notification.notification_type === 'module-assessment-coach-feedback') {
      const module = findModuleById(modules, notification.object_id);
      if (!module) {
        Swal.fire('Error!', 'Something went wrong', 'error');
        return;
      }
      Segment.track('Enter Show Feedback', {
        moduleId: module.id,
        title: module.title,
      });
      let feedbackObj = { lesson_type: 'feedback', module };
      history.push({
        pathname: `/modules/_${notification.object_id}/feedback`,
        state: {
          lessonData: feedbackObj,
          allModule: '',
          modulePath: `/modules/_${notification.object_id}`,
          title: 'Feedback',
        },
      });
    } else history.push(`/notifications/${notification.id}`, { prevPath: '/modules' });
  };

  return (
    <div className="cf-notification-page">
      {user.notifications.map((notification, index) => (
        <NotificationItem
          key={index}
          className="cf-notification-item"
          header={notification.header}
          icon={notification.icon}
          title={notification.title}
          subtitle={notification.subtitle}
          date={notification.created_at}
          unread={!notification.is_read}
          onClick={() => clickNotification(notification)}
        />
      ))}
      {user.notifications.length === 0 && <span className="cf-noNotification">No notifications</span>}
    </div>
  );
};

export const NotificationContent = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [notification, setNotification] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userNotification = user.notifications.find((notification) => notification.id == id);
    if (!userNotification) return;
    else if (!userNotification.is_read) {
      readTheNotification(userNotification);
    }

    switch (userNotification.notification_type) {
      case 'initial-assessment-coach-feedback':
        setLoading(true);
        getHumanCoachFeedback().then((res) => {
          if (res && !res.isError && !res.errorCode) {
            dispatch(setHumanCoach(res.data));
            setNotification({
              ...userNotification,
              ...{
                icon: res.data.coach.photo_url,
                title: res.data.coach.name,
                data: res.data,
              },
            });
          } else {
            setNotification(null);
          }
          setLoading(false);
        });
        break;
      case 'initial-assessment-ai-feedback':
        setLoading(true);
        getAICoachFeedback().then((res) => {
          if (res && !res.isError && !res.errorCode) {
            setNotification({ ...userNotification, data: res.ai_response.data });
          } else {
            setNotification(null);
          }
          setLoading(false);
        });
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  const readTheNotification = (notification) => {
    readNotification(notification.id).then((res) => {
      if (res && !res.isError && !res.errorCode) {
        const userNotification = user.notifications.find((elm) => elm.id == notification.id);
        if (userNotification.is_read !== res.userNotification.is_read) {
          const notificationIndex = user.notifications.indexOf(userNotification);
          const newNotifications = [
            ...user.notifications.slice(0, notificationIndex),
            res.userNotification,
            ...user.notifications.slice(notificationIndex + 1),
          ];
          dispatch(setNotifications(newNotifications));
          if (user.unreadNotificationCount) dispatch(setUnreadNotificationCount(user.unreadNotificationCount - 1));
        }
      }
    });
  };

  const RenderContent = ({ type, notification }) => {
    switch (type) {
      case 'initial-assessment-coach-feedback':
        return <CoachFeedback feedback={notification.data} />;
      case 'initial-assessment-ai-feedback':
        return (
          <AIResult result={notification.data} asNotification={true} onContinue={() => history.push('/modules')} />
        );
      default:
        return <span className="cf-noNotification">No notifications</span>;
    }
  };

  RenderContent.propTypes = {
    type: PropTypes.string,
    notification: PropTypes.object,
  };

  return (
    <div className={`${styles.container}`}>
      {notification ? (
        <>
          <div className={styles.header}>
            <div className={styles.headerText}>{notification.header}</div>
            <div className={styles.headerBody}>
              <div className={styles.circle}>
                <img className="cf-icon" alt="" src={notification.icon} />
              </div>
              <div className={styles.headerTitle}>
                <div className={styles.title}>{notification.title}</div>
                <div className={styles.subtitle}>{notification.subtitle}</div>
              </div>
              <div className={styles.headerDate}>
                {notification.created_at
                  ? new Date(notification.created_at.replace(/-/g, '/')).toLocaleDateString()
                  : ''}
              </div>
            </div>
          </div>
          <div className="cf-body">
            <RenderContent type={notification.notification_type} notification={notification} />
          </div>
        </>
      ) : (
        <span className="cf-noNotification">No notifications</span>
      )}
    </div>
  );
};
