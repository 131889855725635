import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import MicRecorder from 'mic-recorder-to-mp3';
import RecordRTC from 'recordrtc';
import { confirmAlert } from 'react-confirm-alert';
import { isSamsungBrowser, isFirefox, isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';

import { blobToFile, msToTime, sleep } from '../../../util';

import AudioPlayer from '../../components/CustomAudioPlayer';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import styles from './Assessment.module.css';
import globalStyles from './InitialAssessment.module.css';
import {
  uploadInitialAssessmentRecord,
  uploadInitialAssessmentRecordV2,
} from '../../../services/initialAssessmentServices';
import { getData } from '../../../util/localStorageHandler';

const Assessment = (props) => {
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const [recorded, setRecorded] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recordedData, setRecordedData] = useState({});
  const [loading, setLoading] = useState(false);
  const indexRef = useRef(0);
  const uploadedRecordsRef = useRef({
    assessment_record_ids: [],
    texts: [],
  });
  const timeoutRef = useRef();
  const count = props.items.length;
  const recorderRef = useRef();
  const startTimeRef = useRef();
  const authUserRef = useRef();
  const uploadAssessmentFunctionRef = useRef();
  const anonymousUserIdRef = useRef();

  useEffect(() => {
    authUserRef.current = getData();
    if (authUserRef.current) {
      uploadAssessmentFunctionRef.current = uploadInitialAssessmentRecord;
    } else {
      const anonymousUserId = localStorage.getItem('anonymousUserId');
      anonymousUserIdRef.current = anonymousUserId;
      uploadAssessmentFunctionRef.current = uploadInitialAssessmentRecordV2;
    }
    // mp3
    if (!isSamsungBrowser)
      recorderRef.current = new MicRecorder({
        bitRate: 128,
      });
  }, []);

  useEffect(() => {
    indexRef.current = index;
  }, [index]);

  const onSkipClick = () => {
    props.onSkip();
  };

  const onSubmitClick = async () => {
    const extension = isSamsungBrowser ? 'webm' : 'mp3';
    setLoading(true);
    const formData = new FormData();
    if (!authUserRef.current) formData.append('anonymousUserId', anonymousUserIdRef.current);
    formData.append(
      'recorded_file',
      blobToFile(recordedData[index].blob, `initial_assessment_${props.items[index].id}.${extension}`),
    );
    formData.append('initial_assessment_id', props.items[index].id);
    formData.append('text', props.items[index].title);
    formData.append(
      'duration',
      `Duration: ${msToTime(recordedData[index].stopTime - recordedData[index].startTime, 'mm:ss')}`,
    );

    uploadAssessmentFunctionRef
      .current(formData)
      .then((res) => {
        if (res && !res.isError && !res.errorCode) {
          uploadedRecordsRef.current.assessment_record_ids.push(res.assessment_record_id);
          uploadedRecordsRef.current.texts.push(props.items[index].title);

          if (index + 1 < count) {
            setRecorded(false);
            setIndex(index + 1);
            setLoading(false);
          } else {
            props.onFinished(uploadedRecordsRef.current);
          }
        } else {
          confirmAlert({
            title: '',
            message: 'Something went wrong, please record again',
            overlayClassName: `${isMobile && 'mobile'}`,
            buttons: [
              {
                label: 'Ok',
                onClick: () => recordAgain(),
              },
            ],
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        confirmAlert({
          title: '',
          message: error.message,
          overlayClassName: `${isMobile && 'mobile'}`,
          buttons: [
            {
              label: 'Ok',
              onClick: () => {
                if (authUserRef.current) history.push('/');
                else history.push('/initialAssessment');
              },
            },
          ],
        });
      });
  };

  const recordAgain = () => {
    setRecorded(false);
  };

  const toggleRecord = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (!recording)
      timeoutRef.current = setTimeout(() => {
        stopRecording();
      }, 45000);

    if (recording) stopRecording();
    else startRecording();
  };

  const startRecording = async () => {
    if (isSamsungBrowser) {
      // RTC
      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorderRef.current = new RecordRTC(mediaStream, { type: 'audio' });
      recorderRef.current.startRecording();
      startTimeRef.current = Date.now();
      setRecording(true);
    } else {
      // mp3
      recorderRef.current
        .start()
        .then(async () => {
          if (isFirefox) await sleep(500);
          startTimeRef.current = Date.now();
          setRecording(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const stopRecording = async () => {
    if (isSamsungBrowser) {
      // RTC
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        setRecording(false);
        onRecordStop({
          blob,
          startTime: startTimeRef.current,
          stopTime: Date.now(),
          blobURL: window.URL.createObjectURL(blob),
        });
        recorderRef.current.reset();
      });
    } else {
      // mp3
      recorderRef.current
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setRecording(false);
          onRecordStop({
            blob,
            startTime: startTimeRef.current,
            stopTime: Date.now(),
            blobURL: window.URL.createObjectURL(blob),
          });
        })
        .catch((e) => {
          alert('We could not retrieve your message');
          console.log(e);
        });
    }
  };

  const onRecordStop = (recordedBlob) => {
    setRecorded(true);
    setRecordedData({ ...recordedData, [indexRef.current]: recordedBlob });
  };

  return (
    <div className={`${globalStyles.contentContainer} ${globalStyles.edgeSpace}`}>
      <div className={`${globalStyles.contentTop} ${styles.contentTop}`}>
        <div className={styles.content}>
          <div className={`${styles.cardsHolder} ${styles.playCard} playCardProgress`}>
            <div className="progressBar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
                data-toggle="modal"
                data-target="#skipModal"
              >
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
              </svg>
              <ProgressBar variant="gray" now={(index / count) * 100} />
            </div>
            <div className={`${styles.topText} audioPlayer-topText`}>
              {recorded && recordedData[index] ? (
                <span>Listen to your recording. If it&apos;s good to go, press &apos;next one&apos;</span>
              ) : (
                <span>{isMobile ? 'Tap' : 'Click'} to record and say the text below</span>
              )}
            </div>
            <div className={`${styles.body} ${props.items[index].title.length > 40 && styles.sentence}`}>
              <span>{props.items[index].title}</span>
            </div>
            <div className={styles.footer}>
              {recorded && recordedData[index] ? (
                <div className={`${styles.soundBtnAudio} ${styles.record}`}>
                  <AudioPlayer
                    mode={'client'}
                    className={`${styles.audioPlayer} capsule`}
                    id={1}
                    source={recordedData[index].blobURL}
                    hideBar={true}
                    playIcon={'/images/audio/playWhite.svg'}
                    pauseIcon={'/images/audio/pauseWhite.svg'}
                  />
                </div>
              ) : (
                <div
                  className={`${styles.soundBtn} ${styles.recordBtn} ${recording ? styles.recording : ''}`}
                  onClick={toggleRecord}
                >
                  <img
                    alt=""
                    src={recording ? '/images/audio/stopIconCircleWhite.svg' : '/images/audio/micIconWhite.svg'}
                  />
                </div>
              )}
            </div>
            {recorded && recordedData[index] && (
              <div className={styles.btnGroup}>
                <Button type="link" content="Try Again" onClick={recordAgain} />
                <Button content="Next One" onClick={onSubmitClick} isLoading={loading} />
              </div>
            )}
          </div>
        </div>

        <Modal
          modalId="skipModal"
          iconSrc="/images/attentionIcon.png"
          title="Are you sure you want to quit?"
          message={
            indexRef.current > 0 || recorded
              ? 'Your recordings might be lost if you quit before submitting.'
              : 'Taking this assessment will give you access to a personalized learning plan prepared by a human pronunciation coach.'
          }
          yesText="Stay"
          noText="Close"
          noClick={onSkipClick}
          className="initial-assessment"
        />
      </div>
    </div>
  );
};

Assessment.propTypes = {
  items: PropTypes.array,
  onSkip: PropTypes.func,
  onFinished: PropTypes.func,
};

export default Assessment;
