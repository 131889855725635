import { API_BASEURL } from '../constants/global';
import { get, post } from '../util/httpClient';
import { getData } from '../util/localStorageHandler';
import _ from 'lodash';

export const getActivityDetailsServices = async (token, id) => {
  let path = `${API_BASEURL}/get-user-activity`;
  return get(path, token);
};

export const addKartraTag = async (tag, _token) => {
  let token = _token;
  if (!token) {
    const _data = getData();
    token = _.get(_data, 'api_token', undefined);
  }

  let path = `${API_BASEURL}/add-kartra-tag`;
  let data = { 'user-tag': tag };
  return post(path, data, token);
};
