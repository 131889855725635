import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';
import DotLoading from '../DotLoading';

const Button = (props) => {
  let {
    type,
    content,
    icon,
    iconImage,
    iconPosition,
    style,
    className,
    isLoading,
    isDisabled,
    submit,
    size,
    ...otherProps
  } = props;
  // icon = 'refresh'
  // iconPosition = 'right'
  let _className = `${styles.btn} ${icon} ${size} `;
  if (className) _className += ` ${className}`;
  switch (type) {
    case 'primary':
      _className += ` ${styles.btnPrimary} `;
      break;
    case 'secondary':
      _className += ` ${styles.btnSecondary} `;
      break;
    case 'gold':
      _className += ` ${styles.btnGold} `;
      break;
    case 'none':
      _className += ` ${styles.btnNone} `;
      break;
    case 'noneBlack':
      _className += ` ${styles.btnNoneBlack} `;
      break;
    case 'warning':
      _className += ` ${styles.btnWarning} `;
      break;
    case 'link':
      _className += ` ${styles.btnLink} `;
      break;
    case 'defaultOutline':
      _className += ` ${styles.btnDefaultOutline} `;
      break;
    default:
      _className += ` ${styles.btnDefault} `;
      break;
  }

  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={_className}
      style={style}
      {...otherProps}
      disabled={isLoading || isDisabled}
    >
      <span
        className={`${styles.icon} ${styles.leftIcon} ${iconPosition ? iconPosition : styles.noIcon} leftIconHolder`}
      >
        {iconImage ? <img src={iconImage} /> : null}
      </span>
      <span className={`${styles.label} labelHolder ${!iconPosition && styles.noIcon}`}>
        {isLoading ? (
          <DotLoading className={styles.loading} type="elastic" color="rgba(255,255,255,.7)" />
        ) : (
          <span>{content}</span>
        )}
        {/* <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> */}
      </span>
      <span
        className={`${styles.icon} ${styles.rightIcon} ${iconPosition ? iconPosition : styles.noIcon} rightIconHolder`}
      >
        {iconImage ? <img src={iconImage} /> : null}
      </span>
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  content: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  icon: PropTypes.oneOf(['rightArrow', 'leftArrow', 'refresh']),
  iconImage: PropTypes.any,
  iconPosition: PropTypes.oneOf(['right', 'left']),
  size: PropTypes.string,
  isDisabled: PropTypes.bool,
  submit: PropTypes.bool,
};

export default Button;
