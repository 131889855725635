import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import { FloatingLabel, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { confirmAlert } from 'react-confirm-alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import { googleSignInFromApp } from '../../../services/socialLoginServices';
import Button from '../../components/Button';
import { API_BASEURL, CF_USER } from '../../../constants/global';
import { setUser } from '../../../actions/user/userSlice';
import { post } from '../../../util/httpClient';
import { checkCouponValidity, internalLogin, isUserLoggedIn } from '../../../services/authServices';
import LoginTemplate from '../../components/Templates/LoginTemplate';
import Segment from '../../../segment';
import styles from './Signup.module.css';

const Signup = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const role_id = CF_USER;
  const [passwordType, setPasswordType] = useState('password');
  const [isLoading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState();
  const redirectUrl = _.get(props, 'location.state.redirectUrl', 'modules');
  const redirectUrlQueryParam = _.get(props, 'location.state.redirectUrlQueryParam', '');

  const SignupSchema = Yup.object().shape({
    email: Yup.string().required('Enter your email id.').email(),
    password: Yup.string().required('Enter your password.'),
    // termsAccepted: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      // termsAccepted: false,
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      if (values.email && values.password) {
        registerUser(values.email, values.password);
      }
    },
  });

  useEffect(() => {
    isUserLoggedIn(history);
  }, []);

  const goToPage = (path, data) => {
    history.push({
      pathname: path,
      state: data,
    });
  };

  const checkReferralCode = async () => {
    if (coupon) {
      try {
        let response = await checkCouponValidity(coupon);
        if (response) {
          Swal.fire('Info', 'Referral code is valid', 'info');
        } else {
          Swal.fire('Info', 'Referral code is not valid', 'info');
        }
      } catch (error) {
        Swal.fire('Error!', error.message ? error.message : 'Some error occurred. Please try again.', 'error');
      }
    } else {
      Swal.fire('Error', 'Enter code.', 'error');
    }
  };

  const responseGoogle = async (response) => {
    if (!response || response.error) {
      if (response.error == 'popup_closed_by_user') {
        Swal.fire('Info!', 'user has closed the login popup', 'info');
      } else if (response.error == 'idpiframe_initialization_failed') {
        Swal.fire('Info!', 'please enable your third party cookies from browser settings for social login', 'info');
      } else {
        Swal.fire('Info!', response.details ? response.details : response.error, 'info');
      }
    } else {
      let socialId = response.googleId;
      let email = response.profileObj && response.profileObj.email ? response.profileObj.email : '';
      let accessToken = response.accessToken;
      //let name = response.xt?response.xt.Ad?response.xt.Ad:"":response.Nt?response.Nt.EW:"";

      let name = response.profileObj ? response.profileObj.givenName : '';

      let dataToSend = {
        token: accessToken,
        role: CF_USER,
        email: email,
        username: name,
      };

      if (email && email != '') {
        const anonymousUserId = localStorage.getItem('anonymousUserId');
        if (anonymousUserId) dataToSend.anonymousUserId = anonymousUserId;
        googleSignIn(dataToSend);
      } else {
        // setLoginStatus('gmail');
        // setShow(true);
        // setPayload(dataToSend);
      }
    }
  };

  const googleSignIn = async (dataToSend) => {
    setLoading(true);

    googleSignInFromApp(dataToSend).then((response) => {
      if (response && !response.isError) {
        Segment.track('WebSignUp', { email: dataToSend.email });
        // Remove anonymousUserId
        localStorage.removeItem('anonymousUserId');
        dispatch(setUser(response.data));
        // Analytics: identify
        let aUser = {
          email: _.get(response, 'data.user.email', ''),
          name: _.get(response, 'data.user.name', ''),
          country: _.get(response, 'data.user.country', ''),
          gender: _.get(response, 'data.user.gender', ''),
          age: _.get(response, 'data.user.birthday', ''),
          hasSubscription: _.has(response, 'data.active_subscription'),
        };
        Segment.identify(aUser.email, aUser);
        Segment.track('WebLogin', {}, response.api_token);

        Swal.fire('Success', response.message && response.message, 'success');
        setLoading(false);
        goToPage('/modules/3', { firstLogin: true }, redirectUrlQueryParam);

        //creating a custom event for loading again some jQuery menu class
        var event = new Event('login', { bubbles: true, cancelable: false });
        document.body.dispatchEvent(event); // event will buble up to window
      } else {
        Segment.track('Login Failed', { error: 'Sign in with Google failed' });
        Swal.fire('Error!', 'Some error occurred. Please try again.', 'error');
        setLoading(false);
      }
      // setLoginStatus('');
      // setModalMail('');
      // setPayload({});
    });
  };

  /**
   * email.not@gmail.com registered non exist email
   */
  const registerUser = async (email, password) => {
    let isValid = true;
    setLoading(true);
    if (coupon) {
      try {
        let response = await checkCouponValidity(coupon);
        if (!response) {
          isValid = false;
          // setCouponError('Referral code is not valid');
        }
      } catch (error) {
        isValid = false;
        // setCouponError('Referral code is not valid');
      }
    }
    // If validate
    if (isValid) {
      const url = `${API_BASEURL}/v1/register`;
      const dataToSend = { email, role_id, password, password_confirmation: password, device_type: 'Web' };
      const anonymousUserId = localStorage.getItem('anonymousUserId');
      if (anonymousUserId) dataToSend.anonymousUserId = anonymousUserId;
      try {
        // Register user
        const response = await post(url, dataToSend);
        if (response && !response.isError) {
          Segment.track('WebSignUp', { email });
          //#region Verification Link
          localStorage.removeItem('anonymousUserId');
          // // Need internal login to apply coupon code.
          const userData = await internalLogin(email, password);
          dispatch(setUser(userData));
          // Analytics: identify
          const aUser = {
            email: _.get(userData, 'user.email', ''),
            name: _.get(userData, 'user.name', ''),
            country: _.get(userData, 'user.country', ''),
            gender: _.get(userData, 'user.gender', ''),
            age: _.get(userData, 'user.birthday', ''),
          };
          Segment.identify(aUser.email, aUser);
          Segment.track('WebLogin', {}, userData.api_token);
          //#endregion

          setLoading(false);
          history.push({
            pathname: '/modules/3',
            state: {
              firstLogin: true,
            },
          });
          // goToPage('/email-verification', { coupon, email, password });
        } else {
          Segment.track('Register Failed', { email, error: response.message });
          if (response.errorCode === 409) {
            confirmAlert({
              title: 'Failed',
              message:
                'It seems you already used this email to register for ChatterFox. Would you like to retrieve your password?',
              overlayClassName: `${isMobile && 'mobile'}`,
              buttons: [
                {
                  label: 'No',
                },
                {
                  label: 'Yes',
                  onClick: () => history.push('/forgot-password'),
                },
              ],
            });
          } else {
            Swal.fire(response.message);
          }
          setLoading(false);
        }
      } catch (error) {
        Swal.fire('Error!', error.message ? error.message : 'Some error occurred. Please try again.', 'error');
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const toggleShowPassword = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  return (
    <LoginTemplate>
      <h1>
        <span className="cf-t-highlight">Create</span> your account
      </h1>
      <div className="cf-t-redLine"></div>
      <div className={`${styles.note} ${styles.listView}`}>
        <span>
          Membership provides access to the ChatterFox platform, a sample lesson, and personal assessment with lesson
          planning
        </span>
        <span>Lessons and coaching feedback require subscription to a paid membership plan</span>
        <span>To see clear results, Chatterfox strongly recommends at least 10 minutes of daily practice</span>
      </div>
      <div className={`${styles.form} cf-t-form`}>
        <GoogleLogin
          className="googleLogin"
          buttonText="Continue with Google"
          clientId="1076767246869-5c8ecj7o5nsdj434km1l2ct3qo9bpqg5.apps.googleusercontent.com"
          onSuccess={responseGoogle}
          onFailure={(error) => console.log(error)}
          cookiePolicy={'single_host_origin'}
        />
        <div className="cf-t-horizontalLine">
          <span>Or</span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <FloatingLabel controlId="floatingEmail" label="Email">
            <Form.Control
              type="email"
              isInvalid={formik.touched.email && !!formik.errors.email}
              disabled={isLoading}
              required
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              autoComplete="new-password"
            />
            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
              type={passwordType}
              isInvalid={formik.touched.password && !!formik.errors.password}
              disabled={isLoading}
              required
              placeholder="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              autoComplete="new-password"
            />
            <img
              className="eye-icon"
              size="lg"
              src={
                passwordType === 'text'
                  ? process.env.PUBLIC_URL + '/images/icon/eye.svg'
                  : process.env.PUBLIC_URL + '/images/icon/eye-slash.svg'
              }
              onClick={toggleShowPassword}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
          </FloatingLabel>
          <div className={styles.termsAndConditions}>
            By signing up, you agree to our{' '}
            <a href="https://chatterfox.com/terms-and-conditions/" target="_blank" rel="noreferrer">
              Terms & Conditions and Privacy Policy
            </a>
          </div>
          <Button content="Become a ChatterFox" submit={true} isLoading={isLoading} onClick={formik.handleSubmit} />
        </form>
        <div className={styles.login}>
          <a href="/">Already have an account? </a>
        </div>
      </div>
    </LoginTemplate>
  );
};

Signup.propTypes = {};

export default Signup;
