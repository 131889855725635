import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './app/store';
import Login from './app/views/pages/auth/Login';
import Signup from './app/views/pages/auth/Signup';
import FluencyActivity from './app/views/pages/settings/activity/fluencyActivity';
import ExternalPayment from './app/views/pages/auth/ExternalPayment';
import ForgotPassword from './app/views/pages/auth/ForgotPassword';
import EmailVerification from './app/views/pages/auth/EmailVerification';
import VerificationSuccess from './app/views/pages/auth/VerificationSuccess';
import ResetPassword from './app/views/pages/auth/ResetPassword';
import PaymentSuccessExternal from './app/views/pages/payment/payment_success_external';
import ScrollToTop from './app/views/components/ScrollToTop';
import InitialAssessment from './app/views/pages/initialAssessment';
import NoomAssessment from './app/views/pages/noomAssessment';
import { TrackHistory } from './app/segment';
import GetLead from './app/views/pages/embedded/GetLead';
import CheckoutWrapper from './app/views/pages/embedded/CheckoutWrapper';
import packageJson from '../package.json';
import { isMobile } from 'react-device-detect';
import './firebase';
import './facebook';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

console.log(`ChatterFox@${packageJson.version} on ${process.env.REACT_APP_ENV}`);

const MobileDetect = () => {
  if (isMobile) {
    document.body.classList.add('mobile');
  }
  return <></>;
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <TrackHistory />
        <MobileDetect />

        <Switch>
          <Route exact path="/" name="Sign In" component={Login} />
          <Route path="/signin" name="Sign In" component={Login} />
          <Route path="/signup" name="Sign Up" component={Signup} />
          <Route path="/emb/form-external-payment" name="External payment" component={GetLead} />
          <Route path="/emb/checkout" name="External payment checkout" component={CheckoutWrapper} />
          <Route path="/emb/confirm" name="External payment confirm" component={PaymentSuccessExternal} />
          <Route path="/form-external-payment" name="External payment" component={ExternalPayment} />
          <Route path="/confirm-external" name="Payment Confirm" component={PaymentSuccessExternal} />
          <Route path="/reset-password" name="Reset Password" component={ResetPassword} />
          <Route path="/forgot-password" name="Forgot Password" component={ForgotPassword} />
          <Route path="/email-verification" name="Email Verification" component={EmailVerification} />
          <Route path="/verification-success" name="Verification Success" component={VerificationSuccess} />
          <Route path="/initialAssessment" name="Fluency Assessment" component={InitialAssessment} />
          <Route path="/get-started" name="Noom Assessment" component={NoomAssessment} />
          <Route path="/fluency" name="Conversational Tips" component={FluencyActivity} />
          <Route component={App} />
        </Switch>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
