import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import facebook from '../../facebook';

const TrackHistory = () => {
  const history = useHistory();

  useEffect(() => {
    return history.listen(async (location) => {
      // console.log(`You changed the page to: ${location.pathname}`, location, window.location.href);
      try {
        window.analytics.page({
          title: location.title || _.get(location, 'state.title', document.title),
        });

        facebook.fbSendPageView();
      } catch (error) {
        console.error(error);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return null;
};

export default TrackHistory;
