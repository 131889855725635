import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import { selectUser, setUser } from '../../../actions/user/userSlice';
import { API_BASEURL, CF_USER } from '../../../constants/global';
import { get, post } from '../../../util/httpClient';
import { setData, getData } from '../../../util/localStorageHandler';
import withAuthorization from '../../../Session/withAuthorization';
import Segment from '../../../segment';
import styles from './Profile.module.css';
import Button from '../../components/Button';

const Profile = (props) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const fileRef = useRef(null);
  let [isLoading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [profileDetails, setProfileDetails] = useState(isMobile ? false : true);
  const [profileMenu, setProfileMenu] = useState(true);
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState({});
  const [countries, setCountries] = useState([]);
  const [languageList, setLanguageList] = useState([
    { name: 'Chinese' },
    { name: 'Spanish' },
    { name: 'Hindi' },
    { name: 'Persian' },
    { name: 'Arabic' },
    { name: 'Portuguese' },
    { name: 'Bengali' },
    { name: 'Russian' },
    { name: 'Japanese' },
    { name: 'Punjabi' },
    { name: 'German' },
    { name: 'Javanese' },
    { name: 'Malay/Indonesian' },
    { name: 'Korean' },
    { name: 'Telugu' },
    { name: 'Vietnamese' },
    { name: 'French' },
    { name: 'Marathi' },
    { name: 'Tamil' },
    { name: 'Urdu' },
    { name: 'Turkish' },
    { name: 'Italian' },
    { name: 'Thai' },
    { name: 'Polish' },
    { name: 'Others' },
  ]);
  const [ages, setAges] = useState([
    '6-10',
    '11-15',
    '16-20',
    '21-25',
    '26-30',
    '31-35',
    '36-40',
    '41-45',
    '46-50',
    '51-55',
    '56-60',
    '61-65',
  ]);
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [language, setLanguage] = useState('');
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [code, setCode] = useState('');

  const SignupSchema = Yup.object().shape({
    email: Yup.string().required('Enter your email id.').email(),
    password: Yup.string().required('Enter your password.'),
    termsAccepted: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      termsAccepted: false,
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      if (values.email && values.password) {
        // registerUser(values.email, values.password);
      }
    },
  });

  useEffect(() => {
    Segment.track('ProfileVisited');
    const data = getData();
    if (data) {
      getUserData(data.api_token);
      setAuthToken(data.api_token);
    }
    getCountryList();
  }, []);

  const getCountryList = async () => {
    let path = `${API_BASEURL}/countries`;
    let res = await get(path);

    if (res && !res.isError) {
      res.data && setCountries(res.data);
    }
  };

  const detailsHandler = (show) => {
    setProfileDetails(show);
    isMobile && setProfileMenu(!show);
  };

  const getUserData = async (data) => {
    let path = `${API_BASEURL}/get-user-data`;
    let res = await get(path, data);
    if (res && !res.isError) {
      if (res.userDetails) {
        const details = res.userDetails;
        details.remainingCoachingCredit = user.remainingCoachingCredit;
        setUserData(details);
        setEmail(details.email ? details.email : '');
        setName(details.name ? details.name : details.username ? details.username : '');
        setCountry(details.country ? details.country : '');
        setGender((details.gender ? details.gender : '').toLowerCase());
        setAge(details.birthday ? details.birthday : '');
        setLanguage(details.native_language ? details.native_language : '');
      }
    }
  };

  const onFileChange = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    if (file) {
      let blnValid = false;
      let sFileName = file.name;
      var _validFileExtensions = ['.jpg', '.jpeg', '.png'];
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() ==
          sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        Swal.fire('Error!', 'File type is not valid.', 'error');
      } else {
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        const photo = new FormData();
        photo.append('image', file);

        let headers = {
          'Content-Type': 'multipart/form-data',
        };
        if (authToken) headers['Authorization'] = `Bearer ${authToken}`;

        axios
          .post(API_BASEURL + '/upload-avatar', photo, { headers })
          .then((serverResponse) => {
            let res = serverResponse.data;
            if (res && !res.isError) {
              Swal.fire('Success!', 'Successfully Uploaded.', 'success');
              getUserData(authToken);

              let newObj = JSON.parse(JSON.stringify(user));
              newObj.photo_url = res.response.file_path;
              Object.preventExtensions(newObj);
              dispatch(setUser({ user: newObj, api_token: newObj.api_token }));
            } else {
              res && Swal.fire('Error!', 'Sorry! something went wrong.', 'error');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const updateProfile = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (edit) {
      let dataToSend = {
        email: email,
        role: CF_USER,
        name: name,
        username: name,
        birthday: age,
        gender: gender,
        native_language: language,
        country: country,
      };

      let path = `${API_BASEURL}/update-profile`;
      let res = await post(path, dataToSend, authToken);

      if (res && !res.isError) {
        Swal.fire('Success!', 'Successfully Updated.', 'success');
        getUserData(authToken);
        if (res.userDetails) {
          setData(res.userDetails);
          dispatch(setUser(res.userDetails));
          // Analytics: identify
          let aUser = {
            email: _.get(res, 'userDetails.user.email', ''),
            name: _.get(res, 'userDetails.user.name', ''),
            country: _.get(res, 'userDetails.user.country', ''),
            gender: _.get(res, 'userDetails.user.gender', ''),
            age: _.get(res, 'userDetails.user.birthday', ''),
            nativeLanguage: _.get(res, 'userDetails.user.native_language', ''),
          };
          Segment.track('ProfileUpdated');
          Segment.identify(aUser.email, aUser);
        }
        setEdit(false);
      } else {
        res && Swal.fire('Error!', res.message, 'error');
      }

      setLoading(false);
    }
  };

  const changePassword = (event) => {
    event.preventDefault();
    history.push({
      pathname: '/changePassword',
      state: {},
    });
  };

  const billingInfo = (event) => {
    event.preventDefault();
    history.push({
      pathname: '/billingInfo',
      state: {},
    });
  };

  const giftCard = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCode('');
  };

  const handleSubmit = async () => {
    let dataToSend = {
      coupon: code,
    };

    let path = `${API_BASEURL}/apply-coupon`;
    let res = await post(path, dataToSend, authToken);

    if (res && !res.isError) {
      Swal.fire('Success!', 'Successfully Updated.', 'success');
      getUserData(authToken);
      handleClose();
      getUserData(authToken);
    } else {
      res && Swal.fire('Error!', res.message, 'error');
      handleClose();
    }
  };

  return (
    <>
      {isMobile && profileDetails && (
        <div className={styles.profileDetailsBack} onClick={() => detailsHandler(false)}>
          <FontAwesomeIcon className={styles.back} icon={faChevronLeft} />
        </div>
      )}
      <div className={styles.container}>
        {profileMenu && (
          <div className={styles.menuCol}>
            <div
              className={`${styles.mCard} ${styles.linkable} ${profileDetails && styles.active}`}
              onClick={() => detailsHandler(true)}
            >
              <div className={`${styles.circleImage} ${styles.icon}`}>
                {userData.photo_url ? <img src={userData.photo_url} /> : <img src="images/profile-ic.png" />}
              </div>
              <div className={styles.contentHolder}>
                <div className={styles.title}>{name}</div>
              </div>
            </div>
          {user.isCoachCreditEnable && (
              <div className={`${styles.mCard}`}>
                <div className={styles.icon}>
                  <img src="images/coachingIcon.png" />
                </div>
                <div className={styles.contentHolder}>
                  <div className={styles.content}>Coaching Credit</div>
                </div>
                <div className={styles.badge}>{userData.remainingCoachingCredit}</div>
              </div>
            )}
            <div className={`${styles.mCard} ${styles.linkable}`}>
              <div className={`${styles.icon} ${styles.small}`}>
                <img src="images/termsIcon.png" />
              </div>
              <div className={styles.contentHolder}>
                <a
                  href="https://chatterfox.com/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.content}
                >
                  Terms & Conditions
                </a>
              </div>
            </div>
            {/* <button type="submit" className="btn-red btn-orange d-block m-auto" onClick={() => giftCard()}>
                Gift Card
              </button> */}
          </div>
        )}
        {profileDetails && (
          <div className={styles.contentCol}>
            <div className={`${styles.circleImage} ${styles.profilePic}`}>
              {userData.photo_url ? <img src={userData.photo_url} /> : <img src="images/profile-ic.png" />}
              <input
                type="file"
                ref={fileRef}
                name="profilePic"
                onChange={(e) => {
                  onFileChange(e);
                }}
              />
            </div>
            <div className={styles.userInfo}>
              <span className={styles.username}>{userData.email && userData.email}</span>
              <span className={styles.plan}>{userData.plan_id ? 'You have an Active Plan' : 'No Active Plan'}</span>
            </div>
            {!edit && (
              <div className={styles.btnHolder}>
                <Button
                  type="secondary"
                  content="Edit Profile"
                  iconImage="./images/editIcon.png"
                  iconPosition="left"
                  size="small"
                  isLoading={isLoading}
                  isDisabled={edit}
                  onClick={() => setEdit(true)}
                />
                <Button
                  type="secondary"
                  content="Billing Info"
                  iconImage="./images/billIcon.png"
                  iconPosition="left"
                  size="small"
                  isLoading={isLoading}
                  // isDisabled={counter >= lesson.words.length}
                  onClick={(e) => billingInfo(e)}
                />
              </div>
            )}
            <div className={`${styles.profileContainer} ${edit && styles.edit}`}>
              <form>
                <div className={styles.profileRow}>
                  <label className={styles.profileLabel}> Name </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name *"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    readOnly={edit ? false : true}
                  />
                </div>
                <div className={styles.profileRow}>
                  <label className={styles.profileLabel}> Country </label>
                  <select
                    className="form-control"
                    id=""
                    onChange={(e) => setCountry(e.target.value)}
                    disabled={edit ? false : true}
                  >
                    <option value="">Country of your current Resident</option>
                    {countries.map((data, index) => {
                      return (
                        <option value={data.name} selected={data.name == country} key={index}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={`${styles.profileRow} ${styles.radioRow}`}>
                  <label className={styles.profileLabel}>Gender</label>
                  <br className={styles.normalBr} />
                  {!edit ? (
                    <span className={styles.genderText}>{gender}</span>
                  ) : (
                    <>
                      <div className="form-check-inline">
                        <label className={styles.profileRadio} htmlFor="radio1">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="radio1"
                            name="optradio"
                            value="option1"
                            checked={gender == 'male' ? true : false}
                            onClick={() => setGender('male')}
                            disabled={edit ? false : true}
                          />
                          Male
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <label className={styles.profileRadio} htmlFor="radio2">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="radio2"
                            name="optradio"
                            value="option2"
                            checked={gender == 'female' ? true : false}
                            onClick={() => setGender('female')}
                            disabled={edit ? false : true}
                          />
                          Female
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <label className={styles.profileRadio} htmlFor="radio3">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="radio3"
                            name="optradio"
                            value="option2"
                            checked={gender == 'other' ? true : false}
                            onClick={() => setGender('other')}
                            disabled={edit ? false : true}
                          />
                          Others
                        </label>
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.profileRow}>
                  <label className={styles.profileLabel}> Age </label>
                  <select
                    className="form-control"
                    id=""
                    onChange={(e) => setAge(e.target.value)}
                    disabled={edit ? false : true}
                  >
                    <option value="">Select Age</option>
                    {ages &&
                      ages.map((data, index) => {
                        return (
                          <option selected={data == age ? true : false} value={data} key={index}>
                            {data}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className={`${styles.profileRow} ${styles.lastRow}`}>
                  <label className={styles.profileLabel}> Language </label>
                  <select
                    className="form-control"
                    id=""
                    onChange={(e) => setLanguage(e.target.value)}
                    disabled={edit ? false : true}
                  >
                    <option value="" selected>
                      Select Native Language
                    </option>
                    {languageList &&
                      languageList.map((data, index) => {
                        return (
                          <option value={data.name} selected={data.name == language ? true : false} key={index}>
                            {data.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div>
                  {edit ? (
                    <div className={styles.centerBtn}>
                      <Button
                        type="primary"
                        content="Save Changes"
                        iconPosition="left"
                        size="large"
                        isLoading={isLoading}
                        onClick={(e) => updateProfile(e)}
                      />
                    </div>
                  ) : null}
                </div>
                <div>
                  {!edit ? (
                    <div className={styles.centerBtn}>
                      <Button
                        type="secondary"
                        content="Change Password"
                        iconPosition="left"
                        size="large"
                        isLoading={isLoading}
                        isDisabled={edit}
                        onClick={(e) => changePassword(e)}
                      />
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Enter the code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Redeem Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(Profile);
