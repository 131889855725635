import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import userReducer from './actions/user/userSlice';
import moduleReducer from './actions/module/moduleSlice';
import lessonReducer from './actions/lesson/lessonStore';
import lessonProgressReducer from './actions/lesson/lessonProgresStore';
import lessonResultReducer from './actions/lesson/lessonResultStore';
import breadCrumpsReducer from './actions/breadcrumps/breadCrumps';
import initialAssessmentReducer from './actions/initialAssessment/initialAssessmentStore';
import logger from 'redux-logger';
import { reduxBatch } from '@manaflair/redux-batch';

// const middleware = [...getDefaultMiddleware(), logger];
const middleware = [...getDefaultMiddleware()];

const reducer = {
  user: userReducer,
  module: moduleReducer,
  breadcrumps: breadCrumpsReducer,
  lessons: lessonReducer,
  lessonsresult: lessonResultReducer,
  lessonprogress: lessonProgressReducer,
  initialAssessment: initialAssessmentReducer,
};

export default configureStore({
  reducer,
  middleware,
  enhancers: [reduxBatch],
});

/**
 * https://redux-toolkit.js.org/api/configureStore
 * https://www.npmjs.com/package/@manaflair/redux-batch
 * https://github.com/LogRocket/redux-logger#readme
 */
