import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Switch from '../../../components/MTSwitch';
import Segment from '../../../../segment';
import { selectLessonStore, setLessonStore } from '../../../../actions/lesson/lessonStore';
import { selectSelectedModule } from '../../../../actions/module/moduleSlice';
import { CF_USER } from '../../../../constants/global';
import withAuthorization from '../../../../Session/withAuthorization';
import { getData } from '../../../../util/localStorageHandler.js';
import { getModuleDetailService, clearPostLesson, getFeedback } from '../../../../services/moduleServices';
import { getListenOptions, getFluencyCategory } from '../../../../services/lessonsServices';
import ModuleSection from './ModuleSection';
import styles from './index.module.css';
import Feedback from '../lesson/Feedback';
import DotLoading from '../../../components/DotLoading';

const ModuleDetails = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();
  let allLessonData = useSelector(selectLessonStore);
  const selectedModule = useSelector(selectSelectedModule);
  let allModulesData = [];
  let moduleData = { id: props.moduleId };
  let moduleSectionIndex = 1;
  const modulePath = `/modules/${props.moduleId}`;
  const [loading, setLoading] = useState(false);
  const [moduleDetailsData, setModuleDetailsData] = useState({});
  const [guided_lesson, setGuidedLesson] = useState([]);
  const [pre_lesson, setPreLesson] = useState([]);
  const [fluency_activity, setFluencyActivity] = useState([]);
  const [final_assignment, setFinalAssignment] = useState([]);
  const [feedback, setFeedback] = useState();
  const flagRef = useRef(true); // One time send Segment track
  const css = `#feedbacklabel {display: none !important;}`;

  useEffect(() => {
    if (!selectedModule) {
      history.push('/modules');
      return;
    }
    if (selectedModule.is_feed_back_avilable) {
      getFeedback({ module_id: selectedModule.id }).then((res) => {
        if (res && !res.isError && !res.errorCode) {
          setFeedback({
            lesson_type: 'feedback',
            type: 'feedback',
            unique_id: '',
            title: `Coach ${_.get(res.data, 'coachData.name', 'Clara')}`,
            is_completed: true,
            icon_url: _.get(res.data, 'coachData.photo'),
            hasFeedback: true,
            isCompleted: true,
          });
        }
      });
    } else {
      setFeedback({
        lesson_type: 'feedback',
        type: 'feedback',
        unique_id: '',
        title: 'Coach Clara',
        is_completed: true,
        icon_url: process.env.PUBLIC_URL + '/images/coachAvatar.jpg',
        hasFeedback: false,
        isCompleted: false,
      });
    }
    if (selectedModule.intro_module) {
      Segment.track('IntoPVisited');
    }
    Segment.track('Module Start', {
      moduleId: props.moduleId,
      name: _.get(props, 'location.state.title'),
    });
    let userDataFromLocalStorage = getData();
    if (userDataFromLocalStorage && moduleData) {
      getModuleDetails(userDataFromLocalStorage.api_token, moduleData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (feedback && !feedback.hasFeedback) {
      let isCompleted = false;
      if (final_assignment.length !== 0)
        isCompleted = final_assignment
          .map((value) => value.is_completed)
          .reduce((previous, current) => previous && current, true);
      if (selectedModule.intro_module && isCompleted) setFeedback({ ...feedback, hasFeedback: true, isCompleted });
      else setFeedback({ ...feedback, isCompleted });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [final_assignment]);

  useEffect(() => {
    if (_.get(allLessonData, '[0].is_completed') === false && flagRef.current) {
      Segment.track('Module Init', {
        moduleId: props.moduleId,
        name: _.get(props, 'location.state.title'),
      });
      flagRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLessonData]);

  const getModuleDetails = async (token, id) => {
    setLoading(true);
    getModuleDetailService(id).then((res) => {
      if (!res.isError && !res.errorCode) {
        getListenOptions(token, id).then((wordRes) => {
          getFluencyCategory(token).then((res) => {});

          setLoading(false);
          setModuleDetailsData(res.module);

          generatePreLessonArray(res.module);
          generateGuidedLessonArray(res.module, wordRes.listen_options);
          generateFluencyActivityArray(res.module);
          generateFinalAssignmentArray(res.module);

          //store all lesson data to global store
          dispatch(setLessonStore(allModulesData));
        });
      } else {
        setLoading(false);
      }
    });
  };

  const generatePreLessonArray = (module) => {
    let temArr = [];
    let pre_lesson = module.prelessson ? module.prelessson : [];

    for (let i = 0; i < pre_lesson.prelessons.length; i++) {
      //push start and completion message to lesson array
      if (i == 0) {
        pre_lesson.prelessons[i].start_message = pre_lesson.pre_lesson_start_msg;
      }
      if (i == pre_lesson.prelessons.length - 1) {
        pre_lesson.prelessons[i].end_message = pre_lesson.pre_lesson_completion_msg;
      }

      temArr.push(pre_lesson.prelessons[i]);

      pre_lesson.prelessons[i].lesson_type = 'preLesson';
      pre_lesson.prelessons[i].unique_id = 'preLesson' + i;

      allModulesData.push(pre_lesson.prelessons[i]);
    }

    setPreLesson(temArr);
  };

  const generateFluencyActivityArray = (module) => {
    let temArr = [];
    let fluency_lesson = module.fluency_activity ? module.fluency_activity : [];

    for (let i = 0; i < fluency_lesson.fluency_activities.length; i++) {
      //push start and completion message to lesson array
      if (i == 0) {
        fluency_lesson.fluency_activities[i].start_message = fluency_lesson.fluency_activities_start_message;
      }
      if (i == fluency_lesson.fluency_activities.length - 1) {
        fluency_lesson.fluency_activities[i].end_message = fluency_lesson.fluency_activities_completion_msg;
      }

      temArr.push(fluency_lesson.fluency_activities[i]);

      fluency_lesson.fluency_activities[i].lesson_type = 'fluencyActivity';
      fluency_lesson.fluency_activities[i].unique_id = 'fluencyActivity' + i;
      allModulesData.push(fluency_lesson.fluency_activities[i]);
    }

    setFluencyActivity(temArr);
  };

  const generateFinalAssignmentArray = (module) => {
    let temArr = [];
    let final_lesson = module.final_assignment ? module.final_assignment : [];

    for (let i = 0; i < final_lesson.length; i++) {
      //push start and completion message to lesson array
      if (i == 0) {
        final_lesson[i].start_message = final_lesson[i].start_msg;
      }
      if (i == final_lesson.length - 1) {
        final_lesson[i].end_message = final_lesson[i].completion_msg;
      }

      temArr.push(final_lesson[i]);
      final_lesson[i].lesson_type = 'finalAssignment';
      final_lesson[i].unique_id = 'finalAssignment' + i;
      final_lesson[i].currentNumber = i + 1;
      final_lesson[i].totalCount = final_lesson.length;
      allModulesData.push(final_lesson[i]);
    }

    setFinalAssignment(temArr);
  };

  const generateGuidedLessonArray = (module, wordListingForListenAndChoose) => {
    let temArr = [];
    let guided_lesson = module.guided_lesson;

    //video_lessons >> lessons
    for (let i = 0; i < guided_lesson.video_lessons.lessons.length; i++) {
      //push start and completion message to lesson array
      if (i == 0) {
        guided_lesson.video_lessons.lessons[i].start_message = guided_lesson.video_lessons.video_lesson_start_msg;
      }
      if (i == guided_lesson.video_lessons.lessons.length - 1) {
        guided_lesson.video_lessons.lessons[i].end_message = guided_lesson.video_lessons.video_lesson_completion_msg;
      }

      //guided_lesson.video_lessons.lessons[i].is_completed = guided_lesson.video_lessons.is_completed?guided_lesson.video_lessons.is_completed:false;

      temArr.push(guided_lesson.video_lessons.lessons[i]);
      guided_lesson.video_lessons.lessons[i].lesson_type = 'guidedLesson';
      guided_lesson.video_lessons.lessons[i].unique_id = 'video_lesson' + i;
      allModulesData.push(guided_lesson.video_lessons.lessons[i]);
    }

    //lesson_and_records
    for (let i = 0; i < guided_lesson.listen_and_records.lesson_and_records.length; i++) {
      //push start and completion message to lesson array
      if (i == 0) {
        guided_lesson.listen_and_records.lesson_and_records[i].start_message =
          guided_lesson.listen_and_records.listen_record_start_msg;
      }
      if (i == guided_lesson.listen_and_records.lesson_and_records.length - 1) {
        guided_lesson.listen_and_records.lesson_and_records[i].end_message =
          guided_lesson.listen_and_records.listen_record_completion_msg;
      }

      guided_lesson.listen_and_records.lesson_and_records[i].is_completed = guided_lesson.listen_and_records
        .is_completed
        ? guided_lesson.listen_and_records.is_completed
        : false;

      guided_lesson.listen_and_records.lesson_and_records[i].words = [];

      for (let b = 0; b < guided_lesson.listen_and_records.lesson_and_records.length; b++) {
        guided_lesson.listen_and_records.lesson_and_records[i].words.push({
          word: guided_lesson.listen_and_records.lesson_and_records[b].word,
          //unique_id:guided_lesson.listen_and_records.lesson_and_records[b].unique_id,
          unique_id: 'listen_record' + b,
        });
      }

      guided_lesson.listen_and_records.lesson_and_records[i].icon_url = guided_lesson.listen_and_records.icon_url;
      guided_lesson.listen_and_records.lesson_and_records[i].gray_icon_url =
        guided_lesson.listen_and_records.gray_icon_url;

      temArr.push(guided_lesson.listen_and_records.lesson_and_records[i]);
      guided_lesson.listen_and_records.lesson_and_records[i].lesson_type = 'guidedLesson';
      guided_lesson.listen_and_records.lesson_and_records[i].lesson_title = guided_lesson.listen_and_records.title;
      guided_lesson.listen_and_records.lesson_and_records[i].unique_id = 'listen_record' + i;

      allModulesData.push(guided_lesson.listen_and_records.lesson_and_records[i]);
    }

    //listen_and_choose_correct_options
    for (let i = 0; i < guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options.length; i++) {
      //push start and completion message to lesson array
      if (i == 0) {
        guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].start_message =
          guided_lesson.listen_and_choose_correct_option.listen_choose_start_msg;
      }
      if (i == guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options.length - 1) {
        guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].end_message =
          guided_lesson.listen_and_choose_correct_option.listen_option_result_msg;
      }

      guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].icon_url =
        guided_lesson.listen_and_choose_correct_option.icon_url;
      guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].gray_icon_url =
        guided_lesson.listen_and_choose_correct_option.gray_icon_url;

      temArr.push(guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i]);
      guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].lesson_type = 'guidedLesson';
      guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].lesson_title =
        guided_lesson.listen_and_choose_correct_option.title;
      guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].unique_id =
        'listen_choose' + i;

      guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].is_completed = guided_lesson
        .listen_and_choose_correct_option.is_completed
        ? guided_lesson.listen_and_choose_correct_option.is_completed
        : false;

      let pos = wordListingForListenAndChoose
        .map((e) => e.id)
        .indexOf(guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].id);

      guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].wordListing =
        wordListingForListenAndChoose[pos];
      guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].index = i + 1;
      guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i].totalllength =
        wordListingForListenAndChoose.length;

      allModulesData.push(guided_lesson.listen_and_choose_correct_option.listen_and_choose_correct_options[i]);
    }

    //recognising_tools
    for (let i = 0; i < guided_lesson.recognising_tools.recognising_tools.length; i++) {
      //push start and completion message to lesson array
      if (i == 0) {
        guided_lesson.recognising_tools.recognising_tools[i].start_message =
          guided_lesson.recognising_tools.recognising_tool_start_msg;
      }
      if (i == guided_lesson.recognising_tools.recognising_tools.length - 1) {
        guided_lesson.recognising_tools.recognising_tools[i].end_message =
          guided_lesson.recognising_tools.recognising_tool_completion_msg;
      }

      guided_lesson.recognising_tools.recognising_tools[i].words = [];

      guided_lesson.recognising_tools.recognising_tools[i].is_completed = guided_lesson.recognising_tools.is_completed
        ? guided_lesson.recognising_tools.is_completed
        : false;

      for (let b = 0; b < guided_lesson.recognising_tools.recognising_tools.length; b++) {
        guided_lesson.recognising_tools.recognising_tools[i].words.push({
          word: guided_lesson.recognising_tools.recognising_tools[b].word,
          //unique_id:guided_lesson.recognising_tools.recognising_tools[b].unique_id,
          unique_id: 'recognising_tools' + b,
        });
      }

      guided_lesson.recognising_tools.recognising_tools[i].icon_url = guided_lesson.recognising_tools.icon_url;
      guided_lesson.recognising_tools.recognising_tools[i].gray_icon_url =
        guided_lesson.recognising_tools.gray_icon_url;

      temArr.push(guided_lesson.recognising_tools.recognising_tools[i]);
      guided_lesson.recognising_tools.recognising_tools[i].lesson_type = 'guidedLesson';
      guided_lesson.recognising_tools.recognising_tools[i].lesson_title = guided_lesson.recognising_tools.title;
      guided_lesson.recognising_tools.recognising_tools[i].unique_id = 'recognising_tools' + i;

      allModulesData.push(guided_lesson.recognising_tools.recognising_tools[i]);
    }

    setGuidedLesson(temArr);
  };

  const goToReview = () => {
    let reviewObj = { lesson_type: 'review', module_id: moduleData.id };
    redirectTo(modulePath + '/review', {
      lessonData: reviewObj,
      allModule: '',
      modulePath: modulePath,
    });
  };

  const redirectTo = (path, data = {}) => {
    if (path && path != '') {
      history.push({
        pathname: path,
        state: data,
      });
    }
  };

  return (
    <div className={styles.container}>
      <style>{css}</style>
      <div className={`${isMobileOnly && styles.headerMobile} ${styles.header} wave-bg-white`}>
        <div className={styles.headerTitleBox}>
          <div className={styles.moduleImage}>
            {moduleDetailsData.list_icon ? (
              <img src={`https://chatterfox-app-static-storage.s3.amazonaws.com${moduleDetailsData.list_icon}`} />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/images/empty.png`} />
            )}
          </div>
          <div className={styles.titleHolder}>
            <h2>{moduleDetailsData.title ? moduleDetailsData.title : 'Loading...'}</h2>
            <h3>{moduleDetailsData.description ? moduleDetailsData.description : 'Loading...'}</h3>
          </div>
        </div>
      </div>
      {loading ? (
        <DotLoading className={styles.loading} type="elastic" color="rgba(250,100,0,1)" />
      ) : (
        <div className={styles.body}>
          {pre_lesson.length ? (
            <ModuleSection
              title="Pre Lesson"
              type="pre_lesson"
              index={moduleSectionIndex++}
              module={pre_lesson}
              modulePath={modulePath}
              path={modulePath + '/preLesson'}
            />
          ) : null}

          {guided_lesson.length ? (
            <ModuleSection
              title="Guided Lesson"
              type="guided_lesson"
              index={moduleSectionIndex++}
              module={guided_lesson}
              modulePath={modulePath}
              path={modulePath + '/guidedLesson'}
            />
          ) : null}

          {fluency_activity.length ? (
            <ModuleSection
              title="Conversational Tips"
              type="fluency_activity"
              index={moduleSectionIndex++}
              module={fluency_activity}
              modulePath={modulePath}
              path={modulePath + '/fluencyActivity'}
            />
          ) : null}

          {final_assignment.length ? (
            <ModuleSection
              title="Final Assessment"
              type="final_assignment"
              index={moduleSectionIndex++}
              module={final_assignment}
              modulePath={modulePath}
              path={modulePath + '/finalAssessment'}
            />
          ) : null}

          {feedback ? (
            <ModuleSection
              title="Human Coaching and Feedback"
              type="feedback"
              index={moduleSectionIndex++}
              module={[feedback]}
              modulePath={modulePath}
              path={modulePath + '/feedback'}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

ModuleDetails.propTypes = {
  moduleId: PropTypes.string,
  path: PropTypes.string,
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(ModuleDetails);
