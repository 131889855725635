import React from 'react';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import styles from './LoginTemplate.module.css';

const LoginTemplate = (props) => {
  return (
    <div className={`${styles.container} ${isMobile && 'mobile'}`}>
      <Helmet>
        <meta name="keywords" content="ChatterFox" />
      </Helmet>
      <div className={styles.content}>
        <img className={styles.logo} src={process.env.PUBLIC_URL + '/logo.png'} />
        {props.children}
      </div>
    </div>
  );
};

LoginTemplate.propTypes = {
  children: PropTypes.any,
};

export default LoginTemplate;
