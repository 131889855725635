import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import _ from 'lodash';

import { getpaymentsDetailsforPlan, addOrder, stripeSubscripe } from '../../../services/paymentServices';
import { getSessionData } from '../../../util/localStorageHandler.js';
import SubmitButtonBig from '../../components/SubmitButtonBig';
import Segment from '../../../segment';
import styles from './Checkout.module.css';

const Checkout = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const userData = getSessionData();

  const [isLoading, setLoading] = useState(false);
  const [plans, setPlans] = useState(null);
  const [zipCode, setZipCode] = useState('');
  const [isSubmitHit, setIsSubmitHit] = useState(false);
  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardExpiryError, setCardExpiryError] = useState(false);
  const [cardCvvError, setCardCvvError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [adjective, setAdjective] = useState('');
  const [userEmail, setUserEmail] = useState(_.get(userData, 'user.email', ''));
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [isRedirected, setIsRedirected] = useState(false);

  useEffect(() => {
    // Hide zoho feedback widget
    var sheet = document.createElement('style');
    sheet.innerHTML = '.feedbacklab {display: none !important;}';
    document.body.appendChild(sheet);

    const values = queryString.parse(history.location.search);

    if (!values.pid) {
      Swal.fire('Error!', 'Please give a proper plan id', 'error').then(() =>
        window.top.postMessage('redirectToPricing', '*'),
      );
    } else {
      let token = userData.api_token_external;

      getpaymentsDetailsforPlan(token, values.pid).then((res) => {
        Segment.track('Checkout Step View', {
          name: res.plans[0].display_name,
          price: res.plans[0].original_price,
          hasTrial: res.plans[0].is_trial_plan,
        });
        setPlans(res);

        if (res.plans[0].interval_count == 1) {
          if (res.plans[0].interval.toLowerCase() == 'month') {
            setAdjective('ly');
          } else if (res.plans[0].interval.toLowerCase() == 'year') {
            setAdjective('ly');
          } else if (res.plans[0].interval.toLowerCase() == 'week') {
            setAdjective('ly');
          } else {
            setAdjective('');
          }
        } else {
          setAdjective('');
        }

        if (!res.plans[0].is_subscribeble) {
          Swal.fire('Error!', 'This plan is not upgradable with your current plan', 'error').then(() =>
            window.top.postMessage('redirectToWebClient', '*'),
          );
        }

        if (res.user_current_plan.is_admin_subscribed) {
          Swal.fire('Error!', res.user_current_plan.admin_subscribed_upgrade_message, 'error').then(() =>
            window.top.postMessage('redirectToWebClient', '*'),
          );
        } else if (res.user_current_plan.plan_id) {
          if (parseInt(values.pid) == parseInt(res.user_current_plan.plan_id)) {
            Swal.fire('Info!', 'You already subscribe this plan', 'info').then(() =>
              window.top.postMessage('redirectToWebClient', '*'),
            );
            // redirectTo('/confirm-external', {
            //   package_name: res.plans[0].display_name,
            //   title: 'External Payment Confirmation',
            // });
          }
        }
      });
    }

    // window.addEventListener('beforeunload', (ev) => {
    //   ev.preventDefault();
    //   logOut();
    //   return (ev.returnValue = 'Are you sure you want to close?');
    // });
  }, []);

  const goToTop = () => {
    //it always scroll to top when lesson get change
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onClickPaypal = () => {
    Segment.track('Payment Info Entered', {
      paymentMethod: 'Paypal',
      name: _.get(plans, 'plans[0].display_name'),
      price: _.get(plans, 'plans[0].original_price'),
      hasTrial: _.get(plans, 'plans[0].is_trial_plan'),
    });
    setLoading(true);
    let userToken = userData.api_token_external;
    let planId = plans ? plans.plans[0].id : '';
    let stripeToken = '';
    addOrder(userToken, planId, 'paypal').then((res) => {
      stripeSubscripe(userToken, res.order_id, stripeToken).then((resp) => {
        console.log('stripe subscription response...', resp);
        if (resp && resp.isError == 0) {
          setLoading(false);
          redirectToPaypal(resp.data.paypal_link);
        } else {
          if (!resp.message) {
            Swal.fire('Error!', 'Something went wrong', 'error');
          } else {
            Swal.fire('Error!', resp.message, 'error');
          }
          setLoading(false);
        }
      });
    });
  };

  const redirectToPaypal = (url) => {
    try {
      window.open(url, '_blank').focus();
      setIsRedirected(true);
    } catch (error) {
      setIsRedirected(false);
    }
  };

  const addPlanOrder = async () => {
    Segment.track('Payment Info Entered', {
      paymentMethod: 'Stripe',
      name: _.get(plans, 'plans[0].display_name'),
      price: _.get(plans, 'plans[0].original_price'),
      hasTrial: _.get(plans, 'plans[0].is_trial_plan'),
    });
    setIsSubmitHit(true);
    if (!userFirstName || !userEmail || !userLastName) {
      goToTop();
      return false;
    }

    if (!stripe || !elements) {
      console.log('stripe or element not found...');
      return;
    }
    setLoading(true);

    const cardElement = await elements.getElement('cardNumber');
    if (cardElement) {
      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: userFirstName + ' ' + userLastName,
            address: {
              postal_code: zipCode,
            },
          },
        })
        .then((result) => {
          if (!result.error) {
            let userToken = userData.api_token_external;
            let planId = plans ? plans.plans[0].id : '';
            let stripeToken = result.paymentMethod.id;

            addOrder(userToken, planId, 'stripe').then((res) => {
              if (res.isError == 0) {
                stripeSubscripe(userToken, res.order_id, stripeToken).then((resp) => {
                  setLoading(false);
                  if (resp && resp.isError == 0) {
                    Segment.track('Order Completed', {
                      paymentMethod: 'Stripe',
                      name: _.get(plans, 'plans[0].display_name'),
                      price: _.get(plans, 'plans[0].original_price'),
                      hasTrial: _.get(plans, 'plans[0].is_trial_plan'),
                    });
                    setIsSubmitHit(false);
                    window.top.postMessage({ status: 'confirm', packageName: plans.plans[0].display_name }, '*');
                  } else {
                    Segment.track('Payment Failed', {
                      paymentMethod: 'Stripe',
                      name: _.get(plans, 'plans[0].display_name'),
                      price: _.get(plans, 'plans[0].original_price'),
                      hasTrial: _.get(plans, 'plans[0].is_trial_plan'),
                      error: resp.message,
                    });
                    Swal.fire('Error!', 'Something went wrong', 'error');
                    setLoading(false);
                  }
                });
              } else {
                Swal.fire('Error!', res.message, 'error');
                setLoading(false);
              }
            });
          } else {
            if (result.error.code == 'incomplete_number' || result.error.code == 'invalid_number') {
              setCardNumberError(true);
            } else if (result.error.code == 'incomplete_expiry' || result.error.code == 'invalid_expiry_year_past') {
              setCardExpiryError(true);
            } else if (result.error.code == 'incomplete_cvc' || result.error.code == 'invalid_cvc') {
              setCardCvvError(true);
            } else {
              Swal.fire('Error!', 'card not valid', 'error');
            }
            setLoading(false);
          }
        });
    }
  };

  return (
    <section className={styles.mainForm} style={{ marginTop: 'unset' }}>
      <div className="container">
        <div className="row">
          <div className={styles.header}>
            <img className={styles.logo} src={process.env.PUBLIC_URL + '/images/logoWtext.png'} />
            <span className={styles.headerTitle}>CHECKOUT</span>
          </div>

          <div className="col-lg-7">
            {userData ? (
              <div className={styles.panel}>
                <div className="col-lg-12">
                  <div className={styles.billHeading}>
                    <img className={styles.titleBullet} src={process.env.PUBLIC_URL + '/images/titleArrow.svg'} />
                    <h2>Billing Details</h2>
                  </div>
                </div>
                <div className="col-lg-6 text-left mt-1">
                  <label>First Name</label>
                  <input
                    type="text"
                    className={!userFirstName && isSubmitHit ? 'form-control mb-cus border-red' : 'form-control mb-cus'}
                    placeholder="First name"
                    onChange={(e) => {
                      setUserFirstName(e.target.value);
                    }}
                  />
                  {!userFirstName && isSubmitHit ? <p className="mb-1 red">Please enter user first name </p> : null}
                </div>
                <div className="col-lg-6 text-left mt-1">
                  <label>Last Name</label>
                  <input
                    type="text"
                    className={!userLastName && isSubmitHit ? 'form-control mb-cus border-red' : 'form-control mb-cus'}
                    placeholder="Last name"
                    onChange={(e) => {
                      setUserLastName(e.target.value);
                    }}
                  />
                  {!userLastName && isSubmitHit ? <p className="mb-1 red">Please enter user last name </p> : null}
                </div>
                <div className="col-lg-12 mt-1">
                  <label>Email</label>
                  <input
                    type="email"
                    className={!userEmail && isSubmitHit ? 'form-control mb-cus border-red' : 'form-control mb-cus'}
                    placeholder="email"
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                    value={userEmail}
                  />
                  {!userEmail && isSubmitHit ? <p className="mb-1 red">Please enter user email </p> : null}
                </div>
              </div>
            ) : null}

            <div className={styles.panel}>
              <div className="col-lg-12">
                <div className={styles.billHeading}>
                  <img className={styles.titleBullet} src={process.env.PUBLIC_URL + '/images/titleArrow.svg'} />
                  <h2>Payment Method</h2>
                </div>
              </div>
              <div className="col-lg-12 mt-1">
                <ul className="nav nav-tabs payment-method">
                  <ul className="nav nav-tabs payment-method">
                    <li className={paymentMethod == 'stripe' ? 'active' : ''}>
                      <button
                        type="button"
                        data-toggle="tab"
                        href="#stripe"
                        className={
                          paymentMethod == 'stripe'
                            ? `btn ${styles.paymentMethodBtn} active`
                            : `btn ${styles.paymentMethodBtn}`
                        }
                        onClick={() => {
                          setPaymentMethod('stripe');
                        }}
                      >
                        <img src={process.env.PUBLIC_URL + '/images/card_icon.svg'} /> Credit Card
                        <img
                          className={styles.activeCheck}
                          src={process.env.PUBLIC_URL + '/images/greenTickCircle.svg'}
                        />
                      </button>
                    </li>
                    <li className={paymentMethod == 'paypal' ? 'active' : ''}>
                      <button
                        type="button"
                        data-toggle="tab"
                        href="#paypal"
                        className={
                          paymentMethod == 'paypal'
                            ? `btn ${styles.paymentMethodBtn} active`
                            : `btn ${styles.paymentMethodBtn}`
                        }
                        onClick={() => {
                          setPaymentMethod('paypal');
                          setIsRedirected(false);
                        }}
                      >
                        <img src={process.env.PUBLIC_URL + '/images/payPal_icon.png'} /> PayPal
                        <img
                          className={styles.activeCheck}
                          src={process.env.PUBLIC_URL + '/images/greenTickCircle.svg'}
                        />
                      </button>
                    </li>
                  </ul>
                </ul>
              </div>

              {paymentMethod == 'stripe' && (
                <>
                  <div className="col-lg-12 col-xs-12 mt-1">
                    <label>Card Number</label>
                    <CardNumberElement
                      className={cardNumberError && isSubmitHit ? 'form-control border-red mb-cus' : 'form-control'}
                      options={{ style: { base: { backgroundColor: 'white' } } }}
                      onChange={() => {
                        setCardNumberError(false);
                      }}
                    />
                    {cardNumberError && isSubmitHit ? <p className="mb-2 red">Please enter Card Number </p> : null}
                  </div>
                  <div className="col-lg-4 col-xs-12 mt-1">
                    <label>EXP Month & Year</label>
                    <CardExpiryElement
                      className={cardExpiryError && isSubmitHit ? 'form-control border-red mb-cus' : 'form-control'}
                      options={{ style: { base: { backgroundColor: 'white' } } }}
                      onChange={() => {
                        setCardExpiryError(false);
                      }}
                    />
                    {cardExpiryError && isSubmitHit ? <p className="mb-2 red">Please enter Card Expiry date </p> : null}
                  </div>
                  <div className="col-lg-4 col-xs-12 mt-1">
                    <label>CVV</label>
                    <CardCvcElement
                      className={
                        cardCvvError && isSubmitHit
                          ? 'form-control border-red mb-cus float-right'
                          : 'form-control float-right'
                      }
                      options={{ style: { base: { backgroundColor: 'white' } } }}
                      onChange={() => {
                        setCardCvvError(false);
                      }}
                    />
                    {cardCvvError && isSubmitHit ? (
                      <p className="mb-2 red pl-cus">Please enter Card CVV number </p>
                    ) : null}
                  </div>
                  <div className="col-lg-4 col-xs-12 mt-1">
                    <label>Zip Code</label>
                    <input
                      type="text"
                      className={zipCodeError && isSubmitHit ? 'form-control mb-cus border-red' : 'form-control mb-cus'}
                      placeholder="Zip"
                      onChange={(e) => {
                        setZipCode(e.target.value);
                        setZipCodeError(false);
                      }}
                    />
                    {zipCodeError && isSubmitHit ? <p className="mb-1 red">Please enter Zip code </p> : null}
                  </div>
                </>
              )}
            </div>

            {paymentMethod == 'stripe' && (
              <div>
                <div className={styles.totalContainer}>
                  <span className={styles.hLine}></span>
                  <h3 className="payment-total">
                    <span className="trial_plan">
                      {plans
                        ? plans.plans[0].trial_days > 0
                          ? '$' +
                            plans.plans[0].trial_amount +
                            ' for the first ' +
                            plans.plans[0].trial_days +
                            ' days, '
                          : null
                        : null}
                      <br />
                      Then
                    </span>
                    {plans ? '$' + plans.plans[0].cost + '' : '-'}
                    <span className="trial_plan">
                      {plans
                        ? plans.plans[0].interval_count > 1
                          ? ' per ' + plans.plans[0].interval_count + ' ' + plans.plans[0].interval
                          : '' + ' ' + plans.plans[0].interval + adjective
                        : ''}
                    </span>
                  </h3>
                  <span className={styles.hLine}></span>
                </div>
                <div className={styles.btnBox}>
                  <SubmitButtonBig
                    caption="Submit Payment"
                    btnClass={styles.payBtn}
                    isLoading={isLoading}
                    onClick={() => addPlanOrder()}
                  />
                  <span className={styles.termBox}>
                    By purchasing a product, you are agreeing to our{' '}
                    <a href="https://chatterfox.com/terms-and-conditions" target="_blank" rel="noreferrer">
                      terms and conditions
                    </a>
                    .
                  </span>
                </div>
              </div>
            )}
            {paymentMethod == 'paypal' && (
              <div>
                {!isRedirected ? (
                  <>
                    <div className={styles.totalContainer}>
                      <span className={styles.hLine}></span>
                      <h3 className="payment-total">
                        <span className="trial_plan">
                          {plans
                            ? plans.plans[0].trial_days > 0
                              ? '$' +
                                plans.plans[0].trial_amount +
                                ' for the first ' +
                                plans.plans[0].trial_days +
                                ' days, '
                              : null
                            : null}
                          <br />
                          Then
                        </span>
                        {plans ? '$' + plans.plans[0].cost + '' : '-'}
                        <span className="trial_plan">
                          {plans
                            ? plans.plans[0].interval_count > 1
                              ? ' per ' + plans.plans[0].interval_count + ' ' + plans.plans[0].interval
                              : '' + ' ' + plans.plans[0].interval + adjective
                            : ''}
                        </span>
                      </h3>
                      <span className={styles.hLine}></span>
                    </div>
                    <div className={styles.btnBox}>
                      <SubmitButtonBig
                        caption="Pay With Paypal"
                        btnClass={`${styles.payBtn} ${styles.paypalBtn}`}
                        isLoading={isLoading}
                        onClick={() => onClickPaypal()}
                      />
                      <span className={styles.termBox}>
                        By purchasing a product, you are agreeing to our{' '}
                        <a href="https://chatterfox.com/terms-and-conditions" target="_blank" rel="noreferrer">
                          terms and conditions
                        </a>
                        .
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="col-lg-12 text-center  p-0">
                    <h4>
                      Make payment on paypal page then
                      <span
                        style={{ cursor: 'pointer', color: 'red' }}
                        onClick={() => console.log('ok') /*redirectTo('/modules')*/}
                      >
                        click here
                      </span>
                    </h4>
                  </div>
                )}
              </div>
            )}

            <div className={styles.secureNoteBox}>
              <span className={styles.title}>
                <span className={styles.bigLine}>Guaranteed</span>
                <br />
                Safe Checkout
              </span>
              <img className={styles.secureIcons} src={process.env.PUBLIC_URL + '/images/securePayment.jpg'} />
            </div>
          </div>

          <div className="col-lg-1"></div>

          {plans ? (
            <div className="col-lg-4 mt-2">
              <div className={styles.sideBox}>
                <h2>{plans.plans[0].display_name}</h2>
                <ul className="list blueIcon subslist">
                  <li>
                    <strong>Has free trial :</strong> {plans.plans[0].is_trial_plan == 1 ? 'Yes' : 'No'}
                  </li>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: '<strong>Description : </strong> ' + plans.plans[0].description,
                    }}
                  />
                </ul>
              </div>

              {plans.plans[0].plan_option.length ? (
                <div className={`${styles.sideBox} ${styles.sideBoxBottom}`}>
                  <ul className="list blueIcon subslist">
                    {plans.plans[0].plan_option.map((elm, ind) => {
                      return (
                        <li key={'key' + ind}>
                          <strong>{elm.option_name}</strong>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Checkout;
