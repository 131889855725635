import { createSlice } from '@reduxjs/toolkit';

export const lessonResultStore = createSlice({
  name: 'lessonsresult',
  initialState: {
    listen_option_result: 0,
  },

  reducers: {
    setLessonResultStore: (state, action) => {
      state.listen_option_result = action.payload.listen_option_result;
    },
  },
});

//this is distach action function
export const { setLessonResultStore } = lessonResultStore.actions;

// The function below is called a thunk and allows us to perform async logic. It
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const setLessonResultStoreAsync = (lessons) => (dispatch) => {
  setTimeout(() => {
    dispatch(setLessonResultStore(lessons));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectLessonResultStore = (state) => state.lessonsresult;

export default lessonResultStore.reducer;

/**
 * https://redux-toolkit.js.org/api/createslice
 */
