import axios from 'axios';
import { ipapiService } from './app/services/ipapiService';
import { v4 as uuidv4 } from 'uuid';
import ReactPixel from 'react-facebook-pixel';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { getData, getSessionData } from './app/util/localStorageHandler';
import CryptoJS from 'crypto-js';

let clientIp;

// Initiate facebook pixel
const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('440149383407589', advancedMatching, options);
window.fbq.disablePushState = true;

const fbSendPageView = async () => {
  if (!clientIp) clientIp = _.get(await ipapiService.getClientLocationInfo(), 'ip', '0.0.0.0');
  const eventID = uuidv4();

  // Get user email
  const userData = getData() || getSessionData();
  let em = _.get(userData, 'user.email', '').toLowerCase();
  if (em) em = CryptoJS.SHA256(em).toString(CryptoJS.enc.Hex);

  ReactPixel.init('440149383407589', { em: _.get(userData, 'user.email', '').toLowerCase() }, options);
  ReactPixel.fbq('trackSingle', '440149383407589', 'PageView', {}, { eventID });

  const data = {
    data: [
      {
        event_name: 'PageView',
        event_time: Math.floor(Date.now() / 1000),
        event_id: eventID,
        event_source_url: window.location.href,
        action_source: 'website',
        user_data: {
          em,
          client_ip_address: clientIp,
          client_user_agent: navigator.userAgent,
          fbp: Cookies.get('_fbp'),
          fbc: Cookies.get('_fbc'),
        },
      },
    ],
    // test_event_code: 'TEST17085',
    access_token:
      'EAA6qmxrSaFIBAILMveitVvwfwVOw29I2KG8FhBIWNOXkVbKIS5s25dPFqvku14chLZAf7XmmGJ4HfJvZAAQOHk5Y54lLrXjrRGv4InyT8547bK0ojuOwXSD5vWvyZBFrPaeWzsqig1ipJWWqtWZBGaAtph3dDW9ZBIRTgZCWMaI3wp4nqRStdW',
  };
  setTimeout(
    () =>
      axios
        .post('https://graph.facebook.com/v11.0/440149383407589/events', data)
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          // console.error(error);
        }),
    500,
  );
};

const fbSendPurchase = async () => {
  if (!clientIp) clientIp = _.get(await ipapiService.getClientLocationInfo(), 'ip', '0.0.0.0');
  const eventID = uuidv4();

  // Get user email
  const userData = getData() || getSessionData();
  let em = _.get(userData, 'user.email', '').toLowerCase();
  if (em) em = CryptoJS.SHA256(em).toString(CryptoJS.enc.Hex);

  ReactPixel.init('440149383407589', { em: _.get(userData, 'user.email', '').toLowerCase() }, options);
  ReactPixel.fbq('trackSingle', '440149383407589', 'Purchase', { currency: 'USD', value: 80 }, { eventID });

  const data = {
    data: [
      {
        event_name: 'Purchase',
        event_time: Math.floor(Date.now() / 1000),
        event_id: eventID,
        event_source_url: window.location.href,
        action_source: 'website',
        user_data: {
          em,
          client_ip_address: clientIp,
          client_user_agent: navigator.userAgent,
          fbp: Cookies.get('_fbp'),
          fbc: Cookies.get('_fbc'),
        },
        custom_data: {
          currency: 'USD',
          value: 80,
        },
      },
    ],
    // test_event_code: 'TEST17085',
    access_token:
      'EAA6qmxrSaFIBAILMveitVvwfwVOw29I2KG8FhBIWNOXkVbKIS5s25dPFqvku14chLZAf7XmmGJ4HfJvZAAQOHk5Y54lLrXjrRGv4InyT8547bK0ojuOwXSD5vWvyZBFrPaeWzsqig1ipJWWqtWZBGaAtph3dDW9ZBIRTgZCWMaI3wp4nqRStdW',
  };
  setTimeout(
    () =>
      axios
        .post('https://graph.facebook.com/v11.0/440149383407589/events', data)
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          // console.error(error);
        }),
    500,
  );
};

fbSendPageView();

export default {
  fbSendPageView,
  fbSendPurchase,
};
