import React from 'react';
import PropTypes from 'prop-types';
import styles from './threeDot.module.css';

const DotLoading = (props) => {
  const { type, color, className } = props;

  const colorStyle = `
    .cf-three-dot,
    .cf-three-dot:after,
    .cf-three-dot:before{
        background-color : ${color};
        color : ${color};
    }
  `;

  return (
    <div className={`${styles.loadingHolder} ${className} cf-three-dot-holder`}>
      <div className={`${styles['dot-' + type]} cf-three-dot`}>
        <style>{colorStyle}</style>
      </div>
    </div>
  );
};
// params: elastic, pulse, flashing, collision, revolution, carousel, typing,
// windmill, bricks, floating, fire, spin, falling, stretching
// Preview URL: https://nzbin.github.io/three-dots/
DotLoading.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
};

export default DotLoading;
