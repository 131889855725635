import React from 'react';
import PropTypes from 'prop-types';

import styles from './NotificationItem.module.css';

const NotificationItem = (props) => {
  const { header, headerIcon, icon, title, subtitle, date, unread, onClick } = props;
  const _className = `${styles.container} ${props.className || ''} ${unread ? styles.unread : ''}`;

  return (
    <div className={_className} onClick={onClick}>
      <div className={styles.header}>
        <span className={styles.text}>{header}</span>
        {/* <img className={styles.icon} alt='' src={headerIcon} /> */}
      </div>
      <div className={styles.body}>
        {/* <img className={styles.icon} alt="" src={icon} /> */}
        <div className={styles.content}>
          {/* <div className={styles.title}>{title}</div> */}
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        <div className={styles.date}>
          {date ? new Date(Date.parse(date.replace(' ', 'T') + 'Z')).toLocaleDateString() : ''}
        </div>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  headerIcon: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  unread: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NotificationItem;
