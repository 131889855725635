import React from 'react';
import PropTypes from 'prop-types';

const ActionLink = (props) => {
  return (
    <strong>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}
      >
        {props.caption}
      </a>
    </strong>
  );
};

ActionLink.propTypes = {
  onClick: PropTypes.func,
  caption: PropTypes.string,
};

export default ActionLink;
