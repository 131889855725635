import { decryptData, encryptData } from './cryptoConverter';
import { v4 as uuidv4 } from 'uuid';
import { getSandomString } from './randomSrtingGenaretor';

export function setData(data) {
  try {
    if (data) {
      localStorage.setItem('CtF_Local', encryptData(JSON.stringify(data)));
    } else {
      localStorage.setItem('CtF_Local', JSON.stringify(data));
    }
    return true;
  } catch (error) {
    return null;
  }
}

export function getData() {
  try {
    let storage = localStorage.getItem('CtF_Local');
    storage = storage ? JSON.parse(decryptData(storage)) : null;
    // console.log('storage', storage);
    return storage;
  } catch (error) {
    return null;
  }
}

export function setSessionData(data) {
  try {
    if (data) {
      sessionStorage.setItem('CtF_Local', encryptData(JSON.stringify(data)));
    } else {
      sessionStorage.setItem('CtF_Local', JSON.stringify(data));
    }
    return true;
  } catch (error) {
    return null;
  }
}

export function getSessionData() {
  try {
    let storage = sessionStorage.getItem('CtF_Local');
    storage = storage ? JSON.parse(decryptData(storage)) : null;
    // console.log('storage', storage);
    return storage;
  } catch (error) {
    return null;
  }
}

export function getSessionId() {
  try {
    let storage = sessionStorage.getItem('CtF_Local_I');
    storage = storage ? decryptData(storage) : null;
    if (!storage) {
      let rndString = getSandomString(8);
      storage = uuidv4();
      storage = `${storage}_${rndString}`;
      sessionStorage.setItem('CtF_Local_I', encryptData(storage));
    }
    return storage;
  } catch (error) {
    return null;
  }
}
