import {
  DICTIONARY_API_BASEURL,
  DICTIONARY_TOKEN,
  AI_API_BASEURL,
  AI_TOKEN,
  RAPIDAPI_API_BASEURL,
  X_RAPIDAPI_HOST,
  X_RAPIDAPI_KEY,
} from '../constants/global';
import { post, get } from '../util/httpClient';

export const getToken = async () => {
  const path = `${DICTIONARY_API_BASEURL}/token`;
  return get(path);
};

export const getSuggestion = async (query) => {
  const path = `${DICTIONARY_API_BASEURL}/videos/suggestion`;
  return post(path, { query, languages_id: 1 }, null, { Authorization: DICTIONARY_TOKEN }, false);
};

export const getVideoResult = async ({ search, page }) => {
  const path = `${DICTIONARY_API_BASEURL}/videos/list`;
  return post(path, { search, page, search_language: 1 }, null, { Authorization: DICTIONARY_TOKEN }, false);
};

export const getTextAnalysis = async (text) => {
  const path = `${AI_API_BASEURL}/text-analysis`;
  return get(path, null, { text }, { Authorization: AI_TOKEN });
};

export const getDefinition = async (term) => {
  const headers = {
    'x-rapidapi-key': X_RAPIDAPI_KEY,
    'x-rapidapi-host': X_RAPIDAPI_HOST,
  };
  const path = `${RAPIDAPI_API_BASEURL}/define`;
  return get(path, null, { term }, headers);
};
