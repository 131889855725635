import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { sendWebPushNotificationToken } from './app/services/notificationServices';

import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const config = {
  apiKey: 'AIzaSyBfB6vz8jQnxC2ZDoNaWMWOYUu9fHqmk9M',
  authDomain: 'chatterfox-c7103.firebaseapp.com',
  databaseURL: 'https://chatterfox-c7103.firebaseio.com',
  projectId: 'chatterfox-c7103',
  storageBucket: 'chatterfox-c7103.appspot.com',
  messagingSenderId: '1076767246869',
  appId: '1:1076767246869:web:d3b395dd3c0e16d866dc1c',
  measurementId: 'G-XSHTCXLDW8',
};

const app = initializeApp(config);

export const getMessagingToken = () => {
  isSupported().then((status) => {
    // Browser support firebase API
    if (status) {
      // Get registration token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      const messaging = getMessaging();
      // Add the public key generated from the console here.
      getToken(messaging, {
        vapidKey: 'BHgKbS8CAbjK_0PnGmzyjEDHsy3XJn3sb86HxNPpXjqcq1j-sqhvwqcaZgRXhSVwJHls1FOKTnsDVvm-4sxVydg',
      })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            sendWebPushNotificationToken(currentToken);
            console.log(currentToken);
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    }
  });
};

// Show push notification
isSupported().then(
  (status) =>
    status &&
    onMessage(getMessaging(), (payload) => {
      console.log('Message received. ', payload);
      toast.info(payload.notification.title, {
        toastId: payload.messageId,
        autoClose: false,
        hideProgressBar: true,
        onClick: () => Swal.fire(payload.notification.title, payload.notification.body, 'info'),
      });
    }),
);
console.log('Firebase initialized');

/*
const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  enable_initial_assessment: false,
};
remoteConfig
  .fetchAndActivate()
  .then(() => {
    console.log('remoteConfig fetched successfully');
    // ...
  })
  .catch((err) => {
    console.log('error on get remoteConfig', err);
    // ...
  });
  */

export default app;
