import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '../../components/Button';
import DotLoading from '../../components/DotLoading';

import Segment from '../../../segment';
import styles from './index.module.css';
import nodes from './nodes.json';
import edges from './edges.json';
import languages from './languages.json';

const NoomAssessment = (props) => {
  const history = useHistory();
  const choices = useRef();

  const [item, setItem] = useState();
  const [itemNumber, setItemNumber] = useState(nodes._startSlide);
  const [selected, setSelected] = useState([]);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [pageLoading, setPageLoading] = useState();
  const [checkout, setCheckout] = useState({});
  const [multiPrice, setMultiPrice] = useState(false);
  const [planURL, setPlanURL] = useState("");
  const [afterPlans, setAfterPlans] = useState([
    {
      period: "Monthly", 
      des: "Billed after your trial, unless canceled beforehand",
      price: "$47"
    }
  ]);

  let itemPrep = {};
  let answer, based_answer, destination;
  let edgesList = edges.edges;
  let answersObj;
  const plans = {
    1: { link: 'https://chatterfox.com/checkout-green-card-n1/', price: 1 },
    2: { link: 'https://chatterfox.com/checkout-green-card-n2/', price: 3 },
    3: { link: 'https://chatterfox.com/checkout-green-card-n3/', price: 10 },
    4: {
      link: 'https://chatterfox.com/checkout-green-card-n4/',
      price: 18.85,
      description: 'Supports our choose trial program',
    },
  };
  const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => {
    answersObj = answers;
  }, [answers]);


  const goToSlide = (node) => {
    setMultiPrice(node == 'planList' ? true : false);
    let basedQuestion = nodes[node].based_on;
    answer = answersObj[node].option;
    if (basedQuestion) {
      based_answer = answersObj[basedQuestion] ? answersObj[basedQuestion].option : 'default';
      destination = _.find(edgesList, { source: node, option: answer, based_option: based_answer });
      destination = destination
        ? destination
        : _.find(edgesList, { source: node, option: 'default', based_option: based_answer });
      destination = destination
        ? destination
        : _.find(edgesList, { source: node, option: answer, based_option: 'default' });
      destination = destination
        ? destination
        : _.find(edgesList, { source: node, option: 'default', based_option: 'default' });
    } else {
      destination = _.find(edgesList, { source: node, option: answer });
      destination = destination ? destination : _.find(edgesList, { source: node, option: 'default' });
    }
    answer = '';
    selected.map((select, index) => {
      // let selectedAnswer = select.option;
      // answer += index + 1 == selected.length ? selectedAnswer : selectedAnswer + ', ';
      Segment.track('QuizQA', {
        type: nodes[node].mode,
        question: node,
        answer: select.option,
      });
    });
    setItemNumber(destination.destination);
  };

  const checkQueryParam = () => {
    const query = new URLSearchParams(props.location.search);
    if (query && query.get('step')) {
      setItemNumber(query.get('step'));
    }
    if (query && query.get('planURL')) {
      setPlanURL(query.get('planURL'));
    }
  };

  useEffect(() => {
    checkQueryParam();
  }, [locationKeys]);

  useState(() => {
    checkQueryParam();
  }, []);

  useState(() => {
    const script = document.createElement("script");
    const rnd = Math.floor(Math.random() * 100000000);
    // script.src = "http://127.0.0.1/quizNotice.js?r="+rnd;
    script.src = "https://chatterfox.com/public/quizAssets/quizNotice.js?r="+rnd;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (window.newPlan) {
        setAfterPlans(window.newPlan);
      };
    };

  }, []);

  useEffect(() => {
    itemPrep = nodes[itemNumber];

    if (itemPrep.mode == 'information' || itemPrep.mode == 'planList' || itemPrep.mode == 'singlePlan') {
      setPageLoading(true);
      let delay = Math.floor((Math.random() * 2 + 3) * 1000);
      setTimeout(() => {
        setPageLoading(false);
        if (itemPrep.mode == 'information') {
          let selectedDescription = itemPrep.description.split('<R>');
          selectedDescription.map((describe, index) => {
            if (index % 2) {
              let random = selectedDescription[index].split('-');
              selectedDescription[index] = Math.floor(
                Math.random() * (random[1] - random[0] + 1) + parseInt(random[0]),
              );
            }
          });
          itemPrep.description = selectedDescription.join('');
        }
        setItem(itemPrep);
      }, delay);
    } else {
      setPageLoading(false);
      setItem(itemPrep);
    }
    history.push(`get-started?step=${itemNumber}`);
    setSelected([]);
  }, [itemNumber]);

  const selectOption = (e, choiceId, choiceOption) => {
    if (item.mode == "information"){
      setSelected([{id: choiceId, option: choiceOption}]);
      let nextSlide = choiceOption.toLowerCase();
      nextSlide = nextSlide.replace(" ","_");
      handleNext(nextSlide);
    }else{
      if (item.type == 'single_choice') {
        setSelected([{id: choiceId, option: choiceOption}]);
        setSelectedOption(choiceOption ? choiceOption : choiceId);
        e && e.currentTarget.classList.add(styles.selected);
      } else {
        if (_.find(selected, { id: choiceId })) {
          setSelected(_.remove(selected, (n) => n.id != choiceId));
        } else {
          setSelected([...selected, {id: choiceId, option: choiceOption}]);
        }
      }
    }
  };

  const Options = (props) => {
    let data;
    switch (props.list) {
      case 'language':
        data = languages.langs;
        break;
      default:
        break;
    }

    return (
      <>
        {data.map((lang, index) => {
          const key = Object.keys(lang)[0];
          return (
            <option key={index} value={lang[key]}>
              {key}
            </option>
          );
        })}
      </>
    );
  };

  const Choices = (items) => {
    return items.items.map((choice, index) => {
      return (
        <>
          <div
            className={`${styles.info} ${_.find(selected, { id: choice.id }) ? styles.selected : ''}`}
            key={choice.id}
            onClick={(e) => selectOption(e, choice.id, choice.option || choice.label)}
          >
            <div className={styles.selectBoxHolder}>
              <span className={styles.selectBox}></span>
            </div>
            <span dangerouslySetInnerHTML={{ __html: choice.label }}></span>
          </div>
          {choice.label == 'Other' && _.find(selected, { id: choice.id }) && (
            <Form.Select
              onChange={(e) => {
                // setSelectedOption(e.target.value);
                selectOption(e, 10, e.target.value);
              }}
              value={selectedOption}
            >
              <option value="other" selected disabled>
                Select your native languages
              </option>
              <Options list={item.otherOptions} />
            </Form.Select>
          )}
        </>
      );
    });
  };

  const handleNext = (opt) => {
    let answerOpt = (opt && typeof opt === 'string') ? opt : selectedOption;
    setAnswers({ ...answers, [itemNumber]: { id: selected, option: answerOpt } });
    answersObj = { ...answers, [itemNumber]: { id: selected, option: answerOpt } };
    goToSlide(itemNumber);
    setSelected([]);
  };
  
  const gotoURL = () => {
    window.location.replace(planURL);
  }

  const handlePlanClicked = (planId) => {
    setCheckout({
      planId: planId,
      link: plans[planId].link,
      price: plans[planId].price,
    });
    handleNext();
    // Segment.track('NoomPlanClicked', { planId });
  };

  const checkoutSingle = (
    <div className={`${pageLoading && styles.blur} ${styles.questionBox} ${styles.invoice}`}>
      <p className={styles.infoDescription}>
        <br />
        <h3>Your Membership Includes:</h3>
        {item && item.planPrice < 10 ? (
          <ul>
            <li>Initial assessment & personalized study plan</li>
            <li>Detailed instant feedback on your pronunciation</li>
            <li>Bite-sized lessons and interactive smart activities</li>
          </ul>
        ) : (
          <ul>
            <li>Initial assessment & personalized study plan</li>
            <li>Human coaching inside the app (5 every month)</li>
            <li>Bite-sized lessons and interactive smart activities.</li>
          </ul>
        )}
      </p>
      <br />
      <hr />
      <br />
      <p>
        {item && item.planPrice < 10 && (
          <p className={styles.bold}>
            We believe in fair prices and long-term commitment, as well as equal opportunities for everybody. That’s why
            we are offering the most affordable package for English speaking and fluency improvement.
            <br />
          </p>
        )}
        <h3 className={styles.bold}>Price Today:</h3>
        <div className={styles.invoiceRow}>
          <span>Monthly</span>
          <span>${item && item.planPrice}</span>
        </div>
        <hr className={styles.dotLine} />
      </p>
      <br />
      <p className={styles.infoDescription}>
        <div className="slideBtnHolder">
          <a href={item && item.checkoutLink} className={styles.noomBtn}>
            Checkout
          </a>
        </div>
      </p>
      <div className={styles.questionBoxBorder}></div>
    </div>
  );

  const checkout47 = (
    <div className={`${pageLoading && styles.blur} ${styles.questionBox} ${styles.invoice}`}>
      <p className={styles.infoDescription}>
        <br />
        <h3>Your Membership Includes:</h3>
        <ul>
          <li>Initial assessment & personalized study plan</li>
          <li>Human coaching inside the app (5 every month)</li>
          <li>Bite-sized lessons and interactive smart activities.</li>
        </ul>
      </p>
      <br />
      <hr />
      <br />
      <p>
        <h3 className={styles.bold}>Price Today:</h3>
        <div className={styles.invoiceRow}>
          <span>Monthly</span>
          <span>$47</span>
        </div>
        <span className={styles.centerText}>Billed after your trial, unless canceled beforehand</span>
        <hr className={styles.dotLine} />
      </p>
      <br />
      <p className={styles.infoDescription}>
        <div className="slideBtnHolder">
          <a href={checkout.link} className={styles.noomBtn}>
            Checkout
          </a>
        </div>
      </p>
      <div className={styles.questionBoxBorder}></div>
    </div>
  );

  const checkoutMulti = (
    <div className={`${pageLoading && styles.blur} ${styles.questionBox} ${styles.invoice}`}>
      <div className={styles.infoDescription}>
        <br />
        <h3>Your Membership Includes:</h3>
        <ul>
          <li>Initial assessment & personalized study plan</li>
          <li>Human coaching inside the app (1 in trial, 5 every month)</li>
          <li>Bite-sized lessons and interactive smart activities.</li>
          <li>Extra Practice inside ChatterFox exclusive WhatsApp group.</li>
        </ul>
      </div>
      <br />
      <hr />
      <br />
      <div>
        <h3 className={styles.bold}>Price Today:</h3>
        <div className={styles.invoiceRow}>
          <span>7-day trial</span>
          <span>${checkout.price}</span>
        </div>
        <span className={styles.centerText}>
          You will have 1 week to try ChatterFox out and receive one feedback from your coach.
        </span>
        <hr className={styles.dotLine} />
        <h3 className={styles.bold}>After 7-day Trial:</h3>
        {afterPlans.map((afterPlan, index) => {
          return (
            <div key={index}>
              <div className={styles.invoiceRow}>
                <div>
                  <span>{afterPlan.period}</span>
                  <span className={`${styles.centerText} ${styles.planDescription}`} dangerouslySetInnerHTML={{ __html: afterPlan.des }}></span>
                  <span className={`${styles.centerText} ${styles.compareDescription}`} dangerouslySetInnerHTML={{ __html: afterPlan.discountDes }}></span>
                </div>
                <div className={styles.prices}>
                  <span className={styles.comparePrice}>{afterPlan.comparePrice}</span>
                  <span>{afterPlan.price}</span>
                </div>
              </div>
              <hr className={styles.dotLine} />
            </div>
          );
        })}
      </div>
      <br />
      <p className={styles.infoDescription}>
        <div className="slideBtnHolder">
          <a href={checkout.link} className={styles.noomBtn}>
            Checkout
          </a>
        </div>
      </p>
      <div className={styles.questionBoxBorder}></div>
    </div>
  );

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <img className={styles.logo} src="./images/logo.png" />
        <div className={styles.progressBar}></div>
      </div>
      <div className={`container`}>
        {pageLoading && (
          <div className={styles.loadingHolder}>
            {item && item.loadingType == 'dynamic' ? (
              <>
                <img src="./images/noomPreparingTransparent.gif" className={styles.loading} />
                <span>Checking our database</span>
              </>
            ) : (
              <DotLoading className={styles.loading} type="elastic" color="rgba(250,100,0,1)" />
            )}
          </div>
        )}
        {item && item.mode == 'question' && (
          <>
            <div
              className={`${pageLoading && styles.blur} ${styles.questionBox} ${
                item.type == 'multi_choice' && styles.multiChoice
              } ${item.header && styles.withHeader}`}
              ref={choices}
            >
              <div className={styles.infoTitleBox}>
                {item.header && <h2 className={styles.infoTitle}>{item.header}</h2>}
              </div>
              <span className={styles.question} dangerouslySetInnerHTML={{ __html: item && item.title }}></span>
              {item && item.subtitle && (
                <span
                  className={styles.questionSubtitle}
                  dangerouslySetInnerHTML={{ __html: item && item.subtitle }}
                ></span>
              )}
              {item && <Choices items={item.choices} />}
              <div className={styles.questionBoxBorder}></div>
            </div>
            <div className={styles.btnHolder}>
              <Button
                type="default"
                content={`Next`}
                size="medium"
                data-dismiss="modal"
                isDisabled={selected.length == 0}
                onClick={handleNext}
                // isLoading={loading}
              />
            </div>
          </>
        )}
        {item && item.mode == 'information' && (
          <>
            <div className={`${pageLoading && styles.blur} ${styles.questionBox} ${item.title && styles.withHeader}`}>
              <div className={styles.infoTitleBox}>
                {item.title && <h2 className={styles.infoTitle}>{item.title}</h2>}
              </div>
              {item.headerImage && (
                <div className={`${styles.infoImgBox} ${styles.headerImage}`}>
                  <img src={item.headerImage} />
                </div>
              )}
              {item.headerVideo && (
                <div className={`${styles.infoVidBox} ${styles.headerVideo}`}>
                  <video controls src={item.headerVideo} />
                </div>
              )}
              <p className={styles.infoDescription} dangerouslySetInnerHTML={{ __html: item && item.description }}></p>
              {item.image && (
                <div className={styles.infoImgBox}>
                  <img src={item.image} />
                </div>
              )}
              {item.description2 && (
                <p
                  className={styles.infoDescription}
                  dangerouslySetInnerHTML={{ __html: item && item.description2 }}
                ></p>
              )}
              <div className={styles.questionBoxBorder}></div>
            </div>
            <div className={styles.btnHolder}>
              <Button
                type="default"
                className={styles.infoBtn}
                content={item.btnText ? item.btnText : 'Got It'}
                size="medium"
                data-dismiss="modal"
                onClick={item.btnText == "Let's Give it a try!" && planURL ? gotoURL : handleNext}
                // isLoading={loading}
              />
              {item.secondaryBtn && (
                <Button
                type="defaultOutline"
                className={styles.infoBtn}
                content={item.secondaryBtn}
                size="medium"
                data-dismiss="modal"
                onClick={() => selectOption("", 0, item.secondaryBtn)}
                // isLoading={loading}
              />
              )}
            </div>
          </>
        )}
        {item && item.mode == 'planList' && (
          <>
            <div className={styles.questionBox}>
              <div className={styles.infoTitleBox}>
                <span className={styles.sideLine}></span>
                <h2 className={styles.infoTitle}>Choose your 7-day trial price</h2>
                <span className={styles.sideLine}></span>
              </div>
              <p className={styles.infoDescription}>
                <br />
                <br />
                Since we have been in your shoes, we believe money shouldn't stand in the way of improving your life and
                career by registering for an American English course. ChatterFox's choose a trial price feature allows
                you to select an amount reasonable for you to evaluate the software.
                <br />
                <br />
                <span className={styles.bold}>
                  This trial includes one certified human coach’s feedback for your recordings. It will cost us
                  approximately $10 to offer you this trial. But, please choose the price that you’re most comfortable
                  with.
                </span>
              </p>
              <br />
              <p className={styles.infoDescription}>
                <div className="slideBtnHolder">
                  {Object.keys(plans).map((button, index) => {
                    return (
                      <span className={styles.noomBtn} key={index} onClick={() => handlePlanClicked(button)}>
                        ${plans[button].price}
                        {plans[button].description && (
                          <span className={styles.btnSubtitle}>{plans[button].description}</span>
                        )}
                      </span>
                    );
                  })}
                </div>
              </p>
              <div className={styles.questionBoxBorder}></div>
            </div>
          </>
        )}
        {item && item.mode == 'checkout' && checkoutSingle}
        {item && item.mode == 'checkoutMulti' && checkoutMulti}
      </div>
      <div className={styles.waveLine}>
        <img src="./images/waveLine.svg"></img>
      </div>
    </div>
  );
};

NoomAssessment.propTypes = {
  location: PropTypes.object,
};

export default NoomAssessment;
