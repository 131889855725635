import React, { useEffect, useState } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { isMobile, osName } from 'react-device-detect';
import OneSignal from 'react-onesignal';
import CryptoJS from 'crypto-js';
import _ from 'lodash';

import { getData } from './app/util/localStorageHandler';
import withAuthentication from './app/Session/withAuthentication';
import {
  setNotifications,
  setSubscription,
  setUnreadNotificationCount,
  setUser,
  setCoachingCredit,
} from './app/actions/user/userSlice';
import { getInitialAssessmentStatus } from './app/services/initialAssessmentServices';
import { getUnreadNotificationCount, getUserNotifications } from './app/services/notificationServices';
import { setStatus } from './app/actions/initialAssessment/initialAssessmentStore';
import { version } from './app/constants/global';
import Header from './app/views/layouts/Header';
import ModuleIndex from './app/views/pages/modules';
import Profile from './app/views/pages/profile/Profile';
import ChangePass from './app/views/pages/profile/changePass';
import Settings from './app/views/pages/settings/settingsPage';
import BillingInfo from './app/views/pages/settings/billing/info';
import PurchaseHistory from './app/views/pages/settings/billing/purchaseHistory';
import UserActivity from './app/views/pages/settings/activity/userActivity';
import FluencyActivity from './app/views/pages/settings/activity/fluencyActivity';
import Invitation from './app/views/pages/invitation/invite';
import PaymentWrap from './app/views/pages/payment/payment_wrap';
import Subscription from './app/views/pages/subscription/subscription';
import PaymentSuccess from './app/views/pages/payment/payment_success';
import Dictionary from './app/views/components/Dictionary';
import DictionaryResult from './app/views/components/Dictionary/DictionaryResult';

import { NotificationPage, NotificationContent } from './app/views/components/Notification';
import Menu from './app/views/layouts/Menu';
import MyLessons from './app/views/pages/modules/MyLessons';
import styles from './App.module.css';
import 'react-toastify/dist/ReactToastify.css';
import { getModuleList, getModuleListV3, getUserSubscription } from './app/services/moduleServices';
import { acceptTermsAndConditions } from './app/services/userServices';
import { setModules, setSelectedModule, selectSelectedModule } from './app/actions/module/moduleSlice';
import { selectSelectedLesson } from './app/actions/lesson/lessonStore';
import { findModuleById, getLessonTypeName } from './app/util';
import { getMessagingToken } from './firebase';
import { getUserCredit } from './app/services/coachingServices';
import { getIdentityVerification } from './app/services/onesignalServices';
import Segment from './app/segment';
import DotLoading from './app/views/components/DotLoading';
import { updateUserProfile } from './app/services/userServices';
import { getActiveSubscription } from './app/services/paymentServices';
import moment from 'moment';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const selectedModule = useSelector(selectSelectedModule);
  const selectedLesson = useSelector(selectSelectedLesson);
  const [headerState, setHeaderState] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const authUser = getData();

    if (authUser) {
      setLoading(true);
      // Analytics: identify
      const user = {
        email: _.get(authUser, 'user.email', ''),
        name: _.get(authUser, 'user.name', ''),
        osName: osName,
      };
      Segment.identify(user.email, user);
      // push notification from firebase
      getMessagingToken();
      // push notification from onesignal
      OneSignal.init({
        appId: 'f0f6ad4e-2f21-411c-8e4e-cd2a131a5707',
      }).then(() => {
        getIdentityVerification().then((res) => {
          if (res && !res.isError && !res.errorCode) {
            OneSignal.setEmail(authUser.user.email, { emailAuthHash: res.data.emailAuthHash });
            OneSignal.setExternalUserId(CryptoJS.MD5(authUser.user.email).toString(), res.data.externalUserIdAuthHash);
          }
        });
      });
      getUserCredit().then((res) => {
        dispatch(
          setUser({
            ...authUser,
            remaining_coaching_credit: _.get(res, 'user_credit_info.data.remaining_coaching_credit', 0),
          }),
        );
      });
      getUnreadNotificationCount().then((res) => {
        if (res && !res.isError && !res.errorCode) {
          dispatch(setUnreadNotificationCount(res.userNotificationsCount));
        } else {
          dispatch(setUnreadNotificationCount(0));
        }
      });
      getUserNotifications().then(async (res) => {
        if (res && !res.isError && !res.errorCode) {
          dispatch(setNotifications(res.userNotifications));
          if (_.get(location, 'state.firstLogin', false)) {
            try {
              await acceptTermsAndConditions();
            } catch (error) {
              console.log('acceptTermsAndConditions is failed', error);
            }
            const notification = res.userNotifications.filter(
              (notification) => notification.notification_type === 'initial-assessment-ai-feedback',
            )[0];
            if (notification) history.push(`/notifications/${notification.id}`, { prevPath: '/modules' });
          }
        } else {
          dispatch(setNotifications([]));
        }
      });
      getInitialAssessmentStatus().then((res) => {
        if (res && !res.isError && !res.errorCode) {
          dispatch(setStatus(res.data));
        } else {
          dispatch(setStatus({}));
        }
      });
      getUserSubscription().then((res) => {
        if (res && !res.isError && !res.errorCode) {
          //set user subscription data
          const newObj = JSON.parse(JSON.stringify(authUser));
          newObj.is_subscribed = res.is_subscribed;
          newObj.is_free_plan = res.is_free_plan;
          newObj.isTrialing = res.isTrialing;
          Object.preventExtensions(newObj);
          dispatch(setSubscription({ user: newObj, api_token: newObj.api_token, active_subscription: res }));

          // Analytics: identify
          const user = {
            email: _.get(authUser, 'user.email', ''),
            hasSubscription: res.is_subscribed,
          };
          Segment.identify(user.email, user);

          const getModuleListFunc = res.is_subscribed ? getModuleListV3 : getModuleList;
          getModuleListFunc()
            .then((res) => {
              if (res && !res.isError && !res.errorCode) {
                const payload = {
                  othModules: res.module_list ? res.module_list : [],
                  introModules: res.intro_modules ? res.intro_modules : [],
                  trialModules: res.trial_modules ? res.trial_modules : [],
                };
                dispatch(setModules(payload));
                if (location.pathname.includes('/modules') && location.pathname.split('/').length > 2) {
                  const moduleId = location.pathname.split('/')[2];
                  const module = findModuleById(payload, moduleId);
                  dispatch(setSelectedModule({ module }));
                }
              }
            })
            .finally(() => setLoading(false));
        }
      });

      getActiveSubscription(authUser.api_token).then((res) => {
        if (res && !res.isError) {
          if (res.active_subscription) {
            const subscriptionStartDate = _.get(res, 'active_subscription.created_at', '2022-01-01');
            dispatch(setCoachingCredit({ isCoachCreditEnable: moment(subscriptionStartDate).isAfter('2022-05-06') }));
          }
        }
      });

      if (localStorage.getItem('nativeLanguage')) {
        updateUserProfile({ nativeLanguage: localStorage.getItem('nativeLanguage') });
        localStorage.removeItem('nativeLanguage');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tempHeaderState = {};
    if (!_.has(location, 'state.title')) {
      switch (location.pathname) {
        case '/modules':
          tempHeaderState.title = 'Modules';
          break;
        case '/initialAssessment':
          tempHeaderState.title = 'Fluency Assessment';
          break;
        case '/notifications':
          tempHeaderState.title = 'Notifications';
          break;
        case '/checkout':
          tempHeaderState.title = 'Checkout';
          break;
        case '/subscription':
          tempHeaderState.title = 'Subscription';
          break;
        case '/profile':
          tempHeaderState.title = 'Profile';
          break;
        case '/changePassword':
          tempHeaderState.title = 'Change Password';
          break;
        case '/settings':
          tempHeaderState.title = 'Settings';
          break;
        case '/invitation':
          tempHeaderState.title = 'Invitation';
          break;
        case '/billingInfo':
          tempHeaderState.title = 'Billing Info';
          tempHeaderState.backUrl = `/profile`;
          break;
        case '/user_activity':
          tempHeaderState.title = 'Activities';
          break;
        case '/dictionary':
          tempHeaderState.title = 'Dictionary';
          break;
        case '/my-lessons':
          tempHeaderState.title = 'My Lessons';
      }
    } else {
      tempHeaderState.title = location.state.title;
    }
    if (location.pathname.includes('/modules')) {
      tempHeaderState.backUrl = undefined;
      if (location.pathname.split('/').length === 3 && selectedModule) {
        tempHeaderState.backUrl = `/modules`;
        if (!tempHeaderState.title && !isMobile) {
          tempHeaderState.title = selectedModule.title;
        } else if (isMobile) {
          tempHeaderState.title = undefined;
          tempHeaderState.hideMainMenu = true;
        }
      } else if (location.pathname.split('/').length > 3 && selectedModule) {
        tempHeaderState.backUrl = `/modules/${selectedModule.id}`;
        //location.pathname.substring(0, location.pathname.lastIndexOf('/'));
        // On back we don't have module name
        if (!tempHeaderState.title) {
          tempHeaderState.title = selectedModule.title;
        }
        if (location.pathname.includes('/feedback')) {
          if (selectedLesson) {
            tempHeaderState.title = `${getLessonTypeName(selectedLesson)} - ${selectedModule.title}`;
          }
          tempHeaderState.hideMenu = false;
          tempHeaderState.hideMainMenu = false;
          tempHeaderState.withClose = false;
        }
      }
      if (location.pathname.split('/').length > 4) {
        if (selectedLesson) {
          tempHeaderState.title = getLessonTypeName(selectedLesson);
        }
        tempHeaderState.hideMenu = isMobile;
        tempHeaderState.hideMainMenu = isMobile;
        tempHeaderState.withClose = isMobile;
      }
    } else if (location.pathname.includes('/notifications')) {
      tempHeaderState.title = 'Notification';
      tempHeaderState.backUrl = '/modules';
    }
    setHeaderState(tempHeaderState);
  }, [location, selectedModule, selectedLesson]);

  useEffect(() => {
    if (_.get(location, 'state.coachingCreditRefresh')) {
      console.log('---- Set User 2 ----');
      const authUser = getData();
      if (authUser) {
        getUserCredit().then((res) => {
          dispatch(
            setUser({
              ...authUser,
              remaining_coaching_credit: _.get(res, 'user_credit_info.data.remaining_coaching_credit', 0),
            }),
          );
        });
      }
    }
    if (_.get(location, 'state.moduleListRefresh')) {
      getModuleListV3().then((res) => {
        if (res && !res.isError && !res.errorCode) {
          const payload = {
            othModules: res.module_list ? res.module_list : [],
            introModules: res.intro_modules ? res.intro_modules : [],
            trialModules: res.trial_modules ? res.trial_modules : [],
          };
          dispatch(setModules(payload));
          if (location.pathname.includes('/modules') && location.pathname.split('/').length > 2) {
            const moduleId = location.pathname.split('/')[2];
            const module = findModuleById(payload, moduleId);
            dispatch(setSelectedModule({ module }));
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={`${styles.app} ${isMobile && 'mobile'}`}>
      {version}

      {!headerState.hideMainMenu && <Menu />}

      <div className={styles.appContent} style={isMobile ? { width: '100%' } : {}}>
        {!_.get(location, 'state.externalPayment', false) && (
          <Header
            className="cf-main-header"
            title={headerState.title}
            backUrl={headerState.backUrl}
            hideMenu={headerState.hideMenu}
            withClose={headerState.withClose}
          />
        )}
        {loading ? (
          <DotLoading className={styles.loading} type="elastic" color="rgba(250,100,0,1)" />
        ) : (
          <div className={styles.content}>
            <Route
              path="/modules"
              render={({ match: { url } }) => (
                <>
                  <Route path={`${url}/`} component={ModuleIndex} exact />
                  <Route path={`${url}/:moduleId`} component={ModuleIndex} exact />
                  <Route path={`${url}/:moduleId/:lessonType`} component={ModuleIndex} exact />
                  <Route path={`${url}/:moduleId/:lessonType/:lessonId`} component={ModuleIndex} exact />
                </>
              )}
            />
            <Route exact path="/notifications" name="Notifications" component={NotificationPage} />
            <Route path="/notifications/:id" name="Notifications" component={NotificationContent} />

            <Route path="/checkout" name="Payments" component={PaymentWrap} />
            <Route path="/confirm" name="Payment Confirm" component={PaymentSuccess} />

            <Route path="/subscription" name="Subscription" component={Subscription} />

            <Route path="/profile" name="Profile" component={Profile} />
            <Route path="/changePassword" name="Change Password" component={ChangePass} />

            <Route path="/settings" name="Settings" component={Settings} />
            <Route path="/billingInfo" name="Billing Info" component={BillingInfo} />
            <Route path="/purchases/:id" name="Purchase History" component={PurchaseHistory} />
            <Route path="/user_activity" name="Activities" component={UserActivity} />
            <Route path="/fluency" name="Conversational Tips" component={FluencyActivity} />
            <Route path="/invitation" name="Invite Friends" component={Invitation} />
            <Route exact path="/dictionary" name="Dictionary" component={Dictionary} />
            <Route path="/dictionary/search" name="Dictionary" component={DictionaryResult} />
            <Route path="/my-lessons" name="My Lessons" component={MyLessons} />
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default withAuthentication(App);
