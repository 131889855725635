import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const SubmitButtonBig = props => {

	let btnClass = props.btnClass?props.btnClass:"btn-red btn-orange ";
	let width = props.width?props.width:"auto";
	let isLoading = props.isLoading?props.isLoading:false;
	let isDisabled = props.isDisabled?props.isDisabled:false;

  if (isLoading) {
    return (
      <button type="button" disabled className={btnClass+ " disable"} style={{width:width}}> 
      		{props.caption} 
      		<FontAwesomeIcon icon={faSpinner} className="ml-1" />
      </button>
    )
  } else if (isDisabled) {
    return (
      <button type="button" className={btnClass+" disabled red  btn-lightorng"} style={{width:width}}>
      	{props.caption}
      </button>
    )
  }else{
  	return (
      <button type="button" onClick={(e) => props.onClick(e)} className={btnClass} style={{width:width}}>
      	{props.caption}
      </button>
    )
  }
};

// SubmitButtonBig.propTypes = {

// };

export default SubmitButtonBig;