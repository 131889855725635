import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Nav } from 'react-bootstrap';
import styles from './Menu.module.css';

const Menu = (props) => {
  const history = useHistory();
  const location = useLocation();

  const handleMenuClick = (e) => {
    if (e.currentTarget.dataset.path) history.push(e.currentTarget.dataset.path);
  };

  const MenuWrapper = ({ children }) => {
    if (isMobile) {
      return children;
    } else {
      return <div className={styles.mainMenu}>{children}</div>;
    }
  };

  return (
    <Nav
      className={isMobile ? 'sticky-bottom fixed-bottom' : `${styles.menu} flex-column`}
      fill
      variant="tabs"
      defaultActiveKey="modules"
      sty
      bsPrefix="cf-nav"
    >
      {!isMobile && (
        <div className={styles.logo}>
          <img alt="" src={process.env.PUBLIC_URL + '/images/logo.png'} onClick={() => history.push('/modules')} />
        </div>
      )}
      <MenuWrapper>
        <Nav.Item bsPrefix="cf-nav-item" data-path="/modules" onClick={handleMenuClick}>
          <Nav.Link bsPrefix="cf-nav-link" eventKey="modules">
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/modules-icon-${
                location.pathname.split('/')[1] === 'modules' ? '' : 'de'
              }active.svg`}
            />
            <span>Modules</span>
          </Nav.Link>
        </Nav.Item>
        {/* <Nav.Item bsPrefix="cf-nav-item" data-path="/my-lessons" onClick={handleMenuClick}>
          <Nav.Link bsPrefix="cf-nav-link" eventKey="my-lessons">
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/my-lessons-icon-${
                location.pathname.split('/')[1] === 'my-lessons' ? '' : 'de'
              }active.svg`}
            />
          </Nav.Link>
        </Nav.Item> */}
        <Nav.Item bsPrefix="cf-nav-item" data-path="/dictionary" onClick={handleMenuClick}>
          <Nav.Link bsPrefix="cf-nav-link" eventKey="dictionary">
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/dictionary-icon-${
                location.pathname.split('/')[1] === 'dictionary' ? '' : 'de'
              }active.svg`}
            />
            <span>Dictionary</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item bsPrefix="cf-nav-item" data-path="/profile" onClick={handleMenuClick}>
          <Nav.Link bsPrefix="cf-nav-link" eventKey="profile">
            <img
              src={`${process.env.PUBLIC_URL}/images/menu/profile-icon-${
                location.pathname.split('/')[1] === 'profile' ? '' : 'de'
              }active.svg`}
            />
            <span>Profile</span>
          </Nav.Link>
        </Nav.Item>
      </MenuWrapper>
      {/* {!isMobile && (
        <div className={styles.feedback} data-path="/feedback" onClick={handleMenuClick}>
          <img
            src={`${process.env.PUBLIC_URL}/images/menu/feedback-icon-${
              location.pathname.split('/')[1] === 'feedback' ? '' : 'de'
            }active.svg`}
          />
          <span>Feedback</span>
        </div>
      )} */}
    </Nav>
  );
};

export default Menu;
