import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FloatingLabel, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { sendPasswordResetOTP } from '../../../services/authServices';
import Button from '../../components/Button';
import Segment from '../../../segment';
import LoginTemplate from '../../components/Templates/LoginTemplate';
import styles from './ForgotPassword.module.css';

const ForgotPassword = () => {
  let history = useHistory();
  let [isLoading, setLoading] = useState(false);

  const FormSchema = Yup.object().shape({
    email: Yup.string().required('Enter your email id.').email(),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      if (values.email) {
        sendForgotPasswordOTP(values.email);
      }
    },
  });

  const goToPage = (path, data) => {
    history.push({
      pathname: path,
      state: data,
    });
  };

  const sendForgotPasswordOTP = async (email) => {
    setLoading(true);
    try {
      Segment.track('Forgot Password', { email });
      await sendPasswordResetOTP(email);
      setLoading(false);
      goToPage('/reset-password', { email });
    } catch (error) {
      setLoading(false);
      Swal.fire('Error!', error.message ? error.message : 'Some error occurred. Please try again.', 'error');
    }
  };

  return (
    <LoginTemplate>
      <h1>
        <span className="cf-t-highlight">Forgot</span> password
      </h1>
      <div className={`${styles.form} cf-t-form`}>
        <span className={styles.circle} onClick={() => goToPage('/signin')}>
          <FontAwesomeIcon className={styles.back} icon={faChevronLeft} />
        </span>
        <form onSubmit={formik.handleSubmit}>
          <FloatingLabel controlId="floatingEmail" label="Email Address">
            <Form.Control
              type="email"
              isInvalid={formik.touched.email && !!formik.errors.email}
              disabled={isLoading}
              required
              fluid
              placeholder="Email Address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
          </FloatingLabel>
          <Button content="Send Verification Code" isLoading={isLoading} onClick={formik.handleSubmit} />
        </form>
        <div className={styles.tip}>You will receive a verification code to reset your password</div>
      </div>
    </LoginTemplate>
  );
};

ForgotPassword.propTypes = {};

export default ForgotPassword;
