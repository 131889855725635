import CryptoJS from "crypto-js";
import { SH } from "../constants/global";
export function encryptData(data) {
  try {
    let ciphertext = CryptoJS.AES.encrypt(data, Buffer.from(SH, 'base64').toString('ascii')).toString();
    return ciphertext;
  } catch (error) {
    throw error;
  }
}

export function decryptData(ciphertext) {
  try {
    let bytes = CryptoJS.AES.decrypt(ciphertext, Buffer.from(SH, 'base64').toString('ascii'));
    let originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  } catch (error) {
    throw error;
  }
}