import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal as BModal } from 'react-bootstrap';
import Button from '../components/Button';
import { isMobile } from 'react-device-detect';
import _ from 'lodash';

import './Modal.css';
import styles from './Modal.module.css';

const Modal = (props) => {
  const { message, title, iconSrc } = props;
  const backdrop = _.get(props, 'backdrop', true);
  const keyboard = _.get(props, 'keyboard', true);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleClose = () => {
    props.handleClose();
    setShow(false);
  };

  const handleYesClick = () => {
    setLoading(true);
    if (props.yesClick) props.yesClick();
    else handleClose();
  };

  const handleNoClick = () => {
    setLoading(true);
    if (props.noClick) props.noClick();
    else handleClose();
  };

  return (
    <BModal
      show={show}
      className={`${styles.modal} ${isMobile && 'mobile'}`}
      onHide={handleClose}
      backdrop={backdrop}
      keyboard={keyboard}
      dialogClassName={`defaultModal ${props.className}`}
    >
      {title && (
        <BModal.Header closeButton>
          <BModal.Title>{title}</BModal.Title>
        </BModal.Header>
      )}
      {/* <BModal.Body><div dangerouslySetInnerHTML={{ __html: message }}></div></BModal.Body> */}
      <BModal.Body>
        {iconSrc && (
          <span className={styles.modalContentIcon}>
            <img src={iconSrc} />
          </span>
        )}
        <p>{message}</p>
      </BModal.Body>
      {(props.noText || props.yesText) && (
        <BModal.Footer>
          {props.noText && (
            <Button
              type="secondary"
              content={props.noText}
              size="medium"
              data-dismiss="modal"
              icon="leftArrow"
              iconPosition="left"
              onClick={handleNoClick}
              isLoading={loading}
            />
          )}
          {props.yesText && (
            <Button
              type="primary"
              content={props.yesText}
              size="medium"
              data-dismiss="modal"
              icon="rightArrow"
              iconPosition="right"
              onClick={handleYesClick}
              isLoading={loading}
            />
          )}
        </BModal.Footer>
      )}
    </BModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  yesClick: PropTypes.func,
  noClick: PropTypes.func,
  className: PropTypes.string,
  backdrop: PropTypes.string,
  keyboard: PropTypes.bool,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  iconSrc: PropTypes.string,
};

export default Modal;
