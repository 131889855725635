import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Rating from 'react-rating';
import PropTypes from 'prop-types';
import StartMessage from '../message/StartMessage';
import EndMessage from '../message/EndMessage';
import { getData } from '../../../../util/localStorageHandler';
import { uploadFluencyActivity, rateFluencyActivity, fluencyEvaluation } from '../../../../services/lessonsServices';
import SubmitButtonBig from '../../../components/SubmitButtonBig';
import AudioPlayer from '../../../components/CustomAudioPlayer';
import styles from './FluencyActivity.module.css';
import Button from '../../../components/Button';
import Segment from '../../../../segment';
import { isIOS, isMobile } from 'react-device-detect';
import RecordRTC from 'recordrtc';
import MicRecorder from 'mic-recorder-to-mp3';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import DotLoading from '../../../components/DotLoading';

const FluencyActivity = (props) => {
  const sampleData = {
    widget_data: {
      voice_urls: [
        'https://chatterfox-ai-staging-public-storage1.s3.amazonaws.com/tts/fluency_score_tts_2022_03_12_15_33_16_m1MnHKY.mp3',
        'https://chatterfox-ai-staging-public-storage1.s3.amazonaws.com/external_uploads/chatterfox/433_th_navandngmailcom_888_622cbd39b8d77.mp3',
      ],
      score: [
        {
          comment: 'Fair',
          text_tips: [],
          score: 39,
          quality: 'fair',
        },
      ],
      text: 'This|part|needs|a|description',
      analysis: [
        [
          {
            widget_sort_order: 0,
            widget_category: 'word_analysis',
            widget_status: 'active',
            widget_data: {
              syllable_score_list: [
                {
                  stress_level: 1,
                  extent: [147, 171],
                  predicted_stress_level: 0,
                  stress_score: 100,
                  phone_count: 3,
                  pitch_range: [120.29615384615386, 195.91461538461536],
                  intonation: [null, 'RISE'],
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 75,
                    quality: 'fair',
                  },
                  letters: 'this',
                },
              ],
              word: 'This',
              score: {
                comment: 'Fair',
                text_tips: [],
                score: 75,
                quality: 'fair',
              },
              phone_score_list: [
                {
                  stress_level: null,
                  extent: [147, 148],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'dh',
                  sound_most_like: 'hh',
                  score: {
                    comment: 'Missing',
                    text_tips: [],
                    score: 35,
                    quality: 'missing',
                  },
                },
                {
                  stress_level: 1,
                  extent: [148, 159],
                  predicted_stress_level: 0,
                  stress_score: 100,
                  phone: 'ih',
                  sound_most_like: 'ih',
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 95.72727272727273,
                    quality: 'fair',
                  },
                },
                {
                  stress_level: null,
                  extent: [159, 171],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 's',
                  sound_most_like: 's',
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 93.75,
                    quality: 'fair',
                  },
                },
              ],
            },
            widget_type: 'type_1',
          },
        ],
        [
          {
            widget_sort_order: 1,
            widget_category: 'word_analysis',
            widget_status: 'active',
            widget_data: {
              syllable_score_list: [
                {
                  stress_level: 1,
                  extent: [176, 204],
                  predicted_stress_level: 1,
                  stress_score: 100,
                  phone_count: 4,
                  pitch_range: [209.89, 136.38],
                  intonation: ['RISE', 'FALL'],
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 73,
                    quality: 'fair',
                  },
                  letters: 'part',
                },
              ],
              word: 'part',
              score: {
                comment: 'Fair',
                text_tips: [],
                score: 73,
                quality: 'fair',
              },
              phone_score_list: [
                {
                  stress_level: null,
                  extent: [176, 186],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'p',
                  sound_most_like: 'p',
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 96.6,
                    quality: 'fair',
                  },
                },
                {
                  stress_level: 1,
                  extent: [186, 198],
                  predicted_stress_level: 1,
                  stress_score: 100,
                  phone: 'aa',
                  sound_most_like: 'aa',
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 98.75,
                    quality: 'fair',
                  },
                },
                {
                  stress_level: null,
                  extent: [198, 198],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'r',
                  sound_most_like: null,
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 0,
                    quality: 'fair',
                  },
                },
                {
                  stress_level: null,
                  extent: [198, 204],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 't',
                  sound_most_like: 't',
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 96,
                    quality: 'fair',
                  },
                },
              ],
            },
            widget_type: 'type_1',
          },
        ],
        [
          {
            widget_sort_order: 2,
            widget_category: 'word_analysis',
            widget_status: 'active',
            widget_data: {
              syllable_score_list: [
                {
                  stress_level: 1,
                  extent: [208, 260],
                  predicted_stress_level: 1,
                  stress_score: 100,
                  phone_count: 4,
                  pitch_range: [100.03777777777778, 106.56],
                  intonation: ['FALL', 'RISE'],
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 78,
                    quality: 'fair',
                  },
                  letters: 'needs',
                },
              ],
              word: 'needs',
              score: {
                comment: 'Fair',
                text_tips: [],
                score: 78,
                quality: 'fair',
              },
              phone_score_list: [
                {
                  stress_level: null,
                  extent: [208, 240],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'n',
                  sound_most_like: 'dh',
                  score: {
                    comment: 'Missing',
                    text_tips: [],
                    score: 79.54166666666666,
                    quality: 'missing',
                  },
                },
                {
                  stress_level: 1,
                  extent: [240, 252],
                  predicted_stress_level: 1,
                  stress_score: 100,
                  phone: 'iy',
                  sound_most_like: 'iy',
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 100,
                    quality: 'fair',
                  },
                },
                {
                  stress_level: null,
                  extent: [252, 258],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'd',
                  sound_most_like: 'd',
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 91,
                    quality: 'fair',
                  },
                },
                {
                  stress_level: null,
                  extent: [258, 260],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'z',
                  sound_most_like: 'dh',
                  score: {
                    comment: 'Missing',
                    text_tips: [],
                    score: 41.666666666666664,
                    quality: 'missing',
                  },
                },
              ],
            },
            widget_type: 'type_1',
          },
        ],
        [
          {
            widget_sort_order: 3,
            widget_category: 'word_analysis',
            widget_status: 'active',
            widget_data: {
              syllable_score_list: [
                {
                  stress_level: 0,
                  extent: [265, 279],
                  predicted_stress_level: 0,
                  stress_score: 100,
                  phone_count: 1,
                  pitch_range: null,
                  intonation: null,
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 97,
                    quality: 'fair',
                  },
                  letters: 'a',
                },
              ],
              word: 'a',
              score: {
                comment: 'Fair',
                text_tips: [],
                score: 97,
                quality: 'fair',
              },
              phone_score_list: [
                {
                  stress_level: 0,
                  extent: [265, 279],
                  predicted_stress_level: 0,
                  stress_score: 100,
                  phone: 'ah',
                  sound_most_like: 'ah',
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 97.28571428571429,
                    quality: 'fair',
                  },
                },
              ],
            },
            widget_type: 'type_1',
          },
        ],
        [
          {
            widget_sort_order: 4,
            widget_category: 'word_analysis',
            widget_status: 'active',
            widget_data: {
              syllable_score_list: [
                {
                  stress_level: 0,
                  extent: [309, 324],
                  predicted_stress_level: 0,
                  stress_score: 100,
                  phone_count: 2,
                  pitch_range: null,
                  intonation: null,
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 62,
                    quality: 'fair',
                  },
                  letters: 'de',
                },
                {
                  stress_level: 1,
                  extent: [324, 337],
                  predicted_stress_level: 0,
                  stress_score: 0,
                  phone_count: 5,
                  pitch_range: null,
                  intonation: null,
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 11,
                    quality: 'fair',
                  },
                  letters: 'scrip',
                },
                {
                  stress_level: 0,
                  extent: [337, 375],
                  predicted_stress_level: 1,
                  stress_score: 98,
                  phone_count: 3,
                  pitch_range: null,
                  intonation: null,
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 80,
                    quality: 'fair',
                  },
                  letters: 'tion',
                },
              ],
              word: 'description',
              score: {
                comment: 'Fair',
                text_tips: [],
                score: 39,
                quality: 'fair',
              },
              phone_score_list: [
                {
                  stress_level: null,
                  extent: [309, 315],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'd',
                  sound_most_like: 'g',
                  score: {
                    comment: 'Missing',
                    text_tips: [],
                    score: 65.83333333333331,
                    quality: 'missing',
                  },
                },
                {
                  stress_level: 0,
                  extent: [315, 324],
                  predicted_stress_level: 0,
                  stress_score: 100,
                  phone: 'ih',
                  sound_most_like: 'uh',
                  score: {
                    comment: 'Missing',
                    text_tips: [],
                    score: 58.166666666666664,
                    quality: 'missing',
                  },
                },
                {
                  stress_level: null,
                  extent: [324, 324],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 's',
                  sound_most_like: null,
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 0,
                    quality: 'fair',
                  },
                },
                {
                  stress_level: null,
                  extent: [324, 324],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'k',
                  sound_most_like: null,
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 0,
                    quality: 'fair',
                  },
                },
                {
                  stress_level: null,
                  extent: [324, 336],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'r',
                  sound_most_like: 'ah',
                  score: {
                    comment: 'Missing',
                    text_tips: [],
                    score: 22.08333333333334,
                    quality: 'missing',
                  },
                },
                {
                  stress_level: 1,
                  extent: [336, 336],
                  predicted_stress_level: 0,
                  stress_score: 0,
                  phone: 'ih',
                  sound_most_like: null,
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 0,
                    quality: 'fair',
                  },
                },
                {
                  stress_level: null,
                  extent: [336, 337],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'p',
                  sound_most_like: 's',
                  score: {
                    comment: 'Missing',
                    text_tips: [],
                    score: 35,
                    quality: 'missing',
                  },
                },
                {
                  stress_level: null,
                  extent: [337, 351],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'sh',
                  sound_most_like: 's',
                  score: {
                    comment: 'Missing',
                    text_tips: [],
                    score: 41.666666666666686,
                    quality: 'missing',
                  },
                },
                {
                  stress_level: 0,
                  extent: [351, 360],
                  predicted_stress_level: 1,
                  stress_score: 98,
                  phone: 'ah',
                  sound_most_like: 'ih',
                  score: {
                    comment: 'Missing',
                    text_tips: [],
                    score: 71.5,
                    quality: 'missing',
                  },
                },
                {
                  stress_level: null,
                  extent: [360, 375],
                  predicted_stress_level: null,
                  stress_score: null,
                  phone: 'n',
                  sound_most_like: 'n',
                  score: {
                    comment: 'Fair',
                    text_tips: [],
                    score: 94.6,
                    quality: 'fair',
                  },
                },
              ],
            },
            widget_type: 'type_1',
          },
        ],
      ],
    },
  };

  let textParagraph =
    ' rang out across the capital region on Saturday as rocket barrages sent residents running for shelter. Fighting erupted in multiple areas around Kyiv';
  // lesson.description

  const history = useHistory();
  const dispatch = useDispatch();
  const lesson = props.lessonData;
  const nextFire = props.nextFire;
  let interval;
  let millisecondCounter = 10;
  let userData = '';
  let isEndMessage = props.lessonData.end_message ? true : false;
  const [isLoading, setLoading] = useState(false);
  const [isStartMessage, setStartMessage] = useState(!nextFire ? (lesson.start_message ? true : false) : false);
  const [isEndMessageShow, setEndMessageShow] = useState(false);
  const [voiceToText, setVoiceToText] = useState('');
  const [rating, setRating] = useState(0);
  const [userRecordedFile, setUserRecordedFile] = useState(lesson.user_recorded_file);
  const [videoFinished, setVideoFinished] = useState(false);
  const [recorded, setRecorded] = useState(false);
  const [recording, setRecording] = useState(false);
  // const [fluencyData, setFluencyData] = useState(_.get(sampleData,'widget_data'));
  const [fluencyData, setFluencyData] = useState(null);
  const [submitFluencyEvaluation, setSubmitFluencyEvaluation] = useState(false);
  const [audioUrl, setAudioUrl] = useState(props.lessonData.user_recorded_file);
  const [showTooltip, setShowTooltip] = useState();
  const [totalScore, setTotalScore] = useState();
  const [state, setState] = useState({
    record: false,
    recordData: '',
    assignment_file: lesson.user_recorded_file,
  });
  const timeoutRef = useRef();
  const mediaStreamRef = useRef();
  const recorderRef = useRef();
  const startTimeRef = useRef();
  const wordRef = useRef([]);

  const resultScore = {
    excellent: 100,
    very_good: 80,
    good: 60,
    fair: 40,
    poor: 20,
    missing: 0,
  };

  // console.log('Fluency Activity >', props);

  useEffect(() => {
    // console.log('fluencyData', fluencyData);
  }, [fluencyData]);

  useEffect(() => {
    if (!isIOS)
      recorderRef.current = new MicRecorder({
        bitRate: 128,
      });
  }, []);

  useEffect(() => {
    if (props.module.intro_module) Segment.track('IntroFAStarted');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setVoiceToText('');
  }, [lesson]); // Only re-run the effect if currentLesson changes

  const goToNextLesson = () => {
    setFluencyData('');
    if (!isEndMessage) {
      props.next(lesson);
    } else {
      if (props.module.intro_module) Segment.track('IntroFAEnded');
      setEndMessageShow(true);
    }
  };

  const submitRecord = (voice) => {
    Segment.track('Fluency Activity', {
      state: 'recorded',
      moduleId: lesson.module_id,
    });
    try {
      setFluencyData('');
      setLoading(true);
      const extension = isIOS ? 'wav' : 'mp3';
      const formData = new FormData();
      formData.append('module_id', props.moduleId);
      formData.append('module_code', props.moduleCode);
      formData.append('recorded_file', blobToFile(voice.blob, `lesson_record_${lesson.id}.${extension}`));
      formData.append('text', lesson.text_to_speech);

      fluencyEvaluation(formData).then((res) => {
        Segment.track('Fluency Activity', {
          state: 'respond',
          response: res,
          moduleId: lesson.module_id,
        });
        if (!res) {
          confirmAlert({
            title: 'Error',
            message: 'Something went wrong, please record again',
            overlayClassName: `cf-one-button ${isMobile && 'mobile'}`,
            buttons: [
              {
                label: 'OK',
              },
            ],
          });
        } else if (res.ai_data.status === 653 || res.ai_data.status === 648) {
          confirmAlert({
            title: 'Error',
            message: _.get(res, 'ai_data.data.error_message', 'Something went wrong, please record again'),
            overlayClassName: `cf-one-button ${isMobile && 'mobile'}`,
            buttons: [
              {
                label: 'OK',
              },
            ],
          });
        } else {
          setFluencyData(_.get(res, 'ai_data.data.widgets[0].widget_data'));
          // setFluencyData(res.ai_data.data);
          setSubmitFluencyEvaluation(true);
        }
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
      Segment.track('Smart Coach', {
        state: 'failed',
        error,
        moduleId: lesson.module_id,
      });
    }
  };

  const rateFluency = (fluency_id) => {
    setLoading(true);
    userData = getData();
    let dataToSend = {
      fluency_activities_id: fluency_id,
      rating: rating,
      review: '',
    };
    rateFluencyActivity(userData.api_token, dataToSend).then((res) => {
      console.log('response for rating...', res);
      setLoading(false);
    });
  };

  const recordAgain = () => {
    setRecorded(false);
    setFluencyData(null);
  };

  const startRecording = async () => {
    if (isIOS) {
      // RTC
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorderRef.current = new RecordRTC(mediaStreamRef.current, {
        type: 'audio',
        // recorderType: StereoAudioRecorder,
        sampleRate: 44100,
        bufferSize: 4096,
        numberOfAudioChannels: 1,
      });
      recorderRef.current.startRecording();
      startTimeRef.current = Date.now();
      setRecording(true);
    } else {
      // MP3
      recorderRef.current
        .start()
        .then(() => {
          startTimeRef.current = Date.now();
          setRecording(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const stopRecording = () => {
    if (isIOS) {
      // RTC
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        setRecording(false);
        onRecordStop({
          blob,
          startTime: startTimeRef.current,
          stopTime: Date.now(),
          blobURL: window.URL.createObjectURL(blob),
        });
        mediaStreamRef.current.stop();
      });
    } else {
      // MP3
      recorderRef.current
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setRecording(false);
          onRecordStop({
            blob,
            startTime: startTimeRef.current,
            stopTime: Date.now(),
            blobURL: window.URL.createObjectURL(blob),
          });
        })
        .catch((e) => {
          alert('We could not retrieve your message');
          console.error(e);
        });
    }
  };

  const onRecordStop = (voice) => {
    setAudioUrl(voice.blobURL);
    submitRecord(voice);
  };

  const blobToFile = (theBlob, fileName) => {
    var file = new File([theBlob], fileName, { type: 'audio/mpeg', lastModified: new Date() });
    return file;
  };

  const toggleRecord = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (!recording)
      timeoutRef.current = setTimeout(() => {
        stopRecording();
      }, 45000);

    if (recording) stopRecording();
    else startRecording();
  };

  const wordClickHandle = (index) => {
    setShowTooltip(showTooltip == `t_${index}` ? '' : `t_${index}`);
  };

  const colorRange = (number) => {
    let color = '';
    console.log('number', number);
    number = parseFloat(number);
    if (number >= 0 && number < 70) {
      color = 'redStyle';
    } else if (number >= 70) {
      color = 'greenStyle';
    }
    return color;
  };

  const ResultParagraph = (data) => {
    let paragraphScore = 0;
    console.log('data', data);
    return data.data.map((word, index) => {
      console.log('data', word);
      const details = word[0].widget_data;
      console.log('details.syllable_score_list[0].score.score', details.score.score);
      let lastIndex = 0;
      let phones = [];
      let result = [];
      let score = 0;
      details.phone_score_list.map((phone, index) => {
        let phoneScore = resultScore[phone.score.quality];
        score = phone.score.score;
        console.log('phone.sound_most_like', phone.sound_most_like, '-');
        let comment =
          phone.sound_most_like == null
            ? phone.score.comment
            : phone.sound_most_like != phone.phone && phone.score.quality != 'good'
            ? `Sound like ${phone.sound_most_like}`
            : phone.score.comment;
        result.push({
          letter: '',
          length: 0,
          phone: phone.phone,
          score: comment,
          color: colorRange(phone.score.score),
        });
      });
      // score = Math.round(score / details.phone_score_list.length);
      details.syllable_score_list.map((syllable, index) => {
        result[lastIndex].letter = syllable.letters;
        result[lastIndex].length = syllable.phone_count;
        lastIndex += syllable.phone_count;
      });
      paragraphScore += score;
      if (index + 1 == data.data.length) {
        // setTotalScore(data.score.score);
        // setTotalScore(Math.round(paragraphScore / data.data.length));
      }
      console.log('result', result);
      console.log('score', score);

      return (
        <span key={`t_${index}`} className={`${styles.wordBox}`}>
          {showTooltip == `t_${index}` && (
            <span className={styles.detailsCover} onClick={() => wordClickHandle('')}></span>
          )}
          {showTooltip == `t_${index}` && (
            <span className={styles.wordDetails} onClick={() => wordClickHandle('')}>
              <span className={styles.detailsClose}>
                <img src="/images/icon/icon-exit.svg" />
              </span>
              <div className={styles.scrollPanel}>
                <table className={styles.resultTable}>
                  <tr key={index} className={styles.tableHeader}>
                    <td className={styles.detailsTitle}>Syllable</td>
                    <td className={styles.detailsTitle}>Phone</td>
                    <td className={styles.detailsTitle}>Score</td>
                  </tr>
                  {result.map((row, index) => {
                    return (
                      <tr key={index} className={styles.detailsRow1}>
                        {row.letter && (
                          <td rowSpan={row.length} className={styles.detailsTitle1}>
                            {row.letter}
                          </td>
                        )}
                        <td className={styles[row.color]}>{row.phone}</td>
                        <td className={styles[row.color]}>{row.score}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </span>
          )}
          <span
            ref={(el) => (wordRef.current[index] = el)}
            className={`${styles.wordHolder} ${styles.word} ${styles[colorRange(details.score.score)]}`}
            onClick={() => wordClickHandle(index)}
          >
            {/* {details.word} */}
            {details.syllable_score_list.map((syllable, index) => {
              return (
                <span key={index} className={`${styles.word}`}>
                {/* <span key={index} className={`${styles.word} ${styles[colorRange(syllable.score.score)]}`}> */}
                  {syllable.letters}
                </span>
              );
            })}{' '}
          </span>
        </span>
      );
    });
  };

  const fluencyResult = (
    <>
      <div className={styles.totalScore}>
        <span className={styles.scoreText}>Score</span>
        <span className={styles.scoreBox}>{_.get(fluencyData, 'score[0].score')}%</span>
      </div>
      <div className={styles.wordsNotice}>Please click on each word to see detailed feedback.</div>
      <div className={styles.resultBox}>
        <div className={styles.leftCol}>
          <AudioPlayer
            id="recorded"
            hideBar
            source={fluencyData && fluencyData.voice_urls[1] ? fluencyData.voice_urls[1] : ''}
          />
        </div>
        <div className={styles.rightCol}>
          <div className={styles.paragraphResult}>
            <ResultParagraph data={_.get(fluencyData, 'analysis')} />{' '}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {/* {isStartMessage ? (
        <StartMessage message={lesson.start_message} next={() => setStartMessage(false)} />
      ) :  */}
      {isEndMessageShow ? (
        <EndMessage
          message={lesson.end_message}
          nextLesson="Final Assessment"
          next={() => props.next(lesson)}
          backToHome={props.backToHome}
        />
      ) : (
        <>
          <div className={`container ${styles.container} ${videoFinished && styles.paragraphFocus}`}>
            <div className={styles.content}>
              <div className={styles.titleBar}>
                <h2 className={styles.title}>{lesson.title ? lesson.title : 'title'}</h2>
                {lesson.voice_url && (
                  <AudioPlayer
                    id="voice"
                    className={'lite'}
                    source={lesson.voice_url ? lesson.voice_url : ''}
                    hideBar={true}
                    playIcon={'/images/audio/speakerIcon.svg'}
                    pauseIcon={'/images/audio/pauseIcon.svg'}
                  />
                )}
              </div>
              <video
                id="video_frame"
                className={styles.video}
                src={lesson.file_url ? lesson.file_url : ''}
                onEnded={() => setVideoFinished(true)}
                onPlaying={() => setVideoFinished(false)}
                controls
              ></video>
              {lesson.text_to_speech ? (
                <>
                  <span className={styles.practiceDescription}>Now record yourself saying the text below</span>
                  <div className={styles.practiceBox}>
                    <div className={styles.description}>{lesson.text_to_speech}</div>
                    <div className={styles.recordBtnHolder}>
                      <div
                        className={`${isLoading ? styles.loading : ''} ${styles.soundBtn} ${styles.recordBtn} ${recording ? styles.recording : ''}`}
                        onClick={!isLoading && toggleRecord}
                      >
                        {!isLoading ? (
                          <img
                            alt=""
                            src={recording ? '/images/audio/stopIconCircleWhite.svg' : '/images/audio/micIconWhite.svg'}
                          />
                        ):(
                          <DotLoading className={styles.dotLoading} type="elastic" color="rgba(255,255,255,.7)" />
                        )}
                      </div>
                    </div>
                      {!isLoading && (
                          <span className={`${styles.mobilRecordBtnHint} mobilRecordBtnHint`}>{recording ? 'Tap to stop' : 'Tap to record'}</span>
                      )}
                  </div>
                </>
              ) : null}
              <div className={styles.btnHolder}>
                <Button
                  type="secondary"
                  content="Next Video"
                  icon="rightArrow"
                  iconPosition="right"
                  isLoading={isLoading}
                  onClick={() => goToNextLesson()}
                />
              </div>
            </div>
          </div>

          <div className="modal" tabIndex="-1" id="ratingModal" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Rate this acitvity</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <Rating
                    initialRating={0}
                    onChange={(data) => {
                      setRating(data);
                    }}
                    emptySymbol="fa fa-star-o fa-3x yellow"
                    fullSymbol="fa fa-star fa-3x yellow"
                  />
                </div>
                <div className="modal-footer">
                  <SubmitButtonBig
                    caption="Submit"
                    width={200}
                    btnClass="btn btn-secondary"
                    isLoading={isLoading}
                    onClick={() => rateFluency(lesson.id)}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {fluencyData && (
        <div className={styles.modal}>
          <div className={styles.modalCover}></div>
          <div className={styles.modalContainer}>
            <div className={styles.modalContent}>{fluencyResult}</div>
            <div className={styles.modalFooter}>
              <Button
                type="secondary"
                content="Try Again"
                icon="refresh"
                size="medium"
                iconPosition="left"
                isLoading={isLoading}
                onClick={() => recordAgain()}
              />
              <Button
                type="primary"
                content="Next Video"
                icon="rightArrow"
                size="medium"
                iconPosition="right"
                isLoading={isLoading}
                onClick={() => goToNextLesson()}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

FluencyActivity.propTypes = {
  next: PropTypes.func,
  lessonData: PropTypes.object,
  moduleId: PropTypes.string,
  module: PropTypes.object,
  backToHome: PropTypes.func,
};

export default FluencyActivity;
