import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorTextInline from '../../components/ErrorTextInline';
import SubmitButtonBig from '../../components/SubmitButtonBig';
import { isUserExist, updatePaymentProfile, getUserDetails } from '../../../services/paymentServices';
import { setUser } from '../../../actions/user/userSlice';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Segment from '../../../segment';

const ExternalPayment = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();

  let [isLoading, setLoading] = useState(false);
  let [isDisabled, setIsDisabled] = useState(true);
  let [emailError, setEmailError] = useState('');
  let [userNameError, setUserNameError] = useState('');
  let [userFirstName, setUserFirstName] = useState('');
  let [userLastName, setUserLastName] = useState('');
  let [userName, setUserName] = useState('');
  let [email, setEmail] = useState('');
  let [height, setHeight] = useState(window.innerHeight);
  let queryString = props.location.search;

  const checkBlankInput = () => {
    let isValid = true;
    if (!email) {
      isValid = false;
    }
    if (!userName) {
      isValid = false;
    }

    if (isValid) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    if (!queryString) history.push('/signin');
  }, []);

  const paymentProcess = () => {
    let isValid = true;

    // Validate field
    if (!email) {
      isValid = false;
      setEmailError('Enter your email id.');
    }
    if (!userName) {
      isValid = false;
      setUserNameError('Enter your user name.');
    }

    // If validate
    if (isValid) {
      setLoading(true);

      isUserExist(userName, email).then((res) => {
        setLoading(false);

        if (!res) {
          Swal.fire('Error!', 'something went wrong', 'error');
          return false;
        }

        if (res.isError == 1 && res.email_exists) {
          Swal.fire('Info!', 'User has register account, Please login ', 'info');

          history.push('/signin');
        } else if (res.isError == 1 && !res.email_exists) {
          Swal.fire('Error!', res.message, 'error');
        } else {
          updatePaymentProfile(userFirstName, userLastName, res.data.api_token).then((rs) => {
            getUserDetails(res.data.api_token).then((usrdata) => {
              let API_TOKEN = res.data.api_token;

              let external_user_data_store = {
                user: {
                  first_name: usrdata.data.first_name,
                  last_name: usrdata.data.last_name,
                  email: res.data.user.email,
                  id: res.data.user.id,
                },

                api_token_external: API_TOKEN,
                isSession: true,
              };

              dispatch(setUser(external_user_data_store));

              Segment.identify(external_user_data_store.user.email, {
                email: external_user_data_store.user.email,
              });
              //Swal.fire('Info!', 'New user has been register, pleas check associate email for login details', 'info');
              history.push({
                pathname: '/checkout',
                search: queryString,
                state: { externalPayment: true },
                title: 'External Payment Checkout',
              });

              //creating a custom event for loading again some jQuery menu class
              var event = new Event('login', { bubbles: true, cancelable: false });
              document.body.dispatchEvent(event); // event will buble up to window

              setLoading(false);
            });
          });
        }
      });
    }
  };

  return (
    <section className="login sec-pad external-theme text-center" style={{ height: height }}>
      <div className="container">
        <h2 className="header">Enter your information to continue</h2>

        <div className="login-form extra-padding base shadow">
          <form className="text-left">
            <label>Name </label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setUserName(e.target.value);
                setUserNameError('');
              }}
              onKeyUp={() => {
                checkBlankInput();
              }}
            />
            <label>Email </label>
            <input
              type="email"
              className="form-control"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError('');
              }}
              onKeyUp={() => {
                checkBlankInput();
              }}
            />

            <SubmitButtonBig
              caption="NEXT"
              width="100%"
              isLoading={isLoading}
              onClick={() => paymentProcess()}
              isDisabled={isDisabled}
              btnClass="grad btn"
            />
          </form>
          <ErrorTextInline text={` ${emailError} ${userNameError}`} />
        </div>
      </div>
    </section>
  );
};

export default ExternalPayment;
