import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FloatingLabel, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import { API_BASEURL } from '../../../constants/global';
import { sendPasswordResetOTP } from '../../../services/authServices';
import Button from '../../components/Button';
import { post } from '../../../util/httpClient';
import Segment from '../../../segment';
import LoginTemplate from '../../components/Templates/LoginTemplate';
import styles from './ResetPassword.module.css';

const ResetPassword = () => {
  const history = useHistory();
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [isLoading, setLoading] = useState(false);
  const emailRef = useRef();

  const FormSchema = Yup.object().shape({
    otp: Yup.string().required('Enter your OTP.'),
    password: Yup.string().required('Enter New Password'),
    confirmPassword: Yup.string()
      .required('Reenter your Password')
      .oneOf([Yup.ref('password'), null], 'New Password and Reenter Password should match.'),
  });

  const formik = useFormik({
    initialValues: {
      otp: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      if (values.otp && values.password && values.confirmPassword) {
        resetPassword(emailRef.current, values.otp, values.password);
      }
    },
  });

  useEffect(() => {
    let email = _.get(history, 'location.state.email', '');
    if (!email) {
      goToPage('/forgot-password');
    } else {
      emailRef.current = email;
    }
  }, []);

  const goToPage = (path) => {
    history.push(path);
  };

  const toggleShowPassword = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const toggleShowConfirmPassword = () => {
    setConfirmPasswordType(confirmPasswordType === 'password' ? 'text' : 'password');
  };

  const resendOtp = async () => {
    try {
      Segment.track('Retry Forgot Token', { email: emailRef.current });
      let result = await sendPasswordResetOTP(emailRef.current);
      Swal.fire('Success', result.message ? result.message : 'OTP has been sent.', 'success');
    } catch (error) {
      Swal.fire('Error', error.message ? error.message : 'Some error occurred. Please try again.', 'error');
    }
  };

  const resetPassword = async (email, otp, password) => {
    setLoading(true);

    let url = `${API_BASEURL}/reset-password`;
    let dataToSend = { email, otp, newPassword: password };
    try {
      // Verify email
      let response = await post(url, dataToSend);
      if (response && !response.isError) {
        setLoading(false);
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Your password has been reset.',
          showConfirmButton: false,
          timer: 1500,
        });
        goToPage('/signin');
      } else {
        Swal.fire('Error', response && response.message ? response.message : 'Invalid OTP', 'error');
        setLoading(false);
      }
    } catch (error) {
      Swal.fire('Error', error.message ? error.message : 'Some error occurred. Please try again.', 'error');
      setLoading(false);
    }
  };

  return (
    <LoginTemplate>
      <h1>
        <span className="cf-t-highlight">Reset</span> your password
      </h1>
      <div className={`${styles.form} cf-t-form`}>
        <span className={styles.circle} onClick={() => goToPage('/signin')}>
          <FontAwesomeIcon className={styles.back} icon={faChevronLeft} />
        </span>
        <form onSubmit={formik.handleSubmit}>
          <FloatingLabel controlId="floatingOtp" label="Verification Code">
            <Form.Control
              type="text"
              isInvalid={formik.touched.otp && !!formik.errors.otp}
              disabled={isLoading}
              required
              fluid
              placeholder="Verification Code"
              name="otp"
              value={formik.values.otp}
              onChange={formik.handleChange}
              autoComplete="new-password"
            />
            <Form.Control.Feedback type="invalid">{formik.errors.otp}</Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel controlId="floatingNewPassword" label="New Password">
            <Form.Control
              type={passwordType}
              isInvalid={formik.touched.password && !!formik.errors.password}
              disabled={isLoading}
              required
              placeholder="New Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              className={styles.passwordInput}
              autoComplete="new-password"
            />
            <FontAwesomeIcon
              className="eye-icon"
              size="lg"
              icon={passwordType === 'text' ? faEye : faEyeSlash}
              onClick={toggleShowPassword}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel controlId="floatingReEnterNewPassword" label="Re-Enter New Password">
            <Form.Control
              type={confirmPasswordType}
              isInvalid={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
              disabled={isLoading}
              required
              placeholder="Re-Enter New Password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              className={styles.passwordInput}
            />
            <FontAwesomeIcon
              className="eye-icon"
              size="lg"
              icon={confirmPasswordType === 'text' ? faEye : faEyeSlash}
              onClick={toggleShowConfirmPassword}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.confirmPassword}</Form.Control.Feedback>
          </FloatingLabel>

          <Button content="Reset Password" submit={true} isLoading={isLoading} onClick={formik.handleSubmit} />
          <div className={styles.resend}>
            Didn’t get the verification code?
            <span className={styles.link} onClick={resendOtp}>
              Resend
            </span>
          </div>
        </form>
      </div>
    </LoginTemplate>
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;
