import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import styles from './EndMessage.module.css';
import { selectLessonResultStore } from '../../../../actions/lesson/lessonResultStore';
import { getModuleScore } from '../../../../services/moduleServices';
import { selectUser } from '../../../../actions/user/userSlice';

const EndMessage = (props) => {
  const selectorResult = useSelector(selectLessonResultStore);
  const user = useSelector(selectUser);
  let result = props.result || selectorResult.listen_option_result;
  let total = props.total;
  const [isLoading, setLoading] = useState(false);
  const [scoreProgress, setScoreProgress] = useState();
  const [score, setScore] = useState();

  const nextLesson = props.nextLesson;
  const message =
    props.postLesson && user.is_subscribed && !user.isWithFeedback
      ? "Congratulations! You've completed this module.\nIf you want detailed feedback on your pronunciation and fluency, upgrade to the Green Card plan."
      : props.message || '';
  const title = props.title;
  const moduleId = location.pathname.split('/')[2];
  // Number is PI*(line_r*2)
  const css = '.cf-main-header { display: none; }';

  useEffect(() => {
    getModuleScore(moduleId)
      .then((res) => {
        calculateScore(res.module_score);
      })
      .catch(() => {
        calculateScore(null);
      });
  }, []);

  const calculateScore = (preScore) => {
    const calculatedScore = preScore || props.score || (result * 10) / total;

    setScore(calculatedScore);
    setScoreProgress(408 - calculatedScore * 4.08);
  };

  const backToHome = () => {
    setLoading(true);
    props.backToHome();
  };

  const next = () => {
    setLoading(true);
    if (props.postLesson && user.is_subscribed && !user.isWithFeedback)
      window.open('https://chatterfox.com/upgrade-to-green-card-inapp/', '_self');
    else props.next();
  };

  return (
    <div className={`${styles.container} ${styles.whiteBg}`}>
      <style>{css}</style>
      <div className={styles.fullBackground}></div>
      <div className={`${styles.fullBackground} ${styles.cover}`}></div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.icon}>
          <span>
            <img className={styles.defaultIcon} src="/images/icon/cupIcon.svg" />
          </span>
          {score ? (
            <div className={styles.scoreBox}>
              <span className={styles.scoreTitle}>Score</span>
              <span>{score}</span>
            </div>
          ) : null}
          <svg className={styles.scoreProgress} height="150" width="150">
            <circle style={{ fill: '#fff' }} cx="75" cy="75" r="75" />
            <circle className={styles.background} cx="75" cy="75" r="67" />
            <circle
              className={styles.line}
              style={{ strokeDashoffset: scoreProgress || 0 }}
              cx="75"
              cy="75"
              r="65"
              stroke="#34a27f"
              strokeWidth="5"
              fill="transparent"
            />
            <circle style={{ fill: '#FFFFFF' }} cx="75" cy="75" r="61" />
            {/* <circle style={{ fill: '#FFF5E1' }} cx="75" cy="75" r="66" /> */}
          </svg>
        </div>
        <div
          className={styles.message}
          dangerouslySetInnerHTML={{ __html: message.replace('\r\n', '<br/>').replace('\n', '<br/>') }}
        />
        {nextLesson && (
          <div className={styles.nextLesson}>
            <span>Next Lesson:</span>
            {nextLesson}
          </div>
        )}
        {/* <div className="descrip-two start-msg" disabled>
          {startMessage}
        </div> */}
        <div className={styles.btnHolder}>
          <Button
            type="primary"
            content={props.postLesson && user.is_subscribed && !user.isWithFeedback ? 'Upgrade Now' : 'Go Ahead'}
            onClick={next}
            icon="rightArrow"
            iconPosition="right"
            isLoading={isLoading}
          />
          {props.onSkip && props.postLesson && user.is_subscribed && !user.isWithFeedback ? (
            <Button type="link" content="Skip" onClick={props.onSkip} />
          ) : null}
          {props.backToHome && (
            <Button
              type="link" //none for white text color
              content="Back to Home"
              onClick={backToHome}
              icon="refresh"
              iconPosition="left"
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EndMessage.propTypes = {
  message: PropTypes.string,
  nextLesson: PropTypes.string,
  total: PropTypes.number,
  next: PropTypes.func,
  backToHome: PropTypes.func,
  title: PropTypes.string,
  result: PropTypes.any,
  score: PropTypes.number,
  onSkip: PropTypes.func,
  postLesson: PropTypes.bool,
};

export default EndMessage;
