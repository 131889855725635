import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import AuthUserContext from './context';
import { SITE_CONTEXT } from '../constants/global';
import { getData } from '../util/localStorageHandler';

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      let authUser = getData();
      // change here
      if (authUser && authUser.api_token && authUser.user.email && authUser.user.id) {
        if (!condition(authUser)) {
          this.props.history.push(`${SITE_CONTEXT}/`);
        } else {
          // console.log('here1');
        }
      } else {
        this.props.history.push({
          pathname: `${SITE_CONTEXT}/signin`,
          state: {
            redirectUrl: this.props.location.pathname.replace('/', ''),
            redirectUrlQueryParam: this.props.location.search,
          },
        });
      }
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => (condition(authUser) ? <Component {...this.props} /> : null)}
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(withRouter)(WithAuthorization);
};

export default withAuthorization;
