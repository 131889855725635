import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import AudioPlayer from '../../../components/CustomAudioPlayer';
import AudioRecorder from '../../../components/AudioRecorder';
import { saveModuleStep } from '../../../../services/moduleServices';
import { submitUserRecordedData } from '../../../../services/lessonsServices';
import { getData } from '../../../../util/localStorageHandler.js';
import StartMessage from '../message/StartMessage.js';
import EndMessage from '../message/EndMessage.js';
import Segment from '../../../../segment';
import { blobToFile, msToTime } from '../../../../util';
import Button from '../../../components/Button';
import styles from './PreLesson.module.css';

const PreLesson = (props) => {
  const history = useHistory();
  const lesson = props.lessonData;
  const allLessonData = props.allLessonData;
  const [isAudioSubmitted, setAudioSubmitted] = useState(false);
  let userData = '';
  let isEndMessage = props.lessonData.end_message ? true : false;
  const [isStartMessage, setStartMessage] = useState(lesson.start_message ? true : false);
  const [isEndMessageShow, setEndMessageShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [nextLessonStartMessage, setNextLessonStartMessage] = useState('');
  const [oldRecordedFile, setOldRecordedFile] = useState();
  const [recordedData, setRecordedData] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.module.intro_module) Segment.track('IntroPreStarted');
    checkLessonLength();
  }, []);

  useEffect(() => {
    setAudioSubmitted(false);
    setOldRecordedFile(lesson.assignment_file);
  }, [lesson]);

  const goToNextLesson = () => {
    if (!isEndMessage) {
      props.next(lesson);
    } else {
      setEndMessageShow(true);
    }
  };

  const submitRecord = () => {
    Segment.track('Submit PreLesson', {
      moduleId: props.moduleId,
      name: props.name,
    });
    setLoading(true);
    let audioDuration = msToTime(recordedData.stopTime - recordedData.startTime, 'mm:ss');
    userData = getData();
    const formData = new FormData();
    formData.append('recorded_file', blobToFile(recordedData.blob, `pre_lesson)_${lesson.id}.mp3`));
    formData.append('assessment_id', lesson.id);
    formData.append('assessment_type', 'pre_lesson');
    formData.append('duration', audioDuration);

    submitUserRecordedData(formData, userData.api_token, false)
      .then((res) => {
        setLoading(false);
        setAudioSubmitted(true);
        if (props.module.intro_module) Segment.track('IntroPreEnded');

        if (!lesson.is_completed) {
          const dataToSend = {
            module_id: lesson.module_id,
            step: lesson.lesson_type.toLowerCase(),
            step_id: lesson.id,
          };
          saveModuleStep(dataToSend);
        }

        recordedData.blobURL && window.URL.revokeObjectURL(recordedData.blobURL);
        goToNextLesson();
      })
      .catch((error) => {
        setLoading(false);
        confirmAlert({
          title: '',
          message: error.message,
          overlayClassName: `${isMobile && 'mobile'}`,
          buttons: [
            {
              label: 'Ok',
              onClick: () => console.log('hello'),
            },
          ],
        });
      });
  };

  const deleteRecord = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      overlayClassName: `${isMobile && 'mobile'}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            recordedData.blobURL && window.URL.revokeObjectURL(recordedData.blobURL);
            setRecordedData(null);
          },
        },
        {
          label: 'No',
          onClick: () => console.log('hello'),
        },
      ],
    });
  };

  const checkLessonLength = () => {
    const pos = allLessonData.map((e) => e.unique_id).indexOf(lesson.unique_id);
    if (allLessonData[pos + 1]) {
      setNextLessonStartMessage(allLessonData[pos + 1].start_message);
    }
  };

  const handleSkip = () => {
    if (props.module.intro_module) Segment.track('IntroPreSkipped');
    goToNextLesson();
  };

  return (
    <>
      {/* {isStartMessage ? (
        <StartMessage icon={lesson.icon_url} message={lesson.start_message} next={() => setStartMessage(false)} />
      ) : */}
      {isEndMessageShow ? (
        <EndMessage
          message={lesson.end_message}
          nextLesson="Video Lesson"
          next={() => props.next(lesson)}
          backToHome={() => history.push(`/modules/${props.moduleId}`)}
        />
      ) : (
        <div className={`${styles.container} container`}>
          <div className={styles.description}>{lesson.description || ''}</div>
          <div className={styles.content}>
            {lesson.file_type == 'image' ? (
              <div className={styles.image}>
                {/* <img src={lesson.file_url ? lesson.file_url : process.env.PUBLIC_URL + '/images/intro-img.png'} /> */}
                <img src={'/images/soundWave.jpg'} />
              </div>
            ) : (
              <ReactPlayer url={lesson.file_url ? lesson.file_url : 'https://www.youtube.com/watch?v=ysz5S6PUM-U'} />
            )}
            <div className={styles.recordButton}>
              {oldRecordedFile || recordedData ? (
                <AudioPlayer
                  className={'capsule'}
                  id={1}
                  source={oldRecordedFile || recordedData.blobURL}
                  hideBar={true}
                  // playIcon={'/images/audio/playWaveIcon.svg'}
                />
              ) : (
                <AudioRecorder onRecordStop={setRecordedData} />
              )}
            </div>
          </div>
          <div className={styles.text}>
            {oldRecordedFile
              ? 'You have already made this recordings.'
              : recordedData
              ? 'Listen to your recordings. If it\'s good to go, click "Next step"'
              : 'Tap to record and read the paragraph'}
          </div>

          <div className={styles.buttonGroup}>
            {oldRecordedFile || recordedData ? (
              !oldRecordedFile ? (
                <>
                  <Button
                    type="secondary"
                    content="Retry"
                    icon="refresh"
                    iconPosition="left"
                    isDisabled={isLoading}
                    onClick={deleteRecord}
                  />
                  <Button
                    type="primary"
                    content="Next Step"
                    icon="rightArrow"
                    iconPosition="right"
                    isLoading={isLoading}
                    onClick={submitRecord}
                  />
                </>
              ) : (
                <>
                  <Button
                    type="primary"
                    content="Next Step"
                    icon="rightArrow"
                    iconPosition="right"
                    onClick={goToNextLesson}
                  />
                </>
              )
            ) : (
              <Button type="secondary" content="Skip Forward" onClick={() => setShowModal(true)} />
            )}
          </div>
          {showModal && (
            <div className={styles.modal}>
              <div className={styles.modalCover}></div>
              <div className={styles.modalContainer}>
                <div className={styles.modalContent}>
                  <div className={styles.msgHolder}>
                    <span className={styles.modalContentIcon}>
                      <img src={process.env.PUBLIC_URL + '/images/attentionIcon.png'} />
                    </span>
                    <p>
                      Your voice in Pre Lesson unit will use for Human Coach analysis.
                      <br />
                      Are you sure to want to skip this part?
                    </p>
                  </div>
                </div>
                <div className={styles.modalFooter}>
                  <Button
                    type="primary"
                    content="Let's do it"
                    icon="leftArrow"
                    iconPosition="left"
                    size="medium"
                    isLoading={isLoading}
                    onClick={() => setShowModal(false)}
                  />
                  <Button
                    type="secondary"
                    content="Skip for now"
                    icon="rightArrow"
                    iconPosition="right"
                    size="medium"
                    isLoading={isLoading}
                    onClick={handleSkip}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

PreLesson.propTypes = {
  allLessonData: PropTypes.any,
  lessonData: PropTypes.object,
  next: PropTypes.func,
  module: PropTypes.object,
};

export default PreLesson;
