import React, { useEffect, useState, useRef } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import RecordRTC from 'recordrtc';
import { isIOS } from 'react-device-detect';
import PropTypes from 'prop-types';

import styles from './AudioRecorder.module.css';

const AudioRecorder = (props) => {
  const [recording, setRecording] = useState(false);
  const timeoutRef = useRef();
  const mediaStreamRef = useRef();
  const recorderRef = useRef();
  const startTimeRef = useRef();

  useEffect(() => {
    if (!isIOS)
      recorderRef.current = new MicRecorder({
        bitRate: 128,
      });
  }, []);

  const toggleRecord = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (!recording)
      timeoutRef.current = setTimeout(() => {
        stopRecording();
      }, 45000);

    if (recording) stopRecording();
    else startRecording();
  };

  const startRecording = async () => {
    if (isIOS) {
      // RTC
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorderRef.current = new RecordRTC(mediaStreamRef.current, {
        type: 'audio',
        // recorderType: StereoAudioRecorder,
        sampleRate: 44100,
        bufferSize: 4096,
        numberOfAudioChannels: 1,
      });
      recorderRef.current.startRecording();
      startTimeRef.current = Date.now();
      setRecording(true);
    } else {
      // MP3
      recorderRef.current
        .start()
        .then(() => {
          startTimeRef.current = Date.now();
          setRecording(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const stopRecording = () => {
    if (isIOS) {
      // RTC
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        setRecording(false);
        props.onRecordStop({
          blob,
          startTime: startTimeRef.current,
          stopTime: Date.now(),
          blobURL: window.URL.createObjectURL(blob),
        });
        mediaStreamRef.current.stop();
      });
    } else {
      // MP3
      recorderRef.current
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setRecording(false);
          props.onRecordStop({
            blob,
            startTime: startTimeRef.current,
            stopTime: Date.now(),
            blobURL: window.URL.createObjectURL(blob),
          });
        })
        .catch((e) => {
          alert('We could not retrieve your message');
          console.error(e);
        });
    }
  };

  return (
    <div className={`${styles.audioRecorder} cf-AudioRecorder`}>
      <div className={`${styles.soundBtn}`} onClick={toggleRecord}>
        <img alt="" src={recording ? '/images/audio/stopRecord.svg' : '/images/audio/icon-main-action-record.svg'} />
      </div>
    </div>
  );
};

AudioRecorder.propTypes = {
  className: PropTypes.string,
  onRecordStop: PropTypes.func,
  recordComplete: PropTypes.func,
};

export default AudioRecorder;
