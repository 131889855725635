import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';

import { selectUser } from '../../../../actions/user/userSlice';
import { CF_USER } from '../../../../constants/global';
import {
  unsubscribePlan,
  getActiveSubscription,
  getOthersSubscription,
  resumePlan,
} from '../../../../services/paymentServices';
import withAuthorization from '../../../../Session/withAuthorization';
import Segment from '../../../../segment';
import styles from './Info.module.css';
import Button from '../../../components/Button';
import { selectCoachCreditEnable } from '../../../../actions/user/userSlice';

const BillingInfo = (props) => {
  const user = useSelector(selectUser);
  const history = useHistory();
  const isCoachCreditEnable = useSelector(selectCoachCreditEnable);
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [activePlan, setActivePlan] = useState({});
  const [isResumable, setIsResumable] = useState(false);
  const [otherPlans, setOtherPlans] = useState([]);

  useEffect(() => {
    setAuthToken(user.api_token);
    getActivePlan(user.api_token);
    getOtherPlans(user.api_token);
  }, []);

  const resumePlanFunction = async () => {
    setLoading(true);
    resumePlan(user.api_token).then((res) => {
      setLoading(false);
      if (res && !res.isError) {
        Segment.track('ResumeSubscriptionSuccess');
        Swal.fire('Success!', res.message && res.message, 'success');
        getActivePlan(user.api_token);
      } else {
        Segment.track('ResumeSubscriptionFailed');
        Swal.fire('Error!', res.message && res.message, 'error');
      }
    });
  };

  const getActivePlan = async (authToken) => {
    getActiveSubscription(authToken).then((res) => {
      if (res && !res.isError) {
        if (res.active_subscription) {
          if (
            res.active_subscription.stripe_status == 'active' ||
            res.active_subscription.stripe_status == 'trialing'
          ) {
            if (res.active_subscription.kartra_raw_response) {
              const kartraResponse = JSON.parse(res.active_subscription.kartra_raw_response);
              setActivePlan({
                ...res.active_subscription,
                payment_method:
                  kartraResponse.action_details.transaction_details.payment_processor_type === 'PayPal'
                    ? 'PayPal'
                    : 'Credit Card',
              });
            } else {
              setActivePlan({
                ...res.active_subscription,
                payment_method: res.active_subscription.payment_method === 'Paypal' ? 'PayPal' : 'Credit Card',
              });
            }
            setIsResumable(false);
          } else {
            setActivePlan(res.isResumable ? res.active_subscription : {});
            setIsResumable(res.isResumable);
          }
        } else {
          setActivePlan({});
          setIsResumable(res.isResumable);
        }
      }
    });
  };

  const getOtherPlans = async (authToken) => {
    getOthersSubscription(authToken).then((res) => {
      if (res && !res.isError) {
        if (res.subscriptions) {
          const subscriptions = res.subscriptions.map((subscription) => {
            if (subscription.kartra_raw_response) {
              const kartraResponse = JSON.parse(subscription.kartra_raw_response);
              return {
                ...subscription,
                payment_method:
                  kartraResponse.action_details.transaction_details.payment_processor_type === 'PayPal'
                    ? 'PayPal'
                    : 'Credit Card',
              };
            } else {
              return {
                ...subscription,
                payment_method: subscription.payment_method === 'Paypal' ? 'PayPal' : 'Credit Card',
              };
            }
          });
          setOtherPlans(subscriptions);
        } else {
          res.subscriptions && res.subscriptions.length > 0 ? setOtherPlans(res.subscriptions) : setOtherPlans([]);
        }
      }
    });
  };

  const unsubscribe = async () => {
    setLoading(true);

    unsubscribePlan(authToken).then((res) => {
      if (res && !res.isError) {
        Segment.track('SubscriptionCanceled');
        const cancelationMsg = 'Your subscription has been successfully canceled. You will receive a confirmation email within the next 5 minutes to confirm your cancellation. If you do not receive the email, please contact us at <b>customers@chatterfox.com</b> and we will be happy to help.';
        Swal.fire('Success!', cancelationMsg, 'success');
        getActivePlan(authToken);
        getOtherPlans(authToken);
        setLoading(false);
      } else {
        Swal.fire('Error!', res.message && res.message, 'error');
      }
    });
  };

  const purchaseHistory = (id) => {
    if (id) {
      history.push({
        pathname: '/purchases/' + id,
        state: { id: id },
      });
    }
  };

  const remainingCoachingCredit = user.remainingCoachingCredit ? user.remainingCoachingCredit : 0;
  const unsubscribeNote =
    (isCoachCreditEnable && remainingCoachingCredit > 0)
      ? `You still have ${remainingCoachingCredit} coaching ${
          remainingCoachingCredit > 1 ? 'sessions' : 'session'
        } remaining. As a ChatterFox member, you receive coaching sessions every month, and they don't expire as long as you have an active subscription. Are you sure to unsubscribe? `
      : `Are you sure to unsubscribe the current plan?`;
  const unsubscribeTitle = (isCoachCreditEnable && remainingCoachingCredit > 0) ? `You're about to lose all of your coaching sessions.` : null;
  const withCredit = (isCoachCreditEnable && remainingCoachingCredit > 0) ? `withCredit` : `withoutCredit`;
  const unsubscribeClicked = () => {
    confirmAlert({
      title: unsubscribeTitle,
      message: unsubscribeNote,
      overlayClassName: `${isMobile && 'mobile'} ${styles.unsubscribeAlert} ${styles[withCredit]}`,
      closeOnEscape: true,
      closeOnClickOutside: true, 
      buttons: [
        {
          label: 'Yes',
          onClick: () => unsubscribe(),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const nextBillingAmount = (activePlan) => {
    if (activePlan.kartra_billing_amount) {
      return activePlan.kartra_billing_amount;
    } else {
      return activePlan.plan && activePlan.plan.cost && parseFloat(activePlan.plan.cost).toFixed('2');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.activePlan}>
        {activePlan && activePlan.plan && activePlan.plan.id ? (
          <section className="">
            <div className={`${styles.planContainer} ${styles.active}`}>
              <table>
                <tr>
                  <td>
                    <h5>{activePlan.stripe_status && activePlan.stripe_status} Plan</h5>
                  </td>
                  <td>{activePlan.plan && activePlan.plan.display_name && activePlan.plan.display_name}</td>
                </tr>
                <tr>
                  <td>Start Time</td>
                  <td>{activePlan.created_at && moment(activePlan.created_at).format('llll')}</td>
                </tr>
                <tr>
                  <td>Billing amount</td>
                  <td>
                    {activePlan.plan.interval_count > 1 ? ` ${activePlan.plan.interval_count}` : ''}{' '}
                    {activePlan.plan.interval}: {nextBillingAmount(activePlan) + '$'}
                  </td>
                </tr>
                <tr>
                  <td>Billing Period</td>
                  <td>{activePlan.plan_duration && activePlan.plan_duration} Days</td>
                </tr>
                <tr>
                  <td>Next Billing Time</td>
                  <td>{activePlan.stripe_status == "canceled" ? "Canceled" : (activePlan.next_invoice_date && moment(activePlan.next_invoice_date).format('llll'))}</td>
                </tr>
                <tr>
                  <td>Payment Method</td>
                  <td>{activePlan.payment_method && activePlan.payment_method}</td>
                </tr>
              </table>
            </div>
            {!isResumable ? (
              <div className={styles.linksHolder}>
                <a href="JavaScript:Void(0);" onClick={() => purchaseHistory(activePlan.id)}>
                  Purchase History
                </a>
                <span>
                  {!loading ? (
                    <a href="JavaScript:Void(0);" onClick={unsubscribeClicked}>
                      Unsubscribe this plan
                    </a>
                  ) : (
                    <p className="mt-3 light">Please Wait ... </p>
                  )}
                </span>
              </div>
            ) : (
              <div className={styles.linksHolder}>
                {/* <a href="JavaScript:Void(0);" onClick={resumePlanFunction}>
                  Resume the subscription
                </a> */}
              </div>
            )}
          </section>
        ) : (
          <div className={styles.noPlan}>
            <img src="./images/emptyArchive.svg" />
            <div className={styles.text}>You don't have any active plan</div>
            <Button
              type="primary"
              content="Start Now"
              size="large"
              isLoading={loading}
              onClick={() => window.open('https://chatterfox.com/american-smart-fluency-program-payment/', '_self')}
            />
          </div>
        )}
      </div>
      <section className="">
        <div className="">
          <div className="">
            {otherPlans &&
              otherPlans.length > 0 &&
              otherPlans.map((data, index) => {
                return !(data.stripe_status == 'active' || data.stripe_status == 'trialing') ? (
                  <div className="" key={index}>
                    <div className={`${styles.planContainer}`}>
                      <table>
                        <tr>
                          <td>
                            <h5>{data.stripe_status}</h5>
                          </td>
                          <td>{data.plan && data.plan.name}</td>
                        </tr>
                        <tr>
                          <td>Start Time</td>
                          <td>{data.created_at && moment(data.created_at).format('llll')}</td>
                        </tr>
                        <tr>
                          <td>Billing amount</td>
                          <td>
                            {data.plan.interval_count > 1 ? ` ${data.plan.interval_count}` : ''} {data.plan.interval}:{' '}
                            {data.plan && data.plan.cost && parseFloat(data.plan.cost).toFixed('2') + '$'}
                          </td>
                        </tr>
                        <tr>
                          <td>Billing Period</td>
                          <td>{data.plan_duration} Days</td>
                        </tr>
                        <tr>
                          <td>Next Billing Time</td>
                          <td>{activePlan.stripe_status == "canceled" ? "Canceled" : data.next_invoice_date && moment(data.next_invoice_date).format('llll')}</td>
                        </tr>
                        <tr>
                          <td>Payment Method</td>
                          <td>{data.payment_method}</td>
                        </tr>
                      </table>
                    </div>
                    <a
                      href="JavaScript:Void(0);"
                      className=""
                      onClick={() => purchaseHistory(data.plan && data.plan.id)}
                    >
                      Purchase History
                    </a>
                  </div>
                ) : null;
              })}
          </div>
        </div>
      </section>
    </div>
  );
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(BillingInfo);
