import { createSlice } from '@reduxjs/toolkit';

export const moduleSlice = createSlice({
  name: 'module',
  initialState: {
    fetched: false,
    othModules: [],
    introModules: [],
    trialModules: [],
    selectedModule: undefined,
  },

  reducers: {
    setModules: (state, action) => {
      state.othModules = action.payload.othModules;
      state.introModules = action.payload.introModules;
      state.trialModules = action.payload.trialModules;
    },

    setSelectedModule: (state, action) => {
      state.selectedModule = action.payload.module;
    },
  },
});

export const { setModules, setSelectedModule } = moduleSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(setUserAsync(user))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const setModuleAsync = (module) => (dispatch) => {
  setTimeout(() => {
    dispatch(setModules(module));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectModule = (state) => state.module;
export const selectSelectedModule = (state) => state.module.selectedModule;

export default moduleSlice.reducer;

/**
 * https://redux-toolkit.js.org/api/createslice
 */
