import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectUser, setMenu } from '../../../actions/user/userSlice';
import { selectInitialAssessment } from '../../../actions/initialAssessment/initialAssessmentStore';
import _ from 'lodash';

import Segment from '../../../segment';
import Modal from '../../components/Modal';
import styles from './Banner.module.css';

import Button from '../../components/Button';

const Banner = (props) => {
  const location = useLocation();
  const userData = useSelector(selectUser);
  const initialAssessment = useSelector(selectInitialAssessment);
  const [subscribed, setSubscribed] = useState();
  const [isWithFeedback, setIsWithFeedback] = useState();
  const [recommendedAvailable, setRecommendedAvailable] = useState(false);
  const [submitted, setSubmitted] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSubscribed(_.get(userData, 'is_subscribed'));
    setIsWithFeedback(_.get(userData, 'isWithFeedback'));
  }, [userData]);

  useEffect(() => {
    if (initialAssessment.status.is_recommended_module_avilable) setRecommendedAvailable(true);
    else setSubmitted(initialAssessment.status.is_user_completed);
  }, [initialAssessment]);

  const gotoSalesPage = () => {
    setLoading(true);
    if (location.pathname.includes('/feedback')) Segment.track('IntroMCoachingCTA');
    else Segment.track('CTAML');
    window.open('https://chatterfox.com/american-smart-fluency-program-payment/', '_self');
  };

  return (
    <>
      {subscribed ? null : subscribed === undefined || subscribed === '' ? null : (
        <div className={styles.subscribeBanner}>
          <div className={styles.leftCol}>
            <div className={styles.subTitle}>Ready to take the next step?</div>
            <div className={styles.title}>Subscribe Now</div>
            <div className={styles.text}>Become a full member for More Lessons + Coaching feedback</div>
          </div>
          <div className={styles.rightCol}>
            <Button type="gold" content="See Plans" size="medium" isLoading={loading} onClick={gotoSalesPage} />
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
