import { API_BASEURL } from '../constants/global';
import { post, get } from '../util/httpClient';
import { getData } from '../util/localStorageHandler.js';

export const getModuleListV3 = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/module/list/v3`;
  return get(path, token);
};

export const getModuleList = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/module/list`;
  return get(path, token);
};

export const getModuleDetailService = async (id) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/module/detail`;
  let data = { module_id: id };
  return post(path, data, token);
};

export const unlockModule = async (token, moduleId) => {
  let path = `${API_BASEURL}/module/unlock-intro-module-suffix`;
  let data = { id: moduleId };
  return post(path, data, token);
};

export const saveModuleStep = async (dataToSend) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/module/save/step`;
  return post(path, dataToSend, token);
};

export const deleteFinalRecord = async (token, dataToSend) => {
  let path = `${API_BASEURL}/module/delete/final-record`;
  return post(path, dataToSend, token);
};

export const reviewModule = async (token, dataToSend) => {
  let path = `${API_BASEURL}/module/review`;
  return post(path, dataToSend, token);
};

export const getFeedback = async (dataToSend) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/module/feedback`;
  return post(path, dataToSend, token);
};

export const getFeedbackAll = async (token, dataToSend) => {
  let path = `${API_BASEURL}/module/feedback-all`;
  return post(path, dataToSend, token);
};

export const getUserSubscription = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/module/subscription-data`;
  return get(path, token);
};

export const clearPostLesson = async (moduleId) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/clear-post-lesson`;
  return post(path, { module_id: moduleId }, token);
};

export const getModuleScore = async (moduleId) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/v1/module/score`;
  return post(path, { module_id: moduleId }, token);
};

export const getFluencyActivityList = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;
  let path = `${API_BASEURL}/fluency-activity/list`;
  return get(path, token);
}

export const getFluencyActivity = async (moduleId) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;
  let path = `${API_BASEURL}/fluency/fluency-activity`;
  return post(path, { id: moduleId }, token);
};
