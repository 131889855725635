import { createSlice } from '@reduxjs/toolkit';

export const breadCrumps = createSlice({
	name: 'breadcrumps',
	initialState: {
		"paths": [],
		"route": []
	},

	reducers: {
		setBreadCrumpsPath: (state, action) => {
			let breadcrumpsList = action.payload.paths.split("/");
			let notNullBreadcrumps = breadcrumpsList.filter(function (el) {
				return el != "";
			});
			// console.log('here === === ',title);
			// if (title && notNullBreadcrumps.length > 0) {
			//   notNullBreadcrumps[notNullBreadcrumps.length - 1] = title;
			// }
			state.paths = notNullBreadcrumps;
			state.route = [];
			notNullBreadcrumps.map((elm, ind) => {
				//state.route.push("/"+elm+"/"+notNullBreadcrumps[ind+1]?notNullBreadcrumps[ind+1]:null);
				if (notNullBreadcrumps[ind + 1]) {
					state.route.push(action.payload.paths.substring(0, action.payload.paths.search(notNullBreadcrumps[ind + 1])))
				} else {
					state.route.push(action.payload.paths)
				}
			})


			// if(action.payload.index != 0){
			//   state.paths.splice(action.payload.index,  state.paths.length-1, action.payload);
			// }else{
			//    state.paths =  [ action.payload ]
			// }

			// if(state.paths.indexOf(action.payload.paths) == -1){ //path is new in breadcrumps array

			//   //state.paths.push(action.payload.paths);
			//   //state.paths.splice(action.payload.index, action.payload.index+1, action.payload.paths.split("_")[0]);
			//   state.paths.splice(action.payload.index, action.payload.index+1, action.payload);

			// }else{ //path allready in breadcrumps array

			//   var newPaths = state.paths.slice(0, state.paths.indexOf(action.payload.paths)+1);
			//   state.paths = newPaths ;

			// }

		},

	}
});

export const { setBreadCrumpsPath } = breadCrumps.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(setBreadCrumpsPathAsync(user))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const setBreadCrumpsPathAsync = breadcrumps => dispatch => {
	setTimeout(() => {
		dispatch(setBreadCrumpsPath(breadcrumps));
	}, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectBreadCrumpsPath = state => state.breadcrumps;

export default breadCrumps.reducer;

/**
 * https://redux-toolkit.js.org/api/createslice
 */