import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { setSelectedModule } from '../../../../actions/module/moduleSlice';
import styles from './index.module.css';
import Segment from '../../../../segment';
import { setSelectedLesson } from '../../../../actions/lesson/lessonStore';
import { selectUser } from '../../../../actions/user/userSlice';

const ModuleCard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { id, iconSrc, title, subtitle, score, locked, feedbackAvailable, groupTitle, groupId, userProgress, module } =
    props;
  const _className = `${styles.container} ${props.className || ''}`;
  let coachScore = score ? score : 0;
  let scoreProgress = 238 - (userProgress / 10) * 23.8;

  // Module card style conditions
  let style = {};
  if (groupId !== 'Zero') style = { borderColor: '#E5E5E5' };
  if (locked) style = { ...style, filter: 'grayscale(100%)' };

  // Score color conditions
  let scoreColor = '#e5e5e5';
  if (score > 0 && score < 70) scoreColor = '#FF0000';
  else if (score >= 70 && score < 90) scoreColor = '#F8D328';
  else if (score >= 90) scoreColor = '#00FF00';

  const customPropsModule = locked
    ? { onClick: () => props.showUnlockModal() }
    : {
        onClick: (e) => {
          if (!e.defaultPrevented) {
            if (id === 'initialAssessment') {
              history.push('/initialAssessment', { banner: true });
            } else {
              if (module.intro_module) Segment.track('IntroMoClick');
              dispatch(setSelectedModule({ module }));
              redirectTo(`/modules/${id}`, {
                id: id,
                title: title,
                feedbackAvailable,
              });
            }
          }
        },
      };

  const loadDefaultImg = (event) => {
    event.target.src = process.env.PUBLIC_URL + '/images/logo.png';
  };

  const redirectTo = (path, data = {}) => {
    if (path && path !== '') {
      history.push({
        pathname: path,
        state: data,
      });
    }
  };

  const goToFeedback = (module, path) => {
    Segment.track('Enter Show Feedback', {
      moduleId: props.id,
      name: props.title,
    });
    const feedbackObj = { lesson_type: 'feedback', module: module };
    dispatch(setSelectedModule({ module }));
    dispatch(setSelectedLesson(feedbackObj));
    redirectTo(path + '/feedback', {
      lessonData: feedbackObj,
      allModule: '',
      modulePath: path,
      title: 'Feedback',
    });
  };

  return (
    <div className={_className} {...customPropsModule} style={style}>
      <div className={styles.icon}>
        <img
          alt=""
          src={iconSrc}
          onError={(ev) => {
            loadDefaultImg(ev);
          }}
        />
        {/* <div className={styles.coachScore}>{coachScore != 0 && parseInt(coachScore)}</div> */}
        {/* <svg className={styles.scoreProgress} height="80" width="80">
          <circle className={styles.background} cx="40" cy="40" r="40" />
          <circle
            className={styles.line}
            style={{ strokeDashoffset: scoreProgress }}
            cx="40"
            cy="40"
            r="37"
            stroke="#34a27f"
            strokeWidth="5"
            fill="transparent"
          />
          <circle style={{ fill: '#FFFFFF' }} cx="40" cy="40" r="34.5" />
          <circle style={{ fill: '#FFF5E1' }} cx="40" cy="40" r="31" />
        </svg> */}
      </div>
      <div className={styles.content}>
        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
      {feedbackAvailable ? (
        <div
          className={styles.rightSide}
          onClick={(e) => {
            e.stopPropagation();
            if (props.id === 'initialAssessment') {
              const notification = user.notifications.filter(
                (notification) => notification.notification_type === 'initial-assessment-ai-feedback',
              )[0];
              history.push(`/notifications/${notification.id}`, { prevPath: '/modules' });
            } else {
              goToFeedback(props.module, `/modules/${id}`);
            }
          }}
        >
          {/* {feedbackAvailable && <img src={process.env.PUBLIC_URL + '/images/coachFeedback.svg'} />} */}
          {coachScore > 0 ? (
            <div className={styles.scoreHolder}>
              <span>Score</span>
              <span className={styles.scoreNumber}>{parseInt(coachScore)}</span>
            </div>
          ) : (
            <div className={styles.scoreHolder}>
              <span>Results</span>
            </div>
          )}
          <span className={styles.rightArrow}></span>
        </div>
      ) : user.is_subscribed && user.isWithFeedback && module.user_progress === 100 && !feedbackAvailable ? (
        <div className={`${styles.rightSide} ${styles.withoutBackground}`}>
          <img src={process.env.PUBLIC_URL + '/images/sand-clock.png'} />
        </div>
      ) : coachScore > 0 ? (
        <div className={styles.rightSide}>
          <div className={styles.scoreHolder}>
            <span>Score</span>
            <span className={styles.scoreNumber}>{parseInt(coachScore)}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

ModuleCard.propTypes = {
  id: PropTypes.any,
  className: PropTypes.string,
  iconSrc: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  groupTitle: PropTypes.string,
  score: PropTypes.number,
  locked: PropTypes.bool,
  feedbackAvailable: PropTypes.bool,
  groupId: PropTypes.any,
  module: PropTypes.object,
  userProgress: PropTypes.number,
  showUnlockModal: PropTypes.func,
};

export default ModuleCard;
