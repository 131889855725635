import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: {},
  aiCoachFeedback: null,
  humanCoachFeedback: null,
  personalizedTrainingPlan: [],
};

export const initialAssessmentSlice = createSlice({
  name: 'initialAssessment',
  initialState,

  reducers: {
    reset: (state) => initialState,

    setAICoach: (state, action) => {
      state.aiCoachFeedback = action.payload;
    },

    setHumanCoach: (state, action) => {
      state.humanCoachFeedback = action.payload;
    },

    setPersonalizedTrainingPlan: (state, action) => {
      state.personalizedTrainingPlan = action.payload;
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setInitialAssessment, setAICoach, setHumanCoach, setPersonalizedTrainingPlan, setStatus, reset } =
  initialAssessmentSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(setUserAsync(user))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const setInitialAssessmentAsync = (initialAssessment) => (dispatch) => {
  setTimeout(() => {
    dispatch(setInitialAssessment(initialAssessment));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectInitialAssessment = (state) => state.initialAssessment;

export default initialAssessmentSlice.reducer;

/**
 * https://redux-toolkit.js.org/api/createslice
 */
