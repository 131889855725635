import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import useAudioPlayer from './useAudioPlayer';
import Bar from './Bar';
import styles from './AudioPlayer.module.scss';
import moment from 'moment';

const Audio = forwardRef(
  (
    { id, source, hideBar, className, playIcon, pauseIcon, label, showDuration, type, mode, pauseable = true, onStart },
    ref,
  ) => {
    const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer({ id: `audio_${id}` });
    const initRef = useRef(true);
    const iconPath = '/images/audio/';

    const modeIcons = {
      default: { play: 'play.svg', pause: 'pause.svg' },
      coach: { play: 'playCoachIcon.svg', pause: 'pause.svg' },
      client: { play: 'playWaveIcon.svg', pause: 'pause.svg' },
    };

    const _playIcon =
      playIcon || (mode ? `${iconPath}${modeIcons[mode].play}` : `${iconPath}${modeIcons.default.play}`);
    const _pauseIcon =
      pauseIcon || (mode ? `${iconPath}${modeIcons[mode].pause}` : `${iconPath}${modeIcons.default.pause}`);

    const formatDuration = (duration) => {
      return moment.duration(duration, 'seconds').format('mm:ss', { trim: false });
    };

    useEffect(() => {
      if (initRef.current) {
        initRef.current = false;
        return;
      }

      if (playing && onStart) onStart();

      if (!playing && !pauseable) {
        setClickedTime(0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playing]);

    useImperativeHandle(ref, () => ({
      stop() {
        setPlaying(false);
      },
    }));

    const handleClickCapture = () => {
      setPlaying(!playing);
    };

    return (
      <div className={`${styles.player} ${className || ''} cf-audioPlayer`}>
        <audio id={`audio_${id}`} loop={false}>
          <source src={source} />
          Your browser does not support the <code>audio</code> element.
        </audio>
        <div className={`${styles.controls} cf-audioPlayer-controls ${type}_audio ${hideBar ? styles.noBar : ''}`}>
          <div className={`${styles.controlButton} cf-audioPlayer-control-button`} onClickCapture={handleClickCapture}>
            <img alt="" src={playing ? _pauseIcon : _playIcon} />
          </div>
          {(label || showDuration) && (
            <div className={`${styles.label} cf-audioPlayer-label`}>
              {label && <span>{label}</span>}
              {showDuration && (
                <span className={`${styles.duration} cf-audioPlayer-duration`}>{formatDuration(duration)}</span>
              )}
            </div>
          )}
          {!hideBar && <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)} />}
        </div>
      </div>
    );
  },
);

Audio.propTypes = {
  id: PropTypes.any,
  source: PropTypes.string,
  hideBar: PropTypes.bool,
  className: PropTypes.string,
  playIcon: PropTypes.string,
  pauseIcon: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  mode: PropTypes.string,
  showDuration: PropTypes.bool,
  pauseable: PropTypes.bool,
  onStart: PropTypes.func,
};

Audio.displayName = 'AudioPlayer';

export default Audio;
