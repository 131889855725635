import React from 'react';
import moment from 'moment';
import 'moment-duration-format';

import styles from './AudioPlayer.module.scss';

const Bar = (props) => {
  const { duration, curTime, onTimeUpdate } = props;

  const curPercentage = (curTime / duration) * 100;

  const formatDuration = (duration) => {
    return moment.duration(duration, 'seconds').format('mm:ss', { trim: false });
  };

  const calcClickedTime = (e) => {
    const clickPositionInPage = e.pageX;
    const bar = document.querySelector(`.${styles.progress}`);
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  };

  const handleTimeDrag = (e) => {
    onTimeUpdate(calcClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calcClickedTime(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  };

  return (
    <div className={styles.bar}>
      {/* <span className={styles.time}>{formatDuration(curTime)}</span> */}
      <div
        className={styles.progress}
        style={{
          background: `linear-gradient(to right, #F86969 ${curPercentage}%, #f1f1f5 0)`,
        }}
        onMouseDown={(e) => handleTimeDrag(e)}
      >
        <span className={styles.knob} style={{ left: `${curPercentage - 2}%` }} />
      </div>
      <span className={styles.time}>{formatDuration(duration)}</span>
    </div>
  );
};

export default Bar;
