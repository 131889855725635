import React from 'react';

const OnboardingLogoText = (props) => {
  return (
    <>
      <div className="figure d-block text-center">
        <img src="images/logo.png" width={100} />
      </div>
      <div className="figcaption text-center">
        <div className="site-navbar d-block mb-2">
          <h2>{props.text}</h2>
          {props.subText&&<p>{props.subText}</p>}
        </div>
      </div>
    </>
  );
};

export default OnboardingLogoText;