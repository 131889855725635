import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './index.module.css';

import ModuleGroup from '../ModuleGroup';
import {
  selectInitialAssessment,
  setPersonalizedTrainingPlan,
} from '../../../../actions/initialAssessment/initialAssessmentStore';
import { getPersonalizedTrainingPlan } from '../../../../services/initialAssessmentServices';

const MyLessons = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const initialAssessment = useSelector(selectInitialAssessment);

  useEffect(() => {
    getPersonalizedTrainingPlan().then((res) => {
      if (res && !res.isError && !res.errorCode) {
        dispatch(setPersonalizedTrainingPlan(res.data));
      }
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.coach}>
          <div className={styles.score}>
            <span>5</span>
          </div>
          <div className={styles.circle}>
            <div className={styles.image}>
              <img src={process.env.PUBLIC_URL + '/images/coach.jpg'} />
            </div>
          </div>
        </div>
        <div className={styles.title}>
          <span>Clara Davis</span>
          Human Coaches Supervisor
        </div>
      </div>
      <div className={styles.content}>
        {initialAssessment.personalizedTrainingPlan.length ? (
          <div className={styles.lessons}>
            <ModuleGroup
              id="Zero"
              type="PersonalizedTrainingPlan"
              title="Active Lessons"
              modules={initialAssessment.personalizedTrainingPlan}
            />
            <div className={styles.tips}>
              <div className={styles.coach}>
                <img src={process.env.PUBLIC_URL + '/images/coach.jpg'} />
              </div>
              <div className={styles.text}>
                <span>Latest tips from Clara</span>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy…
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.wait}>
            <img src={process.env.PUBLIC_URL + '/images/clock.svg'} />
            <span>Your results will be ready in a day or two</span>
            <a href="#" onClick={() => history.push('/modules')}>
              Let’s practice for now!
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

MyLessons.propTypes = {};

export default MyLessons;
