import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { selectUser } from '../../../../actions/user/userSlice';
import { API_BASEURL, CF_USER } from '../../../../constants/global';
import { post } from '../../../../util/httpClient';
import withAuthorization from '../../../../Session/withAuthorization';

const PurchaseHistory = (props) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [planId, setPlanId] = useState('');
  const [details, setDetails] = useState([]);

  useEffect(() => {
    // let id = history.location.state && history.location.state.id;
    let id = props.match.params.id;
    console.log('Url Id is ==', id);
    setAuthToken(user.api_token);
    getHistory(id, user.api_token);
  }, []);

  const getHistory = async (id, authToken) => {
    let dataToSend = {
      subscription_id: id,
    };
    let path = `${API_BASEURL}/payment-invoices`;
    let res = await post(path, dataToSend, authToken);

    if (res && !res.isError) {
      res.invoices && res.invoices.length > 0 ? setDetails(res.invoices) : setDetails([]);
    }
  };

  return (
    <div className="content-wrapper">
      <section className="unlock-membership for-purchase-history">
        <div className="container">
          <div className="row">
            {details &&
              details.length > 0 &&
              details.map((data, index) => {
                return (
                  <div className="col-lg-6" key={index}>
                    <div className="settings">
                      <ul>
                        <li>
                          <figure>
                            <img src="../images/ic11.png" />
                          </figure>
                        </li>
                        <li>
                          <span>Transaction Id.: {data.transaction_id}</span>
                          <span>Amount: {data.amount + '$'}</span>
                          <span>Payment Method: {data.payment_gateway}</span>
                          <span>Credited at: {data.created_at && moment(data.created_at).format('ll')}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(PurchaseHistory);
