import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isIOS, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';

import AudioPlayer from '../../../components/CustomAudioPlayer';
import StartMessage from '../message/StartMessage.js';
import EndMessage from '../message/EndMessage.js';
import { submitUserRecordedData } from '../../../../services/lessonsServices';
import { getData } from '../../../../util/localStorageHandler.js';
import Button from '../../../components/Button';
import styles from './ListenAndRecord.module.css';
import { msToTime } from '../../../../util';
import PlayCard from '../../../components/PlayCard';
import Segment from '../../../../segment';

const ListenAndRecord = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lesson = props.lessonData;
  const nextFire = props.nextFire;
  let isEndMessage = props.lessonData.end_message ? true : false;
  const pos = lesson.words.map((e) => e.unique_id).indexOf(lesson.unique_id);
  const counter = pos + 1;
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isStartMessage, setStartMessage] = useState(!nextFire ? (lesson.start_message ? true : false) : false);
  const [isEndMessageShow, setEndMessageShow] = useState(false);
  const [recorded, setRecorded] = useState(false);
  const [recordedData, setRecordedData] = useState();
  const audiosRef = {
    listenAndRecord_aud_1: useRef(),
    listenAndRecord_aud_2: useRef(),
  };

  useEffect(() => {
    if (props.module.intro_module && counter === 1) Segment.track('IntroLRStarted');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    recorded && recordedData && setShowModal(true);
  }, [recorded, recordedData]);

  const stopOtherAudioPlayers = (currentPlayerId) => {
    Object.keys(audiosRef).forEach((value) => {
      if (value !== currentPlayerId && audiosRef[value].current) audiosRef[value].current.stop();
    });
  };

  const goToNextLesson = () => {
    if (!isEndMessage) {
      props.next();
    } else {
      if (props.module.intro_module) Segment.track('IntroLREnded');
      setEndMessageShow(true);
    }
  };

  const goToPrevLesson = () => {
    if (!isStartMessage) {
      props.prev(lesson);
    } else {
      setStartMessage(true);
    }
  };

  const blobToFile = (theBlob, fileName) => {
    var file = new File([theBlob], fileName, { type: 'audio/mpeg', lastModified: new Date() });
    return file;
  };

  const submitRecord = () => {
    setLoading(true);
    const userData = getData();
    const extension = isIOS ? 'wav' : 'mp3';
    const formData = new FormData();
    formData.append('recorded_file', blobToFile(recordedData.blob, `lesson_record_${lesson.id}.${extension}`));
    formData.append('listen_record_id', lesson.id);
    formData.append('duration', msToTime(recordedData.stopTime - recordedData.startTime));

    submitUserRecordedData(formData, userData.api_token, true)
      .then((res) => {
        /*const pos = props.lessonIndex;
        const newObj = getData().allModulesData;
        newObj[pos].user_recorded_file = res.file_url;
        newObj[pos].audio_duration = `Duration: ${msToTime(recordedData.stopTime - recordedData.startTime)}`;
        // this is for make the object nonextinsable/ noneditable
        Object.preventExtensions(newObj);
        dispatch(setLessonStore(newObj));*/

        recordedData.blobURL && window.URL.revokeObjectURL(recordedData.blobURL);
        goToNextLesson();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        confirmAlert({
          title: '',
          message: error.message,
          overlayClassName: `${isMobile && 'mobile'}`,
          buttons: [
            {
              label: 'Ok',
              onClick: () => console.log('hello'),
            },
          ],
        });
      });
  };

  const onRecordStop = (recordedBlob) => {
    recordedData && recordedData.blobURL && window.URL.revokeObjectURL(recordedData.blobURL);
    setRecorded(true);
    setRecordedData(recordedBlob);
  };

  return (
    <>
      {/* {isStartMessage ? (
        <StartMessage message={lesson.start_message} next={() => setStartMessage(false)} />
      ) :  */}
      {isEndMessageShow ? (
        <EndMessage
          message={lesson.end_message}
          nextLesson="Test your Ears"
          next={props.next}
          backToHome={props.backToHome}
        />
      ) : (
        <div className="container lesson-dropdwn unlock-membership">
          <PlayCard
            word={lesson.word}
            pattern="/images/patterns/waveBgWhite.svg"
            progressCurrent={counter}
            audio={lesson.audio_url}
            progressTotal={lesson.words.length}
            onRecordStop={onRecordStop}
          />
          <div className={styles.description}>Tap to record and say the word</div>
          <div className="row1">
            {/* this audio player for listen user recorded voice */}
            <div className={styles.btnHolder}>
              <Button
                type="secondary"
                content="Previous"
                icon="leftArrow"
                iconPosition="left"
                size="medium"
                isDisabled={counter <= 1 || isLoading}
                onClick={goToPrevLesson}
              />
              <Button
                type="secondary"
                content="Next"
                icon="rightArrow"
                iconPosition="right"
                size="medium"
                isDisabled={isLoading}
                onClick={goToNextLesson}
              />
            </div>
          </div>
          {showModal && (
            <div className={styles.modal}>
              <div className={styles.modalCover}></div>
              <div className={styles.modalContainer}>
                <div className={styles.modalContent}>
                  <div className={styles.msgHolder}>
                    Listen to your voice and compare <br /> with native voice
                  </div>
                  <div className={styles.contentBtnHolder}>
                    <AudioPlayer
                      className={`${styles.audioPlayer} ${styles.modalContentBtn}`}
                      id="listenAndRecord_aud_1"
                      ref={audiosRef['listenAndRecord_aud_1']}
                      label="Your Voice"
                      source={recordedData.blobURL}
                      hideBar={true}
                      playIcon={'/images/audio/playWaveIcon.svg'}
                      pauseIcon={'/images/audio/pause.svg'}
                      pauseable={false}
                      onStart={() => stopOtherAudioPlayers('listenAndRecord_aud_1')}
                    />
                    <AudioPlayer
                      className={`${styles.audioPlayer} ${styles.modalContentBtn}`}
                      id="listenAndRecord_aud_2"
                      ref={audiosRef['listenAndRecord_aud_2']}
                      label="Coach Voice"
                      source={lesson.audio_url}
                      hideBar={true}
                      playIcon={'/images/audio/playCoachIcon.svg'}
                      pauseIcon={'/images/audio/pause.svg'}
                      pauseable={false}
                      onStart={() => stopOtherAudioPlayers('listenAndRecord_aud_2')}
                    />
                  </div>
                </div>
                <div className={styles.modalFooter}>
                  <Button
                    type="secondary"
                    content="Try Again"
                    icon="refresh"
                    iconPosition="left"
                    size="medium"
                    isDisabled={isLoading}
                    onClick={() => setShowModal(false)}
                  />
                  <Button
                    type="primary"
                    content="Next"
                    icon="rightArrow"
                    iconPosition="right"
                    size="medium"
                    isLoading={isLoading}
                    onClick={submitRecord}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

ListenAndRecord.propTypes = {
  moduleId: PropTypes.string,
  lessonData: PropTypes.object,
  lessonIndex: PropTypes.number,
  nextFire: PropTypes.bool,
  next: PropTypes.func,
  module: PropTypes.object,
  backToHome: PropTypes.func,
};

export default ListenAndRecord;
