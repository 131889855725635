import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import { confirmAlert } from 'react-confirm-alert';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import _ from 'lodash';

import AudioPlayer from '../../../components/CustomAudioPlayer';
import AudioRecorder from '../../../components/AudioRecorder';
import { uploadFinalAssessment } from '../../../../services/lessonsServices';
import { deleteFinalRecord } from '../../../../services/moduleServices';
import { getData } from '../../../../util/localStorageHandler.js';
import StartMessage from '../message/StartMessage.js';
import EndMessage from '../message/EndMessage.js';
import { setLessonStore, setSelectedLesson } from '../../../../actions/lesson/lessonStore';
import Segment from '../../../../segment';
import { blobToFile, msToTime } from '../../../../util';
import Button from '../../../components/Button';
import styles from './PreLesson.module.css';
import { selectSelectedModule } from '../../../../actions/module/moduleSlice';
import { selectCoachCreditEnable } from '../../../../actions/user/userSlice';
import { getUserCredit } from '../../../../services/coachingServices';

const PostLesson = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedModule = useSelector(selectSelectedModule);
  const isCoachCreditEnable = useSelector(selectCoachCreditEnable);
  const lesson = props.lessonData;
  const modulesData = getData().allModulesData;
  const description = lesson.description
    ? lesson.description
    : lesson.description_first
    ? lesson.description_first
    : lesson.description_second
    ? lesson.description_second
    : '';
  const allLessonData = props.allLessonData;
  const isEndMessage = props.lessonData.end_message ? true : false;
  const userData = getData();
  const [isAudioSubmitted, setAudioSubmitted] = useState(false);
  const [isStartMessage, setStartMessage] = useState(lesson.start_message ? true : false);
  const [isEndMessageShow, setEndMessageShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [nextLessonStartMessage, setNextLessonStartMessage] = useState('');
  const [oldRecordedFile, setOldRecordedFile] = useState();
  const [recordedData, setRecordedData] = useState();
  const [canRecord, setCanRecord] = useState(true);
  const userCreditResultRef = useRef();
  let titleStyle = `#headerTitle:after{content:' (${lesson.currentNumber}/${lesson.totalCount})'}`;

  useEffect(() => {
    checkLessonLength();
    userCreditResultRef.current = getUserCredit();
  }, []);

  useEffect(() => {
    setAudioSubmitted(false);
    setRecordedData(null);
    setOldRecordedFile(lesson.assignment_file);
  }, [lesson]);

  const goToNextLesson = () => {
    if (allLessonData[props.lessonIndex + 1]) {
      props.goToReview();
    } else {
      if (props.module.intro_module && !canRecord) Segment.track('IntroPostEnded');
      setEndMessageShow(true);
    }
  };

  const submitRecord = () => {
    Segment.track('Submit PostLesson', {
      moduleId: props.moduleId,
      name: props.name,
    });
    setLoading(true);
    let audioDuration = msToTime(recordedData.stopTime - recordedData.startTime, 'mm:ss');
    const formData = new FormData();
    formData.append('recorded_file', blobToFile(recordedData.blob, `final)_${lesson.id}.mp3`));
    formData.append('assessment_id', lesson.id);
    formData.append('module_id', lesson.module_id);
    formData.append('assessment_type', 'final');
    formData.append('duration', audioDuration);

    uploadFinalAssessment(userData.api_token, formData)
      .then((res) => {
        if (res && !res.isError && !res.errorCode) {
          setAudioSubmitted(true);
          let score;
          if (res.ai_scores) {
            const moduleScore = res.ai_scores.data.user_module_scores.filter(
              (m) => m.module_id === props.module.module_code,
            );
            score = moduleScore[0].score;
          }
          updateAllModuleOnGlobalStore(res.file_url, score);
          recordedData.blobURL && window.URL.revokeObjectURL(recordedData.blobURL);
          props.updateUserProgress();
          goToNextLesson();
        } else {
          confirmAlert({
            title: 'Failed',
            message: 'Something went wrong, please record again',
            overlayClassName: `cf-one-button ${isMobile && 'mobile'}`,
            buttons: [
              {
                label: 'Ok',
              },
            ],
          });
        }
        setLoading(false);
      })
      .catch(() => {
        confirmAlert({
          title: 'Failed',
          message: 'Something went wrong, please record again',
          overlayClassName: `cf-one-button ${isMobile && 'mobile'}`,
          buttons: [
            {
              label: 'Ok',
            },
          ],
        });
        setLoading(false);
      });
  };

  const updateAllModuleOnGlobalStore = (fileData, score) => {
    const pos = props.lessonIndex;
    modulesData[pos].assignment_file = fileData;
    modulesData[pos].showEndMessage = isEndMessage;
    modulesData[pos].score = score;
    // this is for make the object nonextinsable/ noneditable
    Object.preventExtensions(modulesData);
    dispatch(setLessonStore(modulesData));
  };

  const deleteRecord = () => {
    confirmAlert({
      title: 'Confirm to cancel',
      message: 'Are you sure to do this.',
      overlayClassName: `${isMobile && 'mobile'}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            recordedData.blobURL && window.URL.revokeObjectURL(recordedData.blobURL);
            setRecordedData(null);
          },
        },
        {
          label: 'No',
          onClick: () => console.log('hello'),
        },
      ],
    });
  };

  const deleteFinalRecordData = () => {
    setLoading(true);
    let dataToSend = {
      module_id: lesson.module_id,
      assessment_id: lesson.id,
    };
    deleteFinalRecord(userData.api_token, dataToSend).then((res) => {
      Swal.fire('Info', 'Record deleted successfully', 'info');
      setRecordedData(null);
      setOldRecordedFile(null);
      updateAllModuleOnGlobalStore('');
      setLoading(false);
    });
  };

  const checkLessonLength = () => {
    const pos = props.lessonIndex;
    if (allLessonData[pos + 1]) {
      setNextLessonStartMessage(allLessonData[pos + 1].start_message);
    }
    if (allLessonData[pos].showEndMessage) {
      Segment.track('Module Completed', {
        moduleId: props.moduleId,
        name: props.name,
      });
      setEndMessageShow(true);
    }

    if (allLessonData[pos].showEndMessage) setStartMessage(false);
    const result = allLessonData
      .filter((value) => value.lesson_type === 'finalAssignment')
      .map((value) => value.is_completed && value.assignment_file)
      .reduce((previous, current) => previous && current, true);
    if (!result && props.module.intro_module) {
      Segment.track('IntroPostStarted');
    }
    setCanRecord(!result);
  };

  const handleStartMessageNext = () => {
    userCreditResultRef.current
      .then((res) => {
        if (isCoachCreditEnable && !_.get(res, 'user_credit_info.data.can_submit_new_coaching_request', true) && canRecord) {
          Segment.track('CoachCreditError');
          confirmAlert({
            title: 'Coaching limit reached',
            message: _.get(res, 'user_credit_info.data.messages', [
              'You are out of coaching credit, please wait until the renewal date.',
            ]).join('\n'),
            overlayClassName: `cf-one-button ${isMobile && 'mobile'}`,
            buttons: [
              {
                label: 'Ok',
                onClick: () => history.push(`/modules/${props.moduleId}`),
              },
            ],
          });
        } else {
          Segment.track('CoachCreditPass');
          setStartMessage(false);
        }
      })
      .catch(() => {
        setStartMessage(false);
      });
  };

  const handleEndMessageNext = () => {
    if (selectedModule.intro_module && canRecord) {
      const feedbackObj = { lesson_type: 'feedback', module: selectedModule };
      dispatch(setSelectedLesson(feedbackObj));
      history.push(`/modules/${props.moduleId}/feedback`, { coachingCreditRefresh: true });
    } else {
      history.push(`/modules/${props.moduleId}`, { coachingCreditRefresh: true, moduleListRefresh: true });
    }
  };

  const handleSkip = () => {
    history.push(`/modules/${props.moduleId}`, { moduleListRefresh: true });
  };

  return (
    <>
      {isStartMessage ? (
        <StartMessage icon={lesson.icon_url} message={lesson.start_message} next={handleStartMessageNext} />
      ) : isEndMessageShow ? (
        <EndMessage
          message={lesson.end_message}
          postLesson
          score={modulesData[props.lessonIndex].score}
          onSkip={handleSkip}
          next={handleEndMessageNext}
        />
      ) : (
        <div className={`${styles.container} container`}>
          {lesson.totalCount > 0 && <style>{titleStyle}</style>}
          <div className={`${styles.description} ${description.length < 40 && styles.short}`}>{description}</div>
          <div className={styles.content}>
            <div className={styles.image}>
              {/* <img src={lesson.image_first_url} onError={(ev) => {loadDefaultImg(ev);}}/> */}
              <img src={'/images/soundWave.jpg'} />
            </div>
            <div className={styles.recordButton}>
              {(!canRecord && oldRecordedFile) || recordedData ? (
                <AudioPlayer
                  className={'capsule'}
                  id={1}
                  source={oldRecordedFile || recordedData.blobURL}
                  hideBar={true}
                />
              ) : (
                <AudioRecorder onRecordStop={setRecordedData} />
              )}
            </div>
          </div>

          <div className={styles.text}>
            {!canRecord && oldRecordedFile
              ? 'You have already made this recordings.'
              : recordedData
              ? 'Listen to your recordings. If it\'s good to go, click "Next step"'
              : 'Tap to record and read the paragraph'}
          </div>

          <div className={styles.buttonGroup}>
            {(!canRecord && oldRecordedFile) || recordedData ? (
              canRecord ? (
                <>
                  <Button
                    type="secondary"
                    content="Retry"
                    icon="refresh"
                    iconPosition="left"
                    isDisabled={isLoading}
                    onClick={deleteRecord}
                  />
                  <Button
                    type="primary"
                    content="Next Step"
                    icon="rightArrow"
                    iconPosition="right"
                    isLoading={isLoading}
                    onClick={submitRecord}
                  />
                </>
              ) : (
                <>
                  <Button
                    type="primary"
                    content="Next Step"
                    icon="rightArrow"
                    iconPosition="right"
                    onClick={goToNextLesson}
                  />
                </>
              )
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

PostLesson.propTypes = {
  allLessonData: PropTypes.any,
  lessonData: PropTypes.object,
  lessonIndex: PropTypes.number,
  goToReview: PropTypes.func,
  moduleId: PropTypes.any,
  name: PropTypes.string,
  module: PropTypes.object,
  updateUserProgress: PropTypes.func,
};

export default PostLesson;
