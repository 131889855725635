import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { version } from '../../../constants/global';
import { useHistory } from 'react-router-dom';
import { selectUser } from '../../../actions/user/userSlice';
import { successPayment } from '../../../services/paymentServices';
import ErrorBoundary from '../../errorBoundaries/ErrorBoundary';

const PaymentSuccessExternal = (props) => {
  let history = useHistory();
  let staticContent = `<p>Congratulations on becoming a {package_name}! Within 5 minutes, you will receive your login credentials by email (password and login link). If you do not receive your login information, please be sure to check your spam and promotions tabs/folders. If you still do not see your login information, click <a data-cke-saved-href="https://web.chatterfox.com/signin" href="https://web.chatterfox.com/signin">here</a> to "reset the password," which will request that login information be sent again. Thanks for using ChatterFox!<br></p>`;
  let package_name = _.get(history, 'location.state.package_name', '');
  const [content, setContent] = useState([]);
  const userData = useSelector(selectUser);
  console.log(userData);

  useEffect(() => {
    let token = userData.api_token_external;
    successPayment(token).then((res) => {
      setContent(res.data);
    });

    var body = document.body;
    body.classList.add('yellow-back');
  }, []);

  const redirectTo = (path, data = {}, query = '') => {
    if (path && path != '') {
      history.push({
        pathname: path,
        search: query,
        state: data,
      });
    }
  };

  const createMarkup = (htmlText) => {
    if (htmlText) {
      return {
        __html: htmlText,
      };
    } else {
      return {
        __html: '<div></div>',
      };
    }
  };

  const injectPlanName = (htmlText) => {
    if (htmlText) {
      let finalHtmlText = htmlText.replace(`{package_name}`, package_name);
      return finalHtmlText;
    } else {
      return '';
    }
  };

  return (
    <>
      {version}
      <div className="app-content p-0 col-lg-12 external-theme text-center pb-3">
        <h2 className="orange-header m-2 mt-3">
          {' '}
          {content ? content.title : 'Confirmation !'}{' '}
          <img
            style={{ width: 40, position: 'relative', top: -5 }}
            src={process.env.PUBLIC_URL + '/images/birthday.png'}
          />{' '}
        </h2>
        <section className="payment-process p-0 text-center base shadow">
          <div className="col-lg-12 pt-0 pb-3 text-center">
            <div className="row">
              <div className="col-lg-9 pt-0 m-auto">
                {/* <h5 className="p-2 small-head">{"Congrasulations on becoming a " + plan + " member. Your temporary password is sent your email. Watch the video carefully to find the next step."} </h5> */}
                <ErrorBoundary>
                  <h5
                    className="p-2 small-head"
                    dangerouslySetInnerHTML={createMarkup(
                      injectPlanName(content.content ? content.content : staticContent),
                    )}
                  />
                </ErrorBoundary>
                <video
                  width=""
                  poster={content ? content.image : ''}
                  className="mw-100 payment-sucess-video"
                  id="video_frame"
                  src={content ? content.video_url : 'https://vimeo.com/515299081/4f64c91ec7'}
                  controls
                ></video>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PaymentSuccessExternal;
