import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../../../actions/user/userSlice';
import { API_BASEURL, CF_USER } from '../../../constants/global';
import { post } from '../../../util/httpClient';
import { getData } from '../../../util/localStorageHandler.js';
import withAuthorization from '../../../Session/withAuthorization';
import Swal from 'sweetalert2';
import styles from './Profile.module.css';
import Button from '../../components/Button';
import Segment from '../../../segment';

const ChangePass = (props) => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const [authToken, setAuthToken] = useState('');
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [cnfPass, setCnfPass] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    Segment.track('PasswordChanged');
    const data = getData();
    if (data) {
      // setAuthToken(user.api_token);
      setAuthToken(data.api_token);
    }
  }, []);

  const changePass = async (e) => {
    setLoading(true);
    e.preventDefault();
    let dataToSend = {
      new_password: newPass,
      old_password: oldPass,
      new_password_confirmation: cnfPass,
    };

    let path = `${API_BASEURL}/update/password`;
    let res = await post(path, dataToSend, authToken);
    setLoading(false);
    // console.log("change password response === ", res);
    if (res && !res.isError) {
      setOldPass();
      setNewPass();
      setCnfPass();
      Swal.fire('Success!', 'Successfully Updated.', 'success');
      history.push({
        pathname: '/profile',
        state: {},
      });
    } else {
      res && Swal.fire('Error!', res.message, 'error');
    }
  };

  return (
    <div className={styles.changePassContainer}>
      <div className={`${styles.profileContainer} ${styles.edit}`}>
        <form>
          <div className={styles.profileRow}>
            <input
              type="password"
              className="form-control"
              placeholder="Enter your Old Password"
              value={oldPass}
              onChange={(e) => setOldPass(e.target.value)}
            />
          </div>

          <div className={styles.profileRow}>
            <input
              type="password"
              className="form-control"
              placeholder="Enter your New Password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
            />
          </div>

          <div className={styles.profileRow}>
            <input
              type="password"
              className="form-control"
              placeholder="Confirm Password"
              value={cnfPass}
              onChange={(e) => setCnfPass(e.target.value)}
            />
          </div>

          <div className={styles.profileRow}>
            <div className={styles.centerBtn}>
              <Button
                type="primary"
                content="Change Password"
                iconPosition="left"
                size="large"
                isLoading={isLoading}
                onClick={(e) => changePass(e)}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(ChangePass);
