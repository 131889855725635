import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SubscriptionWarnWarnModal = props => {

  let message = props.message?props.message:"";
  let title = props.title?props.title:"";

	return <div className="modal warn-modal" tabIndex="-1" id="subsWarn" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content pb-2 pt-2">
               
                <div className="modal-body">
                  <h4> {title} </h4>
                  <p>{message}</p>
                </div>
                <button type="button" className="btn btn-red btn-orange m-auto" style={{width:200}} data-dismiss="modal">ok</button>
                  
              </div>
            </div>
          </div>
};

// SubmitButtonBig.propTypes = {

// };

export default SubscriptionWarnWarnModal;