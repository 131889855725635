import { API_BASEURL } from '../constants/global';
import { get } from '../util/httpClient';
import { getData } from '../util/localStorageHandler.js';

export const getIdentityVerification = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  const path = `${API_BASEURL}/onesignal/identity-verification`;
  return get(path, token);
};
