import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import ReCAPTCHA from 'react-google-recaptcha';
import { Modal, FloatingLabel, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Button from '../../components/Button';
import { isUserLoggedIn, loginWithCaptcha } from '../../../services/authServices';
import { getUserDetails } from '../../../services/paymentServices';
import { facebookSignInFromApp, googleSignInFromApp } from '../../../services/socialLoginServices';
import { CF_USER, RE_CAPTCHA_WEB_KEY } from '../../../constants/global';
import { setUser } from '../../../actions/user/userSlice';
import Segment from '../../../segment';
import styles from './Login.module.css';
import LoginTemplate from '../../components/Templates/LoginTemplate';
import { getMessagingToken } from '../../../../firebase';

//facebook url issue solved by url https://github.com/keppelen/react-facebook-login/issues/272

const Login = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let [modalMail, setModalMail] = useState('');
  let [show, setShow] = useState(false);
  let [loginStatus, setLoginStatus] = useState('');
  let [payload, setPayload] = useState({});
  let [passwordType, setPasswordType] = useState('password');
  let [isLoading, setLoading] = useState(false);
  let [show_captcha, setShowCaptcha] = useState(false);
  let [reCaptchaToken, setCaptchaToken] = useState('');
  const redirectUrl = _.get(props, 'location.state.redirectUrl', 'modules');
  const redirectUrlQueryParam = _.get(props, 'location.state.redirectUrlQueryParam', '');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Enter your email id.').email(),
    password: Yup.string().required('Enter your password.'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      if (values.email && values.password) {
        login(values.email, values.password);
      }
    },
  });

  useEffect(() => {
    isUserLoggedIn(history);
  }, []);

  const goToPage = (path, data = {}, query = '') => {
    history.push({
      pathname: path,
      search: query,
      state: data,
    });
  };

  const login = async (email, password) => {
    setLoading(true);
    try {
      // Login user
      const userData = await loginWithCaptcha(email, password, reCaptchaToken);
      //This services used for geting user last name and firstname
      getUserDetails(userData.api_token).then((res) => {
        userData.user.first_name = _.get(res, 'data.first_name', '');
        userData.user.last_name = _.get(res, 'data.last_name', '');

        dispatch(setUser(userData));
        // Analytics: identify
        const aUser = {
          email: _.get(userData, 'user.email', ''),
          name: _.get(userData, 'user.name', ''),
          country: _.get(userData, 'user.country', ''),
          gender: _.get(userData, 'user.gender', ''),
          age: _.get(userData, 'user.birthday', ''),
          hasSubscription: _.has(userData, 'active_subscription'),
        };
        Segment.identify(aUser.email, aUser);
        Segment.track('WebLogin', {}, userData.api_token);
        getMessagingToken();

        setLoading(false);
        goToPage('/' + redirectUrl, {}, redirectUrlQueryParam);

        //creating a custom event for loading again some jQuery menu class
        var event = new Event('login', { bubbles: true, cancelable: false });
        document.body.dispatchEvent(event); // event will buble up to window
      });
    } catch (error) {
      if (error.show_captcha) {
        setShowCaptcha(true);
      }
      Swal.fire('Error!', error.message ? error.message : 'Some error occurred. Please try again.', 'error');
      setLoading(false);
      Segment.track('Login Failed', { email, error });
    }
  };

  const responseGoogle = async (response) => {
    if (!response || response.error) {
      if (response.error == 'popup_closed_by_user') {
        Swal.fire('Info!', 'user has closed the login popup', 'info');
      } else if (response.error == 'idpiframe_initialization_failed') {
        Swal.fire('Info!', 'please enable your third party cookies from browser settings for social login', 'info');
      } else {
        Swal.fire('Info!', response.details ? response.details : response.error, 'info');
      }
    } else {
      let socialId = response.googleId;
      let email = response.profileObj && response.profileObj.email ? response.profileObj.email : '';
      let accessToken = response.accessToken;
      //let name = response.xt?response.xt.Ad?response.xt.Ad:"":response.Nt?response.Nt.EW:"";

      let name = response.profileObj ? response.profileObj.givenName : '';

      let dataToSend = {
        token: accessToken,
        role: CF_USER,
        email: email,
        username: name,
      };

      console.log('data after google login...', dataToSend);

      if (email && email != '') {
        googleSignIn(dataToSend);
      } else {
        setLoginStatus('gmail');
        setShow(true);
        setPayload(dataToSend);
      }
    }
  };

  const googleSignIn = async (dataToSend) => {
    setLoading(true);

    googleSignInFromApp(dataToSend).then((response) => {
      if (response && !response.isError) {
        dispatch(setUser(response.data));
        // Analytics: identify
        let aUser = {
          email: _.get(response, 'data.user.email', ''),
          name: _.get(response, 'data.user.name', ''),
          country: _.get(response, 'data.user.country', ''),
          gender: _.get(response, 'data.user.gender', ''),
          age: _.get(response, 'data.user.birthday', ''),
          hasSubscription: _.has(response, 'data.active_subscription'),
        };
        Segment.identify(aUser.email, aUser);
        Segment.track('Login', {}, response.api_token);
        getMessagingToken();

        Swal.fire('Success', response.message && response.message, 'success');
        setLoading(false);
        goToPage('/' + redirectUrl, {}, redirectUrlQueryParam);

        //creating a custom event for loading again some jQuery menu class
        var event = new Event('login', { bubbles: true, cancelable: false });
        document.body.dispatchEvent(event); // event will buble up to window
      } else {
        Segment.track('Login Failed', { error: 'Sign in with Google failed' });
        Swal.fire('Error!', 'Some error occurred. Please try again.', 'error');
        setLoading(false);
      }
      setLoginStatus('');
      setModalMail('');
      setPayload({});
    });
  };

  const responseFacebook = async (response) => {
    if (!response || response.error) {
      Swal.fire('Error!', response.error, 'error');
    } else if (response.status && response.status === 'unknown') {
      console.log('User closed popup');
    } else {
      console.log('Response from facebook sign in ==', response);
      let token = response.accessToken;
      let email = response.email ? response.email : '';
      let name = response.name ? response.name : '';

      let dataToSend = {
        token: token,
        role: CF_USER,
        email: email,
        username: name,
      };
      if (email && email != '') {
        facebookSignIn(dataToSend);
      } else {
        setLoginStatus('fb');
        setPayload(dataToSend);
        setShow(true);
      }
    }
  };

  const facebookSignIn = async (dataToSend) => {
    setLoading(true);

    facebookSignInFromApp(dataToSend).then((response) => {
      if (response && !response.isError) {
        dispatch(setUser(response.data));
        // Analytics: identify
        let aUser = {
          email: _.get(response, 'data.user.email', ''),
          name: _.get(response, 'data.user.name', ''),
          country: _.get(response, 'data.user.country', ''),
          gender: _.get(response, 'data.user.gender', ''),
          age: _.get(response, 'data.user.birthday', ''),
          hasSubscription: _.has(response, 'data.active_subscription'),
        };
        Segment.identify(aUser.email, aUser);
        Segment.track('Login', {}, response.api_token);
        getMessagingToken();

        Swal.fire('Success', response.message && response.message, 'success');
        setLoading(false);

        goToPage('/' + redirectUrl, {}, redirectUrlQueryParam);

        //creating a custom event for loading again some jQuery menu class
        var event = new Event('login', { bubbles: true, cancelable: false });
        document.body.dispatchEvent(event); // event will buble up to window
      } else {
        Segment.track('Login Failed', { error: 'Sign in with Facebook failed' });
        Swal.fire('Error!', 'Some error occurred. Please try again.', 'error');
        setLoading(false);
      }
      setLoginStatus('');
      setModalMail('');
      setPayload({});
    });
  };

  const gotoForgotPassword = async () => {
    goToPage('/forgot-password');
  };

  const toggleShowPassword = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSubmit = () => {
    if (loginStatus == 'gmail') {
      payload.email = modalMail;
      googleSignIn(payload);
    } else if (loginStatus == 'fb') {
      payload.email = modalMail;
      facebookSignIn(payload);
    } else {
      console.log('No login status ia available -- ');
    }
    setShow(false);
  };

  const onChangeReCAPTCHA = (value) => {
    console.log('onChangeReCAPTCHA', value);
    setCaptchaToken(value);
  };

  return (
    <LoginTemplate>
      <h1>
        <span className="cf-t-highlight">Log</span> in
      </h1>
      <div className="cf-t-redLine"></div>
      <div className={`${styles.form} cf-t-form`}>
        <GoogleLogin
          className="googleLogin"
          buttonText="Login with Google"
          clientId="1076767246869-5c8ecj7o5nsdj434km1l2ct3qo9bpqg5.apps.googleusercontent.com"
          onSuccess={responseGoogle}
          onFailure={(error) => console.log(error)}
          cookiePolicy={'single_host_origin'}
        />
        <div className="cf-t-horizontalLine">
          <span>Or</span>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <FloatingLabel controlId="floatingEmail" label="Email">
            <Form.Control
              type="email"
              isInvalid={formik.touched.email && !!formik.errors.email}
              disabled={isLoading}
              required
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
              type={passwordType}
              isInvalid={formik.touched.password && !!formik.errors.password}
              disabled={isLoading}
              required
              placeholder="Password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <FontAwesomeIcon
              className="eye-icon"
              size="lg"
              icon={passwordType === 'text' ? faEye : faEyeSlash}
              onClick={toggleShowPassword}
            />
            <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
          </FloatingLabel>
          {show_captcha && <ReCAPTCHA sitekey={RE_CAPTCHA_WEB_KEY} onChange={onChangeReCAPTCHA} />}
          <div className={styles.alternatives}>
            <Form.Check type="checkbox" label="Remember me" />
            <span className={styles.link} onClick={gotoForgotPassword}>
              Forgot Password?
            </span>
          </div>
          <Button
            className="cf-t-button"
            content="Next"
            submit={true}
            isLoading={isLoading}
            onClick={formik.handleSubmit}
          />
        </form>
        <div className={styles.register}>
          Not member yet?
          <a href="/signup">Create an account</a>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Give your Email Id</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="email"
            className="form-control"
            placeholder="email id"
            value={modalMail}
            onChange={(e) => setModalMail(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </LoginTemplate>
  );
};

Login.propTypes = {};

export default Login;
