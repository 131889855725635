import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import styles from './StartMessage.module.css';

const StartMessage = (props) => {
  const message = props.message;
  const css = '.cf-main-header { display: none; }';

  const loadDefaultImg = (event) => {
    event.target.src = process.env.PUBLIC_URL + '/images/logo.png';
  };

  return (
    <div className={styles.container}>
      <style>{css}</style>
      <div className={styles.content}>
        <div className={styles.figure}>
          <div className={styles.line}>
            <div className={styles.imgBack}>
              <img onError={loadDefaultImg} src={props.icon} />
            </div>
          </div>
        </div>
        <div className={styles.message}>
          <span dangerouslySetInnerHTML={{ __html: (message || '').replace('\r\n', '<br/>').replace('\n', '<br/>') }} />
        </div>
        <Button type="primary" content="Start" icon="rightArrow" iconPosition="right" onClick={props.next} />
      </div>
      <div className={`${styles.backgroundPattern} wave-bg-gray`} />
      <div className={`${styles.backgroundPattern} ${styles.header} wave-bg-gray`} />
    </div>
  );
};

StartMessage.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  next: PropTypes.func,
};

export default StartMessage;
