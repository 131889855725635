import { API_BASEURL } from '../constants/global';
import { post, get } from '../util/httpClient';

export const getActivePlans = async (token) => {
  let path = `${API_BASEURL}/plans-v2`;
  return get(path, token);
};

export const getpaymentsDetailsforPlan = async (token, id) => {
  let path = `${API_BASEURL}/web-plan`;
  let data = { plan_id: id };
  return post(path, data, token);
};

export async function applyCouponCode(coupon, authToken) {
  return new Promise(async (resolve, reject) => {
    let url = `${API_BASEURL}/apply-coupon`;
    let dataToSend = { coupon };
    try {
      // Apply Coupon if any
      let response = await post(url, dataToSend, authToken);
      console.log(response);
      if (response && !response.isError) {
        resolve({ message: response.message ? response.message : 'Coupon has been applied.' });
      } else {
        reject({
          message: response && response.message ? response.message : 'Some error occurred. Please try again.',
        });
      }
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.' });
    }
  });
}

export const addOrder = async (token, id, method) => {
  let path = `${API_BASEURL}/add-order`;
  let data = { plan_id: parseInt(id), payment_method: method };
  return post(path, data, token);
};

export const stripeSubscripe = async (token, id, nonce) => {
  let path = `${API_BASEURL}/subscribe`;
  let data = { order_id: parseInt(id), payment_method_nonce: nonce };
  return post(path, data, token);
};

export const isUserExist = async (userName, mail) => {
  let path = `${API_BASEURL}/external-register`;
  //let data = {"first_name":first_name,"last_name":last_name,"email":mail,name:userName, "role_id" : 2};
  let data = { name: userName, email: mail, role_id: 2 };
  return post(path, data);
};

export const updatePaymentProfile = async (firstname, lastname, token) => {
  let path = `${API_BASEURL}/update-payment-profile`;
  let data = { first_name: firstname, last_name: lastname };
  return post(path, data, token);
};

export const isPaymentActive = async (planId) => {
  let path = `${API_BASEURL}/plan-check`;
  let data = { plan_id: planId };
  return post(path, data);
};

export const getUserDetails = async (token) => {
  let path = `${API_BASEURL}/get-payment-profile`;
  return get(path, token);
};

export const unsubscribePlan = async (token) => {
  let path = `${API_BASEURL}/unsubscribe`;
  return get(path, token);
};

export const getActiveSubscription = async (token) => {
  let path = `${API_BASEURL}/active-subscription`;
  return get(path, token);
};

export const getOthersSubscription = async (token) => {
  let path = `${API_BASEURL}/active-subscriptions`;
  return get(path, token);
};

export const successPayment = async (token) => {
  let path = `${API_BASEURL}/page/webpayment-confirmation-page`;
  return get(path, token);
};

export const resumePlan = async (token) => {
  let path = `${API_BASEURL}/resume-plan`;
  return get(path, token);
};
