import { API_BASEURL } from '../constants/global';
import { get, post } from '../util/httpClient';
import { getData } from '../util/localStorageHandler';
import _ from 'lodash';

export const updateUserProfile = async (dataToSend) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  const path = `${API_BASEURL}/update-profile`;
  return post(path, dataToSend, token);
};

export const acceptTermsAndConditions = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  const path = `${API_BASEURL}/update/terms`;
  return post(path, { terms_checked: 1 }, token);
};
