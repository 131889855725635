import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { getData, getSessionData } from '../util/localStorageHandler';

const withAuthorization = (condition) => (Component) => {
    class WithAuthorization extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                isLogedIn: false,
                isExternal: false,
            };
        }

        componentDidMount() {
            // let authUser = getData();
            //  console.log("AUTH USER>>>",authUser);
            // if (authUser && authUser.api_token) {
            //   // if (!condition(authUser)) {
            //   //   this.setState({isLogedIn:true,isExternal:false});
            //   // } else {
            //   //   this.setState({isLogedIn:true,isExternal:false});
            //   // }
            //   this.setState({isLogedIn:true,isExternal:false});
            // } else if(authUser && authUser.api_token_external){
            //   //  if (!condition(authUser)) {
            //   //   this.setState({isLogedIn:false,isExternal:true});
            //   // } else {
            //   //   this.setState({isLogedIn:false,isExternal:false});
            //   // }
            //   this.setState({isLogedIn:false,isExternal:true});
            // }
        }

        render() {
            let authUser = getData();
            let authSessionUser = getSessionData();

            let isLogedIn = false;
            let isExternal = false;

            if (authUser && authUser.api_token) {
                isLogedIn = true;
                isExternal = false;
            } else if (authSessionUser && authSessionUser.api_token_external) {
                isLogedIn = false;
                isExternal = true;
                authUser = authSessionUser;
            }

            return (
                <AuthUserContext.Consumer>
                    {(authUser) =>
                        condition(authUser) ? (
                            <Component {...this.props} isLogedIn={isLogedIn} isExternal={isExternal} />
                        ) : (
                            <Component {...this.props} isLogedIn={isLogedIn} isExternal={isExternal} />
                        )
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return compose(withRouter)(WithAuthorization);
};

export default withAuthorization;
