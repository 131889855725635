import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { CF_USER } from '../../../../constants/global';
import { getData } from '../../../../util/localStorageHandler.js';
import withAuthorization from '../../../../Session/withAuthorization';

import { getActivityDetailsServices } from '../../../../services/activityServices';

const UserActivity = (props) => {
  const history = useHistory();

  const [authToken, setAuthToken] = useState('');
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    let data = getData();
    if (data) {
      getActivityDetails(data.api_token);
      setAuthToken(data.api_token);
      // setAuthToken(user.api_token);
    }
  }, []);

  const getActivityDetails = async (authToken) => {
    getActivityDetailsServices(authToken).then((res) => {
      if (res && !res.isError && res.user_activity) {
        res.user_activity && res.user_activity.data && setActivities(res.user_activity.data);

        res.user_activity.data.map((data, index) => {
          var doc = new DOMParser().parseFromString(data.description, 'text/xml');
          console.log('data', doc);
        });

        console.log('user data ===', res.user_activity.data);
      }
    });
  };

  return (
    <div className="content-wrapper">
      <section className="unlock-membership for-purchase-history">
        <div className="container">
          <div className="row">
            {activities && activities.length > 0 ? (
              activities.map((data, index) => {
                return (
                  <div className="col-lg-6" key={index}>
                    <div className="settings">
                      <ul>
                        <li>
                          <figure>
                            <img src="images/ic13.png" />
                          </figure>
                        </li>

                        <li>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: 'Description: ' + data.description,
                            }}
                          />
                          <span>
                            <b>{data.action && data.action}</b>
                          </span>
                          <span>Credited to: {data.created_at && moment(data.created_at).format('llll')}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })
            ) : (
              <span>No Activities found...</span>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(UserActivity);
