import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';

import { selectLessonStore } from '../../../../actions/lesson/lessonStore';
import { selectLessonResultStore, setLessonResultStore } from '../../../../actions/lesson/lessonResultStore';
import AudioPlayerListenOption from '../../../components/AudioPlayerListenOption';
import AudioPlayer from '../../../components/CustomAudioPlayer';
import StartMessage from '../message/StartMessage';
import ChooseMessage from '../message/chooseMessage';
import EndMessage from '../message/EndMessage';
import styles from './ListenAndChoose.module.css';
import Button from '../../../components/Button';
import Segment from '../../../../segment';

const ListenAndChoose = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lesson = props.lessonData;
  const nextFire = props.nextFire;
  const staticEndMessage =
    'Great!\nIn the next section, you will practice your pronunciation with our AI instant feedback system.';
  let answeringCorrectSoundPlayer;
  let answeringWrongSoundPlayer;
  let isEndMessage = props.lessonData.end_message ? true : false;
  const [isStartMessage, setStartMessage] = useState(!nextFire ? (lesson.start_message ? true : false) : false);
  const [isEndMessageShow, setEndMessageShow] = useState(false);
  const [isCorrect, setIsCorrect] = useState(-1);
  const [isAnswerOptionHit, setIsAnswerOptionHit] = useState(false);
  let allLessonData = useSelector(selectLessonStore);
  let result = useSelector(selectLessonResultStore);

  useEffect(() => {
    if (props.module.intro_module && lesson.index === 1) Segment.track('IntroLCStarted');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsCorrect(-1);
    setEndMessageShow(false);
    setIsAnswerOptionHit(false);
  }, [lesson]);

  const goToNextLesson = () => {
    if (!isEndMessage) {
      props.next(lesson);
    } else {
      if (props.module.intro_module) Segment.track('IntroLCEnded');
      setEndMessageShow(true);
    }
  };

  const SwitchLesson = (lessonToSwitch) => {
    props.switchLesson(lessonToSwitch);
  };

  const tryAgain = (lessonToSwitch) => {
    let entry = {};
    entry.listen_option_result = 0;
    dispatch(setLessonResultStore(entry));
    props.switchLesson(lessonToSwitch);
  };

  const answerHandler = (correct) => {
    setIsCorrect(correct);
    setIsAnswerOptionHit(true);
    // correct && countRightAnswer();
    correct ? answeringCorrectSoundPlayer.audioEl.current.play() : answeringWrongSoundPlayer.audioEl.current.play();
  };

  const countRightAnswer = () => {
    let entry = {};
    entry.listen_option_result = result.listen_option_result ? parseInt(result.listen_option_result) + 1 : 1;
    dispatch(setLessonResultStore(entry));
  };

  return (
    <>
      {/* {isStartMessage ? (
        <StartMessage message={lesson.start_message} next={() => setStartMessage(false)} />
      ) :  */}
      {isEndMessageShow ? (
        <EndMessage
          message={staticEndMessage}
          nextLesson="AI Feedback"
          lessonData={lesson}
          total={lesson.totalllength}
          result={allLessonData.listen_option_result}
          next={props.next}
          backToHome={props.backToHome}
        />
      ) : (
        <div
          className={`container ${styles.container} ${styles.horizontalMode} ${isAnswerOptionHit && styles.optionHit}`}
        >
          <div className={`${styles.cardsHolder}`}>
            <div className="progressBar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
                data-toggle="modal"
                data-target="#skipModal"
              >
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
              </svg>
              <ProgressBar variant="gray" now={(lesson.index / lesson.totalllength) * 100} />
            </div>
            <div className={styles.body}>
              <AudioPlayer
                className={`${styles.audioPlayer} large`}
                id={1}
                source={lesson.audio_url}
                hideBar={true}
                playIcon={'/images/audio/play.svg'}
                pauseIcon={'/images/audio/pause.svg'}
              />

              <span className={styles.audioSubtitle}>Play and select what you listen</span>

              <div className={styles.answerBtnHolder}>
                {lesson.wordListing.listen_select_options
                  ? lesson.wordListing.listen_select_options.map((elm, ind) => {
                      return (
                        <button
                          className={`${styles.answerBtn} ${
                            elm.correct ? isCorrect == 1 && styles.correctAnswer : !isCorrect && styles.wrongAnswer
                          }`}
                          data-valid="true"
                          disabled={isCorrect != -1}
                          onClick={() => answerHandler(elm.correct)}
                          key={ind}
                        >
                          {elm.title}
                        </button>
                      );
                    })
                  : null}
              </div>

              {isAnswerOptionHit ? (
                <div className={`${styles.answerBox} ${isCorrect ? styles.correct : styles.wrong}`}>
                  <p className="">{isCorrect ? 'Correct!' : 'Incorrect!'}</p>
                  <Button
                    type={isCorrect ? `primary` : `secondary`}
                    content={lesson.index === lesson.totalllength ? 'Continue' : 'Next One'}
                    icon="rightArrow"
                    iconPosition="right"
                    onClick={goToNextLesson}
                  />
                </div>
              ) : null}
            </div>
          </div>

          {/*this audio player is for play wrong and right sound after select answer*/}
          <ReactAudioPlayer
            src={process.env.PUBLIC_URL + '/sounds/LandC_Correct.mp3'}
            ref={(element) => {
              answeringCorrectSoundPlayer = element;
            }}
            autoPlay={false}
            controls={false}
          />
          {/*this audio player is for play wrong and right sound after select wrong answer*/}
          <ReactAudioPlayer
            src={process.env.PUBLIC_URL + '/sounds/LandC_Wrong.mp3'}
            ref={(element) => {
              answeringWrongSoundPlayer = element;
            }}
            autoPlay={false}
            controls={false}
          />
        </div>
      )}
    </>
  );
};

ListenAndChoose.propTypes = {
  next: PropTypes.func,
  nextFire: PropTypes.bool,
  lessonData: PropTypes.object,
  moduleId: PropTypes.string,
  module: PropTypes.object,
  backToHome: PropTypes.func,
};

export default ListenAndChoose;
