import { API_BASEURL } from '../constants/global';
import { post, get } from '../util/httpClient';
import { getData } from '../util/localStorageHandler.js';

export const getUserNotifications = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  const path = `${API_BASEURL}/get-user-notifications`;
  return get(path, token);
};

export const getUnreadNotificationCount = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  const path = `${API_BASEURL}/get-unread-notification-count`;
  return get(path, token);
};

export const readNotification = async (notificationId) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/read-notification`;
  let data = { notification_id: notificationId };
  return post(path, data, token);
};

export const sendWebPushNotificationToken = async (messagingToken) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/store-webnotification-token`;
  let data = { token: messagingToken };
  return post(path, data, token);
};
