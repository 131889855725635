import React, { useEffect } from 'react';
import { STRIPE_KEY } from '../../../constants/global';
import withAuthorization from '../../../Session/withAuthorizationForPayment';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isPaymentActive } from '../../../services/paymentServices';
import queryString from 'query-string';
import Checkout from './Checkout';

const CheckoutWrapper = (props) => {
  const stripePromise = loadStripe(STRIPE_KEY);

  useEffect(() => {
    const values = queryString.parse(props.location.search);

    if (values.pid) {
      isPaymentActive(values.pid).then((res) => {
        if (res.is_plan_exists == 0) {
          window.location.href = 'https://chatterfox.com/american-smart-fluency-program-payment';
          return false;
        }
      });
    } else {
      window.location.href = 'https://chatterfox.com/american-smart-fluency-program-payment';
      return false;
    }
  }, [props]);

  return (
    <Elements stripe={stripePromise}>
      <Checkout />
    </Elements>
  );
};

const condition = (authUser) => authUser != null;
export default withAuthorization(condition)(CheckoutWrapper);
