import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import StartMessage from '../message/StartMessage';
import EndMessage from '../message/EndMessage';
import styles from './VideoLesson.module.css';
import Button from '../../../components/Button';
import Segment from '../../../../segment';

const VideoLesson = (props) => {
  const history = useHistory();
  const nextFire = props.nextFire;
  const lesson = props.lessonData;
  const allLessonData = props.allLessonData;
  let isEndMessage = props.lessonData.end_message ? true : false;
  const [isStartMessage, setStartMessage] = useState(!nextFire ? (lesson.start_message ? true : false) : false);
  const [isEndMessageShow, setEndMessageShow] = useState(false);
  const [nextlessonStartMessage, setNextLessonStartMessage] = useState('');
  const videoRef = useRef();
  const passed25 = useRef(false);
  const passed50 = useRef(false);
  const passed75 = useRef(false);
  const passed100 = useRef(false);

  useEffect(() => {
    if (props.module.intro_module) Segment.track('IntroVideoStarted');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentLessonCalculation();
  }, [lesson]);

  const goToNextLesson = () => {
    if (!isEndMessage) {
      props.next(lesson);
    } else {
      setEndMessageShow(true);
    }
  };

  const currentLessonCalculation = () => {
    const pos = allLessonData.map((e) => e.unique_id).indexOf(lesson.unique_id);
    if (allLessonData[pos + 1]) {
      setNextLessonStartMessage(allLessonData[pos + 1].start_message);
    }
  };

  const handleVideoStarted = () => {
    if (props.module.intro_module) Segment.track('IntroVideoPlay');
  };

  const handleVideoTimeUpdate = () => {
    const percentageCompleted = (videoRef.current.currentTime / videoRef.current.duration) * 100;
    if (props.module.intro_module && percentageCompleted >= 25 && !passed25.current) {
      passed25.current = true;
      Segment.track('IntroVideo25');
    } else if (props.module.intro_module && percentageCompleted >= 50 && !passed50.current) {
      passed50.current = true;
      Segment.track('IntroVideo50');
    } else if (props.module.intro_module && percentageCompleted >= 75 && !passed75.current) {
      passed75.current = true;
      Segment.track('IntroVideo75');
    } else if (props.module.intro_module && percentageCompleted === 100 && !passed100.current) {
      passed100.current = true;
      Segment.track('IntroVideoAll');
    }
  };

  const handleVideoEnded = () => {
    if (props.module.intro_module) Segment.track('IntroVideoEnded');
  };

  return (
    <>
      {/* {isStartMessage ? (
        <StartMessage message={lesson.start_message} next={() => setStartMessage(false)} />
      ) : */}
      {isEndMessageShow ? (
        <EndMessage
          message={lesson.end_message}
          nextLesson="Listen and Repeat"
          next={props.next}
          backToHome={props.backToHome}
        />
      ) : (
        <div className={`container ${styles.container}`}>
          {/* <h2 className="top-sub-header mb-2 mt-2">
              <img src={process.env.PUBLIC_URL + '/images/vid.png'} />
              {lesson.title ? lesson.title : 'title'}
            </h2> */}

          <div className={styles.video}>
            <video
              ref={videoRef}
              id="video_frame"
              src={lesson.file_url ? lesson.file_url : ''}
              controls
              onPlay={handleVideoStarted}
              onTimeUpdate={handleVideoTimeUpdate}
              onEnded={handleVideoEnded}
            ></video>
          </div>
          <span className={styles.description}>{lesson.description ? lesson.description : 'description'}</span>
          <Button
            type="secondary"
            content="Next Video"
            icon="rightArrow"
            iconPosition="right"
            size="large"
            onClick={() => goToNextLesson()}
          />
        </div>
      )}
    </>
  );
};

VideoLesson.propTypes = {
  moduleId: PropTypes.string,
  nextFire: PropTypes.any,
  lessonData: PropTypes.any,
  allLessonData: PropTypes.any,
  next: PropTypes.func,
  module: PropTypes.object,
  backToHome: PropTypes.func,
};

export default VideoLesson;
