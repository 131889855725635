import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { confirmAlert } from 'react-confirm-alert';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Segment from '../../../segment';
import { selectInitialAssessment } from '../../../actions/initialAssessment/initialAssessmentStore';

import Questions from './Questions';
import Intro from './Intro';
import Assessment from './Assessment';
import AIResult from './AIResult';
import LoadingRobot from './LoadingRobot';
import styles from './index.module.css';
import {
  getInitialAssessment,
  uploadAIServer,
  generateAnonymousId,
  getInitialAssessmentV2,
  uploadAIServerV2,
} from '../../../services/initialAssessmentServices';
import { getData } from '../../../util/localStorageHandler';
import Header from '../../layouts/Header';
import { selectUser } from '../../../actions/user/userSlice';

const InitialAssessment = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const initialAssessment = useSelector(selectInitialAssessment);
  const enablePrompt = useRef(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const items = useRef([]);
  const aiResult = useRef();
  const init = useRef(true);
  const authUserRef = useRef();
  const css = `#feedbacklabel { display: none !important; } .cf-main-header nav { left: calc(50% - 408px); }`;
  const anonymousUserIdRef = useRef();
  const questionsRef = useRef();

  useEffect(() => {
    authUserRef.current = getData();
    if (!authUserRef.current) {
      setLoading(true);
      generateAnonymousId().then((res) => {
        if (res && !res.isError) {
          localStorage.setItem('anonymousUserId', res.gen_uuid);
          anonymousUserIdRef.current = res.gen_uuid;
          getInitialAssessmentDataV2(res.gen_uuid);
        }
      });
    } else getInitialAssessmentData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authUserRef.current && _.get(initialAssessment, 'status.is_user_completed')) {
      const notification = user.notifications.filter(
        (notification) => notification.notification_type === 'initial-assessment-ai-feedback',
      )[0];
      history.push(`/notifications/${notification.id}`, { prevPath: '/modules' });
    } else {
      if (_.get(props.location, 'state.banner', false)) Segment.track('AssBannerClick');
      enablePrompt.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAssessment]);

  useEffect(() => {
    if (init.current) {
      init.current = false;
      return;
    }

    if (waiting && !loading) {
      setStep(step + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, waiting]);

  const getInitialAssessmentData = async () => {
    setLoading(true);
    getInitialAssessment().then((res) => {
      if (res && !res.isError && !res.errorCode) {
        items.current = res.initial_assessments;
      }
      setLoading(false);
    });
  };

  const getInitialAssessmentDataV2 = async (anonymousUserId) => {
    setLoading(true);
    getInitialAssessmentV2(anonymousUserId).then((res) => {
      if (res && !res.isError && !res.errorCode) {
        items.current = res.initial_assessments;
      }
      setLoading(false);
    });
  };

  const gotoAssessments = () => {
    Segment.track('InitialAssessmentStart');
    if (!loading) {
      // If failed to get assessments
      if (!items.current) {
        confirmAlert({
          title: '',
          message: 'Something went wrong, Please try again later',
          overlayClassName: `${isMobile && 'mobile'}`,
          buttons: [
            {
              label: 'Ok',
              onClick: () => {
                enablePrompt.current = false;
                if (authUserRef.current) history.push('/modules');
                else history.push('/initialAssessment');
              },
            },
          ],
        });
      } else setStep(step + 1);
    } else setWaiting(true);
  };

  const handleAssessmentFinished = (data) => {
    if (questionsRef.current) {
      data.questions = questionsRef.current;
    }
    if (authUserRef.current) {
      Segment.track('AssFinished');
      setStep(3);
      uploadAIServer(data).then((res) => {
        if (res && !res.isError && !res.errorCode) {
          Segment.track('InitialAssessmentAI');
          aiResult.current = res.ai_response.data;
          enablePrompt.current = false;
          setStep(4);
        } else {
          confirmAlert({
            title: '',
            message: 'Something went wrong, Please try again later',
            overlayClassName: `${isMobile && 'mobile'}`,
            buttons: [
              {
                label: 'Ok',
                onClick: () => {
                  enablePrompt.current = false;
                  history.push('/modules');
                },
              },
            ],
          });
        }
      });
    } else {
      Segment.track('AssOutFinished');
      enablePrompt.current = false;
      data.anonymousUserId = anonymousUserIdRef.current;
      uploadAIServerV2(data).then((res) => {
        if (res && !res.isError && !res.errorCode) {
          Segment.track('InitialAssessmentAI');
        }
      });
      setTimeout(() => setStep(5), 5000);
    }
  };

  const handleSkip = () => {
    Segment.track('InitialAssessmentSkip');
    enablePrompt.current = false;
    history.push('/modules');
  };

  const continueToCourse = () => {
    enablePrompt.current = false;
    history.push('/modules', { prevPath: 'initialAssessment' });
  };

  const handleQuestionsNextStep = (questions) => {
    questionsRef.current = questions;
    gotoAssessments();
  };

  const RenderStepContent = ({ step }) => {
    switch (step) {
      case 0:
        return (
          <Intro
            isAuthUser={!!authUserRef.current}
            onStart={() => setStep(step + 1)}
            onSkip={handleSkip}
            loading={waiting}
          />
        );
      case 1:
        return <Questions nextStep={handleQuestionsNextStep} />;
      case 2:
        return <Assessment items={items.current} onSkip={handleSkip} onFinished={handleAssessmentFinished} />;
      case 3:
        return <LoadingRobot />;
      case 4:
        return <AIResult result={aiResult.current} onSkip={handleSkip} onContinue={continueToCourse} />;
      case 5:
        return (
          <Intro
            isAuthUser={!!authUserRef.current}
            end={true}
            onStart={() => history.push('/signup')}
            onSkip={handleSkip}
          />
        );
      default:
        break;
    }
  };

  return (
    <div
      className={`${styles.mainContainer} ${isMobile && 'mobile'} ${
        (!authUserRef.current || step === 0) && styles.noHeader
      }`}
    >
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta
          name="viewport"
          content="viewport-fit=cover, user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <style>{css}</style>
      {authUserRef.current && step !== 0 && (
        <Header
          className="cf-main-header"
          title="Fluency Assessment"
          backUrl={location.pathname !== '/modules' ? _.get(location, 'state.prevPath', '/modules') : undefined}
          hideMenu={isMobile}
          hideLogo={isMobile}
          titleCenter={isMobile}
        />
      )}
      <div className={styles.contentContainerHolder}>
        <Prompt message={() => (enablePrompt.current ? `Are you sure you want to quit?` : true)} />
        <RenderStepContent step={step} />
      </div>
      {/* <div className={styles.footer}>
        <span>Chatter Fox Corp. ©2021 All rights Reserved.</span>
        <a>About ChatterFox</a>
        <a>Terms of Use</a>
      </div> */}
    </div>
  );
};

InitialAssessment.propTypes = {
  location: PropTypes.object,
};

export default InitialAssessment;
