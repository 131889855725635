import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { Dropdown } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { CF_USER } from '../../constants/global';
import { selectUser } from '../../actions/user/userSlice';
import { logOut } from '../../services/authServices';
import withAuthorization from '../../Session/withAuthorization';
import { NotificationIcon } from '../components/Notification';
import styles from './Header.module.css';

// eslint-disable-next-line react/display-name, react/prop-types
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const Header = (props) => {
  const history = useHistory();
  const userData = useSelector(selectUser);
  const backUrl = props.backUrl ? props.backUrl : '/modules';
  const [showModal, setShowModal] = useState(false);

  const redirectTo = (path, data = {}) => {
    if (path && path !== '') {
      history.push({
        pathname: path,
        state: data,
      });
    }
  };

  const [show, setShow] = useState();
  useEffect(() => {
    show & showModal & setShow(false);
  }, [showModal]);

  const CreditsDropDown = (props) => {
    const handleToggle = (isOpen, event, metadata) => {
      setShow(isOpen);
    };

    return (
      <Dropdown align="end" className={`cf-notification-icon`} onToggle={handleToggle} show={show}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <span className={styles.creditsCount}>{userData.remainingCoachingCredit}</span>
          <img src={process.env.PUBLIC_URL + '/images/menu/icon-human-coach-deactive.svg'} />
        </Dropdown.Toggle>

        <Dropdown.Menu
          popperConfig={
            isMobile
              ? {
                  strategy: 'fixed',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -42],
                      },
                    },
                  ],
                }
              : {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [15, 0],
                      },
                    },
                  ],
                }
          }
        >
          <Dropdown.Header>
            Feedback Credits
            <img
              className="cf-notification-close"
              src={process.env.PUBLIC_URL + '/images/icon/icon-exit.svg'}
              onClick={() => handleToggle(false)}
            />
          </Dropdown.Header>
          <div className="cf-notification-content">
            {userData.remainingCoachingCredit == 0 ? (
              userData.isTrialing ? (
                <span>
                  You've gotten all your feedback for the trial period, more will become available upon subscription
                  renewal.
                </span>
              ) : (
                <span>
                  You've already received all of your feedback for this month. More feedback will become available on
                  your renewal date.
                </span>
              )
            ) : userData.isTrialing ? (
              <span>The trial period includes one coaching session.</span>
            ) : (
              <span>You have {userData.remainingCoachingCredit} coaching sessions left this month.</span>
            )}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className={`${styles.container} ${props.className || ''}`}>
      <nav className={styles.header} style={props.styles || {}}>
        <div className={`${styles.content} ${props.withClose ? styles.withClose : ''}`}>
          {props.backUrl && props.withClose ? (
            <img
              className="cf-notification-close"
              src={process.env.PUBLIC_URL + '/images/icon/icon-exit.svg'}
              onClick={() => redirectTo(backUrl)}
            />
          ) : (
            props.backUrl && (
              <FontAwesomeIcon className={styles.back} icon={faChevronLeft} onClick={() => redirectTo(backUrl)} />
            )
          )}
          {props.title && (
            <h2 id="headerTitle" className={`${styles.title} ${props.titleCenter && styles.titleCenter}`}>
              {props.title}
            </h2>
          )}

          {!props.hideMenu && (
            <ul className={`ml-auto ${styles.topMenu}`}>
              {userData.isWithFeedback && userData.isCoachCreditEnable && (
                <li>
                  <CreditsDropDown />
                </li>
              )}
              {/* <li>
                <a href="#">
                  <img src={process.env.PUBLIC_URL + '/images/menu/icon-chatter-bucs-active.svg'} />
                </a>
              </li> */}
              <li>
                <NotificationIcon onToggle={setShowModal} />
              </li>
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    logOut();
                  }}
                >
                  <img src={process.env.PUBLIC_URL + '/images/sign-out.svg'} />
                </a>
              </li>
            </ul>
          )}
        </div>
      </nav>
      <div className={`${styles.modal} ${(showModal || show) && styles.show}`}></div>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  backUrl: PropTypes.string,
  title: PropTypes.string,
  hideLogo: PropTypes.bool,
  hideMenu: PropTypes.bool,
  titleCenter: PropTypes.bool,
  withClose: PropTypes.bool,
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default Header;
