import React, { useEffect, useState } from 'react';
import { getData } from '../../../util/localStorageHandler.js';
import ErrorTextInline from '../../components/ErrorTextInline.js';

import Header from '../../layouts/Header.js';
import Menu from '../../layouts/Menu.js';

const Invitation = (porps) => {
    const [refferal_coupon, setRefferalCoupon] = useState('');
    const [couponAlert, setCouponAlert] = useState('');

    useEffect(() => {
        let data = getData();
        if (data) {
            console.log('store datas are === ', data);
            // setAuthToken(user.api_token);
            setRefferalCoupon(data.user.refferal_coupon);
        }
    }, []);

    const copyToClipbord = () => {
        if (refferal_coupon) {
            navigator.clipboard.writeText(refferal_coupon);
            showCouponAlert('Code copied.');
        } else {
            showCouponAlert('No code found.');
        }
    };

    const showCouponAlert = (alertText) => {
        setCouponAlert(alertText);
        setTimeout(() => {
            setCouponAlert('');
        }, 2000);
    };

    return (
        <>
            <div className="content-wrapper bg-invite">
                <section className="invite-friends">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="figure">
                                    <img src="images/left.png" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="figcaption text-center">
                                    <strong>Learn with your friends+</strong>
                                    <h1>$5 for you, $5 for your friend</h1>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="figure">
                                    <img src="images/right.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section className="body-mid-invite sec-pad">
                <div className="container">
                    <figcaption className="text-center">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. t enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                        <strong className="d-block">Your Code</strong>
                        {refferal_coupon ? (
                            <button className="btn btn-primary copy-code d-block"> {refferal_coupon}</button>
                        ) : (
                            <button className="btn btn-primary copy-code d-block">NO CODE</button>
                        )}
                        {refferal_coupon ? (
                            <button className="btn d-block submit" type="submit" onClick={() => copyToClipbord()}>
                                COPY
                            </button>
                        ) : null}
                    </figcaption>
                    <ErrorTextInline text={`${couponAlert}`} style={{ marginTop: '10px' }} />
                </div>
            </section>
        </>
    );
};

export default Invitation;
