const getClientLocationInfo = async () => {
  return fetch('https://ipapi.co/json/')
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const ipapiService = {
  getClientLocationInfo,
};
