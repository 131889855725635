import React from 'react';
import { connect } from 'react-redux';
import AuthUserContext from './context';
import { getData } from '../util/localStorageHandler';
import { setUser } from '../actions/user/userSlice';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      let authUser = getData();

      if (authUser && authUser.api_token && authUser.user.email && authUser.user.id) {
        this.setState({ authUser })
        this.internalLogin(authUser);
      } else {
        this.setState({ authUser: null });
      }
    }



    internalLogin = (authUser) => {
      // Despatch user
      authUser && this.props.setUser(authUser);
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  const mapDispatchToProps = dispatch => ({
    setUser: data => dispatch(setUser(data)),
  })
  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;