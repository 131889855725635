import React, { useState, useRef } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '../Button';
import { getSuggestion } from '../../../services/dictionaryServices';

import styles from './index.module.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Segment from '../../../segment';

const Dictionary = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const searchInputRef = useRef();
  const optionsRef = useRef([]);
  const className = `${styles.container} ${props.className || ''} content-wrapper`;

  const handleSearch = () => {
    try {
      Segment.track('Dictionary Search', { word: searchInputRef.current.getInput().value });
      history.push(`/dictionary/search?q=${searchInputRef.current.getInput().value}`);
    } catch (error) {
      console.error(error);
    }
  };

  const getDefinition = (word) => {
    history.push(`/dictionary/search?q=${word}`);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) handleSearch();
  };

  const handleSelectedChange = (selected) => {
    if (selected && selected[0]) history.push(`/dictionary/search?q=${selected[0].suggestion_name}`);
  };

  return (
    <div className={className}>
      <div className={styles.body}>
        <div className={styles.header}>
          <h4>ChatterFox Spoken Dictionary</h4>
          <p>Learn how words are pronounced by real people and in context</p>
        </div>
        <div className={styles.content}>
          <div className={styles.searchHolder}>
            <AsyncTypeahead
              id="dictionarySearch"
              ref={searchInputRef}
              className={styles.input}
              placeholder="What do you want to pronounce..."
              isLoading={isLoading}
              labelKey="suggestion_name"
              onChange={handleSelectedChange}
              onKeyDown={handleKeyDown}
              onSearch={(query) => {
                setIsLoading(true);
                getSuggestion(query).then((res) => {
                  optionsRef.current = res.suggestion;
                  setIsLoading(false);
                });
              }}
              options={optionsRef.current}
            />
            <Button className={styles.btn} type="primary" content="Hear Pronunciation" onClick={handleSearch} />
          </div>
          <div className={styles.suggestions}>
            <h4>You may want to try these:</h4>
            <div className={styles.items}>
              <div className={styles.item} onClick={() => getDefinition('Hello')}>
                <span>Hello</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
              <div className={styles.item} onClick={() => getDefinition('World')}>
                <span>World</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Dictionary.propTypes = {
  className: PropTypes.string,
};

export default Dictionary;
