import { API_BASEURL } from '../constants/global';
import { postFileData, post, get } from '../util/httpClient';
import { getData } from '../util/localStorageHandler';

export async function submitUserRecordedData(dataToSend, authToken, isListen_record) {
  return new Promise(async (resolve, reject) => {
    let url;
    if (!isListen_record) {
      url = `${API_BASEURL}/module/upload-record`;
    } else {
      url = `${API_BASEURL}/module/upload-listen-record`;
    }

    try {
      let response = await postFileData(url, dataToSend, authToken);
      if (response && !response.isError) {
        resolve(response);
      } else {
        reject({ message: response && response.message ? response.message : 'Some error occurred. Please try again.' });
      }
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.' });
    }
  });
}

export const getListenOptions = async (token, id) => {
  let path = `${API_BASEURL}/module/getlisten-options`;
  let data = { module_id: id };
  return post(path, data, token);
};

export const rateFluencyActivity = async (token, datatosend) => {
  let path = `${API_BASEURL}/review/store`;
  return post(path, datatosend, token);
};

export const smartCoach = async (dataToSend) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  // {"response": {"status": "completed", "scores": [{"phone": "SIL", "score": 2.77}, {"phone": "S_B", "score": 2.06}, {"phone": "P_I", "score": 2.46}, {"phone": "IY_I", "score": 2.3}, {"phone": "K_E", "score": 2.23}, {"phone": "SIL", "score": 2.94}], "mean_score": 2.46, "variance_scores": 0.113, "nativeness": 77.429, "alignment_info": [{"phone": "S", "letters": "s", "start": 0, "length": 1, "score": 2.06}, {"phone": "P", "letters": "p", "start": 1, "length": 1, "score": 2.46}, {"phone": "IY", "letters": "ea", "start": 2, "length": 2, "score": 2.3}, {"phone": "K", "letters": "k", "start": 4, "length": 1, "score": 2.23}], "letter_based": [{"letter": "s", "score": 2.06, "details": [{"phone": "S", "score": 2.06}], "color": 3}, {"letter": "p", "score": 2.46, "details": [{"phone": "P", "score": 2.46}], "color": 3}, {"letter": "e", "score": 2.3, "details": [{"phone": "IY", "score": 2.3}], "color": 3}, {"letter": "a", "score": 2.3, "details": [{"phone": "IY", "score": 2.3}], "color": 3}, {"letter": "k", "score": 2.23, "details": [{"phone": "K", "score": 2.23}], "color": 3}], "chart_link": "chart/chart1619078169312844.png", "advice_voice": {"has_voice": true, "link": "data/voice-feedbacks/default/phone-based/bad/S%20Sound%202.mp3", "display_text": "", "debug_info": "{\"feedback_type\": \"phone_based\", \"level\": \"bad\", \"phone\": \"S\", \"phone_ordinal\": 0, \"send_probability\": 1, \"phone_matched\": true, \"level_matched\": true, \"voice_phone_ordinal\": null, \"voices\": [\"default/phone-based/bad/S Sound 1.mp3\", \"default/phone-based/bad/S Sound 2.mp3\"], \"module\": \"default\"}"}, "custom_result": [{"custom_title": "Nativeness", "custom_value": "77.4%"}]}, "errors": []}
  let path = `${API_BASEURL}/ai-smart-coach`;
  return postFileData(path, dataToSend, token);
};

export const smartCoachV3 = async (dataToSend) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  // {"response": {"status": "completed", "scores": [{"phone": "SIL", "score": 2.77}, {"phone": "S_B", "score": 2.06}, {"phone": "P_I", "score": 2.46}, {"phone": "IY_I", "score": 2.3}, {"phone": "K_E", "score": 2.23}, {"phone": "SIL", "score": 2.94}], "mean_score": 2.46, "variance_scores": 0.113, "nativeness": 77.429, "alignment_info": [{"phone": "S", "letters": "s", "start": 0, "length": 1, "score": 2.06}, {"phone": "P", "letters": "p", "start": 1, "length": 1, "score": 2.46}, {"phone": "IY", "letters": "ea", "start": 2, "length": 2, "score": 2.3}, {"phone": "K", "letters": "k", "start": 4, "length": 1, "score": 2.23}], "letter_based": [{"letter": "s", "score": 2.06, "details": [{"phone": "S", "score": 2.06}], "color": 3}, {"letter": "p", "score": 2.46, "details": [{"phone": "P", "score": 2.46}], "color": 3}, {"letter": "e", "score": 2.3, "details": [{"phone": "IY", "score": 2.3}], "color": 3}, {"letter": "a", "score": 2.3, "details": [{"phone": "IY", "score": 2.3}], "color": 3}, {"letter": "k", "score": 2.23, "details": [{"phone": "K", "score": 2.23}], "color": 3}], "chart_link": "chart/chart1619078169312844.png", "advice_voice": {"has_voice": true, "link": "data/voice-feedbacks/default/phone-based/bad/S%20Sound%202.mp3", "display_text": "", "debug_info": "{\"feedback_type\": \"phone_based\", \"level\": \"bad\", \"phone\": \"S\", \"phone_ordinal\": 0, \"send_probability\": 1, \"phone_matched\": true, \"level_matched\": true, \"voice_phone_ordinal\": null, \"voices\": [\"default/phone-based/bad/S Sound 1.mp3\", \"default/phone-based/bad/S Sound 2.mp3\"], \"module\": \"default\"}"}, "custom_result": [{"custom_title": "Nativeness", "custom_value": "77.4%"}]}, "errors": []}
  let path = `${API_BASEURL}/v3/ai-smart-coach`;
  return postFileData(path, dataToSend, token);
};

export const fluencyEvaluation = async (dataToSend) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;
  let path = `${API_BASEURL}/fluency-evaluation`;
  return postFileData(path, dataToSend, token);
};

export const uploadFluencyActivity = async (token, dataToSend) => {
  let path = `${API_BASEURL}/module/upload-fluency-activity`;
  return postFileData(path, dataToSend, token);
};

export const getFluencyCategory = async (token) => {
  let path = `${API_BASEURL}/fluency/categories`;
  return get(path, token);
};

export const uploadFinalAssessment = async (token, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    let path = `${API_BASEURL}/module/upload-final-record`;
    try {
      // Upload user recorded file
      let response = await postFileData(path, dataToSend, token);
      if (response && !response.isError) {
        resolve(response);
      } else {
        reject({ message: response && response.message ? response.message : 'Some error occurred. Please try again.' });
      }
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.' });
    }
  });
};
