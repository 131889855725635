import React, { useEffect, useState } from 'react';
import ErrorTextInline from '../../components/ErrorTextInline';
import SubmitButtonBig from '../../components/SubmitButtonBig';
import { isUserExist, updatePaymentProfile, getUserDetails } from '../../../services/paymentServices';
import { setUser } from '../../../actions/user/userSlice';
import { useDispatch } from 'react-redux';
import Segment from '../../../segment';
import PropTypes from 'prop-types';
import styles from './GetLead.module.css';

const GetLead = (props) => {
  let dispatch = useDispatch();

  let [isLoading, setLoading] = useState(false);
  let [isDisabled, setIsDisabled] = useState(true);
  let [emailError, setEmailError] = useState('');
  let [userNameError, setUserNameError] = useState('');
  let [userFirstName, setUserFirstName] = useState('');
  let [userLastName, setUserLastName] = useState('');
  let [userName, setUserName] = useState('');
  let [email, setEmail] = useState('');
  let queryString = props.location.search;

  useEffect(() => {
    if (!queryString) window.top.postMessage({ Swal: ['Error!', 'something went wrong', 'error'] }, '*');

    // Hide zoho feedback widget
    var sheet = document.createElement('style');
    sheet.innerHTML = '.feedbacklab {display: none !important;}';
    document.body.appendChild(sheet);
  }, []);

  const checkBlankInput = () => {
    let isValid = true;
    if (!email) {
      isValid = false;
    }
    if (!userName) {
      isValid = false;
    }

    if (isValid) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const paymentProcess = () => {
    let isValid = true;

    // Validate field
    if (!email) {
      isValid = false;
      setEmailError('Enter your email id.');
    }
    if (!userName) {
      isValid = false;
      setUserNameError('Enter your user name.');
    }

    // If validate
    if (isValid) {
      setLoading(true);

      isUserExist(userName, email).then((res) => {
        setLoading(false);

        if (!res) {
          window.top.postMessage({ Swal: ['Error!', 'something went wrong', 'error'] }, '*');
          return false;
        }

        if (res.isError == 1 && res.email_exists) {
          window.top.postMessage({ Swal: ['Info!', 'User has register account, Please login ', 'info'] }, '*');
        } else if (res.isError == 1 && !res.email_exists) {
          window.top.postMessage({ Swal: ['Error!', res.message, 'error'] }, '*');
        } else {
          updatePaymentProfile(userFirstName, userLastName, res.data.api_token).then((rs) => {
            getUserDetails(res.data.api_token).then((userData) => {
              let API_TOKEN = res.data.api_token;

              let external_user_data_store = {
                user: {
                  first_name: userData.data.first_name,
                  last_name: userData.data.last_name,
                  email: res.data.user.email,
                  id: res.data.user.id,
                },

                api_token_external: API_TOKEN,
                isSession: true,
              };

              dispatch(setUser(external_user_data_store));

              Segment.identify(external_user_data_store.user.email, {
                email: external_user_data_store.user.email,
              });
              //Swal.fire('Info!', 'New user has been register, pleas check associate email for login details', 'info');
              // history.push({
              //   pathname: '/checkout',
              //   search: queryString,
              //   state: { externalPayment: true },
              //   title: 'External Payment Checkout',
              // });
              window.top.postMessage('checkout', '*');

              setLoading(false);
            });
          });
        }
      });
    }
  };

  return (
    <section className={styles.mainForm}>
      <div className="container">
        <h2 className={styles.header}>Enter your information to continue</h2>

        <div className={styles.loginForm}>
          <form className="text-left">
            <label>Name </label>
            <input
              type="text"
              onChange={(e) => {
                setUserName(e.target.value);
                setUserNameError('');
              }}
              onKeyUp={() => {
                checkBlankInput();
              }}
            />
            <label>Email </label>
            <input
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError('');
              }}
              onKeyUp={() => {
                checkBlankInput();
              }}
            />

            <SubmitButtonBig
              caption="NEXT"
              width="100%"
              isLoading={isLoading}
              onClick={() => paymentProcess()}
              isDisabled={isDisabled}
              btnClass={styles.mainBtn}
            />
          </form>
          <ErrorTextInline text={` ${emailError} ${userNameError}`} />
        </div>
      </div>
    </section>
  );
};

GetLead.propTypes = {
  location: PropTypes.object,
};

export default GetLead;
