import React, { useEffect, useState, useRef } from 'react';
import styles from './LoadingRobot.module.css';
import globalStyles from './InitialAssessment.module.css';

const LoadingRobot = () => {
  const [typier, setTypier] = useState();
  let i = 0;
  const speed = 100; /* The speed/duration of the effect in milliseconds */
  const texts = [
    'ChatterFox will use an ever-growing database of pronunciation to detect your weaknesses and provide accurate feedback.',
    'ChatterFox compares your recording with more than 100,000 other data points. This may take a moment.',
    'Did you know that with ChatterFox you can have live AI feedback, and human coaching at the same time?',
    'ChatterFox offers affordable American accent training to more than 60,000 people in the US and the whole world!',
    'With ChatterFox, you can improve the way you speak English, and show your potential employers your true potential!',
    'Looking for better jobs? Join ChatterFox to improve your American English Fluency, and see the difference it will make!',
  ];
  let random = Math.floor(Math.random() * texts.length);
  const typeWriterTimeoutRef = useRef();
  const selectTxtTimeoutRef = useRef();

  useEffect(() => {
    setTypier(1);

    return () => {
      typeWriterTimeoutRef.current && clearTimeout(typeWriterTimeoutRef.current);
      selectTxtTimeoutRef.current && clearTimeout(selectTxtTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (typier == 1) typeWriter();
  }, [typier]);

  const selectTxt = () => {
    random = Math.floor(Math.random() * texts.length);
    document.getElementById('notes').innerHTML = '';
    document.getElementById('notes').classList.remove('fadeOut');
    setTypier(1);
  };

  const typeWriter = () => {
    try {
      if (i < texts[random].length) {
        document.getElementById('notes').innerHTML += texts[random].charAt(i);
        i++;
        typeWriterTimeoutRef.current = setTimeout(typeWriter, speed);
      } else {
        document.getElementById('notes').classList.add('fadeOut');
        setTypier(0);
        selectTxtTimeoutRef.current = setTimeout(selectTxt, 5000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`${styles.contentContainer}`}>
      <div>
        <div className={`${globalStyles.title} ${styles.title}`}>
          Hang Tight! <span className={globalStyles.highlight}>ChatterFox&apos;s AI system</span> is generating your
          feedback! this might take a minute
        </div>
        <div className={styles.content}>
          <img alt="" src={process.env.PUBLIC_URL + '/images/loading-robot.gif'} />
          <div className={styles.notesHolder}>
            <div className={styles.notesBox}>
              <p id="notes" className={styles.notes}></p>
            </div>
          </div>
          {/* <Splide
          // ref={slider}
          options={{
            type: 'slide',
            gap: '0rem',
            speed: 1000,
            arrows: false,
            // navigator: false,
            padding: {
              right: '10px',
              left: '10px',
            },
            // width: '50%',
            perPage: 1,
            // autoHeight: true,
          }}
          >
            <SplideSlide>
              ChatterFox will use an ever-growing database of pronunciation to detect your weaknesses and provide accurate feedback.
            </SplideSlide>
            <SplideSlide>
            ChatterFox compares your recording with more than 100,000 other data points. This may take a moment.
            </SplideSlide>

          </Splide> */}
        </div>
      </div>
    </div>
  );
};

export default LoadingRobot;
