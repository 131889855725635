import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import OnboardingLogoText from '../../components/OnboardingLogoText';
import SubmitButtonBig from '../../components/SubmitButtonBig';
import _ from 'lodash';

const VerificationSuccess = (props) => {
  let history = useHistory();

  const [email, setEmail] = useState('');
  const [coupon, setCoupon] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (
      _.get(history, 'location.state.email') &&
      _.get(history, 'location.state.coupon') &&
      _.get(history, 'location.state.password')
    ) {
      let email = history.location.state.email;
      let coupon = history.location.state.coupon;
      let password = history.location.state.password;
      setEmail(email);
      setCoupon(coupon);
      setPassword(password);
    } else {
      history.push('/signin');
    }
  }, []);

  const goToPage = (path) => {
    history.push(path);
  };

  return (
    <section className="login sec-pad">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="container">
        <OnboardingLogoText text="Success" subText="You have successfully registered your account." />
        <div className="login-video extra-padding">
          <strong className="d-block mb-2">
            {email && 'Your email is: ' + email} <br />
            {password && 'Your password is: ' + password} <br />
            {coupon && 'Your coupon is: ' + coupon}
          </strong>

          {/* <iframe width="100%" height="480" src="https://www.youtube.com/embed/xcJtL7QggTI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          <iframe
            src="https://player.vimeo.com/video/486387881"
            width="100%"
            height="480"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>

          {/* <button type="submit" className="btn-red btn-orange">Go to Home page</button> */}
          <SubmitButtonBig caption="Go to Home page" onClick={() => goToPage('/modules')} />
        </div>
      </div>
    </section>
  );
};

export default VerificationSuccess;
