import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { setNotifications, setUnreadNotificationCount } from '../../../actions/user/userSlice';
import { selectSelectedLesson } from '../../../actions/lesson/lessonStore';
import { getInitialAssessmentStatus } from '../../../services/initialAssessmentServices';
import { setStatus } from '../../../actions/initialAssessment/initialAssessmentStore';
import { CF_USER } from '../../../constants/global';
import withAuthorization from '../../../Session/withAuthorization';

import Modules from './Modules';
import ModuleDetails from './ModuleDetails/index.js';
import Lessons from './lesson/Lessons';
import { getUnreadNotificationCount, getUserNotifications } from '../../../services/notificationServices';

const ModuleIndex = (props) => {
  const { moduleId, lessonType, lessonId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedLesson = useSelector(selectSelectedLesson);

  useEffect(() => {
    if (_.get(props.location, 'state.prevPath') === 'initialAssessment') {
      getUserNotifications().then((res) => {
        if (res && !res.isError && !res.errorCode) {
          dispatch(setNotifications(res.userNotifications));
        } else {
          dispatch(setNotifications([]));
        }
      });
      getUnreadNotificationCount().then((res) => {
        if (res && !res.isError && !res.errorCode) {
          dispatch(setUnreadNotificationCount(res.userNotificationsCount));
        } else {
          dispatch(setUnreadNotificationCount(0));
        }
      });

      getInitialAssessmentStatus().then((res) => {
        if (res && !res.isError && !res.errorCode) {
          dispatch(setStatus(res.data));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location]);

  useEffect(() => {
    if (!selectedLesson && moduleId) history.push(`/modules/${moduleId}`);
    else if (!selectedLesson && !moduleId) history.push('/modules');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLesson]);

  const RenderView = ({ moduleId, lessonType, lesson }) => {
    if (!moduleId && !lessonType) return <Modules />;
    else if (moduleId && !lessonType) return <ModuleDetails moduleId={moduleId} />;
    else if (lesson)
      return (
        <Lessons
          moduleId={moduleId}
          lessonName={lessonType}
          lessonData={lesson}
          modulePath={`/modules/${moduleId}`}
          backUrl={`/modules/${moduleId}`}
        />
      );
    else return <></>;
  };

  return <RenderView moduleId={moduleId} lessonType={lessonType} lesson={selectedLesson} />;
};

ModuleIndex.propTypes = {
  location: PropTypes.object,
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(ModuleIndex);
