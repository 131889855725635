import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { selectLessonStore, setSelectedLesson } from '../../../../actions/lesson/lessonStore';
import { selectModule, selectSelectedModule } from '../../../../actions/module/moduleSlice';
import withAuthorization from '../../../../Session/withAuthorization';
import { CF_USER } from '../../../../constants/global';
import PreLesson from './PreLesson';
import VideoLesson from './VideoLesson';
import ListenAndChoose from './ListenAndChoose';
import ListenAndRecord from './ListenAndRecord';
import SmartCoach from './SmartCoach';
import PostLesson from './PostLesson';
import FluencyActivity from './FluencyActivity';
import ReviewRecord from './ReviewRecord';
import Feedback from './Feedback';
import { saveModuleStep } from '../../../../services/moduleServices';
import Segment from '../../../../segment';
import _ from 'lodash';
import styles from './Lessons.module.css';
import { findModuleById } from '../../../../util';

const Lessons = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const allLessonData = useSelector(selectLessonStore);
  const selectedModule = useSelector(selectSelectedModule);
  const modules = useSelector(selectModule);
  let backUrl = props.backUrl;
  let modulePath = props.modulePath;
  const moduleId = props.moduleId;
  const name = _.get(selectedModule, 'title');
  const moduleCode = _.get(selectedModule, 'module_code');
  const pos = allLessonData.map((e) => e.unique_id).indexOf(props.lessonData.unique_id);
  const [currentLesson, setCurrentLesson] = useState({ lesson: props.lessonData, index: pos });
  const [nextFire, setNextFire] = useState(false);
  const css = '#feedbacklabel {display: none !important;}';

  const updateUserProgress = (lesson) => {
    let lessonType = lesson.lesson_type.toLowerCase();
    let lessonId = lesson.id;
    let moduleId = lesson.module_id;
    let testFlag = true;
    if (lesson.lesson_type == 'review') {
      //in review page no progress API will fire
      return false;
    } else if (lesson.lesson_type == 'guidedLesson') {
      if (lesson.guided_lesson_type == 'lesson') {
        lessonType = 'video_lessons';
      } else if (lesson.guided_lesson_type == 'listen_records') {
        const currentLessonIndex = lesson.words.map((e) => e.unique_id).indexOf(lesson.unique_id) + 1;
        testFlag = currentLessonIndex === lesson.words.length;
        lessonType = 'listen_and_records';
      } else if (lesson.guided_lesson_type == 'listen_options') {
        testFlag = lesson.index === lesson.totalllength;
        lessonType = 'listen_and_choose';
      } else if (lesson.guided_lesson_type === 'recognising_tools') {
        const currentLessonIndex = lesson.words.map((e) => e.unique_id).indexOf(lesson.unique_id) + 1;
        testFlag = currentLessonIndex === lesson.words.length;
        lessonType = lesson.guided_lesson_type;
      } else {
        lessonType = lesson.guided_lesson_type;
      }
    } else if (lesson.lesson_type == 'fluencyActivity') {
      lessonId = lesson.pivot.fluency_activity_id;
      moduleId = lesson.pivot.module_id;
      lessonType = 'fluency_activity';
    } else if (lesson.lesson_type == 'finalAssignment') {
      lessonType = 'final';
    } else if (lesson.lesson_type == 'preLesson') {
      return false;
    }

    const dataToSend = {
      module_id: moduleId,
      step: lessonType,
      step_id: lessonId,
    };

    if (!lesson.is_completed && testFlag) {
      saveModuleStep(dataToSend);
    }
  };

  const finalLessonMove = () => {
    const pos = currentLesson.index;
    if (!allLessonData[pos + 1]) {
      Segment.track('Module Completed', {
        moduleId,
        name,
      });
      updateUserProgress(currentLesson.lesson);
      goToReview();
    } else {
      next();
    }
  };

  const goToReview = () => {
    let moduleId = currentLesson.lesson.module_id;

    if (currentLesson.lesson.lesson_type == 'fluencyActivity') {
      moduleId = currentLesson.lesson.pivot.module_id;
    }
    let reviewObj = { lesson_type: 'review', module_id: moduleId };
    redirectTo(modulePath + '/review', {
      lessonData: reviewObj,
      allModule: '',
      modulePath: modulePath,
      title: name,
      moduleId,
    });
  };

  const gotoModule = (path, data = {}) => {
    history.push({
      pathname: path,
      state: { ...data, moduleCode },
    });
  };

  const next = () => {
    const pos = currentLesson.index;
    if (!allLessonData[pos + 1]) {
      gotoModule(backUrl);
    } else if (allLessonData[pos].lesson_type == allLessonData[pos + 1].lesson_type) {
      // from same lesson to same lesson
      updateUserProgress(currentLesson.lesson);
      // update local component state
      dispatch(setSelectedLesson(allLessonData[pos + 1]));
      setCurrentLesson({ lesson: allLessonData[pos + 1], index: pos + 1 });
      history.replace(`${modulePath}/${allLessonData[pos + 1].lesson_type}/${allLessonData[pos + 1].unique_id}`);
    } else {
      Segment.track('Module Section Completed', {
        moduleId,
        name,
        section: _.get(props, 'lessonName'),
      });
      updateUserProgress(currentLesson.lesson);
      //from one lesson to another lesson
      dispatch(setSelectedLesson(allLessonData[pos + 1]));
      redirectTo(`${modulePath}/${allLessonData[pos + 1].lesson_type}/${allLessonData[pos + 1].unique_id}`, {
        lessonData: allLessonData[pos + 1],
        allModule: allLessonData,
        modulePath: modulePath,
        title: name,
        moduleId,
      });
      // update local component state
      setCurrentLesson({ lesson: allLessonData[pos + 1], index: pos + 1 });
    }
    //set a parameter called "nextFire" true to trigger is next button fire
    setNextFire(true);
  };

  const prev = () => {
    const pos = currentLesson.index;
    if (!allLessonData[pos - 1]) {
      gotoModule(backUrl);
      // return false;
    } else if (allLessonData[pos].lesson_type == allLessonData[pos - 1].lesson_type) {
      //from same lesson to same lesson
      dispatch(setSelectedLesson(allLessonData[pos - 1]));
      setCurrentLesson({ lesson: allLessonData[pos - 1], index: pos - 1 });
      history.replace(`${modulePath}/${allLessonData[pos - 1].lesson_type}/${allLessonData[pos - 1].unique_id}`);
    } else {
      //from one lesson to another lesson
      dispatch(setSelectedLesson(allLessonData[pos - 1]));
      redirectTo(`${modulePath}/${allLessonData[pos - 1].lesson_type}/${allLessonData[pos - 1].unique_id}`, {
        lessonData: allLessonData[pos - 1],
        allModule: allLessonData,
        modulePath: modulePath,
        title: name,
        moduleId,
      });
      setCurrentLesson({ lesson: allLessonData[pos - 1], index: pos - 1 });
    }
  };

  const endMessageHandleBackToHome = () => {
    updateUserProgress(currentLesson.lesson);
    history.push(`/modules/${props.moduleId}`);
  };

  const switchLesson = (el) => {
    const pos = currentLesson.index;
    if (allLessonData[pos].lesson_type == allLessonData[pos].lesson_type) {
      //from same lesson to same lesson
      setCurrentLesson({ lesson: allLessonData[pos], index: pos });
    } else {
      //from one lesson to another lesson
      dispatch(setSelectedLesson(allLessonData[pos]));
      redirectTo(`${modulePath}/${allLessonData[pos].lesson_type}/${allLessonData[pos].lesson_type.unique_id}`, {
        lessonData: allLessonData[pos],
        allModule: allLessonData,
        modulePath: modulePath,
        title: name,
        moduleId,
      });
      setCurrentLesson({ lesson: allLessonData[pos], index: pos });
    }
  };

  const redirectTo = (path, data = {}) => {
    if (path && path != '') {
      history.push({
        pathname: path,
        state: { ...data, moduleCode },
      });
    }
  };

  const RenderLesson = () => {
    switch (_.get(currentLesson, 'lesson.lesson_type', '')) {
      case 'preLesson':
        return (
          <PreLesson
            lessonName={currentLesson.lesson.lesson_type}
            lessonData={currentLesson.lesson}
            allLessonData={allLessonData}
            nextFire={nextFire}
            next={next}
            moduleId={moduleId}
            name={name}
            module={selectedModule}
          />
        );
      case 'guidedLesson':
        switch (currentLesson.lesson.guided_lesson_type) {
          case 'lesson':
            return (
              <VideoLesson
                moduleId={moduleId}
                lessonName={currentLesson.lesson.lesson_type}
                lessonData={currentLesson.lesson}
                allLessonData={allLessonData}
                nextFire={nextFire}
                next={next}
                module={selectedModule}
                backToHome={endMessageHandleBackToHome}
              />
            );
          case 'listen_records':
            return (
              <ListenAndRecord
                moduleId={moduleId}
                lessonName={currentLesson.lesson.lesson_type}
                lessonData={currentLesson.lesson}
                lessonIndex={currentLesson.index}
                nextFire={nextFire}
                next={next}
                switchLesson={switchLesson}
                prev={prev}
                module={selectedModule}
                backToHome={endMessageHandleBackToHome}
              />
            );
          case 'listen_options':
            return (
              <ListenAndChoose
                moduleId={moduleId}
                lessonName={currentLesson.lesson.lesson_type}
                lessonData={currentLesson.lesson}
                lessonIndex={currentLesson.index}
                allLessonData={allLessonData}
                nextFire={nextFire}
                next={next}
                status={false}
                switchLesson={switchLesson}
                module={selectedModule}
                backToHome={endMessageHandleBackToHome}
              />
            );
          case 'recognising_tools':
            return (
              <SmartCoach
                moduleId={moduleId}
                lessonName={currentLesson.lesson.lesson_type}
                lessonData={currentLesson.lesson}
                allLessonData={allLessonData}
                nextFire={nextFire}
                next={next}
                status={false}
                prev={prev}
                switchLesson={switchLesson}
                moduleCode={selectedModule.module_code}
                module={selectedModule}
                backToHome={endMessageHandleBackToHome}
              />
            );
          default:
            return <></>;
        }
      case 'finalAssignment':
        return (
          <PostLesson
            moduleId={moduleId}
            lessonName={currentLesson.lesson.lesson_type}
            lessonData={currentLesson.lesson}
            lessonIndex={currentLesson.index}
            allLessonData={allLessonData}
            nextFire={nextFire}
            goToReview={finalLessonMove}
            next={next}
            name={name}
            module={selectedModule}
            updateUserProgress={() => updateUserProgress(currentLesson.lesson)}
          />
        );
      case 'fluencyActivity':
        return (
          <FluencyActivity
            moduleId={moduleId}
            lessonName={currentLesson.lesson.lesson_type}
            lessonData={currentLesson.lesson}
            allLessonData={allLessonData}
            nextFire={nextFire}
            next={next}
            module={selectedModule}
            backToHome={endMessageHandleBackToHome}
          />
        );
      case 'review':
        return (
          <ReviewRecord
            moduleId={moduleId}
            lessonName={currentLesson.lesson.lesson_type}
            lessonData={currentLesson.lesson}
            backUrl={backUrl}
            next={next}
          />
        );
      case 'feedback':
        return (
          <Feedback
            moduleId={moduleId}
            lessonName={currentLesson.lesson.lesson_type}
            lessonData={currentLesson.lesson}
            next={next}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={`${styles.container} lessonContainer`}>
      <style>{css}</style>
      <RenderLesson />
    </div>
  );
};

Lessons.propTypes = {
  lessonData: PropTypes.any,
  backUrl: PropTypes.string,
  modulePath: PropTypes.string,
  moduleId: PropTypes.string,
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(Lessons);
