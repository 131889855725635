import React, { useContext } from 'react';
import { Accordion, AccordionContext, Card, useAccordionButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { selectUser } from '../../../../actions/user/userSlice';
import ModuleCard from '../ModuleCard';
import styles from './index.module.css';

const ModuleGroup = (props) => {
  const { id, title, subtitle, modules, disabled, disabledNote, isStatic, noTitle } = props;
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(id);
  const userData = useSelector(selectUser);

  return (
    <Card
      className={`${styles.card} ${disabled && styles.disabled} ${disabledNote && styles.withNote} ${
        noTitle && styles.noTitle
      }`}
    >
      <Card.Header
        className={activeEventKey === id ? styles.collapse : styles.collapsed}
        onClick={decoratedOnClick}
        style={_.get(userData, 'is_subscribed', false) ? {} : { filter: 'grayscale(100%)' }}
      >
        <span className="card-title">{title}</span>
        {subtitle && <span className="card-subtitle">{subtitle}</span>}
      </Card.Header>
      {isStatic ? (
        <Card.Body className={styles.cardBody}>
          {disabled && disabledNote && <div className={styles.notice}>{disabledNote}</div>}
          <div className={styles.backLine}></div>
          {modules.map((module, index) => (
            <ModuleCard
              key={'moduleCard-' + id + '-' + index}
              id={module.id}
              iconSrc={module.icon_url}
              title={module.title}
              subtitle={module.sub_title}
              score={module.score}
              userProgress={module.user_progress}
              locked={id !== 'One' && !module.is_unlocked}
              feedbackAvailable={module.is_feed_back_avilable}
              groupTitle={id === 'Zero' ? module.title : title}
              groupId={id}
              module={module}
              showUnlockModal={props.showUnlockModal}
            />
          ))}
        </Card.Body>
      ) : (
        <Accordion.Collapse eventKey={id}>
          <Card.Body className={styles.cardBody}>
            {disabledNote && <div className={styles.notice}>{disabledNote}</div>}
            <div className={styles.backLine}></div>
            {modules.map((module, index) => (
              <ModuleCard
                key={'moduleCard-' + id + '-' + index}
                id={module.id}
                iconSrc={module.icon_url}
                title={module.title}
                subtitle={module.sub_title}
                score={module.score}
                userProgress={module.user_progress}
                locked={id !== 'One' && !module.is_unlocked}
                feedbackAvailable={module.is_feed_back_avilable}
                groupTitle={id === 'Zero' ? module.title : title}
                groupId={id}
                module={module}
                showUnlockModal={props.showUnlockModal}
              />
            ))}
          </Card.Body>
        </Accordion.Collapse>
      )}
    </Card>
  );
};

ModuleGroup.propTypes = {
  id: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  modules: PropTypes.any,
  disabled: PropTypes.bool,
  disabledNote: PropTypes.string,
  isStatic: PropTypes.bool,
  noTitle: PropTypes.bool,
  showUnlockModal: PropTypes.func,
};

export default ModuleGroup;
