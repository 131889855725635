import React, { useState, useEffect } from 'react';
import { STRIPE_KEY } from '../../../constants/global';
import { useHistory } from 'react-router-dom';
import withAuthorization from '../../../Session/withAuthorizationForPayment';
import Payment from './payment.js';
import PaymentsExternal from './payment_external.js';
import { SITE_CONTEXT } from '../../../constants/global';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isPaymentActive } from '../../../services/paymentServices';
import queryString from 'query-string';

const PaymentWrap = (props) => {
  const history = useHistory();
  const [isDone, setIsDone] = useState(false);
  const [planName, setPlanName] = useState('');
  const redirectUrl = history.location.pathname.replace('/', '');
  const redirectUrlQueryParam = history.location.search;

  const stripePromise = loadStripe(STRIPE_KEY);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const values = queryString.parse(props.location.search);

    if (values.pid) {
      isPaymentActive(values.pid).then((res) => {
        if (res.is_plan_exists == 0) {
          window.location.href = 'https://chatterfox.com/american-smart-fluency-program-payment';
          return false;
        } else {
          if (!props.isLogedIn && !props.isExternal) {
            history.push({
              pathname: `${SITE_CONTEXT}/form-external-payment`,
              state: {
                redirectUrl: redirectUrl,
                redirectUrlQueryParam: redirectUrlQueryParam,
              },
            });
          }
        }
      });
    } else {
      window.location.href = 'https://chatterfox.com/american-smart-fluency-program-payment';
      return false;
    }
  }, [props]);

  return (
    <>
      {props.isLogedIn ? (
        <Elements stripe={stripePromise}>
          <Payment setIsDone={(data) => setIsDone(data)} setPlanName={(data) => setPlanName(data)} />
        </Elements>
      ) : props.isExternal ? (
        <Elements stripe={stripePromise}>
          <PaymentsExternal setIsDone={(data) => setIsDone(data)} setPlanName={(data) => setPlanName(data)} />
        </Elements>
      ) : null}
    </>
  );
};

const condition = (authUser) => authUser != null;

export default withAuthorization(condition)(PaymentWrap);
