import { createSlice } from '@reduxjs/toolkit';

export const lessonProgresStore = createSlice({
  name: 'lessonsProgres',
  initialState: {
    data: [],
  },

  reducers: {
    setLessonProgresStore: (state, action) => {
      if (state.data.indexOf(action.payload) > 0) {
        state.data.push(action.payload.toLowerCase());
      }
    },
  },
});

//this is distach action function
export const { setLessonProgresStore } = lessonProgresStore.actions;

// The function below is called a thunk and allows us to perform async logic. It
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const setLessonProgresStoreAsync = (lessons) => (dispatch) => {
  setTimeout(() => {
    dispatch(setLessonProgresStore(lessons));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectLessonProgresStore = (state) => state.lessonsProgres;

export default lessonProgresStore.reducer;

/**
 * https://redux-toolkit.js.org/api/createslice
 */
