import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUser } from '../../../actions/user/userSlice';

import { getActivePlans } from '../../../services/paymentServices';
import { CF_USER } from '../../../constants/global';

import withAuthorization from '../../../Session/withAuthorization';
import SubscriptionWarnWarnModal from '../../components/SubscriptionWarnWarnModal';
import Segment from '../../../segment';

const Subscription = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();
  const userData = useSelector(selectUser);

  const [plans, setPlans] = useState(null);

  useEffect(() => {
    let token = userData.api_token;

    getActivePlans(token).then((res) => {
      setPlans(res);
    });
  }, []);

  const redirectTo = (path, data = {}, query = '') => {
    if (path && path != '') {
      history.push({
        pathname: path,
        search: query,
        state: data,
      });
    }
  };

  //capitalize any striing
  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const startPaymentProcess = (id, planDetails) => {
    Segment.track('Product Clicked', planDetails);
    let path = `/checkout`;
    let query = `?pid=${id}`;
    redirectTo(path, {}, query);
  };

  return (
    <>
      <section className="choose-your-plan pb-2">
        {plans ? (
          <div className="container">
            <div className="top-head-payment text-center">
              <h1>Choose Your Plan</h1>
              <p>{plans.message}</p>
            </div>
            <div className="row">
              {plans.plans.map((elm, ind) => {
                let is_subscribed = plans.user_current_plan.plan_id == elm.id ? true : false;

                return (
                  <div className="col-lg-4" key={'plans' + ind}>
                    <div className="package">
                      <div className="figure text-center d-block pb-1">
                        <p>{elm.display_name}</p>
                        <img src={elm.image_url} />
                        {!is_subscribed && elm.original_price ? (
                          <span className="d-block">{elm.original_price + ' /  Month'}</span>
                        ) : null}
                        {/* <strong className="d-block">{"$" + elm.cost + " / " + capitalize(elm.interval)}</strong> */}
                        {elm.display_price ? (
                          <strong className="d-block">{elm.display_price}</strong>
                        ) : (
                          <strong className="d-block">{'$' + elm.cost + ' / ' + capitalize(elm.interval)}</strong>
                        )}

                        {elm.most_popular == 1 ? <div className="most-popular">Most Popular</div> : null}
                      </div>
                      <div className="figcaption">
                        <ul className="subslist">
                          <li>
                            <strong>Has free trial :</strong> {elm.is_trial_plan == 1 ? 'Yes' : 'No'}{' '}
                          </li>
                          <li>
                            <strong>Status :</strong> {is_subscribed ? 'Active' : 'Not Active'}{' '}
                          </li>
                          <li
                            dangerouslySetInnerHTML={{
                              __html: '<strong>Description : </strong> ' + elm.description,
                            }}
                          />
                        </ul>
                        {!is_subscribed ? (
                          elm.is_subscribeble ? (
                            plans.user_current_plan.is_admin_subscribed ? (
                              <span className="text-center d-block">
                                <button className="btn btn-red btn-orange" data-toggle="modal" data-target="#subsWarn">
                                  Next
                                </button>
                              </span>
                            ) : (
                              <span className="text-center d-block">
                                <button
                                  className="btn btn-red btn-orange"
                                  onClick={() => {
                                    startPaymentProcess(elm.plan_id, {
                                      name: elm.display_name,
                                      price: elm.original_price,
                                      hasTrial: elm.is_trial_plan,
                                    });
                                  }}
                                >
                                  Next
                                </button>
                              </span>
                            )
                          ) : (
                            <span className="text-center d-block">
                              <button className="btn btn-red btn-orange disabled color-white">Not Upgradable</button>
                            </span>
                          )
                        ) : (
                          <span className="text-center d-block">
                            <button className="btn btn-red btn-orange disabled color-white">Already subscribed</button>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </section>

      <SubscriptionWarnWarnModal
        title="oops !"
        message={
          plans
            ? plans.user_current_plan.admin_subscribed_upgrade_message
              ? plans.user_current_plan.admin_subscribed_upgrade_message
              : 'Please Unsubscribe your current plan first'
            : 'Please Unsubscribe your current plan first'
        }
      />
    </>
  );
};

// modules.propTypes = {

// };

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(Subscription);
