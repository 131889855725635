import packageJson from '../../../package.json';

export const isLive = process.env.REACT_APP_ENV === 'production'; //true if deploying to live

export const SITE_CONTEXT = '';
export const SH = 'QFR5b2UgT3IgUGVzdDE=';
export const API_BASEURL = isLive ? 'https://chatterfox.app/api' : 'https://staging.chatterfox.app/api';
export const AI_API_BASEURL = isLive ? 'https://betterthananamerican.com' : 'https://staging.betterthananamerican.com';
export const CF_API_BASEURL = isLive ? 'https://api.chatterfox.com' : 'https://staging.api.chatterfox.com';
export const AI_TOKEN = 'Basic cm9vdDozNjQ4NzhjNC1iOGIxLTQ5ZDAtOWNmMC00YzI4ZjM3NTkwZTM=';
export const DICTIONARY_API_BASEURL = 'https://zizifox.com/api';
export const DICTIONARY_TOKEN = 'gfy69rrncwcvvxahkxwbcqt4oqyrp0atqzaraeyoqv';
export const RAPIDAPI_API_BASEURL = ' https://mashape-community-urban-dictionary.p.rapidapi.com';
export const X_RAPIDAPI_KEY = '47df610714msh9d24f0f794d553ep179e9cjsn2bdb30bc813d';
export const X_RAPIDAPI_HOST = 'mashape-community-urban-dictionary.p.rapidapi.com';

export const CF_USER = 2;

export const SEGMENT_WRITE_KEY = 'c5dRaivTRVRmOfSOWG0yp5O7SDGHJ8J4';

export const RE_CAPTCHA_WEB_KEY = '6LdxwJ8UAAAAAKxC3sOL5RAnhyBzBvb-VdowdfBm';

export const STRIPE_KEY = !isLive ? 'pk_test_1xyH1fR18BJ5pYOLBS1MXPlX' : 'pk_live_oEqSkOqG8ERvFBtrVupsta5F00wiCqJCwB';

// export const STRIPE_KEY_STAGING = 'pk_test_1xyH1fR18BJ5pYOLBS1MXPlX'
// export const STRIPE_KEY_LIVE = 'pk_live_oEqSkOqG8ERvFBtrVupsta5F00wiCqJCwB'

export const version = !isLive ? (
  // eslint-disable-next-line react/react-in-jsx-scope
  <p className="float shadow"> This is a indication for new version updated {packageJson.version} </p>
) : null;
