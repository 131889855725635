export const blobToFile = (theBlob, fileName) => {
  let file = new File([theBlob], fileName, { type: 'audio/mpeg', lastModified: new Date() });
  return file;
};

export const msToTime = (duration, format) => {
  let milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  switch (format) {
    case 'hh:mm:ss.sss':
      return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
    case 'hh:mm:ss':
      return hours + ':' + minutes + ':' + seconds;
    case 'hh:mm':
      return hours + ':' + minutes;
    case 'mm:ss.sss':
      return minutes + ':' + seconds + '.' + milliseconds;
    case 'mm:ss':
      return minutes + ':' + seconds;
    default:
      return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
  }
};

export const findModuleById = (modules, moduleId) => {
  let module;
  // Find module by object_id
  modules.othModules.find((moduleGroup) => {
    module = (moduleGroup.new_modules || moduleGroup.modules).find((elm) => elm.id == moduleId);
    if (module) return module;
  });
  if (module) return module;
  module = modules.introModules.find((introModule) => introModule.id == moduleId);
  if (module) return module;
  module = modules.trialModules.find((trialModule) => trialModule.id == moduleId);

  return module;
};

export const getLessonTypeName = (lesson) => {
  switch (lesson.lesson_type) {
    case 'preLesson':
      return 'Record the Text Below';
    case 'guidedLesson':
      switch (lesson.guided_lesson_type) {
        case 'lesson':
          return lesson.title || 'Lesson';
        case 'listen_records':
          return 'Listen and Repeat';
        case 'listen_options':
          return 'Listen and Choose';
        case 'recognising_tools':
          return 'AI Feedback';
        default:
          return '';
      }
    case 'finalAssignment':
      return 'Record the Text Below';
    case 'fluencyActivity':
      return 'Conversational Tips';
    case 'review':
      return 'Review';
    case 'feedback':
      return 'Feedback';
    default:
      return '';
  }
};
/**
 *
 * @param {number} m milliseconds
 * @returns
 */
export const sleep = (m) => new Promise((r) => setTimeout(r, m));
