import React from 'react';

import AudioPlayer from '../../components/CustomAudioPlayer';
import styles from './CoachFeedback.module.css';

const CoachFeedback = (props) => {

    const { coach_reply, audio_records } = props.feedback;

    const AudioResult = ({ id, audioSource }) => (
        <div className={styles.audio}>
            <div className={styles.icon}><img alt='' src='/images/initial-assessment-img1.png' /></div>
            <div className={styles.player}>
                <AudioPlayer id={`aud_${id}`} source={audioSource} />
            </div>
        </div>
    )

    return (
        <div className='container-fluid'>
            <div className={styles.content}>
                <div className={styles.body}>
                    <div className={styles.result}>
                        <span>{coach_reply}</span>
                        <div className={styles.audioResult}>
                            {audio_records.map((audio, index) => <AudioResult key={index} id={index} audioSource={audio.audio_url} />)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CoachFeedback;