import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { API_BASEURL, CF_USER } from '../../../../constants/global';
import { get } from '../../../../util/httpClient';
import { setData } from '../../../../util/localStorageHandler.js';
import FluencyActivity from '../../modules/lesson/FluencyActivity';

import { selectSelectedModule } from '../../../../actions/module/moduleSlice';
import { getModuleDetailService, getModuleList } from '../../../../services/moduleServices';
import DotLoading from '../../../../views/components/DotLoading';
import Button from '../../../../views/components/Button';
import styles from './fluencyActivity.module.css';

import Header from '../../../layouts/Header.js';


const FluencyActivityTest = (props) => {

  let sampleLessonData = {
    "id": 83,
    "title": "Set the Record Straight",
    "description": "Is it better to set the record straight or just let things be?",
    "answer": "Set the record straight",
    "file_type": "video",
    "voice": "/media/5e74e975ece74.mp3",
    "category_id": 3,
    "views": 0,
    "text_to_speech": "There have been a lot of crazy rumors flying around for the last few days, so I'd like to set the record straight once and for all.",
    "icon_url": "https://chatterfox-app-static-storage.s3.amazonaws.com/thumbs/5ea82f5b86d8a.png",
    "gray_icon_url": "https://chatterfox-app-static-storage.s3.amazonaws.com/thumbs/5ea82f6406d9c.png",
    "file_url": "https://chatterfox-app-static-storage.s3.amazonaws.com/media/5ea1e218e703b.mp4",
    "user_recorded_file": "",
    "audio_duration": "",
    "is_completed": false,
    "is_rated": false,
    "voice_url": "https://chatterfox-app-static-storage.s3.amazonaws.com/media/5e74e975ece74.mp3",
    "like_status": false,
    "pivot": {
      "module_id": 10,
      "fluency_activity_id": 83
    }
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const selectedModule = useSelector(selectSelectedModule);
  // Staging Module ID
  // let fluencyModuleID = 49;
  let fluencyModuleID = 83;
  const askToRateMsg = "Would you recommend ChatterFox to others?";
  const finalMsg = "Challenge Has Been Successfully Completed!";
  const askPeriod = 6;
  const yesDaysOff = 180;
  const noDaysOff = 30;
  let daysDiff = 0;

  const [authToken, setAuthToken] = useState('');
  const [askToRate, setAskToRate] = useState(false);
  const [askAllow, setAskAllow] = useState(true);
  const [welcomeNote, setWelcomeNote] = useState(true);
  const [askAnswer, setAskAnswer] = useState("close");
  const [fluencyList, setFluencyList] = useState([]);
  const [fluencyData, setFluencyData] = useState({});
  const [fluencyIndex, setFluencyIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [fluencyFinished, setFluencyFinished] = useState(false);

  useEffect(() => {
    document.body.classList.add("noContact");
  })
  useEffect(() => {
    const today = Date.now();
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const lastYes = urlParams.get('lastYes');
    const lastNo = urlParams.get('lastNo');
    if (lastYes){
      daysDiff = today - lastYes;
      if (daysDiff < (yesDaysOff * 86400000)){
        setAskAllow(false);
      }
    } else if (lastNo){
      daysDiff = today - lastNo;
      if (daysDiff < (noDaysOff * 86400000)){
        setAskAllow(false);
      }
    }
    if(token){
      setAuthToken(token);
      setData({
        api_token: token
      });
      getModuleList().then((res) => {
        const fluencyModule = res.intro_modules.filter(obj => obj.title === "Pronunciation Challenge");
        fluencyModuleID = fluencyModule[0] && fluencyModule[0].id ? fluencyModule[0].id : fluencyModuleID;
        getModuleDetailService(fluencyModuleID).then((res) => {
          if (res && res.module && res.module.fluency_activities){
            setFluencyList(shuffle(res.module.fluency_activities));
            setFluencyIndex(0);
          }
        })
      })
    }
  }, []);

  useEffect(() => {
    if (authToken){
      if (fluencyIndex >= fluencyList.length) {
        setFluencyFinished(true);
        setLoading(false);
        if (askAllow){
          setAskToRate(true);
        }else{
          window.location.replace(`ChatterFoxRating://finish=${askAnswer}`);
        }
        // history.push(`ChatterFoxRating://status=finish`)
      } else {
        setFluencyData(fluencyList[fluencyIndex])
        if ((fluencyIndex+1) % askPeriod == 0 && askAllow && !fluencyFinished){
          setAskToRate(true);
        }
        history.push({
          search: `?index=${fluencyIndex+1}`
        })
        // setActivities(activities+1);
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    }
  }, [fluencyIndex])
  
  const next = (lesson) => {
    setLoading(true);
    setFluencyIndex(fluencyIndex + 1);
  }

  const shuffle = (fluencies) => {
    let currentIndex = fluencies.length,  randomIndex;
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [fluencies[currentIndex], fluencies[randomIndex]] = [
        fluencies[randomIndex], fluencies[currentIndex]];
    }
    return fluencies;
  }

  const yesHandler = () => {
    setAskAllow(false);
    setAskAnswer("yes");
    setAskToRate(false);
    window.location.replace(`ChatterFoxRating://finish=yes`);
  }
  const noHandler = (finish) => {
    setAskAllow(false);
    setAskAnswer("no");
    setAskToRate(false);
    if (finish){
      window.location.replace(`ChatterFoxRating://finish=no`);
    }else{
      window.location.replace(`ChatterFoxRating://finish=none`);
    }
  }
  const closeHandler = () => {
    setAskAllow(false);
    setAskAnswer("close");
    setAskToRate(false);;
  }
    
  const getActivityDetails = async (authToken) => {
    let path = `${API_BASEURL}/get-user-activity`;
    let res = await get(path, authToken);
  };

  return (
    <>
      {/* <Header title="Pronunciation Challenge" backUrl="#" hideMenu={true} /> */}
      {loading && (
        <div className={styles.switcherSpinner}>
          <DotLoading type="elastic" color="#fa6400" />
        </div>
      )
      }
      {welcomeNote && (
        <div className={styles.welcomeNote}>
          <div className={styles.topCircleOne}></div>
          <div className={styles.topCircleTwo}></div>
          <div className={styles.topCircleThree}></div>
          <div className={styles.wnHeader}>
            <h2>Welcome to <span className="highlight">Pronunciation Challenge!</span></h2>
          </div>
          <div className={styles.wnContent}>
            <div className={styles.wnNotice}>
              To capture your pronunciation, please allow microphone permissions.
            </div>
          </div>
          <div className={styles.wnFooter}>
          <Button
                        type="primary"
                        content="Let's Start"
                        // icon="rightArrow"
                        size="large"
                        iconPosition="right"
                        // isLoading={isLoading}
                        onClick={() => setWelcomeNote(false)}
                      />
          </div>
        </div>
      )
      }
      <div className="app-content content">
        <div className={`${styles.wrapperNoPadding} content-wrapper`}>
          <section className="unlock-membership for-purchase-history">
            <div className="container">
              <div className="row">
              {!fluencyFinished ? (
                <FluencyActivity
                  moduleId= {fluencyModuleID} // {fluencyData && fluencyData.id} 
                  moduleCode = ''
                  lessonName= "fluencyActivity" //{currentLesson.lesson.lesson_type}
                  lessonData={fluencyData ? fluencyData : sampleLessonData}
                  allLessonData={fluencyData ? fluencyData : sampleLessonData}
                  // nextFire={nextFire}
                  next={next}
                  module={ {intro_module : null }}
                  // backToHome={endMessageHandleBackToHome}
                />
              ):(
                <div className={styles.finishedBox}>
                  <span class={styles.finalMsg}>{ finalMsg }</span>
                  {askAnswer == 'close' || askAllow ? (
                  <div class={styles.finalRateBox}>
                    <div className={styles.modalCloseBtn} onClick={() => noHandler(false)}></div>
                    <span className={styles.finalRateMsg}>
                      { askToRateMsg }
                    </span>
                    <div class={styles.finalBtnBox}>
                    <Button
                        type="primary"
                        content="Yes, I will"
                        // icon="rightArrow"
                        size="large"
                        iconPosition="right"
                        // isLoading={isLoading}
                        onClick={() => yesHandler()}
                      />
                      <Button
                        type="link"
                        content="I'm not Sure"
                        // icon="refresh"
                        size="medium"
                        iconPosition="left"
                        // isLoading={isLoading}
                        onClick={() => noHandler(true)}
                      />
                    </div>
                  </div>
              ) : (
                <div class={styles.finalBtnBox}>
                  <Button
                      type="primary"
                      content="Close"
                      // icon="rightArrow"
                      size="large"
                      iconPosition="right"
                      // isLoading={isLoading}
                      onClick={() => noHandler(false)}
                    />
                  </div>
              )}
                </div>)}
              </div>
            </div>
          </section>
        </div>
      </div>
      {askToRate && (
        <div className={styles.modal}>
          <div className={styles.modalCover}></div>
          <div className={styles.modalContainer}>
            <div className={styles.modalCloseBtn} onClick={() => closeHandler()}></div>
            <div className={styles.modalContent}>{askToRateMsg}</div>
            <div className={styles.modalFooter}>
              <Button
                type="primary"
                content="Yes, I will"
                // icon="rightArrow"
                size="medium"
                iconPosition="right"
                // isLoading={isLoading}
                onClick={() => yesHandler()}
              />
              <Button
                type="secondary"
                content="I'm not Sure"
                // icon="refresh"
                size="medium"
                iconPosition="left"
                // isLoading={isLoading}
                onClick={() => noHandler(true)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
// export default withAuthorization(condition)(FluencyActivity);
export default FluencyActivityTest;