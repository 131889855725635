import { API_BASEURL } from '../constants/global';
import { post, get } from '../util/httpClient';
import { getData } from '../util/localStorageHandler.js';

export const generateAnonymousId = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/generate-anonymous-id`;
  return get(path, token);
};

export const getInitialAssessment = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/initial-assessments`;
  return get(path, token);
};

export const getInitialAssessmentV2 = async (anonymousUserId) => {
  let path = `${API_BASEURL}/v2/initial-assessments`;
  return get(path, null, { anonymousUserId });
};

export const uploadInitialAssessmentRecord = async (data) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/upload-initial-assessment-record`;
  return post(path, data, token, { 'Content-Type': 'multipart/form-data' });
};

export const uploadInitialAssessmentRecordV2 = async (data) => {
  let path = `${API_BASEURL}/v2/upload-initial-assessment-record`;
  return post(path, data, null, { 'Content-Type': 'multipart/form-data' });
};

export const uploadAIServer = async (data) => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/upload-ai-server`;
  return post(path, data, token);
};

export const uploadAIServerV2 = async (data) => {
  let path = `${API_BASEURL}/v2/upload-ai-server`;
  return post(path, data, null);
};

export const getInitialAssessmentStatus = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/initial-assessment-status`;
  return get(path, token);
};

export const getAICoachFeedback = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/get-ai-coach-feedback`;
  return get(path, token);
};

export const getHumanCoachFeedback = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/get-human-coach-feedback`;
  return get(path, token);
};

export const getPersonalizedTrainingPlan = async () => {
  const _data = getData();
  const token = _data ? _data.api_token : undefined;

  let path = `${API_BASEURL}/personalized-training-plan`;
  return get(path, token);
};
