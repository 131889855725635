import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import Modal from '../../components/Modal';
import styles from './Intro.module.css';
import globalStyles from './InitialAssessment.module.css';
import { getData } from '../../../util/localStorageHandler';
import Segment from '../../../segment';

const Intro = (props) => {
  const [show, setShow] = useState(false);
  const authUser = getData();

  useEffect(() => {
    if (authUser) Segment.track('AssInStPageVisited');
    else if (!props.end) Segment.track('AssOutStPageVisited');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSkipClick = () => {
    props.onSkip();
  };

  const handleStart = () => {
    if (authUser) Segment.track('AssInStarted');
    else if (!props.end) Segment.track('AssOutStarted');
    props.onStart();
  };

  return (
    <div
      className={`${globalStyles.contentContainer} ${globalStyles.edgeSpace} ${globalStyles.waveBg} ${styles.contentContainer}`}
    >
      <div>
        {!props.end && <div className={globalStyles.subTitle}>Fluency Assessment</div>}
        {props.end ? (
          <div className={globalStyles.title}>
            You have finished the fluency assessment. Create your account to see your results.
          </div>
        ) : (
          <div className={globalStyles.title}>
            You'll be asked to say a few words and read a few sentences. We'll then give you feedback on some areas you
            can improve.
          </div>
        )}
        <div className={styles.btnGroup}>
          <Button
            // type="gold"
            content={props.end ? 'Sign Up' : 'Start'}
            onClick={handleStart}
            isLoading={props.loading}
          />
          {props.isAuthUser && !props.end && <Button type="link" content="Not Now" onClick={() => setShow(true)} />}
        </div>

        <Modal
          show={show}
          iconSrc="/images/attentionIcon.png"
          title="Are you sure you want to skip?"
          message="Taking this assessment will give you access to a personalized learning plan prepared by a human pronunciation coach."
          yesText="Stay"
          noText="Skip"
          noClick={onSkipClick}
          handleClose={() => setShow(false)}
          className="initial-assessment"
        />
      </div>
    </div>
  );
};

Intro.propTypes = {
  end: PropTypes.bool,
  isAuthUser: PropTypes.bool,
  onSkip: PropTypes.func,
  onStart: PropTypes.func,
  loading: PropTypes.bool,
};

export default Intro;
