import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CF_USER } from '../../../constants/global';
import { useHistory } from 'react-router-dom';
import withAuthorization from '../../../Session/withAuthorization';

import { selectUser } from '../../../actions/user/userSlice';
import { successPayment } from '../../../services/paymentServices';
import ErrorBoundary from '../../errorBoundaries/ErrorBoundary';

const PaymentSuccess = (props) => {
  let path = props.location.pathname;
  let currentPath = path.split('/');
  let history = useHistory();
  let package_name =
    history && history.location && history.location.state && history.location.state.package_name
      ? history.location.state.package_name
      : '';
  const [content, setContent] = useState([]);
  const userData = useSelector(selectUser);

  useEffect(() => {
    let token = userData.api_token;
    successPayment(token).then((res) => {
      // res == {"isError":0,"errorCode":0,"data":{"title":"Confirmation !","content":"<p>Congratulations on becoming a {package_name}! Within 5 minutes, you will receive your login credentials by email (password and login link). If you do not receive your login information, please be sure to check your spam and promotions tabs\/folders. If you still do not see your login information, click <a href=\"https:\/\/web.chatterfox.com\/signin\">here<\/a> to &quot;reset the password,&quot; which will request that login information be sent again. Thanks for using ChatterFox!<\/p>","image":"https:\/\/chatterfox-development-bucket.s3.amazonaws.com\/thumbs\/6035064d76d2a.png","video_url":"https:\/\/chatterfox-development-bucket.s3.amazonaws.com\/media\/6035069f18bf9.mp4"}}
      setContent(res.data);
    });

    //it allways scroll to top when lesson get change
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    var body = document.body;
    body.classList.add('yellow-back');
  }, []);

  const redirectTo = (path, data = {}, query = '') => {
    if (path && path != '') {
      history.push({
        pathname: path,
        search: query,
        state: data,
      });
    }
  };

  const createMarkup = (htmlText) => {
    console.log('package_name', package_name);
    if (htmlText) {
      return {
        __html: htmlText,
      };
    } else {
      return {
        __html: '<div></div>',
      };
    }
  };

  const injectPlanName = (htmlText) => {
    if (htmlText) {
      let finalHtmlText = htmlText.replace(`{package_name}`, package_name);
      return finalHtmlText;
    } else {
      return '';
    }
  };

  return (
    <>
      <div className="app-content content p-0 col-lg-12 external-theme text-center pb-3">
        <section className="payment-process p-0 text-center base shadow">
          <div className="col-lg-12 pt-0 pb-3 text-center">
            <div className="row">
              <div className="col-lg-12 pt-0 ">
                <h2 className="orange-header pt-3">
                  {content ? content.title : 'Confirmation !'}
                  <img
                    style={{ width: 40, position: 'relative', top: -5 }}
                    src={process.env.PUBLIC_URL + '/images/birthday.png'}
                  />
                </h2>
                {/* <h5 className="p-2 small-head">{"Congratulations on becoming a " + package_name + " member. Watch the video carefully to find the next step."} </h5> */}
                <ErrorBoundary>
                  <h5
                    className="p-2 small-head"
                    dangerouslySetInnerHTML={createMarkup(injectPlanName(content.content))}
                  />
                </ErrorBoundary>
                <video
                  width=""
                  poster={content ? content.image : ''}
                  className="mw-100 payment-sucess-video"
                  id="video_frame"
                  src={content ? content.video_url : 'https://vimeo.com/515299081/4f64c91ec7'}
                  controls
                ></video>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(PaymentSuccess);
