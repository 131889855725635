import React, { useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';

const AudioPlayer = (props) => {
  let title = props.title;
  let duration = props.duration ? props.duration : '';
  let subTitle = props.subTitle ? props.subTitle : '';
  let audioSource = props.source;
  let customImage = props.customImage ? props.customImage : process.env.PUBLIC_URL + '/images/rc-ic.png';
  let playImage = props.playImage ? props.playImage : process.env.PUBLIC_URL + '/images/play.png';
  let rap;
  const [isPlaying, setIsPlaying] = useState(false);

  // const [state, setState] = useState({
  //   source: props.source,
  //   isPlaying: false
  // });

  useEffect(() => {
    setIsPlaying(false);
  }, [audioSource]);

  return (
    <div className="col-sm-12 p-0">
      <div className="intro-video-sec mb-0">
        <ReactAudioPlayer
          src={audioSource}
          ref={(element) => {
            rap = element;
          }}
          autoPlay={false}
          controls={false}
          onPlay={() => {
            setIsPlaying(true);
          }}
          onEnded={() => {
            setIsPlaying(false);
          }}
          onPause={() => {
            setIsPlaying(false);
          }}
        />
        <ul>
          <li>
            {!isPlaying ? (
              <a className="theme-circle" href="JavaScript:void(0);" onClick={() => rap.audioEl.current.play()}>
                <img src={playImage} />
              </a>
            ) : (
              <a className="theme-circle" href="JavaScript:void(0);" onClick={() => rap.audioEl.current.pause()}>
                <img src={process.env.PUBLIC_URL + '/images/pause.png'} />
              </a>
            )}
          </li>
          <li>
            {title ? <strong className="d-block mb-0">{title}</strong> : null}
            {subTitle ? <span className="mb-1 player-subtitle">{subTitle}</span> : null}
            {duration ? <span className="d-block">{'Duration: ' + duration}</span> : null}
          </li>
          <li> {customImage == 'noImg' ? null : <img src={customImage} width={50} />} </li>
        </ul>
      </div>
    </div>
  );
};

export default AudioPlayer;
