import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser, setUser } from '../../../actions/user/userSlice';
import { getUserSubscription } from '../../../services/moduleServices';
import { CF_USER } from '../../../constants/global';
import withAuthorization from '../../../Session/withAuthorization';
import { getData } from '../../../util/localStorageHandler.js';
import ModuleView from './ModuleView';
import Banner from '../initialAssessment/Banner';
import styles from './Modules.module.css';

const Modules = () => {
  return (
    <div className={styles.cfModuleBody}>
      <div className={styles.cfModuleContent}>
        <Banner />

        <section className="introduction-module-list">
          <ModuleView />
        </section>
      </div>
    </div>
  );
};

Modules.propTypes = {};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(Modules);
