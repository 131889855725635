import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import _ from 'lodash';

import { setUser } from '../../../actions/user/userSlice';
import { API_BASEURL } from '../../../constants/global';
import Segment from '../../../segment';
import { applyRefferalCode, internalLogin, sendOTP } from '../../../services/authServices';
import { post } from '../../../util/httpClient';
import ActionLink from '../../components/ActionLink';
import LoginTemplate from '../../components/Templates/LoginTemplate';
import styles from './EmailVerification.module.css';
import { FloatingLabel, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/Button';

const EmailVerification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  const SignupSchema = Yup.object().shape({
    otp: Yup.string().required('Enter the temporary code which sent to your email.'),
  });

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      if (values.otp) {
        verifyEmail(email, values.otp);
      }
    },
  });

  useEffect(() => {
    const coupon = _.get(history, 'location.state.coupon', '');
    const email = _.get(history, 'location.state.email', '');
    const password = _.get(history, 'location.state.password', '');

    if (!email || !password) {
      history.push('/signup');
    } else {
      setCoupon(coupon);
      setEmail(email);
      setPassword(password);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendOtp = async () => {
    Segment.track('Retry Login Token', { email });
    setLoading(true);
    try {
      let result = await sendOTP(email);
      Swal.fire('Success', result.message ? result.message : 'OTP has been sent.', 'success');
    } catch (error) {
      Swal.fire('Error', error.message ? error.message : 'Some error occurred. Please try again.', 'error');
    }
    setLoading(false);
  };

  const verifyEmail = async (email, otp) => {
    let isValid = true;
    setLoading(true);
    if (isValid) {
      const url = `${API_BASEURL}/verify-email`;
      const dataToSend = { email, otp };
      try {
        // Verify email
        const response = await post(url, dataToSend);
        if (response && !response.isError) {
          localStorage.removeItem('anonymousUserId');
          // // Need internal login to apply coupon code.
          const userData = await internalLogin(email, password);
          dispatch(setUser(userData));
          // Analytics: identify
          const aUser = {
            email: _.get(userData, 'user.email', ''),
            name: _.get(userData, 'user.name', ''),
            country: _.get(userData, 'user.country', ''),
            gender: _.get(userData, 'user.gender', ''),
            age: _.get(userData, 'user.birthday', ''),
            nativeLanguage: _.get(userData, 'user.native_language', ''),
          };
          Segment.identify(aUser.email, aUser);
          Segment.track('WebLogin', {}, userData.api_token);

          // Apply Coupon if any
          if (coupon) {
            try {
              const authToken = userData.api_token;
              const serverRes = await applyRefferalCode(coupon, authToken);
            } catch (error) {
              console.log('can not apply coupon');
            }
          }
          setLoading(false);
          history.push({
            pathname: '/modules',
            state: {
              firstLogin: true,
            },
          });
        } else {
          Swal.fire('', response.message);
          setLoading(false);
        }
      } catch (error) {
        Swal.fire('Error', error.message ? error.message : 'Some error occurred. Please try again.', 'error');
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      verifyEmail();
    }
  };

  return (
    <LoginTemplate>
      <h1>
        <span className="cf-t-highlight">Verify</span> your Email
      </h1>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className={`${styles.form} cf-t-form`}>
        <span className={styles.circle} onClick={() => history.push('/signup')}>
          <FontAwesomeIcon className={styles.back} icon={faChevronLeft} />
        </span>
        <form onSubmit={formik.handleSubmit}>
          <p className="text-center mb-2">Please check your email and enter the one time verification code below:</p>
          <FloatingLabel controlId="floatingEmail" label="Verification code">
            <Form.Control
              isInvalid={formik.touched.otp && !!formik.errors.otp}
              disabled={isLoading}
              required
              placeholder="Verification code"
              name="otp"
              value={formik.values.otp}
              onChange={formik.handleChange}
              autoComplete="new-password"
            />
            <Form.Control.Feedback type="invalid">{formik.errors.otp}</Form.Control.Feedback>
          </FloatingLabel>
          <Button content="Verify" submit={true} isLoading={isLoading} onClick={formik.handleSubmit} />
          <p className="text-center">
            Didn't get the verification code? <ActionLink caption="Resend" onClick={resendOtp} />
          </p>
        </form>
      </div>
    </LoginTemplate>
  );
};

EmailVerification.propTypes = {};

export default EmailVerification;
