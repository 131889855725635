import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './PlayCard.module.css';

import MicRecorder from 'mic-recorder-to-mp3';
import RecordRTC from 'recordrtc';
import { ProgressBar } from 'react-bootstrap';
import AudioPlayer from '../CustomAudioPlayer';
import { isIOS } from 'react-device-detect';

const PlayCard = (props) => {
  const { topText, bottomText, isLoading } = props;
  const progressCurrent = props.progressCurrent || 0;
  const progressTotal = props.progressTotal || 0;
  const word = props.word || 'Undefined';
  const audio = props.audio;
  const pattern = props.pattern || '/images/patterns/waveBgWhite.svg';
  const bgColor = props.bgColor || '#fa6400';
  const [recording, setRecording] = useState(false);
  const timeoutRef = useRef();
  const mediaStreamRef = useRef();
  const recorderRef = useRef();
  const startTimeRef = useRef();

  const playCardProgress = `
       .playCardProgress .progress-bar{
            background-color: ${bgColor} !important;
       }
  `;

  useEffect(() => {
    if (!isIOS)
      recorderRef.current = new MicRecorder({
        bitRate: 128,
      });
  }, []);

  const toggleRecord = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (!recording)
      timeoutRef.current = setTimeout(() => {
        stopRecording();
      }, 45000);

    if (recording) stopRecording();
    else startRecording();
  };

  const startRecording = async () => {
    if (isIOS) {
      // RTC
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
      recorderRef.current = new RecordRTC(mediaStreamRef.current, {
        type: 'audio',
        // recorderType: StereoAudioRecorder,
        sampleRate: 44100,
        bufferSize: 4096,
        numberOfAudioChannels: 1,
      });
      recorderRef.current.startRecording();
      startTimeRef.current = Date.now();
      setRecording(true);
    } else {
      // MP3
      recorderRef.current
        .start()
        .then(() => {
          startTimeRef.current = Date.now();
          setRecording(true);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const stopRecording = () => {
    if (isIOS) {
      // RTC
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current.getBlob();
        setRecording(false);
        props.onRecordStop({
          blob,
          startTime: startTimeRef.current,
          stopTime: Date.now(),
          blobURL: window.URL.createObjectURL(blob),
        });
        mediaStreamRef.current.stop();
      });
    } else {
      // MP3
      recorderRef.current
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setRecording(false);
          props.onRecordStop({
            blob,
            startTime: startTimeRef.current,
            stopTime: Date.now(),
            blobURL: window.URL.createObjectURL(blob),
          });
        })
        .catch((e) => {
          alert('We could not retrieve your message');
          console.error(e);
        });
    }
  };

  return (
    <div className={`${styles.cardsHolder} ${styles.playCard} playCardProgress`}>
      <style>{playCardProgress}</style>
      <div className="progressBar">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-lg"
          viewBox="0 0 16 16"
          data-toggle="modal"
          data-target="#skipModal"
        >
          <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
        </svg>
        <ProgressBar variant="gray" now={(progressCurrent / progressTotal) * 100} />
      </div>
      {topText && <div className={`${styles.topText} audioPlayer-topText`}>{topText}</div>}
      <div
        style={{ background: `url('${pattern}') ${bgColor} center` }}
        className={`${styles.body} ${props.textPosition == 'center' && styles.center} ${
          word.length > 40 && styles.sentence
        }`}
      >
        <div className={styles.audioContent}>
          {audio && !props.recordAndPlay && (
            <AudioPlayer
              className="lite"
              id={1}
              source={audio}
              hideBar={true}
              playIcon={'/images/audio/speakerIcon.svg'}
              pauseIcon={'/images/audio/pauseIcon.svg'}
            />
          )}
          <span>{word ? word : ''}</span>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={`${styles.soundBtn} ${styles.record} ${isLoading && styles.unVisible}`}>
            {audio && props.recordAndPlay ? (
              <AudioPlayer mode={'client'} className={'capsule'} id={1} source={audio} hideBar={true} />
            ) : (
              <img
                alt=""
                src={recording ? '/images/audio/stopRecord.svg' : '/images/audio/icon-main-action-record.svg'}
                onClick={toggleRecord}
              />
            )}
        </div>
      </div>
      {bottomText && <div className={`${styles.bottomText} audioPlayer-bottomText`}>{bottomText}</div>}
    </div>
  );
};

PlayCard.propTypes = {
  className: PropTypes.string,
  onRecordStop: PropTypes.func,
  recordComplete: PropTypes.func,
  word: PropTypes.string,
  audio: PropTypes.any,
  pattern: PropTypes.string,
  bgColor: PropTypes.string,
  progressCurrent: PropTypes.number,
  progressTotal: PropTypes.number,
  topText: PropTypes.string,
  bottomText: PropTypes.string,
  recordAndPlay: PropTypes.bool,
  textPosition: PropTypes.string,
};

export default PlayCard;
