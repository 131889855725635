import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { selectModule } from '../../../actions/module/moduleSlice';
import withAuthorization from '../../../Session/withAuthorization';
import { CF_USER } from '../../../constants/global';
import { selectUser, setMenu } from '../../../actions/user/userSlice';
import ModuleGroup from './ModuleGroup';
import Modal from '../../components/Modal';
// import ModalVideoPlayer from '../../components/ModalVideoPlayer';
import { Accordion } from 'react-bootstrap';
import {
  selectInitialAssessment,
  setPersonalizedTrainingPlan,
} from '../../../actions/initialAssessment/initialAssessmentStore';
import { getPersonalizedTrainingPlan } from '../../../services/initialAssessmentServices';
import styles from './ModuleView.module.css';
import Segment from '../../../segment';

const ModuleView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(selectUser);
  const module = useSelector(selectModule);
  const initialAssessment = useSelector(selectInitialAssessment);
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const initialAssessmentModule =
    _.get(userData, 'is_subscribed', false) && initialAssessment.status.is_user_completed
      ? null
      : {
          id: 'initialAssessment',
          icon_url: process.env.PUBLIC_URL + '/images/icon/analytics.svg',
          title: 'Fluency Assessment',
          sub_title: 'Take our free personal assessment',
          score: 0,
          user_progress: 0,
          is_unlocked: true,
          is_feed_back_avilable: initialAssessment.status.is_user_completed,
        };

  useEffect(() => {
    getPersonalizedTrainingPlan().then((res) => {
      if (res && !res.isError && !res.errorCode) {
        res.data.sort((a, b) => {
          return a.user_progress - b.user_progress;
        });
        dispatch(setPersonalizedTrainingPlan(res.data));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToSubscription = () => {
    dispatch(setMenu(`subscription`));
    redirectTo(`/subscription`);
  };

  const redirectTo = (path, data = {}) => {
    if (path && path !== '') {
      history.push({
        pathname: path,
        state: data,
      });
    }
  };

  const handleLockModuleClicked = () => {
    Segment.track('InActiveMoClick');
    setShowUnlockModal(true);
  };

  const gotoSalesPage = () => {
    Segment.track('CTAInActiveMo');
    window.open('https://chatterfox.com/american-smart-fluency-program-payment/', '_self');
  };

  const initialAssessmentNotGenerated = [
    {
      id: 10,
      title: 'The American Dream ',
      sub_title: null,
      icon_url: 'https://chatterfox-app-dev-static-storage.s3.amazonaws.com/thumbs/5da73464d89f3.png',
    },
    {
      id: 9,
      title: 'Drowning in Debt',
      sub_title: 'T  and D sound',
      icon_url: 'https://chatterfox-app-dev-static-storage.s3.amazonaws.com/thumbs/5da7376a4fd12.png',
    },
    {
      id: 16,
      title: 'Universal Basic Income',
      sub_title: null,
      icon_url: 'https://chatterfox-app-dev-static-storage.s3.amazonaws.com/thumbs/5daa09a017854.jpg',
    },
  ];

  const Separator = (props) => {
    return (
      <div className={styles.separator}>
        <div className={styles.separatorTitle}>
          <span className={styles.line}></span>
          <span>{props.title}</span>
          <span className={styles.line}></span>
        </div>
        <div className={styles.separatorSubtitle}>
          <span>{props.subtitle}</span>
        </div>
      </div>
    );
  };

  Separator.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
  };

  return (
    <>
      {module.introModules.length || initialAssessmentModule ? (
        <ModuleGroup
          noTitle
          isStatic
          id="One"
          type="IntroductionModule"
          title="Sample Lessons"
          modules={[...module.introModules, { ...initialAssessmentModule }]}
          showUnlockModal={handleLockModuleClicked}
        />
      ) : null}
      <Separator
        title="Your Study Plan"
        subtitle="We analyzed your recordings, and determined that these modules are your top learning priorities"
      />
      <ModuleGroup
        noTitle
        isStatic
        id="Zero"
        disabled={_.get(initialAssessment, 'personalizedTrainingPlan.length', 0) === 0}
        disabledNote="Please Complete Your Personal Assessment to View Your Training Plan"
        type="PersonalizedTrainingPlan"
        title="Your Study Plan"
        modules={
          _.get(initialAssessment, 'personalizedTrainingPlan.length', 0) !== 0
            ? initialAssessment.personalizedTrainingPlan
            : initialAssessmentNotGenerated
        }
        showUnlockModal={handleLockModuleClicked}
      />
      <Separator title="Module List" subtitle="All of the modules you can study" />
      <Accordion defaultActiveKey={module.trialModules.length ? 'Two' : 0}>
        {module.trialModules.length ? (
          <ModuleGroup
            id="Two"
            type="TrialModule"
            title="Trial Module"
            modules={module.trialModules}
            showUnlockModal={handleLockModuleClicked}
          />
        ) : null}
        {module.othModules.length
          ? module.othModules.map((moduleGroup, index) => (
              <ModuleGroup
                key={index}
                id={index}
                type="OtherModule"
                title={moduleGroup.title}
                modules={moduleGroup.new_modules || moduleGroup.modules}
                showUnlockModal={handleLockModuleClicked}
              />
            ))
          : null}
      </Accordion>

      <Modal
        show={showUnlockModal}
        title="Subscribe to unlock lessons"
        message="Subscribe Now to unlock lessons and get coaching feedback"
        yesText="Subscribe Now"
        iconSrc="/images/coachingIcon.png"
        yesClick={gotoSalesPage}
        handleClose={() => setShowUnlockModal(false)}
      />

      <Modal
        modalId="coachWaitingModal"
        message="Your coach will give you personalized feedback and tell you how you pronounce well, how to improve your pronunciation, and correct your mispronunciation. You should have Green Card Plan to get feedback from the Coach."
        yesText="Got it"
        yesClick={(e) => e.stopPropagation()}
        className="initial-assessment"
      />

      {/* <ModalVideoPlayer modalId="intro-video" /> */}
    </>
  );
};

const condition = (authUser) => authUser && authUser.user.role_id === CF_USER;
export default withAuthorization(condition)(ModuleView);
