import axios from 'axios';
import { logOut } from '../services/authServices';

export async function post(url, dataToSend = {}, authToken = null, _headers = {}, withCredentials = true) {
  if (url) {
    try {
      let headers = {
        'Content-Type': 'application/json',
        ..._headers,
      };

      if (authToken) headers['Authorization'] = `Bearer ${authToken}`;
      let response = await axios.post(url, dataToSend, { withCredentials, headers });
      if (response && response.data) {
        if (response.data.errorCode == 401) {
          logOut();
          return null;
        } else {
          return response.data;
        }
      }
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}

export async function postToSmartCoachServer(url, dataToSend = {}, authToken = null) {
  if (url) {
    try {
      let headers = {
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
      };

      if (authToken) headers['Authorization'] = `Basic ${authToken}`;
      let response = await axios.post(url, dataToSend, { headers });
      if (response && response.data) {
        if (response.data.errorCode == 401) {
          logOut();
          return null;
        } else {
          return response.data;
        }
      }
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}

export async function get(url, authToken = null, params = null, _headers = {}, withCredentials = true) {
  if (url) {
    try {
      let requestConfig = {
        withCredentials,
        headers: {
          'Content-Type': 'application/json',
          ..._headers,
        },
      };
      if (authToken) requestConfig.headers['Authorization'] = `Bearer ${authToken}`;
      if (params) requestConfig.params = params;

      let response = await axios.get(url, requestConfig);
      if (response && response.data) {
        if (response.data.errorCode == 401) {
          logOut();
          return null;
        } else {
          return response.data;
        }
      }
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}

/**
 *
 * @param {File} file File to be uploaded
 * @param {String} uploadLocation Loctaion where file to be uploaded
 * @param {Object} dataToSend Other optional data
 * @param {function} progressCallBack Callback function for progerss of upload process. Provide object like this { total: number, tillNow: number, percentage: number }
 * @returns {Object} {isError: boolean, message: string, url: string}
 */
export async function postFile(file, uploadLocation, dataToSend = {}, progressCallBack = function () {}) {
  let url = 'https://us-central1-offerservicez-3d273.cloudfunctions.net/uploadImage';
  if (url && file && uploadLocation) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('bucketName', uploadLocation);
    dataToSend &&
      Object.keys(dataToSend).map((key) => {
        formData.append(key, dataToSend[key]);
      });
    try {
      let response = await axios.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          let tillNow = progressEvent.loaded;
          let total = progressEvent.total;
          let percentage = parseInt(Math.round((tillNow / total) * 100));
          progressCallBack({ total, tillNow, percentage });
        },
      });
      if (response && response.data && !response.data.isError && response.data.url) {
        return response.data.url;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}

export async function postFileData(url, dataToSend = {}, authToken = null) {
  if (url) {
    try {
      let headers = {
        //'Content-Type': 'multipart/form-data'
      };

      if (authToken) headers['Authorization'] = `Bearer ${authToken}`;
      let response = await axios.post(url, dataToSend, { headers });
      if (response && response.data) {
        if (response.data.errorCode == 401) {
          logOut();
          return null;
        } else {
          return response.data;
        }
      }
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}
