import { createSlice } from '@reduxjs/toolkit';
import { setData, getData } from '../../util/localStorageHandler';
import _ from 'lodash';

export const lessonStore = createSlice({
  name: 'lessons',
  initialState: {
    data: [],
    selectedLesson: undefined,
  },

  reducers: {
    setLessonStore: (state, action) => {
      state.data = action.payload;
      let userDataFromLocalStorage = getData();
      _.set(userDataFromLocalStorage, 'allModulesData', action.payload);
      setData(userDataFromLocalStorage);
    },
    setSelectedLesson: (state, action) => {
      state.selectedLesson = action.payload;
    },
  },
});

//this is distach action function
export const { setLessonStore, setSelectedLesson } = lessonStore.actions;

// The function below is called a thunk and allows us to perform async logic. It
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const setLessonStoreAsync = (lessons) => (dispatch) => {
  setTimeout(() => {
    dispatch(setLessonStore(lessons));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectLessonStore = (state) =>
  state.lessons.data.length > 0 ? state.lessons.data : getData().allModulesData;
export const selectSelectedLesson = (state) => state.lessons.selectedLesson;

export default lessonStore.reducer;

/**
 * https://redux-toolkit.js.org/api/createslice
 */
