import { API_BASEURL } from '../constants/global';
import OneSignal from 'react-onesignal';
import { post, get } from '../util/httpClient';
import { getData, setData, setSessionData } from '../util/localStorageHandler';

// api/facebook-web-login
// api/google-web-login

export async function internalLogin(email, password) {
  return new Promise(async (resolve, reject) => {
    let url = `${API_BASEURL}/login`;
    let dataToSend = { email, password };
    try {
      // Register user
      let response = await post(url, dataToSend);
      console.log(response);
      if (response && !response.isError) {
        resolve(response.data);
      } else {
        reject({
          message: response && response.message ? response.message : 'Some error occurred. Please try again.',
        });
      }
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.' });
    }
  });
}

export async function loginWithCaptcha(email, password, reCaptchaToken) {
  return new Promise(async (resolve, reject) => {
    //let url = `${API_BASEURL}/web-login`;
    let url = `${API_BASEURL}/web-portal-login`;
    let dataToSend = { email, password };
    if (reCaptchaToken) {
      dataToSend['g-recaptcha-response'] = reCaptchaToken;
    }
    try {
      // Register user
      let response = await post(url, dataToSend);
      console.log(response);
      if (response && !response.isError) {
        resolve(response.data);
      } else {
        reject({
          message: response && response.message ? response.message : 'Some error occurred. Please try again.',
          show_captcha: response.show_captcha,
        });
      }
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.' });
    }
  });
}

export async function checkCouponValidity(coupon) {
  return new Promise(async (resolve, reject) => {
    let url = `${API_BASEURL}/isvalid-coupon`;
    let dataToSend = { coupon };
    try {
      // Check coupon validity
      let response = await post(url, dataToSend);
      console.log(response);
      if (response && !response.isError && response.is_valid_coupon) {
        resolve(true);
      } else {
        reject({ message: response && response.message ? response.message : 'Code is not valid' });
      }
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.' });
    }
  });
}

export async function applyRefferalCode(refferal_code, authToken) {
  return new Promise(async (resolve, reject) => {
    let url = `${API_BASEURL}/update-refferal-code`;
    let dataToSend = { refferal_code };
    try {
      // Apply Coupon if any
      let response = await post(url, dataToSend, authToken);
      console.log(response);
      if (response && !response.isError) {
        resolve({ message: response.message ? response.message : 'Refferal code has been added.' });
      } else {
        reject({
          message: response && response.message ? response.message : 'Some error occurred. Please try again.',
        });
      }
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.' });
    }
  });
}

export async function sendOTP(email) {
  return new Promise(async (resolve, reject) => {
    let url = `${API_BASEURL}/resend-otp`;
    let dataToSend = { email };
    try {
      // sendOTP
      let response = await post(url, dataToSend);
      console.log(response);
      if (response && !response.isError) {
        resolve({ message: response.message });
      } else {
        reject({
          message: response && response.message ? response.message : 'Some error occurred. Please try again.',
        });
      }
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.' });
    }
  });
}

export async function sendPasswordResetOTP(email) {
  return new Promise(async (resolve, reject) => {
    let url = `${API_BASEURL}/send-password-reset-token`;
    let dataToSend = { email };
    try {
      // sendOTP
      let response = await post(url, dataToSend);
      console.log(response);
      if (response && !response.isError) {
        resolve({ message: response.message });
      } else {
        reject({
          message: response && response.message ? response.message : 'Some error occurred. Please try again.',
        });
      }
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.' });
    }
  });
}

export async function isUserLoggedIn(history) {
  let authUser = getData();
  if (authUser && authUser.api_token && authUser.user.email && authUser.user.id) {
    history.push('/modules');
  }
}

export async function logoutUser() {
  return new Promise((resolve, reject) => {
    let url = `${API_BASEURL}/web-logout`;
    let userDataFromLocalStorage = getData();
    try {
      // Register user
      get(url, userDataFromLocalStorage.api_token).then((response) => {
        console.log(response);
        if (response && !response.isError) {
          resolve(response.data);
        } else {
          resolve(response);
          //reject({ message: response && response.message ? response.message : 'Some error occurred. Please try again.', error:response });
        }
      });
    } catch (e) {
      reject({ message: 'Some error occurred. Please try again.', error: e });
    }
  });
}

export async function logOut() {
  logoutUser().then((res) => {
    setData('');
    setSessionData('');
    OneSignal.removeExternalUserId();
    window.location = '/';
  });
}
