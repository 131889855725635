import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { isMobile } from 'react-device-detect';

import AudioPlayer from '../../../components/AudioPlayer';
import { reviewModule, deleteFinalRecord } from '../../../../services/moduleServices';
import { getData } from '../../../../util/localStorageHandler.js';

const ReviewRecord = (props) => {
  let history = useHistory();
  let lesson = props.lessonData;
  let userData = '';
  let backUrl = props.backUrl;
  const [reviewData, setReviewData] = useState(null);

  useEffect(() => {
    userData = getData();
    let dataToSend = {
      module_id: lesson.module_id,
    };
    reviewModule(userData.api_token, dataToSend).then((res) => {
      console.log('review record response...', res);
      setReviewData(res.data);
    });
  }, []);

  const deleteRecord = (id) => {
    confirmAlert({
      title: 'Confirm to cancel',
      message: 'Are you sure to do this.',
      overlayClassName: `${isMobile && 'mobile'}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteFinalRecordData(id),
        },
        {
          label: 'No',
          onClick: () => console.log('hello'),
        },
      ],
    });
  };

  const deleteFinalRecordData = (id) => {
    userData = getData();
    let dataToSend = {
      module_id: lesson.module_id,
      assessment_id: id,
    };
    deleteFinalRecord(userData.api_token, dataToSend).then((res) => {
      console.log('delete record response...', res);
      if (res.isError != 1) {
        //window.location.reload();
      }
    });
  };

  const gotoModule = (path, data = {}) => {
    history.push({
      pathname: path,
      state: data,
    });
  };

  console.log('Rivew lesson....', lesson);

  return (
    <section className="introduction">
      <div className="container">
        {reviewData ? (
          <>
            <div className="unlock-bg justify-content-between review-card">
              <div className="figcaption mr-1 col-lg-7 pl-0 vertical-center">
                <img className="img-rounded p-0 img-fluid col-sm-3" src={reviewData.module.icon_url} />
                <h1 className="pl-1 d-inline-block">{reviewData.module.title ? reviewData.module.title : ''}</h1>
              </div>
              <a href="JavaScript:Void(0);">{'Module code:' + reviewData.module.module_code}</a>
            </div>

            <div className="account ">
              <div className="dropdown">
                <div className="mt-2" data-toggle="dropdown">
                  {/* <img src="../images/grp.png" /> &nbsp; {userName && userName} */}
                  <img className="circle-img" src={reviewData.userdata.photo} /> &nbsp;{' '}
                  <strong>{reviewData.userdata.name}</strong>
                </div>
              </div>
            </div>

            {reviewData.module.prelesssons_wth_assessment.length ? (
              <div className="row">
                <div className="small-heading mt-3 col-sm-12">
                  <h2>Pre Lesson</h2>
                </div>
                {reviewData.module.prelesssons_wth_assessment.map((elm, ind) => {
                  if (elm.assignment_file) {
                    return (
                      <div className="col-sm-12 p-0" key={'pre' + ind}>
                        <AudioPlayer
                          source={elm.assignment_file}
                          playImage={process.env.PUBLIC_URL + '/images/ic14.png'}
                          customImage={elm.is_completed ? process.env.PUBLIC_URL + '/images/check.png' : null}
                          title={elm.title}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            ) : null}

            {reviewData.module.post_lessons_wth_assessment.length ? (
              <div className="row">
                <div className="small-heading mt-3 col-sm-12">
                  <h2>Post Lesson</h2>
                </div>
                {reviewData.module.post_lessons_wth_assessment.map((elm, ind) => {
                  if (elm.assignment_file) {
                    return (
                      <div className="col-sm-12 p-0" key={'post' + ind}>
                        <AudioPlayer
                          source={elm.assignment_file}
                          playImage={process.env.PUBLIC_URL + '/images/ic14.png'}
                          customImage={elm.is_completed ? process.env.PUBLIC_URL + '/images/check.png' : 'noImg'}
                          title={elm.title}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            ) : null}
            <button
              type="submit"
              className="btn"
              onClick={() => {
                gotoModule(backUrl);
              }}
            >
              Done
            </button>
          </>
        ) : null}
      </div>
    </section>
  );
};

export default ReviewRecord;
